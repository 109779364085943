import cn from "classnames";
import React from 'react';
const NumberContent = ({phone, handlePhoneChange, isErrorAuth, setIsErrorAuth, handleClickNext, goToPrevStep, setPhoneFlag, retryPhoneSeconds, setPhoneSeconds, setAuthType}) => {
    const handleModalPhoneChange = (e) => {
        if (isErrorAuth) {
            setIsErrorAuth(false)
        }
        const value = handlePhoneChange(e)
        if (value.length === 13) {
            handleClickNext({phone: value, setIsErrorAuth, goToPrevStep, setPhoneFlag, retryPhoneSeconds, setPhoneSeconds, setAuthType})
        }
    }
    return (
        <div className="p-5 lg:px-10 lg:py-[30px]">
            <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-4">
                Введите<br />номер телефона
            </span>
            <div className="relative">
                <input value={phone} onChange={handleModalPhoneChange} autoFocus={true} inputMode="numeric"
                    className={cn("focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] pl-[44px] lg:pl-[56px] border border-[#CFD0D4] w-[220px] lg:w-[260px]", {
                        'border-[#FE2D2D] focus:border-[#FE2D2D]': isErrorAuth,
                    })}
                    placeholder={'000 000 00 00'}/>
                <span className="block absolute text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] font-medium text-[#0E141A] left-[16px] top-[17px] lg:left-[20px] lg:top-[21px]">
                    +7
                </span>
            </div>
        </div>
    )
}

export {NumberContent};