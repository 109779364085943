import React from 'react';

const defaultYear = `2023-${new Date().getFullYear()}`;

const FooterSection = ({ year = defaultYear }) => {
    return (
        <div className="px-5 lg:px-10 lg:pb-[55px] bg-[#02070D] lg:mb-0">
            <div className="lg:px-10 lg:pt-10 lg:py-5 flex flex-col lg:flex-row justify-between">
                <div>
                    <span className="block text-white font-semibold text-2xl leading-[30px]">
                        Свяжитесь с нами
                    </span>
                    <span className="block text-[#666B71] text-lg leading-[26px] font-normal my-[22px]">
                        Если у вас остались вопросы<br/> или хотите обсудить сотрудничество, <br/>пишите
                    </span>
                    <a href="mailto:hello@priceninja.ru" className="block text-white font-normal text-2xl leading-[30px]">
                        hello@priceninja.ru
                    </a>
                </div>
                <div className="mt-10 lg:mt-0 flex flex-col-reverse lg:flex-col justify-between lg:text-right pb-10 lg:pb-0">
                    <span className="block text-white font-semibold text-2xl leading-[30px] mt-10 lg:mt-0">
                        {year}
                    </span>
                    <div className="flex flex-col">
                        <a href="/" className="block text-[#B6BBC1] text-lg leading-[26px] font-normal underline cursor-pointer hover:duration-500 hover:text-white">Мониторинг цен</a>
                        <a href="/parsing" className="block text-[#B6BBC1] text-lg leading-[26px] font-normal underline cursor-pointer hover:duration-500 hover:text-white mt-2">Парсинг</a>
                        <a href="/pers" className="block text-[#666B71] text-lg leading-[26px] font-normal underline cursor-pointer hover:duration-500 hover:text-white mt-10 lg:mt-2">Политика конфиденциальности</a>
                        <a href="/oferta" className="block text-[#666B71] text-lg leading-[26px] font-normal underline cursor-pointer hover:duration-500 hover:text-white mt-2">Договор оферта</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {FooterSection};
