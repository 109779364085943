import React from 'react';
const LogoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.3775 0V20.1479L32.3214 24.0551V6.68447C32.3214 2.99274 29.3287 0 25.6369 0H24.3775ZM20.4688 7.94433L0.320856 7.94434L0.320855 6.68491C0.320855 2.99318 3.3136 0.000435698 7.00533 0.000435537L24.376 0.000434778L20.4688 7.94433ZM8.26421 32L8.26422 11.8521L0.320314 7.94487L0.320313 25.3155C0.320312 29.0073 3.31305 32 7.00478 32H8.26421ZM32.3209 24.0557H12.1729L8.26571 31.9996H25.6364C29.3281 31.9996 32.3209 29.0068 32.3209 25.3151V24.0557Z"
                  fill="url(#paint0_linear_1012_6407)"/>
            <defs>
                <linearGradient id="paint0_linear_1012_6407" x1="0.320312" y1="0" x2="36.7486" y2="38.1989"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00FFA3"/>
                    <stop offset="1" stopColor="#0145FF"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

const LogoMobileIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M43.446 1.42529C45.0654 -0.517503 48.0657 -0.466667 49.6184 1.52987C50.9988 3.30496 50.6273 5.87173 48.8002 7.18259L44.1278 10.5348L49.6045 10.3062C52.0444 10.2043 53.8792 12.5042 53.2379 14.8605C52.7666 16.5921 51.0948 17.7144 49.3137 17.495L42.8505 16.6986C43.9839 20.0369 44.5703 23.7504 44.5703 27.6633C44.5703 42.2088 31.4018 54.0003 22.2851 54.0003C13.1685 54.0003 0 42.2088 0 27.6633C0 13.1178 8.10369 1.3263 22.2851 1.3263C28.8759 1.3263 34.154 3.87314 37.9159 8.05965L37.9163 8.0592L43.446 1.42529ZM37.862 21.3114C35.4252 18.5731 30.8764 15.8799 22.4102 15.8799C13.9441 15.8799 9.39534 18.5731 6.95848 21.3114C4.1645 24.451 5.23942 28.9956 7.23195 32.696L7.35722 32.9286C9.49572 36.9001 14.4982 38.3113 18.397 36.043C20.8778 34.5996 23.9427 34.5996 26.4235 36.043C30.3223 38.3113 35.3248 36.9001 37.4633 32.9287L37.5885 32.696C37.5885 32.696 40.656 24.451 37.862 21.3114ZM25.476 24.5656C24.7738 24.8426 24.3123 25.5207 24.3123 26.2755C24.3123 27.5027 25.4919 28.3852 26.6691 28.0388L26.7982 28.0008L27.1812 30.4267C27.34 31.4327 28.2071 32.1737 29.2256 32.1737C30.3688 32.1737 31.2955 31.247 31.2955 30.1039V26.9122C31.2955 26.834 31.2916 26.7569 31.2842 26.6809L32.9135 26.2015C33.8197 25.9349 34.4419 25.1033 34.442 24.1587C34.442 22.6565 32.9286 21.6264 31.5311 22.1776L25.476 24.5656ZM20.2588 26.2765C20.2588 25.5216 19.7971 24.8434 19.0947 24.5666L13.038 22.1798C11.6414 21.6294 10.1291 22.6589 10.1291 24.1601C10.1292 25.1043 10.7511 25.9356 11.6569 26.2021L13.286 26.6814C13.2786 26.7571 13.2748 26.8341 13.2748 26.912V30.1037C13.2748 31.2468 14.2015 32.1735 15.3446 32.1735C16.3631 32.1735 17.2302 31.4325 17.3891 30.4265L17.772 28.0013L17.9021 28.0396C19.0793 28.386 20.2588 27.5036 20.2588 26.2765Z" fill="white"/>
        </svg>
    )
}

export {LogoIcon, LogoMobileIcon};