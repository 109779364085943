import cn from "classnames";
import React from 'react';
const MainPhoneInput = ({phone, handlePhoneChange, phoneError}) => {
    return (
        <div className='relative mt-[49px] lg:mt-[60px] '>
            <input onChange={handlePhoneChange} value={phone} inputMode="numeric"
                className={cn("outline-0 cursor-pointer hover:duration-500 hover:bg-[#252B30] bg-[#1B2126] rounded-[1000px] lg:rounded-[100px] pt-[17px] pb-[17px] lg:py-[47px] block text-[27px] lg:text-[95px] leading-[26px] lg:leading-[90px] text-[#FBFBFC] font-medium placeholder:text-[27px] lg:placeholder:text-[95px] placeholder:text-[#3E4349] placeholder:font-medium pr-[18px] lg:pr-[64px] pl-[56px] lg:pl-[192px] w-full lg:w-[963px] lg:min-w-[963px]", {
                    "border-[2px] border-[#FE2D2D]": phoneError
                })}
                placeholder={'000 000 00 00'}/>
            <span className="block absolute text-[27px] lg:text-[95px] leading-[27px] lg:leading-[90px] font-medium text-[#FBFBFC] left-[18px] lg:left-[64px] top-[20px] lg:top-[58px]">
                +7
            </span>
        </div>
    )
}
export {MainPhoneInput}