import React from 'react';
const SecurityIcon = ({ width = "317", height = "292" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 317 292" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="199.328" y="16.5682" width="106.819" height="146.361" transform="rotate(9.04268 199.328 16.5682)" fill="#555F64"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M205.641 4.10622C192.511 2.0166 180.173 10.9666 178.084 24.0967L156.801 157.826C154.711 170.956 163.661 183.294 176.791 185.383L263.715 199.217C276.845 201.307 289.183 192.356 291.273 179.226L312.555 45.4976C314.645 32.3676 305.695 20.0296 292.565 17.94L205.641 4.10622ZM248.993 94.8437C247.195 95.6322 246.006 97.494 246.379 99.4215L255.206 145.087C255.701 147.65 253.499 149.926 250.921 149.516L204.38 142.109C201.802 141.699 200.416 138.852 201.683 136.569L224.249 95.9014C225.201 94.1846 224.649 92.0455 223.184 90.7379C217.243 85.4335 214.041 77.31 215.386 68.8604C217.487 55.6582 229.893 46.6591 243.095 48.7602C256.297 50.8613 265.296 63.267 263.195 76.4691C261.85 84.9178 256.286 91.6452 248.993 94.8437Z" fill="#919BA0"/>
            <rect x="58.0312" y="82.3934" width="106.819" height="146.361" transform="rotate(-13.5876 58.0312 82.3934)" fill="#555F64"/>
            <g filter="url(#filter0_d_536_40196)">
                <path d="M41.3118 97.5179C38.1883 84.5948 46.1325 71.5864 59.0557 68.4629L144.61 47.7847C157.533 44.6612 170.541 52.6054 173.665 65.5286L205.478 197.15C208.601 210.074 200.657 223.082 187.734 226.206L102.179 246.884C89.2563 250.007 76.2479 242.063 73.1244 229.14L41.3118 97.5179Z" fill="#9747FF"/>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M131.224 140.004C141.219 133.606 146.55 121.36 143.605 109.177C139.893 93.8167 124.431 84.3742 109.07 88.0868C93.7099 91.7994 84.2675 107.261 87.98 122.622C90.9247 134.805 101.261 143.265 113.075 144.391L115.407 154.039L115.406 154.039L119.793 172.189L119.793 172.188L126.146 198.472L126.146 198.472L154.967 191.506L150.58 173.356L139.909 175.936L137.943 167.802L148.614 165.223L144.227 147.073L133.556 149.652L131.224 140.004ZM128.665 112.789C130.383 119.897 126.013 127.052 118.905 128.77C111.796 130.488 104.641 126.119 102.923 119.01C101.205 111.902 105.575 104.747 112.683 103.029C119.791 101.311 126.947 105.68 128.665 112.789Z" fill="#FFCB11"/>
            <defs>
                <filter id="filter0_d_536_40196" x="0.632812" y="11.1054" width="245.523" height="280.458" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="20"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.592157 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_536_40196"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_536_40196" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export {SecurityIcon};