import React from 'react';
import {
    CostSection,
    EffectiveParsingSection,
    FooterSection,
    HeadSection, HowWorksSection, ParsingBenefitsSection,
    PricesControlSection,
    QuestionsSection
} from "./sections";
import {
    AuthModal,
    ShowVideoModal,
    ConnectUsModal,
    CallbackModal,
    HiddenMobileButtons,
    ParsingButton
} from "./components";
import {useEffect, useState} from "react";
import {Oferta} from "./components/Oferta";
import cn from "classnames";
import {PersonalData} from "./components/PersonalData";
import {OneMonthFree} from "./components/OneMonthFree";

const App = ({url}) => {
    //const [mode, setMode] = useState(MODES.MONITORING)
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showConnectUsModal, setShowConnectUsModal] = useState(false);
    const [showCallbackModal, setShowCallbackModal] = useState(false);
    const [authStep, setAuthStep] = useState(0);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [emailCode, setEmailCode] = useState('');
    const [name, setName] = useState('');
    const [isErrorAuth, setIsErrorAuth] = useState(false);
    const [retryPhoneSeconds, setPhoneSeconds] = useState(0);
    const [retryPhoneFlag, setPhoneFlag] = useState(false);
    //const [retryEmailSeconds, setEmailSeconds] = useState(0);
    //const [retryEmailFlag, setEmailFlag] = useState(false);
    const [phoneTimerId, setPhoneTimerId] = useState();
    const [showHiddenMobileButtons, setShowHiddenMobileButtons] = useState(false);
    //const [emailTimerId, setEmailTimerId] = useState();

    const scrollHandler = (e) => {
        const scrollTop = e.target.scrollingElement.scrollTop;
        if (url === '/') {
            if (scrollTop >= 650 && scrollTop <= 1850) {
                setShowHiddenMobileButtons(true);
            } else {
                setShowHiddenMobileButtons(false);
            }
        } else {
            if (scrollTop >= 150 && scrollTop <= 3150) {
                setShowHiddenMobileButtons(true);
            } else {
                setShowHiddenMobileButtons(false);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => window.removeEventListener('scroll', scrollHandler)
    }, [])

    function preventDefault(e){
        e.preventDefault();
    }

    useEffect(() => {
        if (showAuthModal || showConnectUsModal || showCallbackModal || showVideo) {
            document.body.addEventListener('touchmove', preventDefault, { passive: false });
        } else {
            document.body.removeEventListener('touchmove', preventDefault);
        }
        return () => document.body.removeEventListener('touchmove', preventDefault);
    }, [showAuthModal, showConnectUsModal, showCallbackModal, showVideo])

    // useEffect(() => {
    //     if (retryEmailSeconds === 0) {
    //         clearInterval(emailTimerId);
    //         setEmailFlag(false);
    //     }
    // }, [retryEmailSeconds, emailTimerId])
    //
    // useEffect(() => {
    //     let timerId;
    //     if (retryEmailFlag) {
    //         setEmailSeconds(59);
    //         timerId = setInterval(() => {
    //             setEmailSeconds((curr) => curr - 1)
    //         }, 1000)
    //         setEmailTimerId(+timerId);
    //     }
    //     return () => clearInterval(timerId)
    // }, [retryEmailFlag]);

    useEffect(() => {
        if (retryPhoneSeconds === 0) {
            clearInterval(phoneTimerId);
            setPhoneFlag(false);
        }
    }, [retryPhoneSeconds, phoneTimerId])

    useEffect(() => {
        let timerId;
        if (retryPhoneFlag) {
            setPhoneSeconds(59);
            timerId = setInterval(() => {
                setPhoneSeconds((curr) => curr - 1)
            }, 1000)
            setPhoneTimerId(+timerId);
        }
        return () => clearInterval(timerId)
    }, [retryPhoneFlag]);
    const handleOpenAuth = () => {
        setAuthStep(0)
        setShowAuthModal(true);
        document.querySelector('body').style.overflow = 'hidden';
        document.querySelector('body').classList.add('disabled-scroll');
        document.querySelector('html').style.overflow = 'hidden';
    }
    const handlePhoneChange = (e) => {
        let content = e.target.value;
        content = Array.from(content).filter((ltr) => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58);
        let [operatorCode, number3, number21, number22] = [
            content.slice(0, 3).join(''),
            content.slice(3, 6).join(''),
            content.slice(6, 8).join(''),
            content.slice(8, 10).join(''),
        ];
        e.target.value = '';
        if (operatorCode.length) e.target.value += `${operatorCode}`;
        if (number3.length) e.target.value += ` ${number3}`;
        if (number21.length) e.target.value += ` ${number21}`;
        if (number22.length) e.target.value += ` ${number22}`;
        setPhone(e.target.value)
        return e.target.value;
    }

  if (url.includes('ecom')) return <OneMonthFree landingNumber={url.split('/')[2]}/>

  return (
      // <BrowserRouter>
        <div className={cn("!bg-black relative overflow-x-hidden", {
            'h-screen flex flex-col': url === '/oferta'
        })}>
            <HeadSection setShowVideo={setShowVideo} handleOpenAuth={handleOpenAuth} setShowConnectUsModal={setShowConnectUsModal} url={url}/>
            {url === '/parsing' &&
                <>
                    <EffectiveParsingSection />
                    <ParsingBenefitsSection setShowConnectUsModal={setShowConnectUsModal}/>
                </>
            }
            {url === '/' &&
                <>
                    <HowWorksSection />
                    <PricesControlSection />
                    <CostSection handleOpenAuth={handleOpenAuth} />
                </>
            }
            {
                url === '/oferta'  &&
                <>
                    <Oferta />
                </>
            }
            {
                url === '/pers'  &&
                <>
                    <PersonalData />
                </>
            }
                {/*<Routes>*/}
                {/*    <Route path={'/'}>*/}
                {/*        <Route path={'parsing/'} element={<div className="relative lg:top-auto">*/}
                {/*            <EffectiveParsingSection />*/}
                {/*            <ParsingBenefitsSection setShowConnectUsModal={setShowConnectUsModal}/>*/}
                {/*        </div>} />*/}
                {/*        <Route index element={<div className="relative -top-[125px] lg:top-auto">*/}
                {/*            <PricesControlSection />*/}
                {/*            <CostSection handleOpenAuth={handleOpenAuth} />*/}
                {/*        </div>}/>*/}
                {/*    </Route>*/}
                {/*</Routes>*/}
            { (url !== '/oferta' && url !== '/pers') &&
                <>
                    {url !=="/parsing" &&
                        <ParsingButton />
                    }
                    <QuestionsSection phone={phone} handlePhoneChange={handlePhoneChange}
                                      handleOpenAuth={handleOpenAuth} setShowCallbackModal={setShowCallbackModal}/>
                </>
            }
            <FooterSection/>
          {showVideo && <ShowVideoModal setShowVideo={setShowVideo}/>}
          <div className="hidden lg:block">
              <ConnectUsModal
                  showConnectUsModal={showConnectUsModal}
                  setShowConnectUsModal={setShowConnectUsModal}
                  name={name}
                  setName={setName}
                  phone={phone}
                  handlePhoneChange={handlePhoneChange}
                  email={email}
                  setEmail={setEmail}
              />
          </div>
          <div className="lg:hidden">
              <ConnectUsModal
                  showConnectUsModal={showConnectUsModal}
                  setShowConnectUsModal={setShowConnectUsModal}
                  name={name}
                  setName={setName}
                  phone={phone}
                  handlePhoneChange={handlePhoneChange}
                  email={email}
                  setEmail={setEmail}
                  isMobile={true}
              />
          </div>
          <AuthModal
              showAuthModal={showAuthModal}
              setShowAuthModal={setShowAuthModal}
              authStep={authStep} setAuthStep={setAuthStep}
              phone={phone}
              handlePhoneChange={handlePhoneChange}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              phoneCode={phoneCode}
              setPhoneCode={setPhoneCode}
              emailCode={emailCode}
              setEmailCode={setEmailCode}
              isErrorAuth={isErrorAuth}
              setIsErrorAuth={setIsErrorAuth}
              retryPhoneSeconds={retryPhoneSeconds}
              //retryEmailSeconds={retryEmailSeconds}
              setPhoneFlag={setPhoneFlag}
              //setEmailFlag={setEmailFlag}
              setPhoneSeconds={setPhoneSeconds}
              //setEmailSeconds={setEmailSeconds}
          />
          <CallbackModal showCallbackModal={showCallbackModal} setShowCallbackModal={setShowCallbackModal}/>
          <HiddenMobileButtons url={url} show={showHiddenMobileButtons} handleOpenAuth={handleOpenAuth} setShowConnectUsModal={setShowConnectUsModal}/>
      </div>
      // </BrowserRouter>
  );
}

export default App;
