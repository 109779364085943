import React from 'react';
const DiagramMobileIcon = () => {
    return (
        <svg width="64" height="61" viewBox="0 0 64 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 53.4077C29.0805 53.4077 25.7453 25.4077 43.6375 25.4077C51.9899 25.4077 56.8211 33.5837 62.5 33.5837" stroke="#9747FF" strokeWidth="2" strokeLinecap="round"/>
            <path d="M62.5 43.4077C34.9195 43.4077 38.2547 25.4077 20.3625 25.4077C12.0101 25.4077 7.1789 32.3197 1.5 32.3197" stroke="#1B76FF" strokeWidth="2" strokeLinecap="round"/>
            <path d="M62.5 51.4077C49.0814 51.4077 47.096 10.4077 31.8973 10.4077C16.6987 10.4077 16.9725 39.8039 1.5 39.8039" stroke="#E0BC3B" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export {DiagramMobileIcon}