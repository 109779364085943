import {HighLights, HighLightsParsing, ParsingData, PrimaryButton} from "../components";
import {
    GalleryIcon,
    HeadScreenshot,
    HeadScreenshotIcon,
    LinkIcon,
    LogInIcon,
    LogoIcon,
    ServerIcon
} from "../screenshots";
import cn from "classnames";
//import {MODES} from "../utils";
import React from 'react';

const HeadSection = (props) => {
    const {/*setShowVideo, */setShowConnectUsModal, url} = props;
    return (
        <div className={cn("bg-black", {
            'relative overflow-hidden': url !== '/oferta'
        })}>
            <div className={"flex justify-between pt-5 mx-5 lg:mx-10 lg:pt-10 items-center"}>
                <div className={"flex items-center"}>
                    <div className="flex items-center">
                        <div className="flex items-center cursor-pointer" onClick={() => {if (window) window.location.pathname = ''}}>
                            <div className={'text-white block'}><LogoIcon /></div>
                            <span className="hidden lg:block ml-3 text-white text-[26px] font-semibold leading-[35px]">
                                {'Прайс Ниндзя'.toUpperCase()}
                            </span>
                        </div>
                        <div className="lg:hidden ml-2 flex items-center">
                            <span className="block text-white text-[14px] font-semibold leading-[17px]">
                                {'Прайс Ниндзя'.toUpperCase()}
                            </span>
                                {/*<select value={url === '/parsing' ? MODES.PARSING : MODES.MONITORING} onChange={(e) => {*/}
                                {/*    if (e.target.value === MODES.PARSING) {*/}
                                {/*        if (window)*/}
                                {/*            window.location.pathname = '/parsing'*/}
                                {/*    } else {*/}
                                {/*        if (window)*/}
                                {/*            window.location.pathname = ''*/}
                                {/*    }*/}
                                {/*}} className="bg-black border-none text-white text-[12px] relative -left-1 opacity-40 outline-none">*/}
                                {/*    <option value={MODES.MONITORING}>Мониторинг</option>*/}
                                {/*    <option value={MODES.PARSING}>Парсинг</option>*/}
                                {/*</select>*/}
                        </div>
                    </div>
                    <div className="hidden lg:flex ml-[38px] h-[45px] border-l border-[rgb(62,67,73,0.40)] pl-[38px] items-center space-x-6">
                        <div className={"h-[45px] flex relative items-center"}>
                            <button className={cn("block text-[#454A50] text-lg leading-[22px] font-semibold cursor-pointer", {
                                "!text-white after:content-[''] after:bg-white after:h-0.5 after:w-[51px] after:absolute after:bottom-0 after:left-[36px]": url === '/'
                            })} onClick={() => {
                                if (window)
                                    window.location.pathname = ''
                            }}>
                                Мониторинг
                            </button>
                        </div>
                        <div className={"h-[45px] flex relative items-center"}>
                            <button className={cn("block text-[#454A50] text-lg leading-[22px] font-semibold cursor-pointer", {
                                "!text-white after:content-[''] after:bg-white after:h-0.5 after:w-[51px] after:absolute after:bottom-0 after:left-[18px]": url === '/parsing'
                            })} onClick={() => {
                                if (window)
                                    window.location.pathname = '/parsing'
                            }}>
                                Парсинг
                            </button>
                        </div>
                    </div>
                    {/*<div className="lg:hidden border-l border-[rgb(62,67,73,0.40)] h-[27px] flex items-center ml-[16px] pl-[16px]">*/}
                    {/*    <select value={mode} onChange={(e) => setMode(e.target.value)} className="bg-black border-none text-white w-[71px] text-[12px]">*/}
                    {/*        <option value={MODES.MONITORING}>Мониторинг цен</option>*/}
                    {/*        <option value={MODES.PARSING}>Парсинг</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                </div>
                {(url !== '/oferta' && url !== '/pers') && <>
                    {(url !== '/parsing') ?
                    <div className="flex space-x-2 lg:space-x-3">
                        <PrimaryButton className={'px-5 py-2.5 lg:px-8 lg:py-4 hidden lg:flex items-center justify-center bg-[rgb(26,41,55,0.65)]'} onClick={(e) => {
                            e.preventDefault();
                            document.getElementById('tariffs').scrollIntoView({
                                behavior: 'smooth',
                                block: 'start'
                            });
                        }} style={{backdropFilter: 'blur(7.5px)'}}>
                        <span className="text-white font-semibold text-xs leading-[14px] lg:text-xl lg:leading-6">
                            Тарифы
                        </span>
                        </PrimaryButton>
                        <PrimaryButton className={'px-5 py-2.5 lg:px-8 lg:py-4 hidden lg:flex items-center justify-center'} onClick={() => window.location.href = 'https://go.priceninja.ru/products'}>
                            <span className="hidden lg:block text-white font-semibold text-xs leading-[14px] lg:text-xl lg:leading-6">
                                Войти
                            </span>
                            <div className="lg:hidden">
                                <LogInIcon />
                            </div>
                        </PrimaryButton>
                        <div className="flex lg:hidden items-center" onClick={() => {
                            if (window)
                                window.location.pathname = '/parsing'
                        }}>
                            <span className="block text-white text-[14px] leading-[17px] mr-1 font-semibold">Парсинг</span>
                            <LinkIcon />
                        </div>
                    </div> :
                        <div>
                            <PrimaryButton className={'px-5 py-2.5 lg:px-8 lg:py-4 hidden lg:flex items-center justify-center'} onClick={() => {
                                setShowConnectUsModal(true);
                                document.querySelector('body').style.overflow = 'hidden';
                                document.querySelector('body').classList.add('disabled-scroll');
                                document.querySelector('html').style.overflow = 'hidden';
                            }}>
                            <span className="block text-white font-semibold text-xs leading-[14px] lg:text-xl lg:leading-6">
                                Связаться
                            </span>
                            </PrimaryButton>
                            <div className="flex lg:hidden items-center" onClick={() => {
                                if (window)
                                    window.location.pathname = ''
                            }}>
                                <span className="block text-white text-[14px] leading-[17px] mr-1 font-semibold">Мониторинг</span>
                                <LinkIcon />
                            </div>
                        </div>
                    }
                </>}
            </div>
            {(url !== '/oferta' && url !== '/pers') && <>
                {url !== '/parsing' ?
                    <>
                        <HighLights />
                        <div className="mx-5 lg:mx-0 mt-10 lg:mt-[50px] flex flex-col lg:items-center z-20">
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        Прайс Ниндзя
                    </span>
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        мощный <br className="lg:hidden"/>сервис
                    </span>
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        мониторинга <br className="lg:hidden"/>цен
                    </span>
                            <div className={"mt-6 mb-10 lg:my-10 flex flex-col lg:items-center"}>
                        <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-2xl lg:leading-[34px] mb-3">
                            Помогает зарабатывать <br className="lg:hidden"/>больше на площадках
                        </span>
                                <div className={'flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0'}>
                                    <div className={'flex items-center'}>
                                        <div className={"rounded-lg bg-[#B531A4] border border-black pt-1 pb-0.5 px-3 font-bold"}>
                                    <span className="block text-white text-[14px] leading-[19px] lg:text-lg lg:leading-[34px]">
                                        WILDBERRIES
                                    </span>
                                        </div>
                                        <span className="block text-[14px] leading-[19px] lg:text-lg lg:leading-[34px] text-[#FBFBFC] font-medium ml-0.5">
                                    ,
                                </span>
                                        <div className={"rounded-lg bg-[#1C77FF] border border-black pt-1 pb-0.5 px-3 font-bold ml-1"}>
                                    <span className="block text-white text-[14px] leading-[19px] lg:text-lg lg:leading-[34px]">
                                        OZON
                                    </span>
                                        </div>
                                        <span className="block text-[14px] leading-[19px] lg:text-lg lg:leading-[34px] text-[#FBFBFC] font-medium ml-0.5">
                                    ,
                                </span>
                                    </div>
                                    <div className={'flex items-center'}>
                                        <div className={"rounded-lg bg-[#EFCE56] border border-black pt-1 pb-0.5 px-3 font-bold lg:ml-1"}>
                                            <span className="block text-[#1E1E1E] text-[14px] leading-[19px] lg:text-lg lg:leading-[34px]">
                                                YANDEX MARKET
                                            </span>
                                        </div>
                                        <span className="block text-[14px] leading-[19px] lg:text-lg lg:leading-[34px] text-[#FBFBFC] font-medium ml-0.5">
                                            ,
                                        </span>
                                    </div>
                                    <div className={'flex items-center'}>
                                        <div className={"rounded-lg bg-[#613CAA] border border-black pt-1 pb-0.5 px-3 font-bold lg:ml-1"}>
                                            <span className="block text-[#FFF] text-[14px] leading-[19px] lg:text-lg lg:leading-[34px]">
                                                МЕГАМАРКЕТ
                                            </span>
                                        </div>
                                        <span className="block text-[14px] leading-[19px] lg:text-lg lg:leading-[34px] text-[#FBFBFC] font-medium ml-0.5">
                                            .
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center flex-col lg:flex-row">
                                <PrimaryButton className={'w-full py-5 lg:w-fit lg:px-12 lg:py-6 flex items-center justify-center'} onClick={() => window.location.href = 'https://go.priceninja.ru/products'}>
                                    <span className="text-white font-semibold text-[18px] leading-[21px] lg:text-xl lg:leading-6">
                                        Попробовать бесплатно
                                    </span>
                                </PrimaryButton>
                                <PrimaryButton className={'w-full py-5 lg:w-fit lg:px-12 lg:py-6 flex lg:hidden items-center justify-center !bg-[#1A2836] mt-3 !hover:bg-[#1A2836]'} onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById('tariffs').scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start'
                                    });
                                }}>
                                    <span className="text-white font-semibold text-[18px] leading-[21px] lg:text-xl lg:leading-6">
                                        Тарифы
                                    </span>
                                </PrimaryButton>
                            </div>
                        </div>
                        <div className="flex items-center mt-10 lg:mt-[109px] relative">
                            <div className="mx-auto z-20 overflow-hidden lg:relative w-full lg:w-auto">
                                <div className="hidden lg:block">
                                    <HeadScreenshot />
                                </div>
                                <div className="lg:hidden relative">
                                    <HeadScreenshotIcon width={"100%"} height={"auto"} />
                                </div>
                                {/*<div className="absolute w-full lg:w-fit px-5 lg:px-0 bottom-5 lg:left-[211px] lg:bottom-10 h-fit">
                                    <PrimaryButton className={"w-full py-5 lg:w-fit lg:py-[50px] lg:px-[120px] bg-[rgb(26,41,55,0.65)] hover:bg-[rgba(26,41,55,0.65)]/70"}
                                                   style={{backdropFilter: 'blur(7.5px)'}}
                                                   noUseHover
                                                   //hoverStyle={{background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(26, 41, 55, 0.65)'}}
                                                   onClick={() => {
                                                       setShowVideo(true);
                                                       document.querySelector('body').style.overflow = 'hidden';
                                                       document.querySelector('body').classList.add('disabled-scroll');
                                                       document.querySelector('html').style.overflow = 'hidden';
                                                   }}>
                                <span className="block text-[#FBFBFC] text-[18px] leading-[21px] lg:text-[60px] lg:leading-[90px] font-medium lg:-tracking-[3px]">
                                    Видео о сервисе
                                </span>
                                    </PrimaryButton>
                                </div>*/}
                            </div>
                        </div>
                    </> :
                    <>
                        <HighLightsParsing />
                        <div className="mx-5 lg:mx-0 mt-10 lg:mt-[124px] flex flex-col lg:items-center z-20">
                            {/*<span className="block lg:hidden text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">*/}
                            {/*    PriceNinja*/}
                            {/*</span>*/}
                            <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px] lg:text-center">
                                Парсим данные <br className={"lg:hidden"}/>и сайты <br className={"hidden lg:block"}/>для <br className={"lg:hidden"}/>вашего бизнеса
                            </span>
                            <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
                                любой сложности
                            </span>
                            <div className={"mt-6 mb-10 lg:mb-0 lg:mt-10 hidden lg:flex flex-col lg:items-center"}>
                                <div className="flex items-center">
                                    <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-[28px] lg:leading-[40px] mr-3">
                                        Парсим более
                                    </span>
                                    <GalleryIcon />
                                    <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-[28px] lg:leading-[40px] ml-2.5">
                                         128 000 000 товаров в сутки
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-[28px] lg:leading-[40px] mr-3">
                                        на серверах мощностью более
                                    </span>
                                    <ServerIcon />
                                    <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-[28px] lg:leading-[40px] ml-2.5">
                                         384 ядер
                                    </span>
                                </div>
                            </div>
                            <div className={"block lg:hidden mt-10 space-y-3"}>
                                <span className={"block text-[16px] font-medium leading-[24px] text-[#FBFBFC]"}>Парсим более</span>
                                <div className="p-4 rounded-[12px] border border-[rgb(251,251,252,0.12)] flex items-center space-x-4 w-fit">
                                    <GalleryIcon />
                                    <span className={"block text-[#FBFBFC] text-[28px] leading-[36px] font-medium"}>128 000 000</span>
                                </div>
                                <span className={"block text-[16px] font-medium leading-[24px] text-[#FBFBFC]"}>товаров в сутки на серверах<br/>мощностью более</span>
                                <div className="p-4 rounded-[12px] border border-[rgb(251,251,252,0.12)] flex items-center space-x-4 w-fit">
                                    <ServerIcon />
                                    <span className={"block text-[#FBFBFC] text-[28px] leading-[36px] font-medium"}>384 ядер</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center mt-[100px] lg:mt-[119px] relative">
                            <ParsingData />
                        </div>
                    </>
                }
            </>}

        </div>
    )
}

export { HeadSection }