import React, {useRef, useState} from 'react';
const Oferta = () => {
    const ref = useRef();
    const [height, setHeight] = useState("8060px");
    // const onLoad = () => {
    //     setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    // };
    return (
        <div className="flex flex-col grow">
            <iframe className="doc pt-10" src="./oferta.pdf" ref={ref} height={height} scrolling="no"
                    /*onLoad={onLoad}*/></iframe>
        </div>
    )
}
export {Oferta};