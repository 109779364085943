import React from 'react';
import {PrimaryButton} from "./PrimaryButton";
const HiddenMobileButtons = ({show, url, handleOpenAuth, setShowConnectUsModal}) => {
    if (!show) {
        return null
    }

    return (
        <div className="fixed lg:hidden bottom-0 flex justify-center z-30 w-full pb-4">
            <div className="absolute bottom-0 w-full h-[97px]" style={{background: 'linear-gradient(180deg, rgba(2, 7, 13, 0.00) 0%, #02070D 233.05%)'}}/>
            {url === '/' ?
                <div className="flex space-x-2 justify-center z-40">
                    <PrimaryButton className={'px-4 py-[5px] items-center justify-center bg-[#2F353A]'} onClick={(e) => {
                        e.preventDefault();
                        document.getElementById('tariffs').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    }} style={{backdropFilter: 'blur(7.5px)'}}>
                    <span className="block text-white font-semibold text-[12px] leading-[15px] -top-[1px] relative">
                        Тарифы
                    </span>
                    </PrimaryButton>
                    <PrimaryButton className={'px-4 py-[5px] items-center justify-center'} onClick={() => handleOpenAuth()}>
                        <span className="blcok text-white font-semibold text-[12px] leading-[15px] -top-[1px] relative">
                            Попробовать
                        </span>
                    </PrimaryButton>
                </div>:
                <div className="flex justify-center z-40">
                    <PrimaryButton className={'px-4 py-[5px] items-center justify-center'} onClick={() => {
                        setShowConnectUsModal(true);
                        document.querySelector('body').style.overflow = 'hidden';
                        document.querySelector('body').classList.add('disabled-scroll');
                        document.querySelector('html').style.overflow = 'hidden';
                    }}>
                        <span className="block text-white font-semibold text-[12px] leading-[15px] -top-[1px] relative">
                            Связаться
                        </span>
                    </PrimaryButton>
                </div>
            }
        </div>
    )
}

export {HiddenMobileButtons};