import React from 'react';
const ComfortViewIcon = () => {
    return (
        <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_665_23798)">
                <rect x="1" y="1.40796" width="18" height="18" rx="3.61719" fill="#E0BC3B" fillOpacity="0.3" stroke="#E0BC3B" strokeWidth="2"/>
                <path d="M25 5.02515C25 3.02743 26.6195 1.40796 28.6172 1.40796H55.3142C57.3119 1.40796 58.9314 3.02743 58.9314 5.02515V15.7908C58.9314 17.7885 57.3119 19.408 55.3142 19.408H28.6172C26.6195 19.408 25 17.7885 25 15.7908V5.02515Z" fill="#9747FF" fillOpacity="0.3" stroke="#9747FF" strokeWidth="2"/>
                <rect x="1" y="41.408" width="58.2346" height="18" rx="3.61719" fill="white" fillOpacity="0.3" stroke="white" strokeWidth="2"/>
                <path d="M1 29.025C1 27.0273 2.61947 25.4078 4.61719 25.4078H35.3828C37.3805 25.4078 39 27.0273 39 29.025V31.7906C39 33.7884 37.3805 35.4078 35.3828 35.4078H4.61719C2.61947 35.4078 1 33.7884 1 31.7906V29.025Z" fill="white" fillOpacity="0.3" stroke="white" strokeWidth="2"/>
                <path d="M45 29.0251C45 27.0274 46.6195 25.408 48.6172 25.408H55.3828C57.3805 25.408 59 27.0274 59 29.0251V31.7908C59 33.7885 57.3805 35.408 55.3828 35.408H48.6172C46.6195 35.408 45 33.7885 45 31.7908V29.0251Z" fill="white" fillOpacity="0.3" stroke="white" strokeWidth="2"/>
            </g>
            <defs>
                <clipPath id="clip0_665_23798">
                    <rect width="60" height="60" fill="white" transform="translate(0 0.407959)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export {ComfortViewIcon}