import React, { useState } from "react";
import cn from "classnames";
import { FooterSection } from "../../sections";
import { LogoIcon } from "../../screenshots";
import { PrimaryButton } from "../PrimaryButton";
import { CallbackModal } from "../CallbackModal";

const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

const validateEmail = (email) => {
  return EMAIL_REGEXP.test(email);
};

const OneMonthFree = ({ landingNumber }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    email: "",
    phone: "",
    comment: "",
    company: "",
  });
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showCallbackModal, setShowCallbackModal] = useState(false);
  const [agreePrivacyPolicy, setAgreePrivacyPolicy] = useState(true);

  const handleChangePrivacyPolicy = (e) => {
    setAgreePrivacyPolicy(e.target.checked);
  };

  const handlePhoneChange = (e) => {
    setPhoneError(false);

    let content = e.target.value;
    content = Array.from(content).filter(
      (ltr) => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58
    );
    let [operatorCode, number3, number21, number22] = [
      content.slice(0, 3).join(""),
      content.slice(3, 6).join(""),
      content.slice(6, 8).join(""),
      content.slice(8, 10).join(""),
    ];
    let formattedPhone = "";
    if (operatorCode.length) formattedPhone += `${operatorCode}`;
    if (number3.length) formattedPhone += ` ${number3}`;
    if (number21.length) formattedPhone += ` ${number21}`;
    if (number22.length) formattedPhone += ` ${number22}`;

    e.target.value = formattedPhone;

    setFormData({
      ...formData,
      phone: formattedPhone,
    });
  };

  const handleChangeField = (field) => (e) => {
    if (field === "email") {
      setEmailError(false);
    }

    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const validateFields = () => {
    let isValid = true;
    if (formData.phone.length !== 13) {
      setPhoneError(true);
      isValid = false;
    }
    if (!validateEmail(formData.email)) {
      setEmailError(true);
      isValid = false;
    }
    if (!agreePrivacyPolicy) {
      isValid = false;
    }
    return isValid;
  };

  const handleSendForm = async () => {
    if (!validateFields()) return;

    const { name, email, company, comment } = formData;
    const phone = "+7" + formData.phone.split(" ").join("");
    const description = landingNumber + ", " + company + ", " + comment;

    const respCode = await fetch(
      `https://back.priceninja.ru/api/submissions?site=priceninja&type=call_back&name=${name}&email=${email}&phone=${phone}&description=${description}`,
      {
        method: "POST",
      }
    );
    if (respCode.status === 200) {
      setFormData({
        name: "",
        description: "",
        email: "",
        phone: "",
        comment: "",
        company: "",
      });
      setShowCallbackModal(true);
    } else {
      setPhoneError(true);
    }
  };

  return (
    <>
      <div className={"!bg-black relative overflow-x-hidden"}>
        <div className={"bg-black"}>
          <div
            className={
              "flex justify-between pt-5 mx-5 lg:mx-10 lg:pt-10 items-center"
            }
          >
            <div className={"flex items-center"}>
              <div className="flex items-center">
                <div className="flex items-center cursor-pointer">
                  <div className={"text-white block"}>
                    <LogoIcon />
                  </div>
                  <span className="hidden lg:block ml-3 text-white text-[26px] font-semibold leading-[35px]">
                    {"Прайс Ниндзя".toUpperCase()}
                  </span>
                </div>
                <div className="lg:hidden ml-2 flex items-center">
                  <span className="block text-white text-[14px] font-semibold leading-[17px]">
                    {"Прайс Ниндзя".toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"OneMonthFree-content "}>
          <div className="mx-5 lg:mx-0 mt-10 lg:mt-[50px] flex flex-col lg:items-center z-20">
            <span className="block text-[36px] font-bold leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] -tracking-[2px] lg:-tracking-[3px]">
              Забери свой
            </span>
            <span className="block text-[36px] font-bold leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] -tracking-[2px] lg:-tracking-[3px]">
              1 месяц в подарок
            </span>
          </div>

          <div
            className={`mt-6 mb-10 mx-[20px] lg:my-10 lg:mr-auto lg:ml-auto flex max-w-[484px] flex-col justify-center lg:items-center`}
          >
            <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-2xl lg:leading-[34px] mb-3">
              Для участников EcomExpo 5 и 6 июня
            </span>
            <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-2xl lg:leading-[34px] mb-3">
              Месяц сервиса в подарок
            </span>
            <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[24px] lg:text-2xl lg:leading-[34px] mb-3">
              Заполни форму
            </span>
          </div>

          <div
            className={
              "oneMonthFreeForm lg:pb-[48px] pb-[20px] lg:px-[48px] px-[20px] mx-[20px] mt-6 mb-10 lg:mr-auto lg:ml-auto flex flex-col max-w-[712px] rounded-42px bg-[#1B2126]"
            }
          >
            <div
              className={
                "oneMonthFreeForm-row flex flex-col lg:flex-row pt-[15px] pb-[15px] lg:pt-[36px] lg:pb-[36px] border-b-1 justify-between lg:items-center"
              }
            >
              <span
                className={
                  "text-[14px] lg:text-[24px] text-[#FBFBFC] leading-[24px]"
                }
              >
                Представьтесь
              </span>
              <input
                value={formData.name}
                onChange={handleChangeField("name")}
                className={
                  "text-[#FBFBFC] text-[16px] lg:text-[34px] lg:text-right outline-0 cursor-pointer hover:duration-500 hover:bg-[#1B2126] bg-[#1B2126]"
                }
                type="text"
                placeholder="Ваше имя"
              />
            </div>

            <div
              className={
                "oneMonthFreeForm-row flex flex-col lg:flex-row pt-[15px] pb-[15px] lg:pt-[36px] lg:pb-[36px] border-b-1 justify-between lg:items-center"
              }
            >
              <span
                className={
                  "text-[14px] lg:text-[24px] text-[#FBFBFC] leading-[24px]"
                }
              >
                Ваша компания
              </span>
              <input
                value={formData.company}
                onChange={handleChangeField("company")}
                className={
                  "text-[#FBFBFC] text-[16px] lg:text-[34px] lg:text-right leading-[34px] outline-0 cursor-pointer hover:duration-500 hover:bg-[#1B2126] bg-[#1B2126]"
                }
                type="text"
                placeholder="Название"
              />
            </div>

            <div
              className={
                "oneMonthFreeForm-row flex flex-col lg:flex-row pt-[15px] pb-[15px] lg:pt-[36px] lg:pb-[36px] border-b-1 justify-between lg:items-center"
              }
            >
              <span
                className={cn(
                  "text-[14px] lg:text-[24px] text-[#FBFBFC] leading-[24px]",
                  { "text-[#FE2D2D]": emailError }
                )}
              >
                Email
              </span>
              <input
                value={formData.email}
                onChange={handleChangeField("email")}
                className={
                  "text-[#FBFBFC] text-[16px] lg:text-[34px] lg:text-right leading-[34px] outline-0 cursor-pointer hover:duration-500 hover:bg-[#1B2126] bg-[#1B2126]"
                }
                type="email"
                placeholder="mail@yandex.ru"
              />
            </div>

            <div
              className={
                "oneMonthFreeForm-row flex flex-col lg:flex-row pt-[15px] pb-[15px] lg:pt-[36px] lg:pb-[36px] border-b-1 justify-between lg:items-center"
              }
            >
              <span
                className={cn(
                  "text-[14px] lg:text-[24px] text-[#FBFBFC] leading-[24px]",
                  { "text-[#FE2D2D]": phoneError }
                )}
              >
                Телефон
              </span>

              <div>
                <span className="ml-auto mr-[10px] text-[#FBFBFC] text-[16px] lg:text-[34px] leading-[34px]">
                  +7
                </span>
                <input
                  style={{ width: 250 }}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  className={
                    "text-[#FBFBFC] text-[16px] lg:text-[34px] lg:text-right leading-[34px] outline-0 cursor-pointer hover:duration-500 hover:bg-[#1B2126] bg-[#1B2126]"
                  }
                  inputMode="numeric"
                  placeholder="000 000 00 00"
                />
              </div>
            </div>

            <div
              className={
                "oneMonthFreeForm-row flex flex-col lg:flex-row pt-[15px] pb-[15px] lg:pt-[36px] lg:pb-[36px] border-b-1 justify-between lg:items-center"
              }
            >
              <span
                className={
                  "text-[14px] lg:text-[24px] text-[#FBFBFC] leading-[24px]"
                }
              >
                Комментарий
              </span>
              <input
                value={formData.comment}
                onChange={handleChangeField("comment")}
                className={
                  "text-[#FBFBFC] text-[16px] lg:text-[34px] lg:text-right leading-[34px] outline-0 cursor-pointer hover:duration-500 hover:bg-[#1B2126] bg-[#1B2126]"
                }
                type="text"
                placeholder="Комментарий"
              />
            </div>

            <div className="flex pt-[36px] pb-[36px] gap-[10px] lg:items-center">
              <input
                checked={agreePrivacyPolicy}
                onChange={handleChangePrivacyPolicy}
                type="checkbox"
                id="policy"
                className="mr-2"
              />
              <label
                htmlFor="policy"
                className="flex gap-[5px] text-[9px] lg:text-[24px] text-[#FBFBFC] font-medium leading-[24px]"
              >
                <span>Принимаю</span>{" "}
                <a href="/pers" className="form-link">
                  политику конфиденциальности
                </a>
              </label>
            </div>

            <PrimaryButton
              onClick={handleSendForm}
              className={
                "px-5 w-full text-[36px] pt-[30px] pb-[30px] lg:flex items-center justify-center"
              }
            >
              <span className="block text-[18px] lg:text-[36px] text-white font-semibold leading-[28px] lg:leading-6">
                Получить месяц в подарок
              </span>
            </PrimaryButton>
          </div>
        </div>

        <div className="oneMonthFree-footer">
          <FooterSection year="2024" />
        </div>
      </div>
      <CallbackModal
        showCallbackModal={showCallbackModal}
        setShowCallbackModal={setShowCallbackModal}
      />
    </>
  );
};
export { OneMonthFree };
