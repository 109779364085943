import { CrossIcon, RightArrow } from "../../screenshots";
import { PrimaryButton } from "../PrimaryButton";
import cn from "classnames";
import { useEffect, useState } from "react";
import React from "react";
const ConnectUsModal = ({
  showConnectUsModal,
  setShowConnectUsModal,
  name,
  setName,
  phone,
  handlePhoneChange,
  email,
  setEmail,
  isMobile,
}) => {
  const [message, setMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [bodyHeight, setBodyHeight] = useState("h-fit");
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    if (showConnectUsModal) {
      if (window && window.innerHeight < (!isMobile ? 820 : 675)) {
        setBodyHeight(window && window.innerHeight - 214);
      } else {
        setBodyHeight("h-fit");
      }
    }
  }, [setBodyHeight, showConnectUsModal, isMobile]);
  if (!showConnectUsModal) {
    return null;
  }

  const clearData = () => {
    setMessage("");
  };

  const handleClickNext = () => {
    if (phone.length === 13) {
      fetch(
        `https://back.priceninja.ru/api/submissions?type=parsing_request&phone=%2B7${phone
          .split(" ")
          .join("")}&site=${
          window && window.location.pathname === "/parsing"
            ? "parsing"
            : "priceninja"
        }&name=${name}&email=${email}&description=${message}`,
        {
          method: "POST",
        }
      );
      setShowSuccess(true);
      clearData();
    } else {
      setPhoneError(true);
    }
  };
  const handleClose = () => {
    setShowConnectUsModal(false);
    setShowSuccess(false);
    clearData();
    document.querySelector("body").style.overflow = "";
    document.querySelector("body").classList.remove("disabled-scroll");
    document.querySelector("html").style.overflow = "";
  };
  return (
    <div
      className={
        "flex flex-col fixed bg-[rgb(15,22,29,0.90)] w-screen h-screen left-0 z-40 items-center justify-end lg:justify-center overflow-hidden top-0"
      }
      /*style={{top: window && window.scrollY}}*/ onClick={handleClose}
    >
      <div className="fixed bottom-0 lg:relative w-full lg:w-[586px]">
        {showSuccess ? (
          <>
            <div
              className="w-full lg:w-[586px] border-b"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={
                  "flex justify-between px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center rounded-t-[12px] "
                }
              >
                <span className="block text-[#0E141A] text-[18px] leading-[27px] lg:text-2xl lg:leading-[34px] font-semibold ">
                  Спасибо за ваш запрос!
                </span>
                <button onClick={handleClose}>
                  <CrossIcon />
                </button>
              </div>
            </div>
            <div
              className="w-full lg:w-[586px] h-fit bg-white p-5 lg:px-10 lg:py-[30px]"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="block text-[#0E141A] text-[16px] font-medium leading-[22px]">
                Свяжемся с вами <br className={"lg:hidden"} />в течение рабочего
                дня
              </span>
            </div>
            <div
              className={
                "flex px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center lg:rounded-b-[12px] w-full lg:w-[586px]"
              }
              onClick={(e) => e.stopPropagation()}
            >
              <PrimaryButton
                className="py-5 pl-8 pr-6 flex grow lg:grow-0 justify-center"
                onClick={handleClose}
              >
                <span className="block text-white text-base font-semibold leading-[20px] mr-2.5">
                  Отлично
                </span>
              </PrimaryButton>
            </div>
          </>
        ) : (
          <div className="max-h-[100vh]">
            <div
              className="w-full lg:w-[586px] border-b"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={
                  "flex justify-between px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center rounded-t-[12px] "
                }
              >
                <span className="block text-[#0E141A] text-[18px] leading-[27px] lg:text-2xl lg:leading-[34px] font-semibold ">
                  Расскажите о вашей задаче
                </span>
                <button onClick={handleClose}>
                  <CrossIcon />
                </button>
              </div>
            </div>
            <div
              className={`w-full lg:w-[586px] h-fit bg-white p-5 lg:px-10 lg:py-[30px] overflow-y-auto`}
              onClick={(e) => e.stopPropagation()}
              style={{
                height: Number.isInteger(bodyHeight)
                  ? bodyHeight
                  : "fit-content",
              }}
            >
              <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-2">
                Представьтесь
              </span>
              <div className="relative">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus={true}
                  className={cn(
                    "hover:border-[#7F8084]/60 focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] border border-[#CFD0D4] w-full lg:w-[300px]"
                  )}
                  placeholder={"Ваше имя"}
                />
              </div>
              <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-2 mt-4 lg:mt-6">
                Введите номер телефона<span className="text-[#F33E14]">*</span>
              </span>
              <div className="relative">
                <input
                  value={phone}
                  onChange={(e) => {
                    handlePhoneChange(e);
                    setPhoneError(false);
                  }}
                  inputMode="numeric"
                  className={cn(
                    "hover:border-[#7F8084]/60 focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] pl-[44px] lg:pl-[56px] border border-[#CFD0D4] w-[220px] lg:w-[260px]",
                    {
                      "border-[#FE2D2D] focus:border-[#FE2D2D]": phoneError,
                    }
                  )}
                  placeholder={"000 000 00 00"}
                />
                <span className="block absolute text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] font-medium text-[#0E141A] left-[16px] top-[17px] lg:left-[20px] lg:top-[20px]">
                  +7
                </span>
              </div>
              <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-2 mt-4 lg:mt-6">
                Адрес эл почты
              </span>
              <div className="relative">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={cn(
                    "hover:border-[#7F8084]/60 focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] border border-[#CFD0D4] w-full lg:w-[400px]"
                  )}
                  placeholder={"E-mail"}
                />
              </div>
              <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-2 mt-4 lg:mt-6">
                Опишите вашу задачу
              </span>
              <div className="relative">
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className={cn(
                    "h-[140px] hover:border-[#7F8084]/60 focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] border border-[#CFD0D4] w-full"
                  )}
                  placeholder={"В общем и вкратце"}
                />
              </div>
            </div>
            <div
              className={
                "flex px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center lg:rounded-b-[12px] w-full lg:w-[586px] border-t"
              }
              onClick={(e) => e.stopPropagation()}
            >
              <PrimaryButton
                className="py-5 pl-8 pr-6 flex grow lg:grow-0 justify-center"
                onClick={handleClickNext}
              >
                <span className="block text-white text-base font-semibold leading-[20px] mr-2.5">
                  Свяжитесь со мной
                </span>
                <RightArrow />
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export { ConnectUsModal };
