import { RefreshIcon } from "../../screenshots";
import cn from "classnames";
import React from "react";
const PhoneConfirmationContent = ({
  phone,
  phoneCode,
  setPhoneCode,
  isErrorAuth,
  setIsErrorAuth,
  goToPrevStep,
  handleClickNext,
  retryPhoneSeconds,
  setPhoneFlag,
  authType,
  setAuthStep,
}) => {
  const handleConfirmChange = (e) => {
    if (isErrorAuth) {
      setIsErrorAuth(false);
    }
    let content = e.target.value;
    content = Array.from(content).filter(
      (ltr) => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58
    );
    let [first, second] = [
      content.slice(0, 2).join(""),
      content.slice(2, 4).join(""),
    ];
    e.target.value = "";
    if (first.length) e.target.value += `${first}`;
    if (second.length) e.target.value += ` ${second}`;
    setPhoneCode(e.target.value);
    if (e.target.value.length === 5) {
      handleClickNext({
        phone,
        phoneCode: e.target.value,
        authType,
        setAuthStep,
      });
    }
  };
  // const checkPhone = useCallback(async () => {
  //         const resp = await fetch(`https://back.priceninja.ru/api/auth/check-phone-number?phone_number=%2B7${phone.split(' ').join('')}`, {
  //             method: 'POST'
  //         })
  //         if (resp.status !== 200) {
  //             setIsErrorAuth(true);
  //             goToPrevStep();
  //         }
  // }, [phone, setIsErrorAuth, goToPrevStep])
  // useEffect(() => {
  //     if(retryPhoneSeconds === 0) {
  //         checkPhone()
  //     }
  // }, [checkPhone, retryPhoneSeconds]);
  return (
    <div className="p-5 lg:px-10 lg:py-[30px]">
      <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-4">
        {`На номер +7 ${phone} поступит `}
        <br className="hidden lg:block" />
        звонок. Введите последние 4 цифры
      </span>
      <div className="relative">
        <input
          value={phoneCode}
          onChange={handleConfirmChange}
          autoFocus={true}
          inputMode="numeric"
          className={cn(
            "focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] border border-[#CFD0D4] w-[94px] lg:min-w-[114px] lg:w-[114px]",
            {
              "border-[#FE2D2D] focus:border-[#FE2D2D]": isErrorAuth,
            }
          )}
          placeholder={"00 00"}
        />
      </div>
      {retryPhoneSeconds === 0 ? (
        <button
          className="flex bg-none border-none items-center mt-5"
          onClick={async () => {
            const resp = await fetch(
              `https://back.priceninja.ru/api/auth/check-phone-number?phone_number=%2B7${phone
                .split(" ")
                .join("")}`,
              {
                method: "POST",
              }
            );
            if (resp.status === 200) {
              setPhoneFlag(true);
            } else {
              setIsErrorAuth(true);
              goToPrevStep();
            }
          }}
        >
          <RefreshIcon />
          <span className="block text-[#0A25FF] text-base leading-[22px] font-medium ml-2 mt-[1px] cursor-pointer">
            Повторите звонок
          </span>
        </button>
      ) : (
        <div className="mt-5">
          <div className={"flex space-x-2 text-[#8E9399]"}>
            <RefreshIcon color={"#8E9399"} />
            <span className="block text-base leading-[22px] font-medium ml-2 mt-[1px] cursor-pointer">
              {`Повторить звонок можно через ${retryPhoneSeconds} сек`}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export { PhoneConfirmationContent };
