import {CrossIcon, RightArrow} from "../../screenshots";
import {getAuthModalData} from "./utils";
import {PrimaryButton} from "../PrimaryButton";
import {useState} from "react";
import React from 'react';
const AuthModal = ({
                       setShowAuthModal, showAuthModal, authStep, name, setName, setAuthStep, handlePhoneChange, phone, email, setPhoneSeconds, /*retryEmailSeconds,*/
                       setEmail, phoneCode, setPhoneCode, emailCode, setEmailCode, isErrorAuth, setIsErrorAuth, retryPhoneSeconds, setPhoneFlag/*, setEmailFlag, retryEmailSeconds*/
}) => {
    const [authType, setAuthType] = useState('registration');
    if (!showAuthModal) {
        return null;
    }
    const modalData = getAuthModalData(authStep);

    const handleClose = () => {
        setShowAuthModal(false);
        setAuthStep(() => 0);
        setPhoneCode('');
        setEmail('');
        setName('');
        setEmailCode('');
        setIsErrorAuth(false)
        document.querySelector('body').style.overflow = '';
        document.querySelector('body').classList.remove('disabled-scroll');
        document.querySelector('html').style.overflow = '';
    }

    const handleClickNext = async ({phone: outerPhone, phoneCode: outerPhoneCode, emailCode: outerEmailCode}) => {
        const isValid = await modalData.checkFunc({
            phone: outerPhone || phone,
            email,
            phoneCode: outerPhoneCode || phoneCode,
            emailCode: outerEmailCode || emailCode,
            name,
            setIsErrorAuth,
            goToPrevStep: () => setAuthStep((step) => step - 1),
            setPhoneFlag,
            retryPhoneSeconds,
            setPhoneSeconds,
            // setEmailFlag,
            // retryEmailSeconds,
            setShowAuthModal,
            handleClose,
            authType,
            setAuthType,
            setAuthStep,
        })
        if (isValid) {
            setIsErrorAuth(false);
            setAuthStep((prev) => {
                if (authType !== 'registration' && prev === 1) {
                    return prev
                }
                    return prev + 1
            });
        } else {
            setIsErrorAuth(true);
        }
    }
    const Content = modalData.content;
    return (
        <div className={"flex flex-col fixed bg-[rgb(15,22,29,0.90)] w-screen h-screen left-0 z-40 items-center justify-end lg:justify-center overflow-hidden top-0"} /*style={{top: window && window.scrollY}}*/
             onClick={handleClose}
             onTouchStart={(e) => {
                 e.stopPropagation();
             }}>
            <div className="fixed bottom-0 lg:relative w-full lg:w-[450px]">
                <div className="w-full lg:w-[450px] border-b" onClick={(e) => e.stopPropagation()}>
                    <div className={"flex justify-between px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center rounded-t-[12px] "}>
                        <span className="block text-[#0E141A] text-[18px] leading-[27px] lg:text-2xl lg:leading-[34px] font-semibold ">
                            {modalData.header}
                        </span>
                        <button onClick={handleClose}>
                            <CrossIcon />
                        </button>
                    </div>
                </div>
                <div className="w-full lg:w-[450px] h-fit lg:h-[294px] bg-white" onClick={(e) => e.stopPropagation()}>
                    <Content
                        phone={phone}
                        handlePhoneChange={handlePhoneChange}
                        email={email}
                        setEmail={setEmail}
                        phoneCode={phoneCode}
                        setPhoneCode={setPhoneCode}
                        emailCode={emailCode}
                        setEmailCode={setEmailCode}
                        name={name}
                        setName={setName}
                        isErrorAuth={isErrorAuth}
                        setIsErrorAuth={setIsErrorAuth}
                        goToPrevStep={() => setAuthStep(authStep - 1)}
                        handleClickNext={handleClickNext}
                        retryPhoneSeconds={retryPhoneSeconds}
                        setPhoneSeconds={setPhoneSeconds}
                        setPhoneFlag={setPhoneFlag}
                        setAuthType={setAuthType}
                        authType={authType}
                        setAuthStep={setAuthStep}
                        // retryEmailSeconds={retryEmailSeconds}
                        // setEmailFlag={setEmailFlag}
                    />
                </div>
                <div className={"flex px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center lg:rounded-b-[12px] w-full lg:w-[450px]"} onClick={(e) => e.stopPropagation()}>
                    {
                        !(authStep === 0 || authStep === 5) && <PrimaryButton className="flex items-center justify-center w-[60px] h-[60px] !bg-[#0A25FF14]/[8%] mr-4 hover:duration-500 !hover:bg-[#0A25FF14]/[8%]" onClick={() => {
                            setAuthStep(authStep - 1);
                            setIsErrorAuth(false);
                        }}>
                            <div className="rotate-180">
                                <RightArrow color={"#0A25FF"}/>
                            </div>
                        </PrimaryButton>
                    }
                    <PrimaryButton className="py-5 pl-8 pr-6 flex grow lg:grow-0 justify-center" onClick={handleClickNext}>
                        <span className="block text-white text-base font-semibold leading-[20px] mr-2.5">
                            {authStep === 5 ? 'Хорошо' : 'Продолжить'}
                        </span>
                        {authStep !== 5 && <RightArrow /> }
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}
export {AuthModal};