const MOCK_DATA = [
    {
        "site": "askona.ru",
        "name": "Кровать Una, цвет Шафран",
        "price": 110088,
        "old_price": 125100,
        "reviews": 10
    },
    {
        "site": "askona.ru",
        "name": "Простыня на резинке Askona kids Аква",
        "price": 990,
        "old_price": 1414,
        "reviews": 15,
        "position": 240
    },
    {
        "site": "askona.ru",
        "name": "Скатерть Itaca Plata",
        "price": 1690,
        "old_price": 1690,
        "reviews": 2
    },
    {
        "site": "askona.ru",
        "name": "Детский ортопедический матрас  Little Angel",
        "price": 11790,
        "old_price": 15720,
        "reviews": 29,
        "position": 16
    },
    {
        "site": "askona.ru",
        "name": "Футболка женская Stay Perfect, цвет жемчужно-серый",
        "price": 2792,
        "old_price": 3490,
        "reviews": 27,
        "position": 144
    },
    {
        "site": "askona.ru",
        "name": "Постельное белье Askona Home Stitch, цвет: Бордовый",
        "price": 4190,
        "old_price": 5557,
        "reviews": 29,
        "position": 116
    },
    {
        "site": "askona.ru",
        "name": "Чехол Terry",
        "price": 6644,
        "old_price": 8859,
        "reviews": 8,
        "position": 174
    },
    {
        "site": "askona.ru",
        "name": "Подвес SUSPENTIONI 4484/1 античная бронза/зеленый",
        "price": 4200,
        "old_price": 4200,
        "reviews": 20,
        "position": 79
    },
    {
        "site": "askona.ru",
        "name": "Лонгслив женский Stay Balanced, цвет нежно-голубой",
        "price": 3192,
        "old_price": 3990,
        "reviews": 19,
        "position": 133
    },
    {
        "site": "askona.ru",
        "name": "Футболка женская Stay Cool, цвет нежно-голубой",
        "price": 2392,
        "old_price": 2990,
        "reviews": 13,
        "position": 243
    },
    {
        "site": "askona.ru",
        "name": "Постельное белье Askona Home Scandi клетка, черно-белый",
        "price": 3141,
        "old_price": 3990,
        "reviews": 5,
        "position": 162
    },
    {
        "site": "askona.ru",
        "name": "Скатерть Нoliday decoration 149*250",
        "price": 2490,
        "old_price": 2490,
        "reviews": 21
    },
    {
        "site": "askona.ru",
        "name": "Кровать с подъемным механизмом Bet, цвет Дуб сонома",
        "price": 31801,
        "old_price": 41300,
        "reviews": 30,
        "position": 32
    },
    {
        "site": "askona.ru",
        "name": "Сковорода Trumpf 24 см",
        "price": 2093,
        "old_price": 2990,
        "reviews": 7
    },
    {
        "site": "askona.ru",
        "name": "Скатерть Pink magic 150 розовый",
        "price": 2890,
        "old_price": 2890,
        "reviews": 3,
        "position": 75
    },
    {
        "site": "askona.ru",
        "name": "Постельное белье Askona Home Scandi точки, черно-белый",
        "price": 3141,
        "old_price": 3490,
        "reviews": 27
    },
    {
        "site": "askona.ru",
        "name": "Доска сервировочная Marm, цвет: Белый",
        "price": 2890,
        "old_price": 2890,
        "reviews": 30,
        "position": 249
    },
    {
        "site": "askona.ru",
        "name": "Нож универсальный Stone 20",
        "price": 290,
        "old_price": 290,
        "reviews": 5,
        "position": 61
    },
    {
        "site": "askona.ru",
        "name": "Салатник Gallery 15 см, цвет зеленый",
        "price": 990,
        "old_price": 990,
        "reviews": 17,
        "position": 18
    },
    {
        "site": "askona.ru",
        "name": "Одеяло Lite Basic",
        "price": 4195,
        "old_price": 6120,
        "reviews": 13
    },
    {
        "site": "askona.ru",
        "name": "Блюдо сервировочное Greendrop 24 см",
        "price": 1190,
        "old_price": 1190,
        "reviews": 27
    },
    {
        "site": "askona.ru",
        "name": "Полотенце Askona Home Legen 40*75, цвет бежевый",
        "price": 650,
        "old_price": 650,
        "reviews": 4,
        "position": 128
    },
    {
        "site": "askona.ru",
        "name": "Угловой диван Frost new, правый",
        "price": 86990,
        "old_price": 141300,
        "reviews": 5,
        "position": 135
    },
    {
        "site": "askona.ru",
        "name": "Блюдо сервировочное на подставке в подарочной упаковке Полярный экспресс 25*21",
        "price": 3590,
        "old_price": 3590,
        "reviews": 26,
        "position": 137
    },
    {
        "site": "askona.ru",
        "name": "Доска разделочная Capri new 32*20 белый",
        "price": 990,
        "old_price": 990,
        "reviews": 27
    },
    {
        "site": "askona.ru",
        "name": "Кровать с подъемным механизмом Bet, цвет Дуб сонома",
        "price": 36036,
        "old_price": 46800,
        "reviews": 5,
        "position": 251
    },
    {
        "site": "askona.ru",
        "name": "Одеяло Infinity",
        "price": 13990,
        "old_price": 18557,
        "reviews": 12,
        "position": 134
    },
    {
        "site": "askona.ru",
        "name": "Электрический чайник Zigmund & Shtain KE-910",
        "price": 2490,
        "old_price": 2843,
        "reviews": 15,
        "position": 18
    },
    {
        "site": "askona.ru",
        "name": "Пробка для бутылки Цветочек",
        "price": 190,
        "old_price": 190,
        "reviews": 32,
        "position": 80
    },
    {
        "site": "askona.ru",
        "name": "Футболка мужская Stay Cool, цвет еловый",
        "price": 2792,
        "old_price": 3490,
        "reviews": 8,
        "position": 95
    },
    {
        "site": "askona.ru",
        "name": "Поднос Старинный прованс 33 пудровый",
        "price": 550,
        "old_price": 550,
        "reviews": 22,
        "position": 140
    },
    {
        "site": "askona.ru",
        "name": "Халат женский Ecosoft, размер S, кремовый",
        "price": 6490,
        "old_price": 6490,
        "reviews": 23
    },
    {
        "site": "askona.ru",
        "name": "Кровать Ohta, Лак прозрачный",
        "price": 14824,
        "old_price": 21177,
        "reviews": 29,
        "position": 210
    },
    {
        "site": "askona.ru",
        "name": "Майка женская Stay Cool, цвет нежно-голубой",
        "price": 1992,
        "old_price": 2490,
        "reviews": 32,
        "position": 38
    },
    {
        "site": "askona.ru",
        "name": "Кровать Gamma Light venge",
        "price": 33152,
        "old_price": 41700,
        "reviews": 6,
        "position": 251
    },
    {
        "site": "askona.ru",
        "name": "Персонник  Экокожа 45*37 черный",
        "price": 450,
        "old_price": 450,
        "reviews": 18
    },
    {
        "site": "askona.ru",
        "name": "Кровать Selma, цвет Ясень Шимо светлый",
        "price": 15184,
        "old_price": 20800,
        "reviews": 17,
        "position": 164
    },
    {
        "site": "askona.ru",
        "name": "Тарелка десертная  Bilbao 21 см",
        "price": 590,
        "old_price": 590,
        "reviews": 7,
        "position": 122
    },
    {
        "site": "askona.ru",
        "name": "Лонгслив женский Stay Balanced, цвет нежно-голубой",
        "price": 3192,
        "old_price": 3990,
        "reviews": 2,
        "position": 54
    },
    {
        "site": "askona.ru",
        "name": "Сито Estilo",
        "price": 190,
        "old_price": 190,
        "reviews": 28
    },
    {
        "site": "askona.ru",
        "name": "Торшер Сеул 35*129 см цвет черный",
        "price": 28990,
        "old_price": 28990,
        "reviews": 27,
        "position": 21
    },
    {
        "site": "askona.ru",
        "name": "Рукавица термостойкая Basic Grey",
        "price": 590,
        "old_price": 590,
        "reviews": 9,
        "position": 60
    },
    {
        "site": "askona.ru",
        "name": "Светильник подвесной Bicones P359-PL-01-W",
        "price": 6990,
        "old_price": 6990,
        "reviews": 26,
        "position": 171
    },
    {
        "site": "askona.ru",
        "name": "Щипцы кулинарные Marmiton, цвет: Сливочный",
        "price": 690,
        "old_price": 690,
        "reviews": 27,
        "position": 8
    },
    {
        "site": "askona.ru",
        "name": "Чехол Dri-Tec",
        "price": 13611,
        "old_price": 20700,
        "reviews": 10,
        "position": 86
    },
    {
        "site": "askona.ru",
        "name": "Тарелка сервировочная Капучино 21 см",
        "price": 590,
        "old_price": 590,
        "reviews": 20
    },
    {
        "site": "askona.ru",
        "name": "Светильник подвесной Aura MOD016PL-06BS",
        "price": 43990,
        "old_price": 43990,
        "reviews": 22,
        "position": 41
    },
    {
        "site": "askona.ru",
        "name": "Кровать Moris, цвет Венге мали",
        "price": 28640,
        "old_price": 35800,
        "reviews": 31
    },
    {
        "site": "askona.ru",
        "name": "Простыня на резинке Askona Home Scandi, цвет лососевый",
        "price": 2990,
        "old_price": 4271,
        "reviews": 11,
        "position": 12
    },
    {
        "site": "askona.ru",
        "name": "Тарелка закусочная  Comet 21,5 см, цвет морская волна",
        "price": 730,
        "old_price": 730,
        "reviews": 18,
        "position": 72
    },
    {
        "site": "askona.ru",
        "name": "Кровать с подъемным механизмом Bliss ice, цвет Дуб Бардолино",
        "price": 39837,
        "old_price": 56910,
        "reviews": 13,
        "position": 25
    },
    {
        "site": "askona.ru",
        "name": "Салатник Soul Kitchen 24 см цвет белый",
        "price": 2990,
        "old_price": 2990,
        "reviews": 22,
        "position": 239
    },
    {
        "site": "askona.ru",
        "name": "Анатомический матрас Flash",
        "price": 18791,
        "old_price": 43700,
        "reviews": 10
    },
    {
        "site": "askona.ru",
        "name": "Дорожка сервировочная Рождество 45*150 красный",
        "price": 1290,
        "old_price": 1290,
        "reviews": 19,
        "position": 46
    },
    {
        "site": "askona.ru",
        "name": "Американский матрас Serta Astoria",
        "price": 40782,
        "old_price": 97100,
        "reviews": 27,
        "position": 178
    },
    {
        "site": "askona.ru",
        "name": "Стул пластиковый Ron зеленый",
        "price": 3991,
        "old_price": 7843,
        "reviews": 3
    },
    {
        "site": "askona.ru",
        "name": "Набор полотенец 3 шт. Venus цвет кремовый",
        "price": 4392,
        "old_price": 5490,
        "reviews": 9,
        "position": 221
    },
    {
        "site": "askona.ru",
        "name": "Простыня на резинке Solid Tencel, цвет Макиато",
        "price": 5561,
        "old_price": 9732,
        "reviews": 13
    },
    {
        "site": "askona.ru",
        "name": "Простыня на резинке Askona kids Аква",
        "price": 1790,
        "old_price": 2557,
        "reviews": 12,
        "position": 16
    },
    {
        "site": "askona.ru",
        "name": "Чехол Base",
        "price": 2402,
        "old_price": 3135,
        "reviews": 5,
        "position": 171
    },
    {
        "site": "askona.ru",
        "name": "Кровать Gamma Venge, размер 90х200",
        "price": 33152,
        "old_price": 41700,
        "reviews": 2
    },
    {
        "site": "askona.ru",
        "name": "Простыня на резинке Solid Tencel, цвет Васильковый",
        "price": 4561,
        "old_price": 7983,
        "reviews": 8,
        "position": 112
    },
    {
        "site": "askona.ru",
        "name": "Кровать с подъемным механизмом Bliss ice, цвет Белый премиум",
        "price": 37940,
        "old_price": 54200,
        "reviews": 1
    },
    {
        "site": "askona.ru",
        "name": "Шкаф однодверный с ящиком и зеркалом Kasli, цвет Графит",
        "price": 16775,
        "old_price": 30500,
        "reviews": 5,
        "position": 136
    },
    {
        "site": "askona.ru",
        "name": "Кровать Magica",
        "price": 44500,
        "old_price": 50000,
        "reviews": 11,
        "position": 115
    },
    {
        "site": "askona.ru",
        "name": "Постельное белье Askona Home Mist",
        "price": 3990,
        "old_price": 5700,
        "reviews": 3,
        "position": 231
    },
    {
        "site": "askona.ru",
        "name": "Стул полубарный ЛУГАНО стул полубарный, дуб тон терра",
        "price": 27449,
        "old_price": 40986,
        "reviews": 7,
        "position": 175
    },
    {
        "site": "askona.ru",
        "name": "Кровать с подъемным механизмом Gretta, цвет Ясень светлый",
        "price": 34595,
        "old_price": 40700,
        "reviews": 19,
        "position": 230
    },
    {
        "site": "askona.ru",
        "name": "Салатник  Meadow 0,8 л цвет мятный",
        "price": 790,
        "old_price": 790,
        "reviews": 30,
        "position": 86
    },
    {
        "site": "askona.ru",
        "name": "Кровать с подъемным механизмом Glory ice, цвет Белый премиум",
        "price": 24056,
        "old_price": 38800,
        "reviews": 11,
        "position": 32
    },
    {
        "site": "divan.ru",
        "name": "Монблан-3 Fancy Santiago",
        "price": 63490,
        "old_price": 86990,
        "reviews": 25,
        "position": 152
    },
    {
        "site": "divan.ru",
        "name": "Бонс-Т Cozy Ivory",
        "price": 52990,
        "old_price": 81990,
        "reviews": 28
    },
    {
        "site": "divan.ru",
        "name": "Теджонс Deco Grafit",
        "price": 28290,
        "old_price": 39990,
        "reviews": 1
    },
    {
        "site": "divan.ru",
        "name": "Сальвадор-3-130-210 Wood",
        "price": 42990,
        "old_price": 56990,
        "reviews": 6,
        "position": 178
    },
    {
        "site": "divan.ru",
        "name": "Стелла-1-50-220 Белый",
        "price": 13990,
        "old_price": 17990,
        "reviews": 24,
        "position": 256
    },
    {
        "site": "divan.ru",
        "name": "Риббл-10 Bucle Grafit",
        "price": 75990,
        "reviews": 29
    },
    {
        "site": "divan.ru",
        "name": "Тайрон-1 Harmony",
        "price": 33290,
        "old_price": 47990,
        "reviews": 16,
        "position": 72
    },
    {
        "site": "divan.ru",
        "name": "Шелтон-1 Grafit",
        "price": 20990,
        "old_price": 29990,
        "reviews": 11,
        "position": 216
    },
    {
        "site": "divan.ru",
        "name": "Монблан-4 Fancy Mentol",
        "price": 43690,
        "old_price": 59990,
        "reviews": 32
    },
    {
        "site": "divan.ru",
        "name": "Монблан-2 Fancy Aquamarine",
        "price": 11490,
        "old_price": 15990,
        "reviews": 17,
        "position": 132
    },
    {
        "site": "divan.ru",
        "name": "Пенни-3 Plywood White",
        "price": 19990,
        "old_price": 28990,
        "reviews": 31,
        "position": 156
    },
    {
        "site": "divan.ru",
        "name": "Эдли Beige",
        "price": 29690,
        "reviews": 23,
        "position": 56
    },
    {
        "site": "divan.ru",
        "name": "Ситено Barhat Gold",
        "price": 5990,
        "old_price": 7990,
        "reviews": 19,
        "position": 88
    },
    {
        "site": "divan.ru",
        "name": "Стелла-2-90-200 Дуб Сонома",
        "price": 18990,
        "old_price": 23990,
        "reviews": 24,
        "position": 199
    },
    {
        "site": "divan.ru",
        "name": "Маркфул Velvet Coral",
        "price": 76990,
        "old_price": 118990,
        "reviews": 8,
        "position": 87
    },
    {
        "site": "divan.ru",
        "name": "Кейсес Velvet Grey",
        "price": 20990,
        "old_price": 23990,
        "reviews": 19,
        "position": 48
    },
    {
        "site": "divan.ru",
        "name": "Льери-1 Soft Ocean",
        "price": 55990,
        "old_price": 62990,
        "reviews": 6
    },
    {
        "site": "divan.ru",
        "name": "Лонди Cozy Dove",
        "price": 49990,
        "old_price": 57990,
        "reviews": 12,
        "position": 100
    },
    {
        "site": "divan.ru",
        "name": "Виллоу 160 Stone",
        "price": 61990,
        "old_price": 75990,
        "reviews": 7
    },
    {
        "site": "divan.ru",
        "name": "Фрэнсис Pink",
        "price": 6990,
        "old_price": 7990,
        "reviews": 15,
        "position": 135
    },
    {
        "site": "divan.ru",
        "name": "Бонс-Т Happy Light",
        "price": 39990,
        "old_price": 65990,
        "reviews": 11,
        "position": 149
    },
    {
        "site": "divan.ru",
        "name": "Стелла-3.1-130-200 Белый",
        "price": 26990,
        "old_price": 33990,
        "reviews": 33,
        "position": 229
    },
    {
        "site": "divan.ru",
        "name": "Тайрон-1 Chevron",
        "price": 16690,
        "old_price": 23990,
        "reviews": 4,
        "position": 7
    },
    {
        "site": "divan.ru",
        "name": "Лайтси 140 Bucle White",
        "price": 36990,
        "old_price": 49990,
        "reviews": 22,
        "position": 116
    },
    {
        "site": "divan.ru",
        "name": "Динс Мини Velvet Grey",
        "price": 46990,
        "old_price": 66990,
        "reviews": 13,
        "position": 108
    },
    {
        "site": "divan.ru",
        "name": "Слипсон Happy Yellow",
        "price": 59990,
        "old_price": 85990,
        "reviews": 18,
        "position": 153
    },
    {
        "site": "divan.ru",
        "name": "Биани-2 Velvet Gold",
        "price": 94990,
        "old_price": 126990,
        "reviews": 5,
        "position": 225
    },
    {
        "site": "divan.ru",
        "name": "Бруно-54 Дуб Сонома",
        "price": 24990,
        "old_price": 31990,
        "reviews": 5
    },
    {
        "site": "divan.ru",
        "name": "Онте Bucle Mint",
        "price": 82990,
        "old_price": 96990,
        "reviews": 4,
        "position": 213
    },
    {
        "site": "divan.ru",
        "name": "Лион Stripes Velvet Cream",
        "price": 7690,
        "old_price": 8490,
        "reviews": 29,
        "position": 5
    },
    {
        "site": "divan.ru",
        "name": "Фиори 160 Bucle White",
        "price": 55990,
        "old_price": 82990,
        "reviews": 26,
        "position": 144
    },
    {
        "site": "divan.ru",
        "name": "Клаум Linia Lavender",
        "price": 4990,
        "old_price": 5990,
        "reviews": 13
    },
    {
        "site": "divan.ru",
        "name": "Лаберт Velvet Terra",
        "price": 99990,
        "old_price": 142990,
        "reviews": 28,
        "position": 82
    },
    {
        "site": "divan.ru",
        "name": "Лилле Velvet Grey",
        "price": 46990,
        "old_price": 54990,
        "reviews": 24,
        "position": 9
    },
    {
        "site": "divan.ru",
        "name": "Ольен Velvet Beige",
        "price": 62990,
        "old_price": 83990,
        "reviews": 12,
        "position": 21
    },
    {
        "site": "divan.ru",
        "name": "Теджонс-1 Diamond Santiago",
        "price": 22990,
        "old_price": 32990,
        "reviews": 8,
        "position": 55
    },
    {
        "site": "divan.ru",
        "name": "Льери Barhat Blue",
        "price": 79990,
        "old_price": 113990,
        "reviews": 13
    },
    {
        "site": "divan.ru",
        "name": "Монблан-2 Leaf Mentol",
        "price": 11490,
        "old_price": 15990,
        "reviews": 24
    },
    {
        "site": "divan.ru",
        "name": "Тайрон-3 Chevron",
        "price": 51990,
        "old_price": 74990,
        "reviews": 18
    },
    {
        "site": "divan.ru",
        "name": "Монблан-2 Fancy Mentol",
        "price": 31290,
        "old_price": 42990,
        "reviews": 2,
        "position": 126
    },
    {
        "site": "divan.ru",
        "name": "Кейн-12 Latte",
        "price": 16690,
        "old_price": 22990,
        "reviews": 12
    },
    {
        "site": "divan.ru",
        "name": "Биаско-Mint 230x250",
        "price": 11490,
        "old_price": 14990,
        "reviews": 8,
        "position": 45
    },
    {
        "site": "divan.ru",
        "name": "Теджонс Deco Milk",
        "price": 19990,
        "old_price": 22990,
        "reviews": 26,
        "position": 185
    },
    {
        "site": "divan.ru",
        "name": "Раверти-Floor 120 Velvet Smoke",
        "price": 33990,
        "old_price": 49990,
        "reviews": 17,
        "position": 63
    },
    {
        "site": "divan.ru",
        "name": "Бонент Velvet Grey",
        "price": 66990,
        "old_price": 88990,
        "reviews": 2,
        "position": 57
    },
    {
        "site": "divan.ru",
        "name": "Тайрон-2 Bohemian",
        "price": 38490,
        "old_price": 54990,
        "reviews": 4,
        "position": 85
    },
    {
        "site": "divan.ru",
        "name": "Теджонс Deco Aquamarine",
        "price": 19990,
        "old_price": 22990,
        "reviews": 1
    },
    {
        "site": "divan.ru",
        "name": "Нордик-1 Wood Grey",
        "price": 14690,
        "old_price": 20990,
        "reviews": 15,
        "position": 157
    },
    {
        "site": "divan.ru",
        "name": "Ансил Happy Light",
        "price": 18990,
        "old_price": 20990,
        "reviews": 5,
        "position": 78
    },
    {
        "site": "divan.ru",
        "name": "Лесли Plywood Mustard",
        "price": 25990,
        "old_price": 35990,
        "reviews": 5,
        "position": 109
    },
    {
        "site": "divan.ru",
        "name": "Кейн-12 Sky",
        "price": 16690,
        "old_price": 22990,
        "reviews": 25,
        "position": 149
    },
    {
        "site": "divan.ru",
        "name": "Лонди Mima Pink",
        "price": 66990,
        "old_price": 72990,
        "reviews": 6,
        "position": 168
    },
    {
        "site": "divan.ru",
        "name": "Дисент-1 Soft Toffe",
        "price": 89990,
        "old_price": 105990,
        "reviews": 1,
        "position": 110
    },
    {
        "site": "divan.ru",
        "name": "Оксфорд Velvet Yellow",
        "price": 16990,
        "reviews": 18
    },
    {
        "site": "divan.ru",
        "name": "Монблан-1 Lunge Aquamarine",
        "price": 10490,
        "old_price": 14990,
        "reviews": 32,
        "position": 190
    },
    {
        "site": "divan.ru",
        "name": "Монмарт-3 Sand",
        "price": 34490,
        "old_price": 41990,
        "reviews": 10,
        "position": 170
    },
    {
        "site": "divan.ru",
        "name": "Пронто-4-170-210 Велюр",
        "price": 67990,
        "old_price": 86990,
        "reviews": 10,
        "position": 26
    },
    {
        "site": "divan.ru",
        "name": "Тренто White",
        "price": 2590,
        "old_price": 2890,
        "reviews": 33
    },
    {
        "site": "divan.ru",
        "name": "Интори Soft Terra",
        "price": 29990,
        "old_price": 34990,
        "reviews": 25,
        "position": 113
    },
    {
        "site": "divan.ru",
        "name": "Тебар-4-200-230 Белый без зеркал",
        "price": 48990,
        "reviews": 23
    },
    {
        "site": "divan.ru",
        "name": "Нордик-5 Light",
        "price": 14690,
        "old_price": 19990,
        "reviews": 4,
        "position": 160
    },
    {
        "site": "divan.ru",
        "name": "Монблан-2 Lunge Santiago",
        "price": 31290,
        "old_price": 42990,
        "reviews": 9,
        "position": 225
    },
    {
        "site": "divan.ru",
        "name": "Фрэнсис White",
        "price": 7990,
        "old_price": 8990,
        "reviews": 31,
        "position": 212
    },
    {
        "site": "divan.ru",
        "name": "Нолан-W 2 Textile Beige",
        "price": 198440,
        "old_price": 222940,
        "reviews": 6,
        "position": 183
    },
    {
        "site": "divan.ru",
        "name": "Види Textile Ocean",
        "price": 22990,
        "old_price": 38090,
        "reviews": 32
    },
    {
        "site": "divan.ru",
        "name": "Монмарт-1 Sand",
        "price": 14990,
        "old_price": 20990,
        "reviews": 6
    },
    {
        "site": "divan.ru",
        "name": "Мист Mint",
        "price": 6690,
        "old_price": 7490,
        "reviews": 29,
        "position": 211
    },
    {
        "site": "divan.ru",
        "name": "Монблан-3 Lunge Lavanda",
        "price": 63490,
        "old_price": 86990,
        "reviews": 21
    },
    {
        "site": "divan.ru",
        "name": "Теджонс-2 Deco Grafit",
        "price": 47990,
        "old_price": 69990,
        "reviews": 12,
        "position": 200
    },
    {
        "site": "pm.ru",
        "name": "Письменный стол Оксфорд Дуб Санремо / Белый",
        "price": 15080,
        "old_price": 15080,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Шкаф-витрина Прато Ясень Шимо светлый / Жемчуг Без подсветки",
        "price": 33390,
        "old_price": 33390,
        "reviews": 5,
        "position": 55
    },
    {
        "site": "pm.ru",
        "name": "Компактный стол-книжка тумба с ящиками Тумбо Венге",
        "price": 7690,
        "old_price": 7690,
        "reviews": 4
    },
    {
        "site": "pm.ru",
        "name": "Набор стальных мисок Микс 4 предмета",
        "price": 1290,
        "old_price": 1290,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Настенная вешалка Куба Дуб Сонома",
        "price": 1390,
        "old_price": 1390,
        "reviews": 5,
        "position": 119
    },
    {
        "site": "pm.ru",
        "name": "Настенная полка Денвер Риббек серый",
        "price": 2590,
        "old_price": 2590,
        "reviews": 5,
        "position": 2
    },
    {
        "site": "pm.ru",
        "name": "Комод Квинт Дуб Сонома / Антрацит",
        "price": 8290,
        "old_price": 8290,
        "reviews": 2
    },
    {
        "site": "pm.ru",
        "name": "Распашной шкаф Кубо Дуб Артизан / Черный матовый / Холодный серый С блоком питания",
        "price": 19790,
        "old_price": 19790,
        "reviews": 5,
        "position": 68
    },
    {
        "site": "pm.ru",
        "name": "Бутылочница Сити Белый / Дуб Галифакс",
        "price": 6090,
        "old_price": 6090,
        "reviews": 5,
        "position": 246
    },
    {
        "site": "pm.ru",
        "name": "Беспружинный матрас Status Х 18 160х200 см",
        "price": 20575,
        "old_price": 20575,
        "reviews": 5,
        "position": 234
    },
    {
        "site": "pm.ru",
        "name": "Обувница Челси О Дуб Сонома",
        "price": 3690,
        "old_price": 3690,
        "reviews": 5,
        "position": 43
    },
    {
        "site": "pm.ru",
        "name": "Прикроватная тумбочка Софи Крем, велюр",
        "price": 5690,
        "old_price": 5690,
        "reviews": 5,
        "position": 113
    },
    {
        "site": "pm.ru",
        "name": "Комод Кубо Дуб Артизан / Черный матовый / Холодный серый",
        "price": 15390,
        "old_price": 15390,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Кухонный стол Прага 1 Слоновая кость",
        "price": 22490,
        "old_price": 22490,
        "reviews": 5,
        "position": 120
    },
    {
        "site": "pm.ru",
        "name": "Распашной шкаф Модерн Персидский жемчуг / Ирландский ликер",
        "price": 13999,
        "old_price": 13999,
        "reviews": 5,
        "position": 202
    },
    {
        "site": "pm.ru",
        "name": "Ящик для белья Аккордеон Белый 800 х 2000 мм",
        "price": 2490,
        "old_price": 2490,
        "reviews": 4,
        "position": 31
    },
    {
        "site": "pm.ru",
        "name": "Письменный стол Трио Белый",
        "price": 9490,
        "old_price": 9490,
        "reviews": 4
    },
    {
        "site": "pm.ru",
        "name": "Настенная вешалка Сахара Дуб Ватан",
        "price": 8790,
        "old_price": 8790,
        "reviews": 5,
        "position": 256
    },
    {
        "site": "pm.ru",
        "name": "Настенная вешалка Квадро Дуб Делано / Белый",
        "price": 9390,
        "old_price": 9390,
        "reviews": 5,
        "position": 71
    },
    {
        "site": "pm.ru",
        "name": "Комод Прага Дуб Делано / Дуб Венге",
        "price": 11990,
        "old_price": 11990,
        "reviews": 4
    },
    {
        "site": "pm.ru",
        "name": "Врезная мойка GR-4202 Бежевый",
        "price": 6190,
        "old_price": 6190,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "ТВ-тумба Куба Дуб Сонома / Белый глянец",
        "price": 13200,
        "old_price": 13200,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Беспружинный матрас Optima 18 relax 120х200 см",
        "price": 15990,
        "old_price": 15990,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Настенная полка Анталия Белый Софт",
        "price": 1510,
        "old_price": 1510,
        "reviews": 5,
        "position": 222
    },
    {
        "site": "pm.ru",
        "name": "Комод Токио Белый текстурный",
        "price": 7290,
        "old_price": 7290,
        "reviews": 4,
        "position": 248
    },
    {
        "site": "pm.ru",
        "name": "Столик для ноутбука Кроха М Венге",
        "price": 4890,
        "old_price": 4890,
        "reviews": 5,
        "position": 133
    },
    {
        "site": "pm.ru",
        "name": "Детский комод-трансформер Френч-3 Белый / Дуб Пастельный (Teddy)",
        "price": 6990,
        "old_price": 6990,
        "reviews": 5,
        "position": 62
    },
    {
        "site": "pm.ru",
        "name": "Журнальный столик Акцент Венге",
        "price": 5190,
        "old_price": 5190,
        "reviews": 5,
        "position": 34
    },
    {
        "site": "pm.ru",
        "name": "Распашной шкаф Остин Белый / Дуб Крафт золотой",
        "price": 24790,
        "old_price": 24790,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Распашной шкаф Санти Антрацит / Дуб Крафт серый",
        "price": 10399,
        "old_price": 10399,
        "reviews": 1,
        "position": 141
    },
    {
        "site": "pm.ru",
        "name": "Шкаф-витрина Сиена Бодега белый, патина золото Со стеклополками",
        "price": 33690,
        "old_price": 33690,
        "reviews": 4,
        "position": 140
    },
    {
        "site": "pm.ru",
        "name": "Надстройка Ренни Дуб Мария / Алина Без доводчиков",
        "price": 5890,
        "old_price": 5890,
        "reviews": 5,
        "position": 201
    },
    {
        "site": "pm.ru",
        "name": "Журнальный столик Одри Белый / Дуб Эдда",
        "price": 9077,
        "old_price": 9077,
        "reviews": 5,
        "position": 252
    },
    {
        "site": "pm.ru",
        "name": "Прямой диван Айверс Мини Дуб / Серый, шенилл",
        "price": 35990,
        "old_price": 35990,
        "reviews": 5,
        "position": 155
    },
    {
        "site": "pm.ru",
        "name": "Комплект постельного белья Моноспейс сатин кремовый",
        "price": 5390,
        "old_price": 5390,
        "reviews": 5,
        "position": 27
    },
    {
        "site": "pm.ru",
        "name": "Комод Куба Дуб Сонома / Белый глянец",
        "price": 8590,
        "old_price": 8590,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "ТВ-тумба Джаз Каштан Найроби / Оникс",
        "price": 8999,
        "old_price": 8999,
        "reviews": 4
    },
    {
        "site": "pm.ru",
        "name": "ТВ-тумба Оливия Лайт Дуб Сонома / Белое дерево",
        "price": 12599,
        "old_price": 12599,
        "reviews": 5,
        "position": 207
    },
    {
        "site": "pm.ru",
        "name": "Прихожая Алекс Венге / Скала",
        "price": 17790,
        "old_price": 17790,
        "reviews": 5,
        "position": 96
    },
    {
        "site": "pm.ru",
        "name": "Кровать с подъемным механизмом Трувор ПМ Дуб Гранж песочный / Интра / Черный",
        "price": 30490,
        "old_price": 30490,
        "reviews": 5,
        "position": 155
    },
    {
        "site": "pm.ru",
        "name": "Бра V1205/1A",
        "price": 1490,
        "old_price": 1490,
        "reviews": 5,
        "position": 255
    },
    {
        "site": "pm.ru",
        "name": "Настенное зеркало Квадро Графит",
        "price": 4490,
        "old_price": 4490,
        "reviews": 5,
        "position": 177
    },
    {
        "site": "pm.ru",
        "name": "Компьютерный стол Эльбрус Белый",
        "price": 7990,
        "old_price": 7990,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Компьютерный стол Лайф Бетон / Белый",
        "price": 7990,
        "old_price": 7990,
        "reviews": 5,
        "position": 227
    },
    {
        "site": "pm.ru",
        "name": "Кухонный стол Вектор Бетон / Белый",
        "price": 18990,
        "old_price": 18990,
        "reviews": 5,
        "position": 248
    },
    {
        "site": "pm.ru",
        "name": "Прихожая Агата Белый Рамух",
        "price": 8890,
        "old_price": 8890,
        "reviews": 4,
        "position": 131
    },
    {
        "site": "pm.ru",
        "name": "Стеллаж Тифани Дуб небраска / Белая сосна 1",
        "price": 10390,
        "old_price": 10390,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Детский стеллаж Калейдоскоп Бирюза / Гикори Джексон",
        "price": 3590,
        "old_price": 3590,
        "reviews": 5,
        "position": 134
    },
    {
        "site": "pm.ru",
        "name": "Комплект прикроватных тумб Сакура Венге / Лоредо",
        "price": 3790,
        "old_price": 3790,
        "reviews": 4
    },
    {
        "site": "pm.ru",
        "name": "Настенное зеркало Ника ТВ500 Венге",
        "price": 1460,
        "old_price": 1460,
        "reviews": 5,
        "position": 21
    },
    {
        "site": "pm.ru",
        "name": "Шкаф распашной Оксфорд Дуб Санремо / Белый",
        "price": 11090,
        "old_price": 11090,
        "reviews": 5,
        "position": 58
    },
    {
        "site": "pm.ru",
        "name": "Стеллаж в детскую Эльбрус Белый",
        "price": 5990,
        "old_price": 5990,
        "reviews": 3,
        "position": 113
    },
    {
        "site": "pm.ru",
        "name": "Кровать-чердак Астра 9/2 Дуб Молочный / Салатовый",
        "price": 19690,
        "old_price": 19690,
        "reviews": 5,
        "position": 106
    },
    {
        "site": "pm.ru",
        "name": "ТВ-тумба Глосс Белый / Белый глянец",
        "price": 12990,
        "old_price": 12990,
        "reviews": 4,
        "position": 239
    },
    {
        "site": "pm.ru",
        "name": "Обувница Ливорно Дуб бунратти / Софт графит",
        "price": 14999,
        "old_price": 14999,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Прикроватная тумбочка Глосс Белый / Белый глянец",
        "price": 3990,
        "old_price": 3990,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Барный стул Турин Бар со спинкой Изумрудный, велюр",
        "price": 8683,
        "old_price": 8683,
        "reviews": 5,
        "position": 142
    },
    {
        "site": "pm.ru",
        "name": "Кровать Дублин Дуб золотой / Камень тёмный 140х200 см",
        "price": 9590,
        "old_price": 9590,
        "reviews": 4,
        "position": 19
    },
    {
        "site": "pm.ru",
        "name": "Детский письменный стол Банни Макарун / Белый / Дуб Сонома",
        "price": 10999,
        "old_price": 10999,
        "reviews": 5,
        "position": 97
    },
    {
        "site": "pm.ru",
        "name": "Тумба Юнит Дуб Крафт Стандарт",
        "price": 2090,
        "old_price": 2090,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Компьютерный стол Роджер Дуб Сонома",
        "price": 14650,
        "old_price": 14650,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Настенное зеркало Александрия Сосна Санторини светлая",
        "price": 3990,
        "old_price": 3990,
        "reviews": 5,
        "position": 39
    },
    {
        "site": "pm.ru",
        "name": "Кресло Роуз Серый, велюр",
        "price": 17990,
        "old_price": 17990,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Распашной шкаф Мерлен 103 Венге / Дуб Атланта",
        "price": 18200,
        "old_price": 18200,
        "reviews": 4,
        "position": 19
    },
    {
        "site": "pm.ru",
        "name": "Бра V1180/1A",
        "price": 2210,
        "old_price": 2210,
        "reviews": 5,
        "position": 188
    },
    {
        "site": "pm.ru",
        "name": "Барный стул Tolix (Толикс)",
        "price": 6990,
        "old_price": 6990,
        "reviews": 5
    },
    {
        "site": "pm.ru",
        "name": "Прямой диван Портленд Серый, велюр",
        "price": 41990,
        "old_price": 41990,
        "reviews": 5,
        "position": 48
    },
    {
        "site": "pm.ru",
        "name": "Прямой диван Сити Бежевый, велюр",
        "price": 56990,
        "old_price": 56990,
        "reviews": 4,
        "position": 98
    },
    {
        "site": "pm.ru",
        "name": "Кресло Сан-Диего Песчаная буря, иск. замша",
        "price": 22990,
        "old_price": 22990,
        "reviews": 5,
        "position": 33
    },
    {
        "site": "pm.ru",
        "name": "Комод Фиеста Венге / Лоредо",
        "price": 5490,
        "old_price": 5490,
        "reviews": 4,
        "position": 70
    },
    {
        "site": "pm.ru",
        "name": "Комод Белла Белый / Джелато софт",
        "price": 18710,
        "old_price": 18710,
        "reviews": 5,
        "position": 110
    },
    {
        "site": "pm.ru",
        "name": "Матрас для дивана Винсен 8 120х200 см",
        "price": 8390,
        "old_price": 8390,
        "reviews": 5,
        "position": 39
    },
    {
        "site": "pm.ru",
        "name": "Комод Неаполь Ясень Анкор светлый / Патина серебро",
        "price": 28790,
        "old_price": 28790,
        "reviews": 5,
        "position": 213
    },
    {
        "site": "pm.ru",
        "name": "Прикроватная тумбочка Натура Дуб табачный Craft / Черный",
        "price": 4277,
        "old_price": 4277,
        "reviews": 5,
        "position": 68
    },
    {
        "site": "wildberries.ru",
        "name": "Фоторамка \"Кора\" 19,7х14,7 см",
        "price": 336,
        "old_price": 570,
        "reviews": 2,
        "seller": "ФАБРИКА УСПЕХА",
        "seller_full": "ООО ТД \"ФАБРИКА УСПЕХА\"",
        "brand": "Сималенд",
        "position": 137
    },
    {
        "site": "ozon.ru",
        "name": "Комбинезон нательный Эскимо",
        "price": 714,
        "old_price": 899,
        "reviews": 321,
        "seller": "Щулькин И.Б.",
        "seller_full": "ИП Щулькин Игорь Борисович",
        "brand": "Эскимо",
        "position": 160
    },
    {
        "site": "wildberries.ru",
        "name": "Брюки",
        "price": 1498,
        "old_price": 4110,
        "reviews": 500,
        "seller": "TOPLES",
        "seller_full": "ООО \"ТОПЛЕС РУС\"",
        "brand": "TOPLES",
        "position": 222
    },
    {
        "site": "ozon.ru",
        "name": "Тюль AMIR GROUP высота 280 см, ширина 400 см, крепление - Лента, пудровый",
        "price": 2599,
        "old_price": 6657,
        "reviews": 2,
        "seller": "-AMIR GROUP-",
        "seller_full": "ООО АМИР ГРУПП",
        "brand": "AMIR GROUP",
        "position": 70
    },
    {
        "site": "wildberries.ru",
        "name": "Наклейка на телефон 3D стикеры Diabolik lovers5",
        "price": 353,
        "old_price": 599,
        "reviews": 1,
        "seller": "KRASNIKOVA",
        "seller_full": "ИП Белоусова Е.И.",
        "brand": "KRASNIKOVA",
        "position": 69
    },
    {
        "site": "ozon.ru",
        "name": "Семена Томат \"Дрова\", 3 упаковки + 2 Подарка",
        "price": 359,
        "old_price": 867,
        "reviews": 78,
        "seller": "Проверенные семена",
        "seller_full": "ИП Иванова Наталия Григорьевна",
        "brand": "Проверенные семена",
        "position": 191
    },
    {
        "site": "wildberries.ru",
        "name": "Брюки классические палаццо с высокой посадкой",
        "price": 861,
        "old_price": 5600,
        "reviews": 1767,
        "seller": "Say Love Store",
        "seller_full": "Say Love Store",
        "brand": "Say Love Store",
        "position": 155
    },
    {
        "site": "wildberries.ru",
        "name": "Валерий Брюсов. Повести и рассказы",
        "price": 406,
        "old_price": 490,
        "reviews": 1,
        "seller": "Книгоед",
        "seller_full": "ИП Аверин Н. В.",
        "brand": "Советская Россия"
    },
    {
        "site": "ozon.ru",
        "name": "Шнур PE YGK X-BRAID Braid Cord X8 #0.3/8LB (150 м, 0.090 мм, 3.6 кг, цв. Шартрез)",
        "price": 1670,
        "old_price": 2982,
        "reviews": 930,
        "seller": "Oldfish",
        "seller_full": "ИП Куликов Михаил Сергеевич",
        "brand": "YGK",
        "position": 173
    },
    {
        "site": "wildberries.ru",
        "name": "Набор маркеров для ткани Textil Marker 2739 10цв. 18мм карто",
        "price": 613,
        "old_price": 1033,
        "reviews": 1,
        "seller": "МАРКЕР",
        "seller_full": "ИП Сергиенко А.Н.",
        "brand": "Centropen"
    },
    {
        "site": "wildberries.ru",
        "name": "Радиатор отопления",
        "price": 5658,
        "old_price": 17250,
        "reviews": 1,
        "seller": "ILDAR&SHOP",
        "seller_full": "ИП ГАЗИЗОВ",
        "brand": "Радиатор Elegant секционный",
        "position": 190
    },
    {
        "site": "wildberries.ru",
        "name": "Костюм летний тройка женский деловой офисный брючный",
        "price": 1360,
        "old_price": 4200,
        "reviews": 1,
        "seller": "Ammii",
        "seller_full": "ИП Абдурахманов Р. А.",
        "brand": "Wammi",
        "position": 46
    },
    {
        "site": "wildberries.ru",
        "name": "Комбинезон детский с начесом новорожденному для малыша",
        "price": 1552,
        "old_price": 7100,
        "reviews": 11,
        "seller": "MILOV",
        "seller_full": "ИП Милов Максим Николаевич",
        "brand": "MILOV",
        "position": 29
    },
    {
        "site": "wildberries.ru",
        "name": "Простыни натяжные",
        "price": 803,
        "old_price": 1959,
        "reviews": 92,
        "seller": "АртДизайн - Текстиль от производителя",
        "seller_full": "ИП Конькова С. В.",
        "brand": "АРТПОСТЕЛЬ",
        "position": 141
    },
    {
        "site": "wildberries.ru",
        "name": "Мягкая игрушка гусь плюшевый 70 см",
        "price": 762,
        "old_price": 1550,
        "reviews": 5,
        "seller": "ИП Бобохонова",
        "seller_full": "ИП Бобохонова О. Д.",
        "brand": "Новогодние подарки",
        "position": 92
    },
    {
        "site": "wildberries.ru",
        "name": "Контейнер для продуктов",
        "price": 442,
        "old_price": 560,
        "reviews": 8,
        "seller": "ТЕПЛОН",
        "seller_full": "Сидоров Евгений Павлович ИП",
        "brand": "ТЕПЛОН",
        "position": 33
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Realme C11 2021",
        "price": 403,
        "old_price": 1300,
        "reviews": 163,
        "seller": "Onlinecase",
        "seller_full": "Кабанов Дмитрий Владимирович ИП",
        "brand": "Onlinecase",
        "position": 35
    },
    {
        "site": "ozon.ru",
        "name": "Кроп-топ",
        "price": 1466,
        "old_price": 3800,
        "reviews": 1,
        "seller": "ип. Бирюкова",
        "seller_full": "ИП Бирюкова Екатерина Евгеньевна",
        "position": 9
    },
    {
        "site": "ozon.ru",
        "name": "Kapous Professional Studio Бальзам-уход для окрашенных волос Color Care Caring Line 1000мл",
        "price": 865,
        "old_price": 1524,
        "reviews": 43,
        "seller": "Солнечная ваниль",
        "seller_full": "ИП Юрчук Алексей Викторович",
        "brand": "STUDIO",
        "position": 85
    },
    {
        "site": "wildberries.ru",
        "name": "Декоративная подушка на молнии 40х40 см на диван для декора",
        "price": 498,
        "old_price": 1510,
        "reviews": 8,
        "seller": "Tex.you store",
        "seller_full": "СИМА-ЛЕНД ООО ТД",
        "brand": "Этель",
        "position": 97
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка",
        "price": 569,
        "old_price": 930,
        "reviews": 5,
        "seller": "Власенко Петр Вячеславович ИП",
        "seller_full": "Власенко Петр Вячеславович ИП",
        "brand": "Власенко П.В.",
        "position": 251
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для чемодана защитный размер S",
        "price": 976,
        "old_price": 3295,
        "reviews": 10,
        "seller": "LeJoy",
        "seller_full": "ИП Темникова Ольга Валентиновна",
        "brand": "LeJoy",
        "position": 97
    },
    {
        "site": "wildberries.ru",
        "name": "Раскраска для будущих мам",
        "price": 1453,
        "old_price": 4225,
        "reviews": 22,
        "seller_full": "Лазарева Татьяна Фридриховна",
        "brand": "Aist letit"
    },
    {
        "site": "wildberries.ru",
        "name": "Шоколадные конфеты с дробленым орехом",
        "price": 484,
        "old_price": 1380,
        "reviews": 19,
        "seller": "Абаканские сласти",
        "seller_full": "ИП Минчак Елена Владимировна",
        "brand": "Абаканские сласти",
        "position": 224
    },
    {
        "site": "wildberries.ru",
        "name": "Платье рубашка летнее",
        "price": 1892,
        "old_price": 5990,
        "reviews": 75,
        "seller": "DAMStudio",
        "seller_full": "Хасанов Дамир Равилевич ИП",
        "brand": "DAMStudio",
        "position": 176
    },
    {
        "site": "wildberries.ru",
        "name": "Кукла для девочек Игрушки сюжетно ролевые Куколки",
        "price": 738,
        "old_price": 1059,
        "reviews": 4,
        "seller_full": "ООО Найс Групп",
        "brand": "Наша Игрушка",
        "position": 6
    },
    {
        "site": "wildberries.ru",
        "name": "Эмблема на решетку Кадиллак Cadillac",
        "price": 2184,
        "old_price": 2600,
        "reviews": 6,
        "seller": "МОЙБРЕЛОК",
        "seller_full": "Суворов Игорь Павлович ИП",
        "brand": "нет бренда/ ИП Суворов",
        "position": 151
    },
    {
        "site": "wildberries.ru",
        "name": "Силиконовый чехол iphone 6 plus",
        "price": 468,
        "old_price": 2600,
        "reviews": 4,
        "seller": "UnionColors",
        "seller_full": "ИП Степченко",
        "brand": "UnionColors",
        "position": 29
    },
    {
        "site": "wildberries.ru",
        "name": "Детские песочные часы набор 3 штуки 2 5 10 минут",
        "price": 360,
        "old_price": 975,
        "reviews": 362,
        "seller": "ORANGEShop",
        "seller_full": "ИП Смола Максим Николаевич",
        "brand": "Детское время",
        "position": 43
    },
    {
        "site": "wildberries.ru",
        "name": "Швабра с телескопической ручкой Hausrein Profi",
        "price": 5349,
        "old_price": 6369,
        "reviews": 46,
        "seller": "Gint",
        "seller_full": "ИП Кучнова Т. Л.",
        "brand": "leifheit",
        "position": 236
    },
    {
        "site": "ozon.ru",
        "name": "Кулон GemsPlanet  черный турмалин натуральный шерл мужской женский, кулон амулет/талисман, подвеска на шею мужская с натуральным камнем, бижутерия на шею женская (Планета Драгоценностей)",
        "price": 607,
        "old_price": 900,
        "reviews": 66,
        "seller": "GemsPlanet",
        "seller_full": "ООО Планета Драгоценностей",
        "brand": "GemsPlanet",
        "position": 111
    },
    {
        "site": "wildberries.ru",
        "name": "От простого к сложному. Тесты для детей",
        "price": 140,
        "old_price": 334,
        "reviews": 27,
        "seller": "Азбука-Аттикус. Издательская Группа",
        "seller_full": "Издательская Группа Азбука-Аттикус ООО",
        "brand": "Издательство Махаон",
        "position": 169
    },
    {
        "site": "wildberries.ru",
        "name": "Махровый домашний халат с вышивкой",
        "price": 3879,
        "old_price": 11975,
        "reviews": 74,
        "seller": "GiftProm",
        "seller_full": "ИП «Бабушкин Данила Михайлович»",
        "brand": "GiftProm",
        "position": 139
    },
    {
        "site": "wildberries.ru",
        "name": "Силиконовые приманки для рыбалки Акара",
        "price": 447,
        "old_price": 746,
        "reviews": 1,
        "seller": "Все для рыбалки",
        "seller_full": "ОДО Белсалмо",
        "brand": "Akara"
    },
    {
        "site": "ozon.ru",
        "name": "Постер, Плакат HENTAI POSTER /Геншин Импакт, Genshin Impact, Хентай / Райден, 61 см x 43 см",
        "price": 501,
        "old_price": 1750,
        "reviews": 36,
        "seller": "COOL POSTER",
        "seller_full": "ИП Шарашова Александра Алексеевна",
        "brand": "HENTAI POSTER",
        "position": 147
    },
    {
        "site": "wildberries.ru",
        "name": "Халва турецкая кунжутная с ванилью, халва пашмак ( 450гр.)",
        "price": 371,
        "old_price": 700,
        "reviews": 74,
        "seller": "ИП Алиева З. А.",
        "seller_full": "ИП Алиева З. А.",
        "brand": "Seyidoglu",
        "position": 210
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Huawei P Smart 2021 с принтом Аниме",
        "price": 450,
        "old_price": 835,
        "reviews": 10,
        "seller": "Foximilyan  ^.^",
        "seller_full": "ИП Богатов Д.Н.",
        "brand": "Fox Case",
        "position": 234
    },
    {
        "site": "wildberries.ru",
        "name": "патчи для глаз",
        "price": 60,
        "old_price": 60,
        "reviews": 1,
        "seller": "Miasto",
        "seller_full": "Козлов Алексей Николаевич ИП",
        "brand": "-",
        "position": 43
    },
    {
        "site": "wildberries.ru",
        "name": "Костюм оверсайз шорты с футболкой Хвоя",
        "price": 1638,
        "old_price": 7490,
        "reviews": 1,
        "seller": "XVOYA",
        "seller_full": "ИП Кучерявый К. Д.",
        "brand": "XVOYA",
        "position": 23
    },
    {
        "site": "wildberries.ru",
        "name": "Краска 6.66 Темный блондин красный интенсивный OLIOSETA",
        "price": 1375,
        "old_price": 2274,
        "reviews": 1,
        "seller": "Eternità",
        "seller_full": "Гуйда Иван Дмитриевич ИП",
        "brand": "Barex",
        "position": 104
    },
    {
        "site": "ozon.ru",
        "name": "Постер \"Свечи В Массажном Салоне\" 50х40 см / постеры для интерьера / картина на стену / картина интерьерная / картина большая / плакат на стену / декоративное панно / панно настенное / плакаты на кухню / для кухни / в спальню / фотопанно ПолиЦентр",
        "price": 391,
        "old_price": 700,
        "reviews": 4,
        "seller": "ПолиЦентр, мастерская рекламы",
        "seller_full": "ИП Киреева Марина Александровна",
        "brand": "ПолиЦентр"
    },
    {
        "site": "wildberries.ru",
        "name": "Съемник ТНВД BMW M47, M47T2, M47TU, M57, M57TU, VR50698",
        "price": 1640,
        "old_price": 3426,
        "reviews": 2,
        "seller": "1 PROFIMAG",
        "seller_full": "Жеребцов Сергей Анатольевич",
        "brand": "VERTUL",
        "position": 175
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Samsung A73 противоударный силиконовый с принтом",
        "price": 326,
        "old_price": 1019,
        "reviews": 14,
        "seller": "INKIT",
        "seller_full": "ИП \"Иванова\"",
        "brand": "INKIT"
    },
    {
        "site": "wildberries.ru",
        "name": "Томат ДАМСКИЙ КАПРИЗ F1, (10шт) 2-ной пак",
        "price": 231,
        "old_price": 231,
        "reviews": 25,
        "seller": "AVP Group",
        "seller_full": "Писакин Андрей Владимирович ИП",
        "brand": "АГРОФИРМА ПАРТНЕР",
        "position": 95
    },
    {
        "site": "ozon.ru",
        "name": "Переходник для газовых горелок ARMA CF-16 (7/16\" - CGA600)",
        "price": 821,
        "old_price": 2769,
        "reviews": 17,
        "seller": "Mr.Arch",
        "seller_full": "ИП Даллакян Артур Игоревич",
        "brand": "ARMA",
        "position": 30
    },
    {
        "site": "wildberries.ru",
        "name": "Русские во Второй мировой войне",
        "price": 1080,
        "old_price": 1412,
        "reviews": 3,
        "seller": "ТД Эксмо",
        "seller_full": "ТД Эксмо ООО",
        "brand": "Эксмо"
    },
    {
        "site": "wildberries.ru",
        "name": "Лак для ногтей быстросохнущий",
        "price": 247,
        "old_price": 799,
        "reviews": 2,
        "seller_full": "ИП Юрина Г. Р.",
        "brand": "Maxfine",
        "position": 204
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол книжка на Xiaomi Redmi 9",
        "price": 548,
        "old_price": 999,
        "reviews": 27,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян С. М.",
        "brand": "Mobi711"
    },
    {
        "site": "ozon.ru",
        "name": "Ветровка",
        "price": 1713,
        "old_price": 6286,
        "reviews": 9,
        "seller": "Cheerful shopping cart",
        "seller_full": "Yunhe Yeshiliang Water and Electricity Installation Service Department",
        "position": 98
    },
    {
        "site": "ozon.ru",
        "name": "Чехол-бампер MyPads для Tecno Spark 9 Pro  Spark 9T с рисунком Любовь-мамы детский, противоударный, силиконовый",
        "price": 553,
        "old_price": 783,
        "reviews": 27,
        "seller": "MyPads Миллионы чехлов",
        "seller_full": "ИП Михайлов Андрей",
        "brand": "MyPads"
    },
    {
        "site": "wildberries.ru",
        "name": "Защитное стекло для iP 12 12 Pro Матовое",
        "price": 52,
        "old_price": 650,
        "reviews": 86,
        "seller_full": "ИП Карданов А.С.",
        "brand": "BINGO",
        "position": 257
    },
    {
        "site": "ozon.ru",
        "name": "Авточехлы для Chevrolet Niva, 04.2002-05.2013 (нива шевроле) ТУРИН СТ /Илана+Орегон/ Беж & Чехлы модельные для автомобильных сидений",
        "price": 7120,
        "old_price": 14443,
        "reviews": 72,
        "seller": "Lord Autofashion",
        "seller_full": "ИП Уваров Геннадий Владимирович",
        "brand": "Lord Autofashion",
        "position": 60
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка оверсайз плотная длинный рукав с карманом",
        "price": 1296,
        "old_price": 3200,
        "reviews": 1517,
        "seller": "ИП Пушкина И.Г.",
        "seller_full": "ИП Пушкина ИГ",
        "brand": "Victoria_Richie"
    },
    {
        "site": "wildberries.ru",
        "name": "Фляга с краном на 10 или 20 литров - бидон пластиковый",
        "price": 1066,
        "old_price": 2000,
        "reviews": 11,
        "seller": "VeneSetiv",
        "seller_full": "ИП ЗСА",
        "brand": "VeneSetiv",
        "position": 237
    },
    {
        "site": "wildberries.ru",
        "name": "Цепь велосипедная 1 2\"X11 128\"X116L (в боксе) Х9",
        "price": 1966,
        "old_price": 4590,
        "reviews": 3,
        "seller": "ВЫБОР 101%",
        "seller_full": "ВЫБОР 101%",
        "brand": "KMC"
    },
    {
        "site": "wildberries.ru",
        "name": "Фигурка-игрушка",
        "price": 468,
        "old_price": 520,
        "reviews": 31,
        "seller": "Efendiev’s",
        "seller_full": "Эфендиев Муса Азер Оглы",
        "brand": "Умная Сова",
        "position": 101
    },
    {
        "site": "wildberries.ru",
        "name": "Столик прикроватный для ноутбука",
        "price": 1512,
        "old_price": 4500,
        "reviews": 1,
        "seller": "Домострой",
        "seller_full": "ООО \"ДОМОСТРОЙ\"",
        "brand": "АЛТ",
        "position": 35
    },
    {
        "site": "wildberries.ru",
        "name": "Джинсы бананы широкие",
        "price": 1263,
        "old_price": 6500,
        "reviews": 125,
        "seller": "ITALOMANIA",
        "seller_full": "ИП Авдалян К. Б.",
        "brand": "italomania"
    },
    {
        "site": "wildberries.ru",
        "name": "Угломер электронный Inclinometer уровень строительный",
        "price": 987,
        "old_price": 1582,
        "reviews": 420,
        "seller": "Точилка ЖУК",
        "seller_full": "Васильев Игорь Викторович ИП",
        "brand": "SKATA",
        "position": 64
    },
    {
        "site": "ozon.ru",
        "name": "Чехол-книжка Нарисованные сердечки на Oppo A54 / Оппо А54 черный",
        "price": 493,
        "old_price": 990,
        "reviews": 7,
        "seller": "Re:pa Store",
        "seller_full": "ИП Грищев Владимир Викторович",
        "brand": "RE:PA"
    },
    {
        "site": "ozon.ru",
        "name": "Бюстгальтер Новое время",
        "price": 2400,
        "old_price": 3900,
        "reviews": 123,
        "seller": "НОВОЕ ВРЕМЯ",
        "seller_full": "ИП Салимджанова Сабина Рамисовна",
        "brand": "Новое время",
        "position": 104
    },
    {
        "site": "wildberries.ru",
        "name": "Брендовый Платок",
        "price": 1392,
        "old_price": 8500,
        "reviews": 5,
        "seller": "Elegant",
        "seller_full": "Elegant",
        "brand": "Cashmere",
        "position": 20
    },
    {
        "site": "wildberries.ru",
        "name": "Термопаста КПТ-8, 20 г",
        "price": 279,
        "old_price": 466,
        "reviews": 3,
        "seller": "ТехМаркет",
        "seller_full": "ООО ТМ",
        "brand": "ТехМаркет"
    },
    {
        "site": "wildberries.ru",
        "name": "75890 Автомобиль Ferrari F40 Competizione",
        "price": 1733,
        "old_price": 3259,
        "reviews": 11,
        "seller_full": "ООО ВАЙЛДБЕРРИЗ",
        "brand": "LEGO",
        "position": 258
    },
    {
        "site": "wildberries.ru",
        "name": "Постельное белье евро комплект",
        "price": 3127,
        "old_price": 7945,
        "reviews": 10,
        "seller": "ИП Кравцун Георгий Николаевич",
        "seller_full": "ИП Кравцун Г. Н.",
        "brand": "PostelSon"
    },
    {
        "site": "wildberries.ru",
        "name": "Полотенце для новорожденного уголок",
        "price": 567,
        "old_price": 1500,
        "reviews": 416,
        "seller": "Matteo",
        "seller_full": "ИП Архипова А. Д.",
        "brand": "Matteo"
    },
    {
        "site": "wildberries.ru",
        "name": "Колпачки для пигмента",
        "price": 259,
        "old_price": 320,
        "reviews": 17,
        "seller": "TatooSuper",
        "seller_full": "ИП Сорока А. Р.",
        "brand": "forTatoo"
    },
    {
        "site": "wildberries.ru",
        "name": "Магнитная игра \"Модная девчонка\" арт. 01937",
        "price": 466,
        "old_price": 551,
        "reviews": 9,
        "seller": "АБАБИКА",
        "seller_full": "ООО «Абабика»",
        "brand": "Десятое королевство",
        "position": 6
    },
    {
        "site": "wildberries.ru",
        "name": "органайзер для косметики кистей канцелярии",
        "price": 518,
        "old_price": 1858,
        "reviews": 287,
        "seller": "ZALUU",
        "seller_full": "ИП Замбалова И. В.",
        "brand": "ZALUU",
        "position": 195
    },
    {
        "site": "wildberries.ru",
        "name": "Шапка бини и снуд комплект",
        "price": 475,
        "old_price": 1600,
        "reviews": 29,
        "seller": "Тетеревятникова С. М.",
        "seller_full": "ИП Тетеревятникова С. М.",
        "brand": "Тетеревятникова С. М.",
        "position": 56
    },
    {
        "site": "wildberries.ru",
        "name": "Офицерский ремень чёрный уставной",
        "price": 1318,
        "old_price": 1690,
        "reviews": 7,
        "seller": "ОНЛАЙН ЭКСПО",
        "seller_full": "ООО \"ОНЛАЙН ЭКСПО\"",
        "brand": "ОНЭКС",
        "position": 140
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол-книжка на Infinix Note 11 Pro (для нот 11 про)",
        "price": 331,
        "old_price": 850,
        "reviews": 1,
        "seller": "NOOTO",
        "seller_full": "ИП Кирюшина Екатерина Игоревна",
        "brand": "NOOTO"
    },
    {
        "site": "wildberries.ru",
        "name": "Маркер для планера, доски, магнитный со стирателем",
        "price": 175,
        "old_price": 175,
        "reviews": 191,
        "seller_full": "Дегтярев Игорь Александрович ИП",
        "brand": "2plan",
        "position": 50
    },
    {
        "site": "wildberries.ru",
        "name": "Постер аниме ходячий замок",
        "price": 315,
        "old_price": 450,
        "reviews": 4,
        "seller": "MugShop",
        "seller_full": "MugShop",
        "brand": "MugShop",
        "position": 10
    },
    {
        "site": "wildberries.ru",
        "name": "Шорты летние спортивные хлопок",
        "price": 1049,
        "old_price": 3600,
        "reviews": 20,
        "seller": "DSGDONG OFFICIAL",
        "seller_full": "DSGDONG OFFICIAL",
        "brand": "AIGULA",
        "position": 162
    },
    {
        "site": "wildberries.ru",
        "name": "Ремешок для часов Huawei band 7",
        "price": 413,
        "old_price": 530,
        "reviews": 13,
        "seller_full": "Зайнов Владимир Евгеньевич ИП",
        "brand": "ТС."
    },
    {
        "site": "wildberries.ru",
        "name": "Бижутерия серьги длинные со стразами",
        "price": 250,
        "old_price": 250,
        "reviews": 2,
        "seller": "Stone&Co.",
        "seller_full": "Токко Руслан Анатольевич",
        "brand": "Stone&Co.",
        "position": 132
    },
    {
        "site": "wildberries.ru",
        "name": "Ручки шариковые синие тонкие",
        "price": 320,
        "old_price": 382,
        "reviews": 4,
        "seller": "НьюБорн",
        "seller_full": "НЬЮБОРН ООО",
        "brand": "Brauberg"
    },
    {
        "site": "wildberries.ru",
        "name": "твердое мыло dove",
        "price": 200,
        "old_price": 200,
        "reviews": 69,
        "seller": "AG market",
        "seller_full": "ИП \"Орынбек\"",
        "brand": "Original of world brands",
        "position": 101
    },
    {
        "site": "wildberries.ru",
        "name": "Леггинсы (лосины, брюки)",
        "price": 385,
        "old_price": 1285,
        "reviews": 50,
        "seller": "MELANA - Чебоксарский Трикотаж",
        "seller_full": "Степанова Людмила Юрьевна ИП",
        "brand": "MELANA"
    },
    {
        "site": "wildberries.ru",
        "name": "Дед Мороз, к Новому Году, Игрушка, Украшение на Елку",
        "price": 258,
        "old_price": 890,
        "reviews": 607,
        "seller": "Гипермаркет. Игрушки и Товары для Дома",
        "seller_full": "ООО \"ГОРОД ДЕТСТВА\"",
        "brand": "LIKE GOODS",
        "position": 217
    },
    {
        "site": "ozon.ru",
        "name": "Туника домашняя De’Emili",
        "price": 1042,
        "old_price": 2400,
        "reviews": 2,
        "seller": "Tenerezza",
        "seller_full": "ООО Дельта Групп",
        "brand": "De’Emili",
        "position": 55
    },
    {
        "site": "wildberries.ru",
        "name": "Силиконовый чехол samsung s21 plus",
        "price": 495,
        "old_price": 550,
        "reviews": 2,
        "seller": "CaseCoCoS",
        "seller_full": "ИП Ковалев В.",
        "brand": "CaseCoCoS",
        "position": 152
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка с котиком",
        "price": 623,
        "old_price": 1490,
        "reviews": 10,
        "seller": "CatPrint",
        "seller_full": "ИП Гаврилов М. В.",
        "brand": "CatPrint"
    },
    {
        "site": "ozon.ru",
        "name": "Меласса \"Мята + Смесь специй \" 650 гр прикормка для рыбалки с Бетаином",
        "price": 297,
        "old_price": 1505,
        "reviews": 1,
        "seller": "HUNTKILLER",
        "seller_full": "ИП Мирошниченко Михаил Иванович"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол с карманом на TECNO POVA NEO (для Техно Пова Нео)",
        "price": 175,
        "old_price": 2500,
        "reviews": 8,
        "seller": "МобиФон",
        "seller_full": "ИП Семашко Алексей Александрович",
        "brand": "МобиФон",
        "position": 46
    },
    {
        "site": "wildberries.ru",
        "name": "Пушистые тапочки меховые тапки домашние",
        "price": 744,
        "old_price": 2700,
        "reviews": 73,
        "seller_full": "ИП Синягина Т. В.",
        "brand": "Wac"
    },
    {
        "site": "wildberries.ru",
        "name": "Профессиональный шампунь от выпадения волос, Биоксин, 300мл",
        "price": 1033,
        "old_price": 1295,
        "reviews": 137,
        "seller": "Глобал Бьюти - официальный дистрибьютор",
        "seller_full": "ГЛОБАЛ БЬЮТИ ООО",
        "brand": "Bioxsine",
        "position": 154
    },
    {
        "site": "wildberries.ru",
        "name": "Деловой брючный костюм",
        "price": 2397,
        "old_price": 14800,
        "reviews": 12,
        "seller": "Ocado",
        "seller_full": "ИП Боровских А. Т.",
        "brand": "Ocado"
    },
    {
        "site": "wildberries.ru",
        "name": "Сумка через плечо кожаная барсетка",
        "price": 1850,
        "old_price": 2551,
        "reviews": 22,
        "seller": "Mar4an",
        "seller_full": "ИП Марчан В.В.",
        "brand": "Marchan",
        "position": 218
    },
    {
        "site": "wildberries.ru",
        "name": "блузка оверсайз,кофта на каждый день,на работу",
        "price": 989,
        "old_price": 3700,
        "reviews": 86,
        "seller": "cherrystyle",
        "seller_full": "ИП Талантбекова С. Т.",
        "brand": "cherrystyle"
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка с принтом бренда",
        "price": 850,
        "old_price": 2500,
        "reviews": 122,
        "seller": "PIMPRINT",
        "seller_full": "ИП Мальченкова Ю. П.",
        "brand": "PimPrint",
        "position": 96
    },
    {
        "site": "ozon.ru",
        "name": "Силиконовый чехол Портрет тигра на Realme 8i / Рилми 8 ай",
        "price": 347,
        "old_price": 740,
        "reviews": 13,
        "seller": "Re:pa Store",
        "seller_full": "ИП Грищев Владимир Викторович",
        "brand": "RE:PA",
        "position": 253
    },
    {
        "site": "wildberries.ru",
        "name": "(230-LNZ) Задние фонари на Ниву",
        "price": 3386,
        "old_price": 9600,
        "reviews": 14,
        "seller": "oxuLED",
        "seller_full": "ИП Багманян Э. Э.",
        "brand": "oxuLED",
        "position": 223
    },
    {
        "site": "wildberries.ru",
        "name": "Веник и совок метла щетка с совком для уборки набор",
        "price": 546,
        "old_price": 1380,
        "reviews": 139,
        "seller_full": "АРМАДА СЕВЕР ООО",
        "brand": "Spin&Clean",
        "position": 18
    },
    {
        "site": "wildberries.ru",
        "name": "Картофельное пюре б п жареный лук",
        "price": 350,
        "old_price": 584,
        "reviews": 770,
        "seller": "Вкусно! Полезно! Здорово!",
        "seller_full": "ООО \"КУЗБАССПРОДСЕРВИС\"",
        "brand": "Кунцево",
        "position": 68
    },
    {
        "site": "wildberries.ru",
        "name": "Приманка",
        "price": 459,
        "old_price": 730,
        "reviews": 1,
        "seller": "Oldfish",
        "seller_full": "ИП Куликов МС",
        "brand": "G2"
    },
    {
        "site": "wildberries.ru",
        "name": "Самолёт инерционный Истребитель, цвета МИКС",
        "price": 206,
        "old_price": 327,
        "reviews": 1,
        "seller": "ИНДИГО",
        "seller_full": "ООО \"ИНДИГО\"",
        "brand": "Unkai",
        "position": 257
    },
    {
        "site": "wildberries.ru",
        "name": "Цветные трусики Toca Boca Life Тока Бока",
        "price": 1368,
        "old_price": 1690,
        "reviews": 259,
        "seller": "FortyFour",
        "seller_full": "Левшина Лидия Павловна",
        "brand": "FORTYFOUR",
        "position": 120
    },
    {
        "site": "wildberries.ru",
        "name": "Механизм складывания для Ninebot Max",
        "price": 1942,
        "old_price": 2569,
        "reviews": 3,
        "seller": "Все Товары и Точка",
        "seller_full": "Все Товары и Точка",
        "brand": "ELECTROMIRO",
        "position": 93
    },
    {
        "site": "ozon.ru",
        "name": "Брелок Brawl Stars Поко на ключи, брелок на сумку, брелок на рюкзак, Бравл Старс, герои мультиков",
        "price": 371,
        "old_price": 704,
        "reviews": 8,
        "seller": "ORIGINAL TOYS",
        "seller_full": "ИП Кравченко Денис Владимирович",
        "brand": "Original Toys",
        "position": 120
    },
    {
        "site": "wildberries.ru",
        "name": "Кардиган длинный оверсайз",
        "price": 1392,
        "old_price": 8596,
        "reviews": 73,
        "seller": "LUCIK SHOP",
        "seller_full": "ИП Латиш С. В.",
        "brand": "LUCIK SHOP",
        "position": 32
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Samsung Galaxy A71 Самсунг Галакси А71",
        "price": 359,
        "old_price": 1499,
        "reviews": 4,
        "seller": "SOLOFAM",
        "seller_full": "ИП Соловьев",
        "brand": "SoloFam",
        "position": 195
    },
    {
        "site": "wildberries.ru",
        "name": "Именная ручка с надписью Рания подарок с именем",
        "price": 348,
        "old_price": 850,
        "reviews": 1,
        "seller_full": "Рябов Валерий Сергеевич ИП",
        "brand": "Msklaser",
        "position": 113
    },
    {
        "site": "wildberries.ru",
        "name": "Блузка",
        "price": 773,
        "old_price": 3950,
        "reviews": 8,
        "seller_full": "Стогс Александрс Александрович ИП",
        "brand": "Hestollina"
    },
    {
        "site": "wildberries.ru",
        "name": "Сапоги кожаные на каблуке зима",
        "price": 8078,
        "old_price": 16350,
        "reviews": 4,
        "seller": "ИП Чуприна А. И.",
        "seller_full": "ИП Чуприна А. И.",
        "brand": "CKALA BOTO"
    },
    {
        "site": "wildberries.ru",
        "name": "Глина для лепки",
        "price": 494,
        "old_price": 687,
        "reviews": 3,
        "seller": "Цветное.ру",
        "seller_full": "ЦВЕТНОЕ ООО",
        "brand": "ARTIFACT",
        "position": 190
    },
    {
        "site": "wildberries.ru",
        "name": "Постер в комнату, Лето Река Водоем Природа",
        "price": 464,
        "old_price": 1200,
        "reviews": 1,
        "seller": "О! Мой постер",
        "seller_full": "ИП Пьянков В. В.",
        "brand": "О! Мой Постер",
        "position": 255
    },
    {
        "site": "ozon.ru",
        "name": "Муфта переходная (переходник) ППР (полипропиленовая) с внутренней резьбой Fusitek FT04204, 25х3/4\", комбинированная, упаковка 2штуки",
        "price": 446,
        "old_price": 489,
        "reviews": 15,
        "seller": "Vavilon Aqua",
        "seller_full": "ИП Туболец Марина Анатольевна",
        "brand": "Fusitek",
        "position": 85
    },
    {
        "site": "wildberries.ru",
        "name": "Сандалии кожаные черные",
        "price": 3969,
        "old_price": 10000,
        "reviews": 3,
        "seller": "ZILYA",
        "seller_full": "ИП Талипов И. Х.",
        "brand": "Zilya"
    },
    {
        "site": "wildberries.ru",
        "name": "Костюм двойка",
        "price": 1099,
        "old_price": 5900,
        "reviews": 1,
        "seller": "SoDa24",
        "seller_full": "꧁༺SoDa24༻꧂",
        "brand": "SoDa24",
        "position": 90
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка камуфляжная 2 шт",
        "price": 515,
        "old_price": 1061,
        "reviews": 338,
        "seller": "ИП Дмитриев И.В.",
        "seller_full": "Дмитриев Игорь Вячеславович ИП",
        "brand": "BoNiki",
        "position": 167
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для iPad Pro 10.5 Air 10.5 2019",
        "price": 1428,
        "old_price": 3590,
        "reviews": 2,
        "seller": "iЧехол",
        "seller_full": "Водянова Наталья АлексеевнаИП",
        "brand": "iЧехол"
    },
    {
        "site": "wildberries.ru",
        "name": "Тумба прикроватная белая",
        "price": 2949,
        "old_price": 9964,
        "reviews": 26,
        "seller": "LAZURIT - фабрика мебели",
        "seller_full": "ООО \"ТД\"ЛАЗУРИТ\"",
        "brand": "Lazurit мебель",
        "position": 198
    },
    {
        "site": "wildberries.ru",
        "name": "Серьги серебряные 925 пробы с камнями ювелирные",
        "price": 893,
        "old_price": 4190,
        "reviews": 53,
        "seller": "Официальный магазин SOKOLOV",
        "seller_full": "АО \"Лакса Трейдинг\"",
        "brand": "SOKOLOV",
        "position": 116
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на samsung a22 m22 m32",
        "price": 449,
        "old_price": 2499,
        "reviews": 22,
        "seller": "PandaCase",
        "seller_full": "ИП Бревнова А. С.",
        "brand": "PandaCase",
        "position": 104
    },
    {
        "site": "ozon.ru",
        "name": "Комплект крыльев, для 26\" колёс TOPEAK DeFender M1 и M2, TC9619",
        "price": 5040,
        "old_price": 5040,
        "reviews": 6,
        "seller": "КС",
        "seller_full": "ИП Сладков Эдуард Вячеславович",
        "brand": "TOPEAK"
    },
    {
        "site": "wildberries.ru",
        "name": "Наматрасник непромокаемый 80х170 см на резинке",
        "price": 1728,
        "old_price": 2160,
        "reviews": 22,
        "seller": "PraktikMatras",
        "seller_full": "ООО Практик",
        "brand": "Praktik"
    },
    {
        "site": "wildberries.ru",
        "name": "Фреза алмазная сферическая, грубой зерн. 2,9 мм. х 5 шт",
        "price": 429,
        "old_price": 537,
        "reviews": 1,
        "seller": "ЭКСПЕРТЫ КРАСОТЫ",
        "seller_full": "ЭКСПЕРТЫ КРАСОТЫ ООО",
        "brand": "ВладМиВа"
    },
    {
        "site": "wildberries.ru",
        "name": "Кардиган",
        "price": 1036,
        "old_price": 3200,
        "reviews": 283,
        "seller": "NDH Brand",
        "seller_full": "NDH Brand",
        "brand": "NDH Brand",
        "position": 89
    },
    {
        "site": "wildberries.ru",
        "name": "Зимний комбинезон для собак вельш-корги (мальчик)",
        "price": 3017,
        "old_price": 5559,
        "reviews": 1,
        "seller": "ForMyDogs official",
        "seller_full": "Казначеева Елена Александровна",
        "brand": "ForMyDogs",
        "position": 171
    },
    {
        "site": "wildberries.ru",
        "name": "Сетка для настольного тенниса с металлическими стойками",
        "price": 840,
        "old_price": 2000,
        "reviews": 38,
        "seller": "TURAN STORE",
        "seller_full": "ИП Афизов",
        "brand": "Weinixun",
        "position": 28
    },
    {
        "site": "wildberries.ru",
        "name": "Кондиционер универсальный, для всех типов волос 1 литр",
        "price": 831,
        "old_price": 1000,
        "reviews": 9,
        "seller": "Дом красоты и уюта",
        "seller_full": "ИП Сайфутдинова М. И.",
        "brand": "Concept",
        "position": 104
    },
    {
        "site": "wildberries.ru",
        "name": "Туфли",
        "price": 5209,
        "old_price": 6431,
        "reviews": 6,
        "seller": "ВЗВ",
        "seller_full": "ВЗВ",
        "brand": "Pixel",
        "position": 110
    },
    {
        "site": "wildberries.ru",
        "name": "Алмазная мозаика Овен 27,5Х35см.Подрамник",
        "price": 1151,
        "old_price": 2878,
        "reviews": 6,
        "seller": "Мастерица",
        "seller_full": "Лисогор Людмила Павловна ИП",
        "brand": "000 - ART- НАБОРЫ МОЗАИКА КАРТИНЫ",
        "position": 82
    },
    {
        "site": "wildberries.ru",
        "name": "Лейка для душа",
        "price": 675,
        "old_price": 4464,
        "reviews": 13,
        "seller_full": "ТД АРГУМЕНТ ООО",
        "brand": "Bath Plus",
        "position": 4
    },
    {
        "site": "wildberries.ru",
        "name": "Полоски индикаторные одноразовые Дезиконт-ДВУ-5 100 шт",
        "price": 738,
        "old_price": 1037,
        "reviews": 30,
        "seller": "ЭКСПЕРТЫ КРАСОТЫ",
        "seller_full": "ЭКСПЕРТЫ КРАСОТЫ ООО",
        "brand": "ВИНАР",
        "position": 111
    },
    {
        "site": "wildberries.ru",
        "name": "Масло для лица массажное, натуральное, увлажняющее, 30мл",
        "price": 167,
        "old_price": 399,
        "reviews": 109,
        "seller": "Francua",
        "seller_full": "ИП Лопарев Н. В.",
        "brand": "Francua",
        "position": 2
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Huawei Nova 5T Хуавей Нова 5Т c рисунком прозрачный…",
        "price": 307,
        "old_price": 684,
        "reviews": 12,
        "seller": "Andy&Paul",
        "seller_full": "ИП Коновалов П. А.",
        "brand": "Andy&Paul"
    },
    {
        "site": "wildberries.ru",
        "name": "Колье из натурального жемчуга и перламутра",
        "price": 4264,
        "old_price": 8000,
        "reviews": 2,
        "seller": "АННА РУКАВИЦЫНА",
        "seller_full": "ИП Рукавицына А. А.",
        "brand": "АННА РУКАВИЦЫНА",
        "position": 214
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Samsung Galaxy A23 самсунг а23 4g для а23 4g",
        "price": 423,
        "old_price": 770,
        "reviews": 175,
        "seller": "PERFECT CASE",
        "seller_full": "ИП Адамович А. Л.",
        "brand": "Perfect case",
        "position": 149
    },
    {
        "site": "wildberries.ru",
        "name": "Готовые очки",
        "price": 643,
        "old_price": 1033,
        "reviews": 1,
        "seller_full": "Савоськин Дмитрий Витальевич ИП",
        "brand": "Очки корригирующие-DS",
        "position": 117
    },
    {
        "site": "wildberries.ru",
        "name": "строительные 3 х 80 мм",
        "price": 488,
        "old_price": 888,
        "reviews": 5,
        "seller": "BARS/SHOP",
        "seller_full": "ИП Кемеев В. С.",
        "brand": "гвозди"
    },
    {
        "site": "wildberries.ru",
        "name": "Платье летнее короткое хлопок",
        "price": 2717,
        "old_price": 6990,
        "reviews": 5,
        "seller": "Модный дом Виктории Тишиной",
        "seller_full": "ВИК ООО",
        "brand": "Модный дом Виктории Тишиной"
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка хамелеон мальчику Алмаз подарок",
        "price": 624,
        "old_price": 2420,
        "reviews": 1,
        "seller": "Саламандра",
        "seller_full": "ИП Худиев А. С.",
        "brand": "Алихандро"
    },
    {
        "site": "wildberries.ru",
        "name": "Клипсер книпсер кусачки",
        "price": 352,
        "old_price": 2200,
        "reviews": 6,
        "seller": "NUKI",
        "seller_full": "Туратбекова Нурзат Туратбековна",
        "brand": "Nukityan"
    },
    {
        "site": "wildberries.ru",
        "name": "Пудра для умывания очищающая сода BAKING SODA",
        "price": 266,
        "old_price": 555,
        "reviews": 8,
        "seller": "КОСМЕТИЧЕСКИЙ БУТИК",
        "seller_full": "ООО \"Косметический бутик\"",
        "brand": "БЕЛИТА"
    },
    {
        "site": "wildberries.ru",
        "name": "Картридж PC-211EV для Pantum M6500W (1600k)",
        "price": 988,
        "old_price": 6700,
        "reviews": 3,
        "seller": "NV Print Минина Виктория Александровна",
        "seller_full": "ИП Минина В. А.",
        "brand": "NV-Print",
        "position": 220
    },
    {
        "site": "ozon.ru",
        "name": "Блузка",
        "price": 1172,
        "old_price": 5048,
        "reviews": 4,
        "seller": "Cheerful shopping cart",
        "seller_full": "Yunhe Yeshiliang Water and Electricity Installation Service Department",
        "position": 180
    },
    {
        "site": "ozon.ru",
        "name": "Лампочка TDM Electric ТДМ-R39-40Вт, Теплый белый свет, E14, 40 Вт, Накаливания, 5 шт.",
        "price": 649,
        "old_price": 945,
        "reviews": 6,
        "seller": "Центр профессиональной электрики \"Электромир\"",
        "seller_full": "ИП Ивашко Мария Олеговна",
        "brand": "TDM Electric"
    },
    {
        "site": "ozon.ru",
        "name": "Чехол-книжка Черника на Honor X8 / Хонор Икс 8 черный",
        "price": 493,
        "old_price": 990,
        "reviews": 1,
        "seller": "Re:pa Store",
        "seller_full": "ИП Грищев Владимир Викторович",
        "brand": "RE:PA"
    },
    {
        "site": "wildberries.ru",
        "name": "Значки на рюкзак игра Life Is Strange LiS",
        "price": 338,
        "old_price": 529,
        "reviews": 4,
        "seller": "Pro Pin Co.",
        "seller_full": "Pro Pin Co.",
        "brand": "Pro Pin Co.",
        "position": 78
    },
    {
        "site": "wildberries.ru",
        "name": "Коврики Lada Priora 2007 - н.в (седан)",
        "price": 2012,
        "old_price": 4060,
        "reviews": 1,
        "seller": "Prime-avto",
        "seller_full": "ИП Рузматов Тимур Эркинович",
        "brand": "Prime-avto",
        "position": 65
    },
    {
        "site": "ozon.ru",
        "name": "Халат Be lo Us Семья",
        "price": 1971,
        "old_price": 5000,
        "reviews": 1,
        "seller": "Be lo Us",
        "seller_full": "ИП Белоусов Владислав Викторович",
        "brand": "Be lo Us"
    },
    {
        "site": "wildberries.ru",
        "name": "Ожерелье с подвеской Токийский Гуль",
        "price": 253,
        "old_price": 390,
        "reviews": 1,
        "seller": "prossperM",
        "seller_full": "ИП Меджидов Ш. А.",
        "brand": "-",
        "position": 54
    },
    {
        "site": "wildberries.ru",
        "name": "Платье",
        "price": 794,
        "old_price": 2790,
        "reviews": 11,
        "seller": "СТИЛЬТЕКС",
        "seller_full": "ООО \"СТИЛЬТЕКС\"",
        "brand": "Адель",
        "position": 9
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Xiaomi Redmi 12C POCO C55 принт рисунок Аниме",
        "price": 307,
        "old_price": 903,
        "reviews": 145,
        "seller": "МОБИЛИУС",
        "seller_full": "ООО \"МОБИЛИУС\"",
        "brand": "Мобилиус"
    },
    {
        "site": "wildberries.ru",
        "name": "Обложка",
        "price": 367,
        "old_price": 1050,
        "reviews": 29,
        "seller": "Расчехлидзе",
        "seller_full": "ИП Димитрищук Александр Юрьевич",
        "brand": "Расчехлидзе",
        "position": 55
    },
    {
        "site": "ozon.ru",
        "name": "Картина по номерам Z-597 \"Скарлетт Йоханссон - Чёрная Вдова\" 60х90",
        "price": 3481,
        "old_price": 4890,
        "reviews": 3,
        "seller": "BRUSHES-PAINTS",
        "seller_full": "ИП Шишков Иван Вячеславович",
        "brand": "BRUSHES-PAINTS",
        "position": 4
    },
    {
        "site": "wildberries.ru",
        "name": "Набор декоративных подсвечников",
        "price": 330,
        "old_price": 330,
        "reviews": 584,
        "seller": "ИП Смирнов",
        "seller_full": "ИП Смирнов",
        "brand": "СТУДИЯ БРОНЗОВЫЙ КОТ",
        "position": 31
    },
    {
        "site": "wildberries.ru",
        "name": "Полка настенная навесная белая для кухни, книг",
        "price": 1040,
        "old_price": 6500,
        "reviews": 4389,
        "seller": "Фортуна-Мебель",
        "seller_full": "ООО \"ЯНИСС\"",
        "brand": "ЯСФортуна-мебель",
        "position": 157
    },
    {
        "site": "wildberries.ru",
        "name": "Наклейка на карту Кишлак стикеры Исполнитель",
        "price": 213,
        "old_price": 300,
        "reviews": 1,
        "seller": "XSML",
        "seller_full": "ИП Белоусова С. А.",
        "brand": "Российская Фабрика Виниловых Наклеек"
    },
    {
        "site": "wildberries.ru",
        "name": "Сандалии",
        "price": 760,
        "old_price": 900,
        "reviews": 13,
        "seller": "Remzzzona",
        "seller_full": "ИП Туманян Г. Р.",
        "brand": "-",
        "position": 130
    },
    {
        "site": "wildberries.ru",
        "name": "Скатерть новогодняя для кухни 150x150",
        "price": 500,
        "old_price": 1265,
        "reviews": 55,
        "seller_full": "ИП Маликов А. А.",
        "brand": "Анима Декор",
        "position": 66
    },
    {
        "site": "wildberries.ru",
        "name": "Сумка Brawl Stars Леон",
        "price": 236,
        "old_price": 1578,
        "reviews": 5,
        "seller": "MF",
        "seller_full": "ИП Ласточкин С. Б.",
        "brand": "Easy breezy",
        "position": 84
    },
    {
        "site": "ozon.ru",
        "name": "Аккумулятор для ноутбука Samsung NP-RC720",
        "price": 1536,
        "old_price": 2062,
        "reviews": 3,
        "seller": "PartsNb.ru",
        "seller_full": "ИП Тарлаковский Леонид Станиславович",
        "brand": "Greenway",
        "position": 247
    },
    {
        "site": "wildberries.ru",
        "name": "Сито для заварки, заварник, ситечко для заваривания чая",
        "price": 255,
        "old_price": 946,
        "reviews": 706,
        "seller": "PPBX",
        "seller_full": "ПОППЛЕРБОКС ООО",
        "brand": "VideoMart",
        "position": 48
    },
    {
        "site": "wildberries.ru",
        "name": "Кулирка ткань хлопок 95% лайкра 5%",
        "price": 720,
        "old_price": 1200,
        "reviews": 72,
        "seller": "Nurumus",
        "seller_full": "ООО \"АТЛАСКОТТОН\"",
        "brand": "Nurumus"
    },
    {
        "site": "wildberries.ru",
        "name": "Дакимакура Amagi Azur Lane 135х45 см",
        "price": 3092,
        "old_price": 4490,
        "reviews": 1,
        "seller": "Dakimakura17",
        "seller_full": "ИП Скачко Д. О.",
        "brand": "dakimakura17",
        "position": 64
    },
    {
        "site": "wildberries.ru",
        "name": "Рубашка школьная",
        "price": 1396,
        "old_price": 1916,
        "reviews": 102,
        "seller": "Sky Lake",
        "seller_full": "ИП Давыдкин Н. М.",
        "brand": "Sky Lake",
        "position": 245
    },
    {
        "site": "wildberries.ru",
        "name": "Клиническая рентгенорадиология. В пяти томах. Том 4",
        "price": 209,
        "old_price": 2096,
        "reviews": 1,
        "seller": "КНИЖНИЙ",
        "seller_full": "ЛАЙК ООО",
        "brand": "Медицина",
        "position": 239
    },
    {
        "site": "wildberries.ru",
        "name": "Свадебное украшение на зеркала и ручки машины",
        "price": 482,
        "old_price": 800,
        "reviews": 58,
        "seller_full": "ИП Дьяченко Е. В.",
        "brand": "Happy Dream",
        "position": 113
    },
    {
        "site": "wildberries.ru",
        "name": "Колготки для новорожденных",
        "price": 450,
        "old_price": 578,
        "reviews": 1,
        "seller": "ВОТОНЯ",
        "seller_full": "ООО ВОТОНЯ",
        "brand": "Palloncino",
        "position": 212
    },
    {
        "site": "wildberries.ru",
        "name": "Нарядный костюм для девочки",
        "price": 774,
        "old_price": 3000,
        "reviews": 24,
        "seller": "ИП  Гаргалык Т. Н.",
        "seller_full": "ИП Гаргалык Т. Н.",
        "brand": "SONIVAN",
        "position": 91
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка",
        "price": 882,
        "old_price": 2180,
        "reviews": 2,
        "seller": "ИП Искужин Марат Сагитуллович",
        "seller_full": "ИП Искужин Марат Сагитуллович",
        "brand": "Герои",
        "position": 165
    },
    {
        "site": "wildberries.ru",
        "name": "Тюль под лен 750х200 в гостиную готовая с утяжелителем Штора",
        "price": 1910,
        "old_price": 5444,
        "reviews": 1,
        "seller": "RANITA&HOME",
        "seller_full": "ИП Исоев М. А.",
        "brand": "RANITA&HOME"
    },
    {
        "site": "ozon.ru",
        "name": "Умная розетка Gosund Smart plug,  белый",
        "price": 926,
        "old_price": 1788,
        "reviews": 124,
        "seller": "MARVEL",
        "seller_full": "ООО МКТ",
        "brand": "Gosund",
        "position": 242
    },
    {
        "site": "wildberries.ru",
        "name": "Кормушка рыболовная фидерная \" Пикер \" 50 грамм 3шт",
        "price": 325,
        "old_price": 465,
        "reviews": 7,
        "seller": "ИВАНОВъ - товары для рыбалки и отдыха",
        "seller_full": "ИП Иванов Геннадий Геннадьевич",
        "brand": "MANKO",
        "position": 14
    },
    {
        "site": "ozon.ru",
        "name": "Сальник хлебопечки 8x18х8 UNI, 03AT011",
        "price": 340,
        "old_price": 560,
        "reviews": 2,
        "seller": "REMMART",
        "seller_full": "ООО \"РЕММАРТ\"",
        "position": 246
    },
    {
        "site": "wildberries.ru",
        "name": "Infiniti 2 флакона по 1 линзе R8.7 -1.75",
        "price": 1083,
        "old_price": 1290,
        "reviews": 30,
        "seller": "Поле Зрения",
        "seller_full": "Поле Зрения",
        "brand": "OKVision Infiniti",
        "position": 180
    },
    {
        "site": "wildberries.ru",
        "name": "Резинка бант для волос, 2шт",
        "price": 348,
        "old_price": 851,
        "reviews": 227,
        "seller": "Booklepa accessories",
        "seller_full": "ИП Симонова Алёна Владимировна",
        "brand": "Booklepa accessories"
    },
    {
        "site": "wildberries.ru",
        "name": "Джинсовая куртка укороченная повседневная джинсовка",
        "price": 1404,
        "old_price": 2990,
        "reviews": 6,
        "seller_full": "Махмуд Тарик ИП",
        "brand": "Rasna",
        "position": 162
    },
    {
        "site": "wildberries.ru",
        "name": "Бейдж с лентой рулеткой для карты пропуска розовый DV-13798",
        "price": 183,
        "old_price": 300,
        "reviews": 86,
        "seller": "Снаб 78",
        "seller_full": "СНАБАВТО ООО",
        "brand": "снаб 78",
        "position": 150
    },
    {
        "site": "wildberries.ru",
        "name": "КРЫШКА МАСЛЯНОГО ФИЛЬТРА OPEL AGILA, ASTRA, CORSA, TIGRA, !",
        "price": 840,
        "old_price": 1000,
        "reviews": 5,
        "seller": "CtoAuto",
        "seller_full": "Белоусов Сергей ВасильевичИП",
        "brand": "Zikmar"
    },
    {
        "site": "wildberries.ru",
        "name": "Shiro Парфюмерная вода жен, 10 мл",
        "price": 1020,
        "old_price": 2250,
        "reviews": 1,
        "seller": "НЕВАЛАЙН",
        "seller_full": "ООО НЕВАЛАЙН",
        "brand": "MASAKI MATSUSHIMA",
        "position": 132
    },
    {
        "site": "wildberries.ru",
        "name": "Магнитные полосы Школьные предметы 100х5см 4шт",
        "price": 1033,
        "old_price": 1800,
        "reviews": 71,
        "seller": "Раскрась Детство",
        "seller_full": "ИП Козлова Е. В.",
        "brand": "Раскрась Детство",
        "position": 191
    },
    {
        "site": "wildberries.ru",
        "name": "Вафельная картинка на торт мужчине Мото Байкер Мотоцик",
        "price": 274,
        "old_price": 549,
        "reviews": 86,
        "seller": "PrinTort",
        "seller_full": "Калинин Сергей Александрович ИП",
        "brand": "PrinTort"
    },
    {
        "site": "wildberries.ru",
        "name": "Светильник детский настенный Облако Нил",
        "price": 1584,
        "old_price": 4200,
        "reviews": 3,
        "seller": "ИП Кириченко",
        "seller_full": "ИП Кириченко М. С.",
        "brand": "WooDLive",
        "position": 121
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Xiaomi Redmi 8 Сяоми Редми 8 c рисунком прозрачный…",
        "price": 225,
        "old_price": 684,
        "reviews": 1,
        "seller": "Andy&Paul",
        "seller_full": "ИП Коновалов П. А.",
        "brand": "Andy&Paul",
        "position": 215
    },
    {
        "site": "wildberries.ru",
        "name": "Чайник заварочный",
        "price": 952,
        "old_price": 3225,
        "reviews": 7,
        "seller": "Посудный Уголок",
        "seller_full": "ИП Федосов Станислав Владимирович",
        "brand": "LovePosuda",
        "position": 213
    },
    {
        "site": "wildberries.ru",
        "name": "Раскраска для будущих мам",
        "price": 1453,
        "old_price": 4225,
        "reviews": 22,
        "seller_full": "Лазарева Татьяна Фридриховна",
        "brand": "Aist letit",
        "position": 112
    },
    {
        "site": "ozon.ru",
        "name": "Чехол-обложка MyPads с подставкой для Huawei MediaPad T3 10 LTE (AGS-L09/L03) 9.6 черный кожаный",
        "price": 987,
        "old_price": 2012,
        "reviews": 29,
        "seller": "MyPads",
        "seller_full": "ИП Никифоров Евгений",
        "brand": "MyPads",
        "position": 66
    },
    {
        "site": "wildberries.ru",
        "name": "Воздушные шарики",
        "price": 300,
        "old_price": 400,
        "reviews": 1,
        "seller": "ЛАС ИГРАС",
        "seller_full": "СИМАОПТ ООО",
        "brand": "Страна Карнавалия"
    },
    {
        "site": "wildberries.ru",
        "name": "Чайник заварочный с фильтром керамический чайник 350 мл",
        "price": 613,
        "old_price": 2005,
        "reviews": 1,
        "seller": "Посудный Уголок",
        "seller_full": "ИП Федосов Станислав Владимирович",
        "brand": "Piattelli",
        "position": 169
    },
    {
        "site": "wildberries.ru",
        "name": "Трусы кружевные слипы",
        "price": 327,
        "old_price": 1090,
        "reviews": 15,
        "seller": "PELICAN",
        "seller_full": "КОМПАНИЯ ПЕЛИКАН ООО",
        "brand": "Pelican",
        "position": 25
    },
    {
        "site": "wildberries.ru",
        "name": "пижама с шортами с разрезом",
        "price": 1247,
        "old_price": 3850,
        "reviews": 10,
        "seller": "MEET LOVE",
        "seller_full": "ИП Митина Е. А.",
        "brand": "MEET LOVE",
        "position": 225
    },
    {
        "site": "wildberries.ru",
        "name": "Картина на стену интерьерная 30х40 см с городом",
        "price": 513,
        "old_price": 1500,
        "reviews": 9,
        "seller": "Короткова Елена Андреевна ИП",
        "seller_full": "Короткова Елена Андреевна ИП",
        "brand": "CustomDesign"
    },
    {
        "site": "ozon.ru",
        "name": "Жидкое мыло для рук Русское Поле Антибактериальное 300 мл. х 4 шт.",
        "price": 489,
        "old_price": 612,
        "reviews": 1,
        "seller": "Эксперты Чистоты",
        "seller_full": "ООО Эксперты Чистоты",
        "brand": "Русское Поле",
        "position": 56
    },
    {
        "site": "wildberries.ru",
        "name": "Мембрана на флисе для самки Бигль",
        "price": 2584,
        "old_price": 5280,
        "reviews": 18,
        "seller": "ТУЗИК",
        "seller_full": "Чебыкин Андрей Михайлович ИП",
        "brand": "ТУЗИК"
    },
    {
        "site": "ozon.ru",
        "name": "Handinsilver ( Посеребриручку ) Цепочка серебристая  плетение \"Снейк\" 60см ширина 1,1 мм",
        "price": 911,
        "old_price": 1897,
        "reviews": 2,
        "seller": "Handinsilver (Посеребриручку)",
        "seller_full": "ИП Фольк Оксана Юрьевна",
        "brand": "Handinsilver ( Посеребриручку )",
        "position": 7
    },
    {
        "site": "ozon.ru",
        "name": "Веломайка CROSSSport",
        "price": 1775,
        "old_price": 4070,
        "reviews": 15,
        "seller": "CROSSsport",
        "seller_full": "ООО Мила-Мода",
        "brand": "CROSSSport"
    },
    {
        "site": "wildberries.ru",
        "name": "Кукла Пупс пьет, писает, плачет 42см",
        "price": 2040,
        "old_price": 4978,
        "reviews": 69,
        "seller": "UniTrain",
        "seller_full": "ООО \"ЮНИТРЭЙН\"",
        "brand": "UniTrain",
        "position": 148
    },
    {
        "site": "wildberries.ru",
        "name": "Костюм с шортами",
        "price": 1644,
        "old_price": 3500,
        "reviews": 4,
        "seller": "Тёма&Даня",
        "seller_full": "ИП Сергеев А. И.",
        "brand": "Driff kids"
    },
    {
        "site": "wildberries.ru",
        "name": "Силиконовый мужской браслет бижутерия на руку",
        "price": 244,
        "old_price": 680,
        "reviews": 57,
        "seller": "ИП Чень",
        "seller_full": "ИП ЧЭНЬ ЧЖЭННАНЬ",
        "brand": "vesnahome",
        "position": 176
    },
    {
        "site": "ozon.ru",
        "name": "Полубарный стул для кухни Чили, мягкий стул с анатомическим сиденьем и спинкой для дома, 1 шт, каркас черный, микрофибра темно-коричневая",
        "price": 6485,
        "old_price": 9330,
        "reviews": 101,
        "seller": "СтолSTUL и точка",
        "seller_full": "ИП Галкин Данил Михайлович",
        "brand": "СтолSTUL и точка",
        "position": 128
    },
    {
        "site": "ozon.ru",
        "name": "Замок накладной дверной, Аллюр, ЗН1А-1М",
        "price": 1028,
        "old_price": 1800,
        "reviews": 1,
        "seller": "ЗАМКИ-РУЧКИ",
        "seller_full": "ИП САФОНОВА ТАТЬЯНА АНАТОЛЬЕВНА",
        "brand": "Аллюр",
        "position": 6
    },
    {
        "site": "wildberries.ru",
        "name": "Покрывало на кровать диван 220х240 велюр",
        "price": 2296,
        "old_price": 5000,
        "reviews": 36,
        "seller": "UmkaHome",
        "seller_full": "ИП Багомедова У. М.",
        "brand": "Umka Home",
        "position": 135
    },
    {
        "site": "wildberries.ru",
        "name": "3D стикеры Blackpink БлэкПинк Дженни",
        "price": 330,
        "old_price": 330,
        "reviews": 6,
        "seller": "XSML",
        "seller_full": "ИП Белоусова С. А.",
        "brand": "Российская Фабрика Виниловых Наклеек"
    },
    {
        "site": "ozon.ru",
        "name": "Бюстгальтер для кормления Teyli 1 шт",
        "price": 3225,
        "old_price": 6449,
        "reviews": 74,
        "seller": "Лингерия",
        "seller_full": "ООО Лингерия",
        "brand": "Teyli",
        "position": 190
    },
    {
        "site": "wildberries.ru",
        "name": "Бесшумные Часы настенные черные наклейки на кухню",
        "price": 628,
        "old_price": 1790,
        "reviews": 54,
        "seller": "Красота и Уют",
        "seller_full": "Чувакова Юлия Владимировна ИП",
        "brand": "Чувакова Ю.В."
    },
    {
        "site": "wildberries.ru",
        "name": "Серьги",
        "price": 380,
        "old_price": 380,
        "reviews": 1,
        "seller": "Express",
        "seller_full": "ИП \"Бакытбеков\"",
        "brand": "fashion jewellery",
        "position": 259
    },
    {
        "site": "ozon.ru",
        "name": "Кукла-модель \"Кевин\"",
        "price": 556,
        "old_price": 1264,
        "reviews": 1,
        "seller": "Фабрика Мечты",
        "seller_full": "ООО Сима-Урал"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Iphone X XS с принтом на айфон 10",
        "price": 382,
        "old_price": 1530,
        "reviews": 8,
        "seller_full": "ИП Колосова Е.А.",
        "brand": "All Simple"
    },
    {
        "site": "wildberries.ru",
        "name": "Гирлянда Бахрома 25 м",
        "price": 1998,
        "old_price": 6999,
        "reviews": 5,
        "seller_full": "ИП Куршин Н. А.",
        "brand": "CoLorful_LiNe",
        "position": 29
    },
    {
        "site": "wildberries.ru",
        "name": "Зубной порошок отбеливающий",
        "price": 130,
        "old_price": 434,
        "reviews": 1945,
        "seller": "АЗАРИЯ",
        "seller_full": "ООО \"АЗАРИЯ\"",
        "brand": "fito косметик",
        "position": 181
    },
    {
        "site": "wildberries.ru",
        "name": "Мешок для обуви, сумка для сменки",
        "price": 1173,
        "old_price": 3175,
        "reviews": 6,
        "seller": "Ранцы STERNBAUER",
        "seller_full": "ИП Устинов А. М.",
        "brand": "STERNBAUER"
    },
    {
        "site": "wildberries.ru",
        "name": "Набор инструментов, 168 предметов",
        "price": 4004,
        "old_price": 5704,
        "reviews": 7763,
        "seller": "Forsage Instrument Bel",
        "seller_full": "ЧТУП Торговый дом Форсаж Инструмент Бел",
        "brand": "WMC Tools",
        "position": 81
    },
    {
        "site": "ozon.ru",
        "name": "Форма для шоколада, мармелада С днем рождения прописью шоколад, форма пластиковая. Молд из толстого пластика",
        "price": 499,
        "old_price": 509,
        "reviews": 25,
        "seller": "САМОВАРОЧКА!",
        "seller_full": "ИП Пестерев Иван Сергеевич",
        "brand": "AnyMolds"
    },
    {
        "site": "wildberries.ru",
        "name": "Головка торцевая Мастер 1 2 Flank 15мм",
        "price": 414,
        "old_price": 592,
        "reviews": 1,
        "seller": "МАРКЕТТРЕЙД",
        "seller_full": "ООО \"МАРКЕТТРЕЙД\"",
        "brand": "ЗУБР",
        "position": 237
    },
    {
        "site": "wildberries.ru",
        "name": "Держатель попсокет для телефона, кольцо",
        "price": 324,
        "old_price": 2499,
        "reviews": 2,
        "seller": "CasElen",
        "seller_full": "ИП Бревнова Е. П",
        "brand": "CasElen",
        "position": 146
    },
    {
        "site": "wildberries.ru",
        "name": "Пижама женская шелковая с брюками и рубашкой",
        "price": 2182,
        "old_price": 7090,
        "reviews": 2,
        "seller": "Maternity",
        "seller_full": "ИП Байдаева Л. С.",
        "brand": "Maternity",
        "position": 18
    },
    {
        "site": "wildberries.ru",
        "name": "Вешалка настенная металлическая лофт",
        "price": 4928,
        "old_price": 11000,
        "reviews": 2,
        "seller": "LOFT18",
        "seller_full": "ИП Шишкин К. А.",
        "brand": "LOFT18"
    },
    {
        "site": "wildberries.ru",
        "name": "Платье вечернее",
        "price": 1003,
        "old_price": 3350,
        "reviews": 11,
        "seller": "Sapar_Shop",
        "seller_full": "ИП Сапаров Х. Х.",
        "brand": "Sapar_Shop",
        "position": 109
    },
    {
        "site": "wildberries.ru",
        "name": "Сталин",
        "price": 259,
        "old_price": 490,
        "reviews": 1,
        "seller": "Книгоед",
        "seller_full": "ИП Аверин Н. В.",
        "brand": "Издательство политической литературы"
    },
    {
        "site": "wildberries.ru",
        "name": "Защитное стекло на Galaxy S22 Plus, Галакси С22+",
        "price": 227,
        "old_price": 990,
        "reviews": 45,
        "seller": "AKSPro",
        "seller_full": "ИП Дмитровский С. А.",
        "brand": "AKSPro"
    },
    {
        "site": "wildberries.ru",
        "name": "Кеды для кукол и игрушек",
        "price": 351,
        "old_price": 878,
        "reviews": 366,
        "seller": "Мир Хобби",
        "seller_full": "ООО \"БЭСТЕКС\"",
        "brand": "Astra&Craft",
        "position": 45
    },
    {
        "site": "wildberries.ru",
        "name": "Термобирки на одежду 4А именная термобирка в школу",
        "price": 320,
        "old_price": 500,
        "reviews": 4,
        "seller": "NAKED SKIN  IVUSHKA",
        "seller_full": "PUCHKOV",
        "brand": "1VUSHKA-art",
        "position": 45
    },
    {
        "site": "wildberries.ru",
        "name": "Школьные папки Гравити Фолз",
        "price": 314,
        "old_price": 604,
        "reviews": 2,
        "seller": "G.STORE",
        "seller_full": "ИП Гейко А. С.",
        "brand": "Disney",
        "position": 239
    },
    {
        "site": "wildberries.ru",
        "name": "аниме клинок рассекающий демонов шинобу кочо танд",
        "price": 851,
        "old_price": 1980,
        "reviews": 1,
        "seller": "ИП Искужин Марат Сагитуллович",
        "seller_full": "ИП Искужин Марат Сагитуллович",
        "brand": "Герои",
        "position": 166
    },
    {
        "site": "ozon.ru",
        "name": "Выпрямитель для волос HQT HQT-909B ,белый",
        "price": 654,
        "old_price": 1150,
        "reviews": 3,
        "seller": "MarketONE",
        "seller_full": "ИП Попов Александр",
        "brand": "HQT",
        "position": 52
    },
    {
        "site": "wildberries.ru",
        "name": "Тюль лен 250x260",
        "price": 1432,
        "old_price": 5400,
        "reviews": 1,
        "seller": "КБ Текстиль",
        "seller_full": "ИП \"Клименок\"",
        "brand": "Готовые Шторы ЭМИ",
        "position": 203
    },
    {
        "site": "ozon.ru",
        "name": "Футболка US Basic",
        "price": 910,
        "old_price": 1890,
        "reviews": 1,
        "seller": "fun.ru",
        "seller_full": "ООО ПРИНТ МИ",
        "brand": "US Basic",
        "position": 160
    },
    {
        "site": "ozon.ru",
        "name": "Колпачки на ниппели S-line Motorsports 4 шт.",
        "price": 596,
        "old_price": 630,
        "reviews": 3,
        "seller": "Auto-Tuning",
        "seller_full": "ИП Погосян Карен Асканазович",
        "position": 201
    },
    {
        "site": "wildberries.ru",
        "name": "полотенце на подарок папе и дедушке",
        "price": 1152,
        "old_price": 2990,
        "reviews": 342,
        "seller": "Kara studio",
        "seller_full": "Карамян Сурен Николаевич",
        "brand": "Kara studio",
        "position": 107
    },
    {
        "site": "ozon.ru",
        "name": "Карниз для штор, карниз для штор трехрядный, карниз потолочный, карниз , 200 см, прямой , с блендой ПРЕМЬЕРА 01",
        "price": 958,
        "old_price": 1946,
        "reviews": 175,
        "seller": "ПОСТАВЩИКОФФ",
        "seller_full": "ИП Ильюхина Надежда Константиновна",
        "brand": "ПоставщикоФФ",
        "position": 144
    },
    {
        "site": "ozon.ru",
        "name": "Сумка на плечо Шоппер Aksisur/Shopper Животные",
        "price": 490,
        "old_price": 1790,
        "reviews": 7,
        "seller": "AKSISUR",
        "seller_full": "ИП Туголуков Феодор Николаевич",
        "brand": "Aksisur/Shopper"
    },
    {
        "site": "wildberries.ru",
        "name": "Скотт В. Айвенго",
        "price": 486,
        "old_price": 648,
        "reviews": 1,
        "seller": "Dreamantica",
        "seller_full": "ИП Карпенко Н. С.",
        "brand": "Амфора"
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка",
        "price": 429,
        "old_price": 1300,
        "reviews": 5,
        "seller": "Souvenirs",
        "seller_full": "Souvenirs",
        "brand": "souvenirs"
    },
    {
        "site": "wildberries.ru",
        "name": "Пигмент для татуажа бровей Bronzer 15 мл",
        "price": 2950,
        "old_price": 5018,
        "reviews": 3,
        "seller": "Lucky Master",
        "seller_full": "ИП Фомичев А. Г.",
        "brand": "Perma Blend"
    },
    {
        "site": "wildberries.ru",
        "name": "Кепка граффити kpop Stray Kids, BTS, NCT",
        "price": 623,
        "old_price": 1400,
        "reviews": 14,
        "seller": "ProСфера",
        "seller_full": "ИП Пастушенко Н. А.",
        "brand": "ProСфера",
        "position": 136
    },
    {
        "site": "wildberries.ru",
        "name": "2 шт. Рамка Systeme Atlas Design, 3 поста (Карбон)",
        "price": 762,
        "old_price": 978,
        "reviews": 20,
        "seller": "СИНЭЛ-ЭЛЕКТРИКА",
        "seller_full": "ООО \"СИНЭЛ-ЭЛЕКТРИКА\"",
        "brand": "Schneider",
        "position": 35
    },
    {
        "site": "wildberries.ru",
        "name": "Кисти подхваты шторные",
        "price": 1092,
        "old_price": 2000,
        "reviews": 1,
        "seller": "ИП Кенжетаева",
        "seller_full": "ВСЕ ДЛЯ ДОМА И УДОБСТВА",
        "brand": "ИП Кенжетаева",
        "position": 200
    },
    {
        "site": "wildberries.ru",
        "name": "Нагревательный элемент (ТЭН оттайки) для холодильника",
        "price": 1702,
        "old_price": 2270,
        "reviews": 1,
        "seller": "MegaZip",
        "seller_full": "Головень Татьяна Ивановна ИП",
        "brand": "BEKO",
        "position": 205
    },
    {
        "site": "ozon.ru",
        "name": "Элемент зеркала KIA Sephia I c 1993 по 1999 левый сферический c обогревом 50190708",
        "price": 876,
        "old_price": 1164,
        "reviews": 2,
        "seller": "Автоград",
        "seller_full": "ИП Виноградов Сергей Владимирович",
        "brand": "Ergon"
    },
    {
        "site": "wildberries.ru",
        "name": "Носки хлопковые с принтом 5 пар",
        "price": 368,
        "old_price": 1600,
        "reviews": 4,
        "seller": "Fashion MGS",
        "seller_full": "М. Г. С.",
        "brand": "Fashion MGS"
    },
    {
        "site": "wildberries.ru",
        "name": "Горизонтальные алюминиевые жалюзи 50*140",
        "price": 1628,
        "old_price": 3600,
        "reviews": 2,
        "seller": "Кравис",
        "seller_full": "ИП Дербенева Н. В.",
        "brand": "Кравис",
        "position": 68
    },
    {
        "site": "wildberries.ru",
        "name": "Набор из 2-х чайных пар \"Маки\", 500 мл",
        "price": 2031,
        "old_price": 8260,
        "reviews": 12,
        "seller": "Elan Gallery",
        "seller_full": "ООО ЭЛАН ГАЛЕРЕЯ",
        "brand": "Elan Gallery",
        "position": 54
    },
    {
        "site": "wildberries.ru",
        "name": "Наклейка на банковскую карту проездной",
        "price": 150,
        "old_price": 500,
        "reviews": 70,
        "seller": "StickerPunk",
        "seller_full": "ИП Камнев Нестор Евгеньевич",
        "brand": "ЭтоТебе"
    },
    {
        "site": "ozon.ru",
        "name": "Тумба под раковину Aquanet Nova Lite 90 дуб рустикальный (1 ящик)",
        "price": 24642,
        "old_price": 26301,
        "reviews": 2,
        "seller": "ТЕХНИКА",
        "seller_full": "ООО \"ТЕХНИКА\"",
        "brand": "Aquanet",
        "position": 158
    },
    {
        "site": "wildberries.ru",
        "name": "Всеволод Иванов. Собрание сочинений в восьми томах. Том 8",
        "price": 431,
        "old_price": 490,
        "reviews": 1,
        "seller": "Книгоед",
        "seller_full": "ИП Аверин Н. В.",
        "brand": "Художественная литература. Москва",
        "position": 212
    },
    {
        "site": "wildberries.ru",
        "name": "Органический лосьон для тела Пряный Цветок Апельсина 245 мл",
        "price": 1440,
        "old_price": 2812,
        "reviews": 2,
        "seller": "Ecovershop",
        "seller_full": "Афанасьев Алексей Анатольевич ИП",
        "brand": "Urtekram"
    },
    {
        "site": "ozon.ru",
        "name": "Набор инструментов 72 предмета 1/2\" 1/4\" 6-гранный хромированный JBM",
        "price": 9055,
        "old_price": 16000,
        "reviews": 20,
        "seller": "JBM",
        "seller_full": "ИП Переладов Евгений Анатольевич",
        "brand": "JBM",
        "position": 108
    },
    {
        "site": "wildberries.ru",
        "name": "Бусины для рукоделия набор сумок буквы русские английские",
        "price": 158,
        "old_price": 990,
        "reviews": 793,
        "seller_full": "ИП Посевкина Н. Г.",
        "brand": "MILLONTI",
        "position": 126
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол Галакси А52",
        "price": 469,
        "old_price": 999,
        "reviews": 1,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "Mobi711",
        "position": 5
    },
    {
        "site": "wildberries.ru",
        "name": "Плетеный шнур для рыбалки ProFix Dark-Green 100m 0.18mm",
        "price": 218,
        "old_price": 445,
        "reviews": 67,
        "seller": "F-STORE",
        "seller_full": "ИП Служаева А.В.",
        "brand": "AQUA",
        "position": 217
    },
    {
        "site": "wildberries.ru",
        "name": "Сапоги демисезонные натуральная кожа на каблуке",
        "price": 9161,
        "old_price": 19500,
        "reviews": 293,
        "seller": "Shoes for woman",
        "seller_full": "Shoes for woman",
        "brand": "Sevani",
        "position": 30
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка Alblak 52 Friendly Thug 52 Алблак Alblak52",
        "price": 874,
        "old_price": 2000,
        "reviews": 15,
        "seller": "НапринтЕ",
        "seller_full": "ИП Чобанян Г. Г.",
        "brand": "напринте",
        "position": 157
    },
    {
        "site": "wildberries.ru",
        "name": "Кровать двухспальная металлическая 160х200 см",
        "price": 11279,
        "old_price": 14099,
        "reviews": 145,
        "seller": "Tove",
        "seller_full": "ООО \"МЕТАЛЛ-ЗАВОД\"",
        "brand": "Tove",
        "position": 234
    },
    {
        "site": "wildberries.ru",
        "name": "Утепленный спортивный костюм больших размеров",
        "price": 5482,
        "old_price": 9400,
        "reviews": 738,
        "seller": "MODISON",
        "seller_full": "MODISON",
        "brand": "-",
        "position": 56
    },
    {
        "site": "wildberries.ru",
        "name": "Тепловая маска Балаклава арт. ТМ 1.1. (белый) 1шт",
        "price": 3310,
        "old_price": 7859,
        "reviews": 2,
        "seller": "БУДЬ ГОТОВ",
        "seller_full": "КАРТБЭЙ ООО",
        "brand": "Сайвер",
        "position": 252
    },
    {
        "site": "wildberries.ru",
        "name": "Сумка женская через плечо маленькая кросс-боди",
        "price": 940,
        "old_price": 2460,
        "reviews": 11,
        "seller": "TopMary",
        "seller_full": "ИП Ивницкая М. М.",
        "brand": "TopMary",
        "position": 251
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для телефона",
        "price": 3750,
        "old_price": 5000,
        "reviews": 7,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "-",
        "position": 127
    },
    {
        "site": "wildberries.ru",
        "name": "Джемпер",
        "price": 1598,
        "old_price": 1973,
        "reviews": 67,
        "seller": "oodji",
        "seller_full": "ООО \"АВГУСТ\"",
        "brand": "oodji",
        "position": 150
    },
    {
        "site": "ozon.ru",
        "name": "Алмазная мозаика \"Пресвятая Богородица Неувядаемый цвет \", икона 17х23, Диамант",
        "price": 758,
        "old_price": 1325,
        "reviews": 1,
        "seller": "Рукоделие и вышивка",
        "seller_full": "ИП Дударева Ольга Владимировна",
        "brand": "Диамант"
    },
    {
        "site": "wildberries.ru",
        "name": "Ботинки",
        "price": 10926,
        "old_price": 13490,
        "reviews": 22,
        "seller": "Rendez-Vous",
        "seller_full": "ООО \"РАНДЕВУ\"",
        "brand": "MAISON DAVID",
        "position": 166
    },
    {
        "site": "wildberries.ru",
        "name": "Поднос декоративный мраморный на кухню",
        "price": 2486,
        "old_price": 3790,
        "reviews": 28,
        "seller": "NATSTONE69",
        "seller_full": "ИП Лисина Ю. В.",
        "brand": "NATSTONE69",
        "position": 257
    },
    {
        "site": "wildberries.ru",
        "name": "Рюкзак женский спортивный в подарок школьный городской",
        "price": 1321,
        "old_price": 4458,
        "reviews": 8854,
        "seller": "Вишневская",
        "seller_full": "ИП Вишневская",
        "brand": "Renessa",
        "position": 215
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол-накладка Clear Case для Samsu",
        "price": 306,
        "old_price": 990,
        "reviews": 9,
        "seller": "ИП Птушкин Олег Евгеньевич",
        "seller_full": "ИП Птушкин Олег Евгеньевич",
        "brand": "Krutoff",
        "position": 204
    },
    {
        "site": "wildberries.ru",
        "name": "Трусы стринги хлопковые 1 штука",
        "price": 627,
        "old_price": 1440,
        "reviews": 12,
        "seller_full": "ЛАУРИ-СТИЛЬ ООО",
        "brand": "Lowry",
        "position": 11
    },
    {
        "site": "wildberries.ru",
        "name": "Кроссовки на платформе",
        "price": 2981,
        "old_price": 8180,
        "reviews": 23,
        "seller": "ОБУВЬ ПЛЮС",
        "seller_full": "ООО \"Обувь плюс\"",
        "brand": "DONNA VERRA",
        "position": 88
    },
    {
        "site": "wildberries.ru",
        "name": "Планшет для рисования детский",
        "price": 396,
        "old_price": 1800,
        "reviews": 310,
        "seller_full": "ИП Плотников",
        "brand": "Smart Toys Store",
        "position": 44
    },
    {
        "site": "wildberries.ru",
        "name": "Защитная гидрогелевая пленка на экран для Oppo Reno 2Z, глян…",
        "price": 168,
        "old_price": 248,
        "reviews": 6,
        "seller": "SmartAcc",
        "seller_full": "ИП Горлов Н. Н.",
        "brand": "SmartAcc",
        "position": 155
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол-книжка на Huawei NOVA Y90 (для Нова У90)",
        "price": 323,
        "old_price": 850,
        "reviews": 3,
        "seller": "NOOTO",
        "seller_full": "ИП Кирюшина Екатерина Игоревна",
        "brand": "NOOTO",
        "position": 192
    },
    {
        "site": "wildberries.ru",
        "name": "Грызунок деревянный Сердце",
        "price": 180,
        "old_price": 180,
        "reviews": 13,
        "seller": "ИП Little Angels",
        "seller_full": "ИП Little Angels",
        "brand": "ИП Little Angels"
    },
    {
        "site": "wildberries.ru",
        "name": "Сумка для тренировок",
        "price": 787,
        "old_price": 1180,
        "reviews": 4,
        "seller": "Luris",
        "seller_full": "ИП Бердников А. В.",
        "brand": "Luris",
        "position": 90
    },
    {
        "site": "wildberries.ru",
        "name": "Часы наручные",
        "price": 210,
        "old_price": 725,
        "reviews": 13,
        "seller": "Sharry's Store",
        "seller_full": "ИП Шахриар М.",
        "brand": "Sharry’s",
        "position": 209
    },
    {
        "site": "wildberries.ru",
        "name": "Постельное белье 1.5 спальное хлопок нав. 70х70 см",
        "price": 1525,
        "old_price": 6000,
        "reviews": 1,
        "seller": "ТД С Текстиль",
        "seller_full": "ООО «Хоум Стайл»",
        "brand": "Спал Спалыч",
        "position": 67
    },
    {
        "site": "ozon.ru",
        "name": "Топ La selva",
        "price": 2129,
        "old_price": 4900,
        "reviews": 220,
        "seller": "La selva",
        "seller_full": "ИП Горохов Евгений Юрьевич",
        "brand": "La selva",
        "position": 39
    },
    {
        "site": "wildberries.ru",
        "name": "Наклейки стикеры на телефон 1 мая Молот Солдат Плакат Союз",
        "price": 290,
        "old_price": 290,
        "reviews": 3,
        "seller": "BELOUSOV",
        "seller_full": "ИП Белоусов Александр Викторович",
        "brand": "Российская Фабрика Виниловых Наклеек"
    },
    {
        "site": "ozon.ru",
        "name": "Sketch with Asia. Рисуем в стиле аниме и манга. | Ладовска Асия",
        "price": 1107,
        "old_price": 1628,
        "reviews": 121,
        "seller": "Издательство АСТ",
        "seller_full": "ООО Белония М",
        "brand": "АСТ",
        "position": 257
    },
    {
        "site": "wildberries.ru",
        "name": "Куртка осенняя прямого кроя стильная на молнии",
        "price": 12752,
        "old_price": 17690,
        "reviews": 9,
        "seller": "Emka Outlet",
        "seller_full": "ТЕКСТУРА ООО",
        "brand": "EMKA FASHION",
        "position": 71
    },
    {
        "site": "ozon.ru",
        "name": "Халат Mia-Mia OLIVIA",
        "price": 3244,
        "old_price": 6922,
        "reviews": 10,
        "seller": "Mary Rose",
        "seller_full": "ИП Рыбников Александр Викторович",
        "brand": "Mia-Mia"
    },
    {
        "site": "wildberries.ru",
        "name": "Матовый чехол на Honor 50 Lite",
        "price": 265,
        "old_price": 985,
        "reviews": 40,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "Караханян Эдуард Сергеевич",
        "brand": "Mobi711"
    },
    {
        "site": "wildberries.ru",
        "name": "платье",
        "price": 1093,
        "old_price": 5000,
        "reviews": 37,
        "seller": "УДАЧА ДНЯ",
        "seller_full": "тотменина анна александровна",
        "brand": "УДАЧА ДНЯ",
        "position": 145
    },
    {
        "site": "wildberries.ru",
        "name": "Джемпер",
        "price": 832,
        "old_price": 2390,
        "reviews": 5,
        "seller_full": "ЮНАЙТЭД ТРЭЙДИНГ ООО",
        "brand": "Colin's"
    },
    {
        "site": "ozon.ru",
        "name": "Аккумулятор для ноутбука Samsung NP-RC720",
        "price": 1536,
        "old_price": 2062,
        "reviews": 3,
        "seller": "PartsNb.ru",
        "seller_full": "ИП Тарлаковский Леонид Станиславович",
        "brand": "Greenway",
        "position": 173
    },
    {
        "site": "ozon.ru",
        "name": "Футболка 3, шт",
        "price": 1173,
        "old_price": 3000,
        "reviews": 8,
        "seller": "Футболка с принтом в подарок",
        "seller_full": "Самозанятый Горбачев Павел Тимофеевич",
        "position": 245
    },
    {
        "site": "wildberries.ru",
        "name": "Колье с именной подвеской Людмила",
        "price": 1626,
        "old_price": 3200,
        "reviews": 40,
        "seller": "Khripsi",
        "seller_full": "ИП Шмелева Оксана Игоревна",
        "brand": "Khripsi",
        "position": 217
    },
    {
        "site": "wildberries.ru",
        "name": "The North Face Сумка мужская женская через плечо",
        "price": 711,
        "old_price": 1427,
        "reviews": 5,
        "seller": "Street_head",
        "seller_full": "Street-head",
        "brand": "Street head",
        "position": 22
    },
    {
        "site": "wildberries.ru",
        "name": "Ковер комнатный, 80х120, Лаос, безворсовой",
        "price": 1917,
        "old_price": 4032,
        "reviews": 23,
        "seller": "Клеопатра",
        "seller_full": "ЗАО \"КЛЕОПАТРА ТРЕЙДИНГ КО\"",
        "brand": "Cleopatra"
    },
    {
        "site": "wildberries.ru",
        "name": "Джинсы",
        "price": 5489,
        "old_price": 22590,
        "reviews": 5,
        "seller_full": "ООО ВАЙЛДБЕРРИЗ",
        "brand": "7 for all mankind",
        "position": 256
    },
    {
        "site": "wildberries.ru",
        "name": "Пилка для ногтей маникюра и педикюра профессиональная",
        "price": 590,
        "old_price": 2343,
        "reviews": 285,
        "seller": "H&D",
        "seller_full": "ИП Омарова Джамиля Варисовна",
        "brand": "MR CARE",
        "position": 145
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол микрофибра для Xiaomi Redmi Note 12S",
        "price": 297,
        "old_price": 850,
        "reviews": 17,
        "seller": "CaseRu",
        "seller_full": "Муминов Исломбек",
        "brand": "CaseRu",
        "position": 34
    },
    {
        "site": "wildberries.ru",
        "name": "Бак масляный Yamaha Jog 3KJ",
        "price": 911,
        "old_price": 1551,
        "reviews": 2,
        "seller": "Скутер Персонал",
        "seller_full": "ИП Антонец Дмитрий Александрович",
        "brand": "Скутер Персонал",
        "position": 116
    },
    {
        "site": "wildberries.ru",
        "name": "Лимонная кислота пищевая порошок 900 грамм",
        "price": 223,
        "old_price": 355,
        "reviews": 10,
        "seller": "Специи",
        "seller_full": "ООО ГК «ОПТОТОРГ»",
        "brand": "PRODOTTO DELICATEZZA",
        "position": 164
    },
    {
        "site": "wildberries.ru",
        "name": "Маленькая сумочка клатч с ремешком",
        "price": 1272,
        "old_price": 3400,
        "reviews": 3,
        "seller": "Bag Station",
        "seller_full": "ИП Димухаметов",
        "brand": "Bag Station"
    },
    {
        "site": "wildberries.ru",
        "name": "Клавиатура для Samsung NP370R5E, NP450R5E плоский Enter",
        "price": 2009,
        "old_price": 2450,
        "reviews": 1,
        "seller": "Техноцентр",
        "seller_full": "ИП Кашинский А. С.",
        "brand": "Azerty"
    },
    {
        "site": "wildberries.ru",
        "name": "Укрывной материал для растений",
        "price": 634,
        "old_price": 880,
        "reviews": 11,
        "seller": "Мама и Я",
        "seller_full": "ИП \"Комиссаров Семен Михайлович\"",
        "brand": "Мама в деле",
        "position": 121
    },
    {
        "site": "wildberries.ru",
        "name": "Рюкзак черный спортивный с карманами",
        "price": 458,
        "old_price": 1699,
        "reviews": 3,
        "seller": "3stl",
        "seller_full": "ИП Зыкова А. А.",
        "brand": "3stl"
    },
    {
        "site": "ozon.ru",
        "name": "Очки VALENCIA с футляром, мод. 32335 Цвет 1 с линзами GLANCE -4.50 РЦ 64-66",
        "price": 2691,
        "old_price": 4806,
        "reviews": 1,
        "seller": "OpticZON",
        "seller_full": "ИП Свистунов Олег Русланович",
        "brand": "VALENCIA",
        "position": 61
    },
    {
        "site": "wildberries.ru",
        "name": "Стеллаж с полками над унитазом h-152см",
        "price": 1590,
        "old_price": 5524,
        "reviews": 1,
        "seller_full": "СКЛАД ДИРЕКТ ООО",
        "brand": "Vetta",
        "position": 165
    },
    {
        "site": "wildberries.ru",
        "name": "Лампа паяльная Профессионал САМОТЛОР",
        "price": 2964,
        "old_price": 5430,
        "reviews": 1,
        "seller": "Avtogen",
        "seller_full": "Гавристов Артем Александрович ИП",
        "brand": "ЗУБР",
        "position": 214
    },
    {
        "site": "wildberries.ru",
        "name": "Постельное белье Семейное 70%бамбук 30%хлопок",
        "price": 5299,
        "old_price": 13190,
        "reviews": 1,
        "seller": "Togas",
        "seller_full": "ООО \"ТОГАС ОНЛАЙН\"",
        "brand": "SLEEPIX",
        "position": 204
    },
    {
        "site": "wildberries.ru",
        "name": "брюки",
        "price": 1012,
        "old_price": 5000,
        "reviews": 205,
        "seller": "Raima",
        "seller_full": "Раима",
        "brand": "Raima",
        "position": 13
    },
    {
        "site": "wildberries.ru",
        "name": "Новогодний чехол на Redmi Note 11 4G Redmi Note 11S",
        "price": 379,
        "old_price": 999,
        "reviews": 1,
        "seller": "RONNIE",
        "seller_full": "ИП Сыворотко К. К.",
        "brand": "Ronnie",
        "position": 47
    },
    {
        "site": "ozon.ru",
        "name": "Силиконовый чехол Портрет тигра на Realme 8i / Рилми 8 ай",
        "price": 347,
        "old_price": 740,
        "reviews": 13,
        "seller": "Re:pa Store",
        "seller_full": "ИП Грищев Владимир Викторович",
        "brand": "RE:PA"
    },
    {
        "site": "wildberries.ru",
        "name": "Табличка запасный выход из пластика белая 2",
        "price": 380,
        "old_price": 1000,
        "reviews": 65,
        "seller_full": "ООО \"НОН-СТОП\"",
        "brand": "Нон-Стоп",
        "position": 173
    },
    {
        "site": "wildberries.ru",
        "name": "Спот Lettura A7009AP-1BR",
        "price": 1773,
        "old_price": 2163,
        "reviews": 3,
        "seller": "220svet.ru",
        "seller_full": "ИП Гусев А. Ю.",
        "brand": "ARTE LAMP",
        "position": 164
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Honor 8A Pro Хонор 8А Про c рисунком прозрачный с п…",
        "price": 307,
        "old_price": 684,
        "reviews": 3,
        "seller": "Andy&Paul",
        "seller_full": "ИП Коновалов П. А.",
        "brand": "Andy&Paul",
        "position": 108
    },
    {
        "site": "wildberries.ru",
        "name": "Купальник",
        "price": 1701,
        "old_price": 4200,
        "reviews": 1,
        "seller_full": "ООО ВАЙЛДБЕРРИЗ",
        "brand": "Lovely Jewelry",
        "position": 191
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для телефона",
        "price": 3750,
        "old_price": 5000,
        "reviews": 1,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "-",
        "position": 194
    },
    {
        "site": "wildberries.ru",
        "name": "Матрас на кушетку",
        "price": 2312,
        "old_price": 5900,
        "reviews": 497,
        "seller": "BEAUTY BLOG",
        "seller_full": "ИП Каляев В. Д.",
        "brand": "BEAUTY BLOG",
        "position": 207
    },
    {
        "site": "ozon.ru",
        "name": "Картина по номерам Волна на закате, 40 х 50 см",
        "price": 1335,
        "old_price": 2090,
        "reviews": 2,
        "seller": "Красиво Красим",
        "seller_full": "ИП Краев Дмитрий Александрович",
        "brand": "Красиво Красим",
        "position": 258
    },
    {
        "site": "ozon.ru",
        "name": "Корзина для пикника, 24 предм. на 4 перс.",
        "price": 9070,
        "old_price": 10900,
        "reviews": 1,
        "seller": "ООО \"Правос\"",
        "seller_full": "ООО Правос"
    },
    {
        "site": "ozon.ru",
        "name": "Гель для наращивания ногтей, гель для моделирования и укрепления ногтей, прозрачный самовыравнивающийся гель для ногтей, жидкий гель лак, LED/UV Building Gel Clear, 14 мл",
        "price": 820,
        "old_price": 1570,
        "reviews": 1,
        "seller": "Компания Виктори",
        "seller_full": "ИП Муравьев Алексей Алексеевич",
        "brand": "ibd",
        "position": 49
    },
    {
        "site": "ozon.ru",
        "name": "Обои Артекс Nature 1.06 на 10.05 S10365-03 на флизелиновой основе метровые, цвет бирюзовый, моющиеся, рисунок однотонный",
        "price": 2824,
        "old_price": 3648,
        "reviews": 2,
        "seller": "МАСТЕРДОМ",
        "seller_full": "ИП Цыпкин Александр Владимирович",
        "brand": "Артекс",
        "position": 73
    },
    {
        "site": "wildberries.ru",
        "name": "Ткань мебельная обивочная велюр",
        "price": 409,
        "old_price": 759,
        "reviews": 231,
        "seller": "PANNO",
        "seller_full": "PANNO",
        "brand": "Panno",
        "position": 142
    },
    {
        "site": "wildberries.ru",
        "name": "Шапка зимняя рваная",
        "price": 1270,
        "old_price": 2800,
        "reviews": 16,
        "seller": "myata.showroom",
        "seller_full": "ИП Сушкова А. А.",
        "brand": "myata.showroom",
        "position": 242
    },
    {
        "site": "wildberries.ru",
        "name": "Набор елочных украшений Домики 3 шт",
        "price": 385,
        "old_price": 550,
        "reviews": 3,
        "seller": "Груша",
        "seller_full": "ИП Мухин А. М.",
        "brand": "Новый год!",
        "position": 109
    },
    {
        "site": "wildberries.ru",
        "name": "Свитер",
        "price": 1190,
        "old_price": 3419,
        "reviews": 14,
        "seller": "KOTON",
        "seller_full": "ООО «Котон Текстиль»",
        "brand": "KOTON",
        "position": 208
    },
    {
        "site": "wildberries.ru",
        "name": "Комбинезон утепленный из футера с начесом",
        "price": 1146,
        "old_price": 5900,
        "reviews": 5,
        "seller": "LESAVA",
        "seller_full": "ИП Дячок Е. И.",
        "brand": "Lesava",
        "position": 128
    },
    {
        "site": "wildberries.ru",
        "name": "Скатерть на стол принт для кухни",
        "price": 812,
        "old_price": 3670,
        "reviews": 19,
        "seller": "Текстиль и декор от производителя",
        "seller_full": "Байдужий Андрей Анатольевич ИП",
        "brand": "Gustav House"
    },
    {
        "site": "wildberries.ru",
        "name": "Сухой корм для кошек ягненок и черника низкозерновой 300г",
        "price": 656,
        "old_price": 738,
        "reviews": 12,
        "seller": "Зоотрейд",
        "seller_full": "ИП Королев А. С.",
        "brand": "FARMINA",
        "position": 2
    },
    {
        "site": "wildberries.ru",
        "name": "Витамин Б12 спрей от усталости для иммунитета и энергии",
        "price": 743,
        "old_price": 1990,
        "reviews": 51,
        "seller": "MyHerb",
        "seller_full": "ИП Алигаджиева П. О.",
        "brand": "Swiss bork"
    },
    {
        "site": "wildberries.ru",
        "name": "Водолазка тонкая с длинным рукавом",
        "price": 1304,
        "old_price": 3500,
        "reviews": 4,
        "seller": "H&V OF WHITE",
        "seller_full": "Гасанов Вусал Аскер Оглы ИП",
        "brand": "Of White Exclusive",
        "position": 223
    },
    {
        "site": "wildberries.ru",
        "name": "Соус \"Терияки сладкий\" пэт-бутылка 250 мл",
        "price": 135,
        "old_price": 209,
        "reviews": 34,
        "seller": "Си-Проджект",
        "seller_full": "СИ-ПРОДЖЕКТ АО",
        "brand": "Asian Fusion"
    },
    {
        "site": "wildberries.ru",
        "name": "Набор тканей для рукоделия и пэчворка, 8 лоскутов 30х30",
        "price": 400,
        "old_price": 400,
        "reviews": 2,
        "seller": "Атмосферные Решения",
        "seller_full": "ИП Ворончихин Д. А.",
        "brand": "Атмосферные Решения",
        "position": 168
    },
    {
        "site": "wildberries.ru",
        "name": "Натуральное Эфирное Масло Лаванды 5 мл",
        "price": 229,
        "old_price": 442,
        "reviews": 302,
        "seller": "Уходовая косметика",
        "seller_full": "Индивидуальный предприниматель Калинин Дмитрий Николаевич",
        "brand": "Viva Lavanda",
        "position": 148
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка Люди Икс X-Men Циклоп",
        "price": 600,
        "old_price": 2300,
        "reviews": 2,
        "seller": "Rock'n'rolla",
        "seller_full": "ИП Тананян В. А.",
        "brand": "Rock'n'Rolla",
        "position": 103
    },
    {
        "site": "wildberries.ru",
        "name": "Свеча накаливания для а м Mercedes-Benz G W463 GLSP 051",
        "price": 612,
        "old_price": 1285,
        "reviews": 7,
        "seller": "CARVILLESHOP",
        "seller_full": "НПО ТАЛИС ООО",
        "brand": "STARTVOLT",
        "position": 21
    },
    {
        "site": "wildberries.ru",
        "name": "Буженина 325 г 1 шт",
        "price": 332,
        "old_price": 852,
        "reviews": 7,
        "seller": "БУДЬ ГОТОВ",
        "seller_full": "КАРТБЭЙ ООО",
        "brand": "Деревня Потанино",
        "position": 175
    },
    {
        "site": "wildberries.ru",
        "name": "Большой набор воздушных шаров с цифрой 19",
        "price": 712,
        "old_price": 2967,
        "reviews": 8,
        "seller": "MagFresh",
        "seller_full": "ИП Бортников Е. Г.",
        "brand": "MagFresh",
        "position": 259
    },
    {
        "site": "wildberries.ru",
        "name": "Именной брелок на ключи с именем Рашид",
        "price": 399,
        "old_price": 420,
        "reviews": 1,
        "seller": "GraverMag",
        "seller_full": "ИП Ковтун Г. Ю.",
        "brand": "GraverMag",
        "position": 140
    },
    {
        "site": "wildberries.ru",
        "name": "Ремешок для эпл вотч, ремешок на apple watch, 42-44-45-49 mm",
        "price": 538,
        "old_price": 1361,
        "reviews": 27,
        "seller": "Tatti Shoes",
        "seller_full": "ИП Танчевская Т. С.",
        "brand": "Smart Watch 42-45",
        "position": 44
    },
    {
        "site": "wildberries.ru",
        "name": "Рубашка женская",
        "price": 2011,
        "old_price": 5399,
        "reviews": 3,
        "seller": "LC Waikiki",
        "seller_full": "ООО \"ТЕМА РИТЕЙЛ РУ\"",
        "brand": "LC Waikiki",
        "position": 48
    },
    {
        "site": "ozon.ru",
        "name": "Футболка PANiN",
        "price": 1475,
        "old_price": 3599,
        "reviews": 1,
        "seller": "Good Room",
        "seller_full": "ИП Морозов Алексей Сергеевич",
        "brand": "PANiN"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для телефона",
        "price": 3750,
        "old_price": 5000,
        "reviews": 2,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "-"
    },
    {
        "site": "wildberries.ru",
        "name": "Штаны - дождевик EVO",
        "price": 4583,
        "old_price": 7860,
        "reviews": 2,
        "seller": "ТМ ТРЕЙД",
        "seller_full": "ООО \"ТМ ТРЕЙД\"",
        "brand": "Dragonfly",
        "position": 99
    },
    {
        "site": "wildberries.ru",
        "name": "Набор клей Neo 5 мл и 50 патчей",
        "price": 1149,
        "old_price": 1369,
        "reviews": 1,
        "seller": "Beauty Bro",
        "seller_full": "ИП Альметов В. Г.",
        "brand": "Lovely"
    },
    {
        "site": "wildberries.ru",
        "name": "Браслет женский Версаче медуза Горгона",
        "price": 902,
        "old_price": 1550,
        "reviews": 6,
        "seller": "Nobility premium collection",
        "seller_full": "ИП Ускова Е. Е.",
        "brand": "Nobility premium collection",
        "position": 107
    },
    {
        "site": "wildberries.ru",
        "name": "Лосьон-спрей для тела с ароматом малины и черной смородины",
        "price": 474,
        "old_price": 850,
        "reviews": 9,
        "seller": "МОЯ КОСМЕТИЧКА",
        "seller_full": "ИП Анохина Л. А.",
        "brand": "AVON",
        "position": 159
    },
    {
        "site": "wildberries.ru",
        "name": "Кроссовки",
        "price": 1992,
        "old_price": 6000,
        "reviews": 10,
        "seller": "EN RIKKI",
        "seller_full": "EN RIKKI",
        "brand": "EN RIKKI",
        "position": 25
    },
    {
        "site": "ozon.ru",
        "name": "Флеш-карта USB 2.0 16 Gb \"Орландо\", цвет зеленый",
        "price": 569,
        "old_price": 1438,
        "reviews": 3,
        "seller": "Oasis",
        "seller_full": "ООО \"МАКСИМУМ\"",
        "brand": "Oasis",
        "position": 124
    },
    {
        "site": "wildberries.ru",
        "name": "Пижама теплая со штанами",
        "price": 773,
        "old_price": 2172,
        "reviews": 4,
        "seller": "Profit",
        "seller_full": "ООО \"ПРОФИТ\"",
        "brand": "BONINIKIDS"
    },
    {
        "site": "wildberries.ru",
        "name": "Защитное стекло антишпион на телефон Xiaomi Redmi 9A",
        "price": 349,
        "old_price": 538,
        "reviews": 3,
        "seller": "КингПро",
        "seller_full": "КИНГПРО ООО",
        "brand": "RageX",
        "position": 20
    },
    {
        "site": "ozon.ru",
        "name": "Пятизубец 27 см",
        "price": 3461,
        "old_price": 6051,
        "reviews": 1,
        "seller": "Подводное снаряжение \"Акула\"",
        "seller_full": "ИП Трунев Юрий Сергеевич",
        "position": 157
    },
    {
        "site": "wildberries.ru",
        "name": "Ткань для рукоделия хлопок отрез метражом для шитья",
        "price": 844,
        "old_price": 2638,
        "reviews": 104,
        "seller": "Ткани Shilla",
        "seller_full": "ИП Брауэр Анастасия Константиновна",
        "brand": "Ткани Shilla",
        "position": 50
    },
    {
        "site": "wildberries.ru",
        "name": "Рюкзак тактический спортивный 40 литров",
        "price": 3315,
        "old_price": 6250,
        "reviews": 8,
        "seller": "сетка2",
        "seller_full": "ИП Хрущев С. М.",
        "brand": "Звезда-М",
        "position": 41
    },
    {
        "site": "wildberries.ru",
        "name": "Полка настенная навесная деревянная для цветов и книг",
        "price": 774,
        "old_price": 2200,
        "reviews": 971,
        "seller": "SANVUT",
        "seller_full": "ООО \"САНВУТ\"",
        "brand": "SANVUT",
        "position": 105
    },
    {
        "site": "wildberries.ru",
        "name": "Рюкзак школьный для мальчика с ортопедической спинкой",
        "price": 2639,
        "old_price": 8057,
        "reviews": 91,
        "seller": "Рельеф-Центр",
        "seller_full": "РЕЛЬЕФ-ЦЕНТР ООО",
        "brand": "Berlingo",
        "position": 72
    },
    {
        "site": "ozon.ru",
        "name": "дерева токарных резцов,UCDO,токарный инструмент для черновой обработки полукруглой формы диаметром 20мм 1шт,Общая длина 420 мм",
        "price": 1919,
        "old_price": 3155,
        "reviews": 5,
        "seller": "Официальный магазин UCDO",
        "seller_full": "ShiFang Computer Network Services Kunshan Co., Ltd",
        "brand": "UCDO",
        "position": 12
    },
    {
        "site": "wildberries.ru",
        "name": "Аниме Бокс - Евангелион (18 предметов)",
        "price": 1243,
        "old_price": 5103,
        "reviews": 2,
        "seller": "AmazinGame",
        "seller_full": "ИП Кокарев Д. М.",
        "brand": "Китай",
        "position": 49
    },
    {
        "site": "ozon.ru",
        "name": "Матрас Dreamtec Spring Hypnos, Независимые пружины, 170х190 см",
        "price": 26091,
        "old_price": 47675,
        "reviews": 907,
        "seller": "Фабрика Матрасов",
        "seller_full": "ООО ФАБРИКА МАТРАСОВ",
        "brand": "Dreamtec"
    },
    {
        "site": "ozon.ru",
        "name": "Клавиатура VB для ноутбука Sony Vaio VPC-SD VPC-SB серебристая c подсветкой",
        "price": 713,
        "old_price": 1585,
        "reviews": 4,
        "seller": "Магазин-Деталей.РУ",
        "seller_full": "ООО \"ТЕХНОМИР\""
    },
    {
        "site": "wildberries.ru",
        "name": "Стопки 20 мл",
        "price": 1071,
        "old_price": 1743,
        "reviews": 20,
        "seller": "Mineral Market.",
        "seller_full": "ООО \"ТИБЕРИУМ ГОЛД\"",
        "brand": "T&Z_Mineral",
        "position": 46
    },
    {
        "site": "wildberries.ru",
        "name": "Вафельная картинка для торта Бабочки декор для выпечки",
        "price": 232,
        "old_price": 750,
        "reviews": 22,
        "seller": "тортокошка",
        "seller_full": "ИП Ушакова Н. Ю.",
        "brand": "Тортокошка",
        "position": 200
    },
    {
        "site": "wildberries.ru",
        "name": "Большая заколка-краб для волос",
        "price": 160,
        "old_price": 800,
        "reviews": 18,
        "seller": "Destrum",
        "seller_full": "ИП Десятниченко В. А.",
        "brand": "Destrum wear",
        "position": 135
    },
    {
        "site": "wildberries.ru",
        "name": "Шар воздушный Цифра 0 размер 42\" 106 см",
        "price": 227,
        "old_price": 307,
        "reviews": 9,
        "seller": "Семейный универмаг",
        "seller_full": "ИП Машкова Е.Н.",
        "brand": "нет бренда",
        "position": 71
    },
    {
        "site": "wildberries.ru",
        "name": "Валик игольчатый 240мм",
        "price": 686,
        "old_price": 1168,
        "reviews": 18,
        "seller": "ФАБРИКА УСПЕХА",
        "seller_full": "ООО ТД \"ФАБРИКА УСПЕХА\"",
        "brand": "ТУНДРА",
        "position": 128
    },
    {
        "site": "wildberries.ru",
        "name": "Липосомальный витамин С 1000 мг 60 капсул",
        "price": 3675,
        "old_price": 6999,
        "reviews": 23,
        "seller": "GreatMarket",
        "seller_full": "ИП Алибеков А. М.",
        "brand": "Dr. Mercola",
        "position": 151
    },
    {
        "site": "ozon.ru",
        "name": "Дизайнерский вертикальный чехол-книжка для Huawei Honor 50 Российский флаг и герб",
        "price": 594,
        "old_price": 1390,
        "reviews": 1,
        "seller": "100gadgets",
        "seller_full": "ООО Формула Успеха",
        "position": 228
    },
    {
        "site": "wildberries.ru",
        "name": "Лоферы",
        "price": 1982,
        "old_price": 4800,
        "reviews": 1,
        "seller": "Papulin",
        "seller_full": "Papulin",
        "brand": "ИРИНА.",
        "position": 143
    },
    {
        "site": "wildberries.ru",
        "name": "Гантели неопреновые для фитнеса, 0.5 кг, красные, 2 шт",
        "price": 443,
        "old_price": 633,
        "reviews": 96,
        "seller": "URM-Technic",
        "seller_full": "ИП Вагапов Т. А.",
        "brand": "URM-Technic"
    },
    {
        "site": "wildberries.ru",
        "name": "Воск Orange & Jasmine, Vanilla 30 мл",
        "price": 785,
        "old_price": 1100,
        "reviews": 60,
        "seller": "KatrinkaParfumer",
        "seller_full": "Иванова Валерия Андреевна",
        "brand": "KatrinkaParfumer"
    },
    {
        "site": "ozon.ru",
        "name": "Игра для Sega: Daffy Duck in Hollywood",
        "price": 491,
        "old_price": 850,
        "reviews": 5,
        "seller": "Segagames",
        "seller_full": "ИП Копылов Арсений Сергеевич",
        "brand": "16 GameBit",
        "position": 135
    },
    {
        "site": "wildberries.ru",
        "name": "Пряжа Плюшевая Puffy Ализе Пуффи 100гр 9м 5шт. № 340",
        "price": 730,
        "old_price": 1450,
        "reviews": 23,
        "seller": "Манучаров Арсен Ашотович ИП",
        "seller_full": "Манучаров Арсен Ашотович ИП",
        "brand": "ALIZE"
    },
    {
        "site": "wildberries.ru",
        "name": "Шлем спортивный, защитный",
        "price": 2489,
        "old_price": 6900,
        "reviews": 15,
        "seller": "POV TRIC CORPORATION",
        "seller_full": "ИП Сычёв Д. В.",
        "brand": "Сычёв Д.В.",
        "position": 67
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка с шортами комплект спортивный детский",
        "price": 613,
        "old_price": 1700,
        "reviews": 52,
        "seller": "NaShe",
        "seller_full": "ИП Мягкая Л. В.",
        "brand": "NaShe",
        "position": 220
    },
    {
        "site": "wildberries.ru",
        "name": "Серьги с жемчугом сердечки гвоздики бижутерия пусеты",
        "price": 250,
        "old_price": 1000,
        "reviews": 176,
        "seller": "The rise",
        "seller_full": "ИП Хайрутдинова А. А.",
        "brand": "The Rise",
        "position": 183
    },
    {
        "site": "wildberries.ru",
        "name": "эмаль автомобильная ремонтная ВАЗ",
        "price": 574,
        "old_price": 1030,
        "reviews": 62,
        "seller": "Все для Жизни",
        "seller_full": "ИП Куприянов А. В. г. Тула",
        "brand": "KUDO"
    },
    {
        "site": "wildberries.ru",
        "name": "Свеча в торт цифра \"0\", 5,5х3 см, золото",
        "price": 162,
        "old_price": 267,
        "reviews": 161,
        "seller": "ФАБРИКА УСПЕХА",
        "seller_full": "ООО ТД \"ФАБРИКА УСПЕХА\"",
        "brand": "Страна Карнавалия",
        "position": 219
    },
    {
        "site": "wildberries.ru",
        "name": "Кружево гипюр для шитья и рукоделия 65мм 2,8м",
        "price": 980,
        "old_price": 1425,
        "reviews": 28,
        "seller": "MagOK",
        "seller_full": "ИП Ларина Т. А.",
        "brand": "Kruzhevo"
    },
    {
        "site": "wildberries.ru",
        "name": "Худи Милые жабки Лягушки Жаба Лягушка",
        "price": 2656,
        "old_price": 3999,
        "reviews": 1,
        "seller": "ИП Тимонова А.В",
        "seller_full": "ИП Тимонова А.В",
        "brand": "Каждому Своё Animals",
        "position": 192
    },
    {
        "site": "wildberries.ru",
        "name": "Фоторамка 13х18 овал \"Вельможа\" серебро",
        "price": 351,
        "old_price": 558,
        "reviews": 5,
        "seller": "ФАБРИКА УСПЕХА",
        "seller_full": "ООО ТД \"ФАБРИКА УСПЕХА\"",
        "brand": "Сималенд",
        "position": 46
    },
    {
        "site": "wildberries.ru",
        "name": "Полуботинки мужские демисезонные",
        "price": 1214,
        "old_price": 2630,
        "reviews": 135,
        "seller": "KARI",
        "seller_full": "ООО \"КАРИ\"",
        "brand": "TimeJump"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на iPhone 11 Pro с принтом рисунком",
        "price": 450,
        "old_price": 835,
        "reviews": 5,
        "seller": "Foximilyan  ^.^",
        "seller_full": "ИП Богатов Д.Н.",
        "brand": "Fox Case",
        "position": 137
    },
    {
        "site": "ozon.ru",
        "name": "Водолазка Cascatto",
        "price": 1430,
        "old_price": 3485,
        "reviews": 11,
        "seller": "ООО \"Вилена\"",
        "seller_full": "ООО \"ВИЛЕНА\"",
        "brand": "Cascatto",
        "position": 210
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка комплименты Павел - с любовью",
        "price": 821,
        "old_price": 1178,
        "reviews": 74,
        "seller": "AV",
        "seller_full": "Колосков Алексей Владимирович",
        "brand": "Подарки Легко",
        "position": 33
    },
    {
        "site": "wildberries.ru",
        "name": "Бинт эластичный компрессионный ширина 10 см длина 1 м",
        "price": 274,
        "old_price": 316,
        "reviews": 3,
        "seller": "МедЛабРус",
        "seller_full": "ООО \"МЕДЛАБРУС\"",
        "brand": "МЕК",
        "position": 247
    },
    {
        "site": "wildberries.ru",
        "name": "Детские тельняшка и берет пограничных войск СССР России",
        "price": 1290,
        "old_price": 1875,
        "reviews": 6,
        "seller": "Честь имею!",
        "seller_full": "АДМИРАЛ ООО",
        "brand": "Честь имею!",
        "position": 82
    },
    {
        "site": "wildberries.ru",
        "name": "Ева Коврики на Ниссан Кашкай J10",
        "price": 2251,
        "old_price": 5255,
        "reviews": 20,
        "seller": "EvaLand",
        "seller_full": "EvaLand - Автомобильные ковры Eva",
        "brand": "ЕваЛэнд",
        "position": 255
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Samsung A72 защитный с принтом животные",
        "price": 140,
        "old_price": 2000,
        "reviews": 45,
        "seller": "Bronos Corporation",
        "seller_full": "Ниндзя",
        "brand": "Meelota",
        "position": 150
    },
    {
        "site": "wildberries.ru",
        "name": "181-Жемчуг Теплая зимняя натуральная шерсть 100%",
        "price": 1280,
        "old_price": 2000,
        "reviews": 17,
        "seller": "MY-OR-OFF",
        "seller_full": "ИП Майоров С. В.",
        "brand": "Пехорка Деревенская",
        "position": 221
    },
    {
        "site": "wildberries.ru",
        "name": "Юбка короткая вязаная мини лапша в рубчик",
        "price": 1793,
        "old_price": 3571,
        "reviews": 29,
        "seller": "FLUFFY SHOPS",
        "seller_full": "ИП Чернышова Н. А.",
        "brand": "FLUFFY SHOPS",
        "position": 136
    },
    {
        "site": "wildberries.ru",
        "name": "Противоударный чехол на iphone 11 с защитой камеры",
        "price": 666,
        "old_price": 749,
        "reviews": 2,
        "seller": "ELEN_PRY",
        "seller_full": "ИП Прыгунова Е. В.",
        "brand": "ELEN_PRY",
        "position": 50
    },
    {
        "site": "wildberries.ru",
        "name": "Омолаживающая сыворотка для лица",
        "price": 529,
        "old_price": 1090,
        "reviews": 1,
        "seller": "ИП Резунова Е.Ю",
        "seller_full": "Синцова Екатерина Юрьевна",
        "brand": "Sintsov Brend",
        "position": 86
    },
    {
        "site": "wildberries.ru",
        "name": "Лампа накаливания синяя 60 Вт Е27",
        "price": 475,
        "old_price": 475,
        "reviews": 76,
        "seller": "ЛУЧШАЯ СДЕЛКА",
        "seller_full": "ООО \"ЛУЧШАЯ СДЕЛКА\"",
        "brand": "ДИСВЕТ",
        "position": 136
    },
    {
        "site": "wildberries.ru",
        "name": "Куртка зимняя с капюшоном и мехом укороченная",
        "price": 12571,
        "old_price": 48500,
        "reviews": 126,
        "seller": "VE MARIE",
        "seller_full": "Нуриев Вугар Бахадур Оглы",
        "brand": "VE MARIE",
        "position": 31
    },
    {
        "site": "wildberries.ru",
        "name": "Щит электрический встраиваемый 48(56) UK648E (640V3)",
        "price": 12514,
        "old_price": 22284,
        "reviews": 3,
        "seller": "SANEL",
        "seller_full": "ИП Шандрюк А.В.",
        "brand": "ABB",
        "position": 214
    },
    {
        "site": "wildberries.ru",
        "name": "МЯГКОЕ ОКНО 180Х150СМ",
        "price": 4207,
        "old_price": 6200,
        "reviews": 7,
        "seller": "ТентовЪ",
        "seller_full": "ООО \"МАСТЕР ПРОФИ\"",
        "brand": "ТентовЪ",
        "position": 229
    },
    {
        "site": "ozon.ru",
        "name": "Матрас Dimax Оптима Ролл Лайт, Беспружинный, 170х190 см",
        "price": 9149,
        "old_price": 45745,
        "reviews": 2,
        "seller": "МАТРАСМОЛЛ",
        "seller_full": "ООО \"ЗДОРОВАЯ СПИНА\"",
        "brand": "Dimax",
        "position": 138
    },
    {
        "site": "ozon.ru",
        "name": "Pletnev Jewelry Браслет серебряный женский 925 пробы, позолоченный. Плетение: Муза. Полновесный с алмазной огранкой.",
        "price": 1381,
        "old_price": 5901,
        "reviews": 70,
        "seller": "Pletnev jewelry",
        "seller_full": "ИП Плетнев Александр Павлович",
        "brand": "Pletnev Jewelry",
        "position": 176
    },
    {
        "site": "ozon.ru",
        "name": "Изолон 1 мм 1 м для рукоделия, хобби и творчества",
        "price": 381,
        "old_price": 761,
        "reviews": 62,
        "seller": "Изолон",
        "seller_full": "ИП Ямалетдинов Марсель Фаритович",
        "brand": "Isolon",
        "position": 13
    },
    {
        "site": "wildberries.ru",
        "name": "Оплетка чехол на руль винил L 39-40 см KOMFORT ВАЗ",
        "price": 557,
        "old_price": 738,
        "reviews": 4,
        "seller": "НижБел",
        "seller_full": "НИЖБЕЛ  ООО",
        "brand": "Vettler",
        "position": 70
    },
    {
        "site": "ozon.ru",
        "name": "Домашний комплект Varadero Fantasy Collection",
        "price": 1111,
        "old_price": 2502,
        "reviews": 1,
        "seller": "VARADERO",
        "seller_full": "ИП Шестаков Сергей Леонидович",
        "brand": "Varadero"
    },
    {
        "site": "wildberries.ru",
        "name": "Коврик универсальный M, 50 х 70 ЭВА EVA соты",
        "price": 1115,
        "old_price": 2093,
        "reviews": 1,
        "seller": "ВИКОМТОРГ",
        "seller_full": "ООО \"ВИКОМТОРГ\"",
        "brand": "ВИКОМТОРГ",
        "position": 26
    },
    {
        "site": "wildberries.ru",
        "name": "кофта",
        "price": 529,
        "old_price": 1200,
        "reviews": 5,
        "seller": "Dovgan",
        "seller_full": "ИП Довгань Е. В.",
        "brand": "Dovgan",
        "position": 182
    },
    {
        "site": "wildberries.ru",
        "name": "Тапки домашние",
        "price": 478,
        "old_price": 1140,
        "reviews": 27,
        "seller": "Мамонтов Михаил Николаевич ИП",
        "seller_full": "Мамонтов Михаил Николаевич ИП",
        "brand": "ООО Обувь",
        "position": 68
    },
    {
        "site": "wildberries.ru",
        "name": "Крестильный комплект для малыша",
        "price": 1676,
        "old_price": 3450,
        "reviews": 149,
        "seller_full": "ИП Магай С. А.",
        "brand": "ДетиВтренде",
        "position": 250
    },
    {
        "site": "wildberries.ru",
        "name": "Коробка для хранения",
        "price": 504,
        "old_price": 728,
        "reviews": 2,
        "seller_full": "ИП Макаров Николай Николаевич",
        "brand": "NoName"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Samsung Galaxy Z Fold 5 Самсунг Гэлакси З Фолд 5",
        "price": 199,
        "old_price": 999,
        "reviews": 1,
        "seller": "100gadgets - аксессуары для мобильной техники",
        "seller_full": "АПОФЕОЗ УСПЕХА ООО",
        "brand": "100gadgets"
    },
    {
        "site": "ozon.ru",
        "name": "Супница Loraine, 1 шт, 500 мл",
        "price": 345,
        "old_price": 580,
        "reviews": 11,
        "seller": "Pan House",
        "seller_full": "ИП Мошин Иосиф Шалвович",
        "brand": "Loraine"
    },
    {
        "site": "wildberries.ru",
        "name": "Набор гель лаков для ногтей, 5 тонов + база в подарок",
        "price": 756,
        "old_price": 1500,
        "reviews": 2178,
        "seller": "Официальный магазин WULA NAILSOUL",
        "seller_full": "ВУЛА ООО",
        "brand": "WULA NAILSOUL",
        "position": 211
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для телефона",
        "price": 260,
        "old_price": 1000,
        "reviews": 9,
        "seller_full": "ИП Чернов Р.В.",
        "brand": "RichArt"
    },
    {
        "site": "wildberries.ru",
        "name": "полотенце спортивное из микрофибры",
        "price": 669,
        "old_price": 2350,
        "reviews": 115,
        "seller": "Козиненко НП",
        "seller_full": "ИП Козиненко Н.П.",
        "brand": "S-telo",
        "position": 194
    },
    {
        "site": "wildberries.ru",
        "name": "BRITE Выключ. 1-кл. перекр. 10А ВС10-1-3-БрТБ бр",
        "price": 549,
        "old_price": 1246,
        "reviews": 1,
        "seller": "Официальный магазин производителя IEK Group",
        "seller_full": "ООО «ИЭК ХОЛДИНГ»",
        "brand": "IEK"
    },
    {
        "site": "wildberries.ru",
        "name": "Пододеяльник 2 спальный 175х215 см",
        "price": 1276,
        "old_price": 3990,
        "reviews": 17,
        "seller": "Songlito",
        "seller_full": "ИП Сергеев Д. В.",
        "brand": "AVIDREAM постель мечты"
    },
    {
        "site": "wildberries.ru",
        "name": "Пуховик зима с капюшоном и поясом",
        "price": 21465,
        "old_price": 26500,
        "reviews": 2,
        "seller": "ORSY",
        "seller_full": "Коробейникова Вероника Анатольевна",
        "brand": "Vo.Tarun",
        "position": 100
    },
    {
        "site": "wildberries.ru",
        "name": "Набор серьги кольца",
        "price": 484,
        "old_price": 912,
        "reviews": 26244,
        "seller": "Viarubi",
        "seller_full": "ИП Кузин А. С.",
        "brand": "Viarubi",
        "position": 143
    },
    {
        "site": "wildberries.ru",
        "name": "Вазы, набор 3 предмета 14х14х38, 12х12х32 12х12х25см",
        "price": 8659,
        "old_price": 16763,
        "reviews": 4,
        "seller": "Glasar",
        "seller_full": "Хрыкина Марина Александровна ИП",
        "brand": "Glasar",
        "position": 8
    },
    {
        "site": "wildberries.ru",
        "name": "Джинсовая куртка укороченная повседневная джинсовка",
        "price": 1404,
        "old_price": 2990,
        "reviews": 6,
        "seller_full": "Махмуд Тарик ИП",
        "brand": "Rasna"
    },
    {
        "site": "wildberries.ru",
        "name": "Мицеллярный шампунь Pure Bounce, 2шт*500 мл",
        "price": 728,
        "old_price": 819,
        "reviews": 1,
        "seller": "ТИЖОРАТ",
        "seller_full": "ООО \"ТИЖОРАТ\"",
        "brand": "SYOSS"
    },
    {
        "site": "wildberries.ru",
        "name": "Спортивный костюм мужской футер 3х нитка с начесом",
        "price": 2916,
        "old_price": 12000,
        "reviews": 350,
        "seller": "Vin_Style",
        "seller_full": "Vin_Style",
        "brand": "Vin-Style"
    },
    {
        "site": "ozon.ru",
        "name": "Чехол для IPhone 12 с рисунком \"Spider Man\" / Накладка с принтом Человек Паук",
        "price": 840,
        "old_price": 1490,
        "reviews": 1,
        "seller": "BLVCK CREATIVE 3.0",
        "seller_full": "ИП Погосян Оксана Бориковна",
        "brand": "BLACK CREATIVE",
        "position": 81
    },
    {
        "site": "wildberries.ru",
        "name": "Перчатки резиновые размер M 38 длинные манжеты 90 гр красный",
        "price": 376,
        "old_price": 396,
        "reviews": 1,
        "seller": "ИП Бизина О. Ю.",
        "seller_full": "ИП Бизина О. Ю.",
        "brand": "Доляна",
        "position": 101
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка женская больших размеров",
        "price": 1019,
        "old_price": 1399,
        "reviews": 13,
        "seller": "ХОМС",
        "seller_full": "ИП Воронина Е. Д.",
        "brand": "ХОМС",
        "position": 231
    },
    {
        "site": "wildberries.ru",
        "name": "Зубная паста для чувствительных зубов",
        "price": 867,
        "old_price": 1665,
        "reviews": 2363,
        "seller": "СКИФ",
        "seller_full": "СКИФ ООО",
        "brand": "Remars",
        "position": 115
    },
    {
        "site": "wildberries.ru",
        "name": "Напильник полукруглый 150 мм",
        "price": 240,
        "old_price": 454,
        "reviews": 5,
        "seller": "Forsage Instrument Bel",
        "seller_full": "ЧТУП Торговый дом Форсаж Инструмент Бел",
        "brand": "FORSAGE",
        "position": 35
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для Xiaomi Redmi Note 10 (4G)",
        "price": 384,
        "old_price": 800,
        "reviews": 6,
        "seller": "MASALI",
        "seller_full": "ИП МОСАВИ САЙЕЕД АЛИ",
        "brand": "MASALI",
        "position": 256
    },
    {
        "site": "wildberries.ru",
        "name": "Кроссовки мужские белые",
        "price": 4042,
        "old_price": 9075,
        "reviews": 5,
        "seller": "ИП Шафиев О.А.",
        "seller_full": "Шафиев Олег Александрович ИП",
        "brand": "Walrus",
        "position": 68
    },
    {
        "site": "ozon.ru",
        "name": "Силиконовый чехол Мопс в капюшоне на Apple iPhone 8 Plus / 7 Plus / Эпл Айфон 7 Плюс / 8 Плюс",
        "price": 347,
        "old_price": 740,
        "reviews": 14,
        "seller": "Re:pa Store",
        "seller_full": "ИП Грищев Владимир Викторович",
        "brand": "RE:PA",
        "position": 141
    },
    {
        "site": "wildberries.ru",
        "name": "Игровой коврик для мыши Rust",
        "price": 623,
        "old_price": 700,
        "reviews": 4,
        "seller": "Shtil_on",
        "seller_full": "ИП Евстафьева И. А.",
        "brand": "Shtilon",
        "position": 39
    },
    {
        "site": "ozon.ru",
        "name": "Farmina Vet корм для котят и взрослых кошек всех пород, восстановление ЖКТ (10 кг)",
        "price": 12335,
        "old_price": 12679,
        "reviews": 11,
        "seller": "Зоомагазин Petfood",
        "seller_full": "ООО \"РЕФЛЕКС\"",
        "brand": "Farmina",
        "position": 175
    },
    {
        "site": "wildberries.ru",
        "name": "Грызунок деревянный Сердце",
        "price": 180,
        "old_price": 180,
        "reviews": 13,
        "seller": "ИП Little Angels",
        "seller_full": "ИП Little Angels",
        "brand": "ИП Little Angels"
    },
    {
        "site": "wildberries.ru",
        "name": "Духи по Resident Evil - Мия",
        "price": 566,
        "old_price": 2999,
        "reviews": 180,
        "seller": "Фандом РФ",
        "seller_full": "ИП Вовк О. С.",
        "brand": "Фандом РФ",
        "position": 5
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка",
        "price": 1030,
        "old_price": 1590,
        "reviews": 7,
        "seller": "MPB Ритейл Групп",
        "seller_full": "ИМПОРТТРЕЙД ООО",
        "brand": "ORIGINAL MARINES"
    },
    {
        "site": "wildberries.ru",
        "name": "Лента клейкая двухсторонняя скотч 10 мм",
        "price": 182,
        "old_price": 455,
        "reviews": 872,
        "seller": "Искусница",
        "seller_full": "ООО \"ГЛОБУС\"",
        "brand": "АЙРИС",
        "position": 89
    },
    {
        "site": "wildberries.ru",
        "name": "Маска для чувствительной кожи 2 шт",
        "price": 279,
        "old_price": 388,
        "reviews": 1292,
        "seller": "UNOVA market",
        "seller_full": "Новиков Максим Игоревич ИП",
        "brand": "Бизорюк"
    },
    {
        "site": "wildberries.ru",
        "name": "Платье вечернее праздничное офисное стильное мини",
        "price": 1202,
        "old_price": 5300,
        "reviews": 35,
        "seller": "MoDesire",
        "seller_full": "ИП Тагаев М. Х.",
        "brand": "MoDesire",
        "position": 45
    },
    {
        "site": "ozon.ru",
        "name": "Шиповки легкоатлетические DOLPHINSPORT",
        "price": 1729,
        "old_price": 6220,
        "reviews": 15,
        "seller": "DOLPHINSPORT",
        "seller_full": "ИП Шатун Ольга Юрьевна",
        "brand": "DOLPHINSPORT"
    },
    {
        "site": "wildberries.ru",
        "name": "Last Chery масляные, женские духи. 6мл Турция",
        "price": 270,
        "old_price": 1231,
        "reviews": 9,
        "seller": "Интернешнл Селлерс",
        "seller_full": "Гиматутдинов Марат Тагирович",
        "brand": "AKSA Esans"
    },
    {
        "site": "wildberries.ru",
        "name": "Постер № 29",
        "price": 400,
        "old_price": 400,
        "reviews": 2,
        "seller_full": "ДАРИНЧИ ООО",
        "brand": "Даринчи",
        "position": 42
    },
    {
        "site": "wildberries.ru",
        "name": "Нож Companion (S), Black",
        "price": 2060,
        "old_price": 2453,
        "reviews": 33,
        "seller": "Многозор",
        "seller_full": "Mnogozor.ru",
        "brand": "Morakniv",
        "position": 235
    },
    {
        "site": "ozon.ru",
        "name": "Биоразлагаемый чехол для iPhone 13 Pro Max \"Птицы\" Пшеница SOLOMA",
        "price": 1335,
        "old_price": 2190,
        "reviews": 28,
        "seller": "SOLOMA Case",
        "seller_full": "ИП Бебина Марина Александровна",
        "brand": "SOLOMA Case",
        "position": 152
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на iPhone 14 Pro Max прозрачный",
        "price": 464,
        "old_price": 820,
        "reviews": 29,
        "seller": "UPGRADE",
        "seller_full": "Перевозчикова Ксения Владимировна ИП",
        "brand": "UPGRAADEE"
    },
    {
        "site": "wildberries.ru",
        "name": "Пижама",
        "price": 7286,
        "old_price": 19990,
        "reviews": 314,
        "seller": "Mia-Amore",
        "seller_full": "МИА ЭНД МИА ООО",
        "brand": "MIA-AMORE",
        "position": 13
    },
    {
        "site": "ozon.ru",
        "name": "Футболка Printio",
        "price": 1270,
        "old_price": 1490,
        "reviews": 1,
        "seller": "Принтио",
        "seller_full": "ООО Первый сервис печати",
        "brand": "Printio",
        "position": 193
    },
    {
        "site": "wildberries.ru",
        "name": "Палантин",
        "price": 648,
        "old_price": 2700,
        "reviews": 2,
        "seller": "ИП Ахмедов",
        "seller_full": "Ахмедов Ахмед Эседуллаевич ИП",
        "brand": "Katie Melua"
    },
    {
        "site": "wildberries.ru",
        "name": "Защелка замка двери передней правой УАЗ 452 (нового образца)",
        "price": 515,
        "old_price": 573,
        "reviews": 5,
        "seller": "NAUAZIK.RU",
        "seller_full": "ИП Адров А. С.",
        "brand": "Завод автозапчастей"
    },
    {
        "site": "wildberries.ru",
        "name": "Во все тяжкие автограф на постере Джонатан Бэнкс, подарок",
        "price": 1327,
        "old_price": 2999,
        "reviews": 1,
        "seller": "COLLECTORS",
        "seller_full": "ООО \"СТРОЙИНВЕСТ\"",
        "brand": "COLLECTORS",
        "position": 239
    },
    {
        "site": "wildberries.ru",
        "name": "Эластичная верёвка для фиксации весла SUP доски зеленая",
        "price": 395,
        "old_price": 899,
        "reviews": 3,
        "seller": "Mister Gift",
        "seller_full": "ИП Соколов",
        "brand": "нет бренда",
        "position": 181
    },
    {
        "site": "wildberries.ru",
        "name": "Сумка Шопер Натуральная Кожа",
        "price": 2623,
        "old_price": 5800,
        "reviews": 1167,
        "seller": "MODESCO Обувь и Аксессуары",
        "seller_full": "Лаврентьев Никита Александрович",
        "brand": "MODESCO"
    },
    {
        "site": "wildberries.ru",
        "name": "Пневмогайковерт с регулировкой 1 2\" и набором головок",
        "price": 5813,
        "old_price": 8567,
        "reviews": 1669,
        "seller": "Forsage Instrument Bel",
        "seller_full": "ЧТУП Торговый дом Форсаж Инструмент Бел",
        "brand": "FORCEKRAFT",
        "position": 247
    },
    {
        "site": "ozon.ru",
        "name": "Салатник, диаметр 18 см. Lenardi. Коллекция \"Жозефина\". Костяной фарфор. Подарочная упаковка.",
        "price": 665,
        "old_price": 1102,
        "reviews": 15,
        "seller": "Ломоносовский фарфор СПБ",
        "seller_full": "ООО Ломоносовский фарфор СПБ",
        "brand": "Lenardi"
    },
    {
        "site": "wildberries.ru",
        "name": "Браслет от комаров силиконовый",
        "price": 185,
        "old_price": 247,
        "reviews": 3,
        "seller": "4HOME",
        "seller_full": "ИП Тимофеева Е. К.",
        "brand": "Nadzor",
        "position": 16
    },
    {
        "site": "wildberries.ru",
        "name": "Леггинсы утепленные женские",
        "price": 1417,
        "old_price": 2500,
        "reviews": 4,
        "seller": "Derevosuvenir",
        "seller_full": "Палийчук Анна Сергеевна ИП",
        "brand": "Амалия",
        "position": 28
    },
    {
        "site": "wildberries.ru",
        "name": "Трусы женские большого размера",
        "price": 745,
        "old_price": 2300,
        "reviews": 43,
        "seller": "Emilien",
        "seller_full": "ИП Мустафаев Р. Д.",
        "brand": "Lanny mode",
        "position": 120
    },
    {
        "site": "wildberries.ru",
        "name": "Tюль для комнаты 280 высота 200 ширина Хлои (баклажан)",
        "price": 3301,
        "old_price": 5162,
        "reviews": 1,
        "seller": "AT-HOME",
        "seller_full": "ИП Амнуэль Ф. Г.",
        "brand": "AT-HOME",
        "position": 197
    },
    {
        "site": "wildberries.ru",
        "name": "Крепежный уголок усиленный 90х90х65. 5шт",
        "price": 259,
        "old_price": 320,
        "reviews": 39,
        "seller": "КрепСкреп",
        "seller_full": "ИП Крестьянова М. О.",
        "brand": "Fixer",
        "position": 245
    },
    {
        "site": "wildberries.ru",
        "name": "Туфли мокасины кеды женские летние повседневные",
        "price": 1302,
        "old_price": 4873,
        "reviews": 1,
        "seller": "MANSIMMA",
        "seller_full": "ИП Азимова Г. С.",
        "brand": "MANSIMMA",
        "position": 136
    },
    {
        "site": "wildberries.ru",
        "name": "Ковровая дорожка 1х1,2 м, Ковер комнатный на пол",
        "price": 1649,
        "old_price": 3530,
        "reviews": 3,
        "seller": "МОС ПАЛАС",
        "seller_full": "ИП Харитонов Д. В.",
        "brand": "МОС ПАЛАС",
        "position": 169
    },
    {
        "site": "ozon.ru",
        "name": "Репродукция/Картина/Сальвадор Дали \"Постоянство памяти\"/Интерьерная фреска на доске/50х40см",
        "price": 1842,
        "old_price": 3625,
        "reviews": 15,
        "seller": "ArtStore",
        "seller_full": "ООО \"САН-ПЕТЕРБУРГ\"",
        "brand": "ArtStore"
    },
    {
        "site": "ozon.ru",
        "name": "Гобелен",
        "price": 941,
        "old_price": 2614,
        "reviews": 3,
        "seller": "GerMhxiao",
        "seller_full": "Golmud Huangxiao Jewelry Trading Co., Ltd.",
        "position": 117
    },
    {
        "site": "ozon.ru",
        "name": "Набор временных переводных татуировок Bag&You, 21x15 см",
        "price": 131,
        "old_price": 499,
        "reviews": 162,
        "seller": "BagToMarket",
        "seller_full": "ИП Шигапова Алина Тагировна",
        "brand": "Bag&You",
        "position": 138
    },
    {
        "site": "wildberries.ru",
        "name": "Сумка кросс-боди на плечо",
        "price": 1007,
        "old_price": 3799,
        "reviews": 48,
        "seller": "Brand shop",
        "seller_full": "АРМАНДО ООО",
        "brand": "KEDDO",
        "position": 255
    },
    {
        "site": "wildberries.ru",
        "name": "Мойка высокого давления АВД-180",
        "price": 15480,
        "old_price": 25420,
        "reviews": 33,
        "seller": "Avtogen-techno",
        "seller_full": "ООО «Автоген-техно»",
        "brand": "ЗУБР",
        "position": 88
    },
    {
        "site": "ozon.ru",
        "name": "Готовые очки для зрения, с покрытием \"антиблик\" и флекс дужками",
        "price": 934,
        "old_price": 1751,
        "reviews": 25,
        "seller": "Оптика24",
        "seller_full": "ИП Токарев Валерий Александрович",
        "brand": "EAE",
        "position": 107
    },
    {
        "site": "wildberries.ru",
        "name": "блеск для губ розовый",
        "price": 191,
        "old_price": 249,
        "reviews": 10,
        "seller": "toys26",
        "seller_full": "ИП \"Мясищев\"",
        "brand": "МИЛАЯ ЛЕДИ",
        "position": 64
    },
    {
        "site": "wildberries.ru",
        "name": "Подвязка",
        "price": 315,
        "old_price": 525,
        "reviews": 6,
        "seller": "Dimanche Lingerie. Нижнее бельё и домашняя одежда",
        "seller_full": "Радуга ООО",
        "brand": "Dimanche lingerie",
        "position": 60
    },
    {
        "site": "wildberries.ru",
        "name": "Наклейка интерьерная Олень Новогодний Лось",
        "price": 165,
        "old_price": 460,
        "reviews": 1,
        "seller": "Sticker_Seller178",
        "seller_full": "Тростин Максим Валерьевич",
        "brand": "Sticker_Seller178",
        "position": 218
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на A53 5G",
        "price": 329,
        "old_price": 999,
        "reviews": 170,
        "seller": "Capsi Case",
        "seller_full": "ИП Полторацкая А. В.",
        "brand": "Samsung",
        "position": 203
    },
    {
        "site": "wildberries.ru",
        "name": "Куртка зимняя короткая с капюшоном",
        "price": 3564,
        "old_price": 11000,
        "reviews": 33,
        "seller": "TDT-FASHION",
        "seller_full": "ИП Данг З.",
        "brand": "TDT-FASHION",
        "position": 243
    },
    {
        "site": "wildberries.ru",
        "name": "Шейкер спортивный, бутылка для воды спортивная, фитнес, бег",
        "price": 287,
        "old_price": 798,
        "reviews": 878,
        "seller": "НД Плэй - официальный лицензионный магазин",
        "seller_full": "СВЕЖИЙ ВЕТЕР ООО",
        "brand": "НД Плэй",
        "position": 103
    },
    {
        "site": "wildberries.ru",
        "name": "Брюки кожаные классика офисные укороченные",
        "price": 2259,
        "old_price": 2999,
        "reviews": 65,
        "seller": "MELON FASHION GROUP",
        "seller_full": "АО \"МЭЛОН ФЭШН ГРУП\"",
        "brand": "ZARINA",
        "position": 240
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на iPhone 13 с картой прозрачно-желтый",
        "price": 184,
        "old_price": 499,
        "reviews": 35,
        "seller": "Чехлы",
        "seller_full": "ИП Чурина Л. А.",
        "brand": "Churina",
        "position": 49
    },
    {
        "site": "wildberries.ru",
        "name": "Bear Розовый медведь MY97044 2630 дет",
        "price": 2015,
        "old_price": 2458,
        "reviews": 1,
        "seller": "Мир Кубиков",
        "seller_full": "ИП Крапивный Я. Д.",
        "brand": "Конструктор",
        "position": 104
    },
    {
        "site": "wildberries.ru",
        "name": "Новогодний фонарь ночник на батарейках",
        "price": 231,
        "old_price": 1650,
        "reviews": 38,
        "seller": "Позитиff Shop",
        "seller_full": "Позитиff Shop",
        "brand": "Позитиff Shop",
        "position": 209
    },
    {
        "site": "ozon.ru",
        "name": "Полка для ванной комнаты Прямая MoKHome 1 ярусная, 1 шт",
        "price": 551,
        "old_price": 1899,
        "reviews": 675,
        "seller": "MoniKs",
        "seller_full": "ИП Мохова Ксения Александровна",
        "brand": "MoKHome"
    },
    {
        "site": "ozon.ru",
        "name": "Чехол-бампер MyPads для Infinix Hot 12i  с рисунком крутой зеленый мужчина в очках, противоударный, силиконовый",
        "price": 475,
        "old_price": 783,
        "reviews": 10,
        "seller": "MyPads Миллионы чехлов",
        "seller_full": "ИП Михайлов Андрей",
        "brand": "MyPads",
        "position": 18
    },
    {
        "site": "wildberries.ru",
        "name": "Лори КОЛОРИ Тоши 25 см",
        "price": 2095,
        "old_price": 4914,
        "reviews": 132,
        "seller": "БУДИ БАСА",
        "seller_full": "Васильева Юлия Викторовна ИП",
        "brand": "БУДИ БАСА",
        "position": 125
    },
    {
        "site": "wildberries.ru",
        "name": "Перчатки демисезонные",
        "price": 389,
        "old_price": 1559,
        "reviews": 44,
        "seller": "Integra",
        "seller_full": "Integra",
        "brand": "Integra"
    },
    {
        "site": "wildberries.ru",
        "name": "Spray Краска для замши и нубука 250 мл+20% Коричневый",
        "price": 402,
        "old_price": 402,
        "reviews": 299,
        "seller": "АГОРА ЭНЕРДЖИ",
        "seller_full": "АГОРА ЭНЕРДЖИ ООО",
        "brand": "SILVER",
        "position": 69
    },
    {
        "site": "wildberries.ru",
        "name": "10 шт. Крышка Твист-Офф D58 Пчелка",
        "price": 216,
        "old_price": 333,
        "reviews": 44,
        "seller": "Магазин товаров для пчеловодства - Бируком",
        "seller_full": "ИП Петренко Д. А.",
        "brand": "Бируком",
        "position": 14
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Poco X4 GT Redmi Note 11T Pro с рисунком",
        "price": 309,
        "old_price": 999,
        "reviews": 41,
        "seller": "Case Place",
        "seller_full": "Бойко Никита ИП",
        "brand": "Xiaomi",
        "position": 44
    },
    {
        "site": "wildberries.ru",
        "name": "Носки корейские CHUNG SAN Лучший Подарок",
        "price": 459,
        "old_price": 3000,
        "reviews": 76,
        "seller": "Zazul s House",
        "seller_full": "ИП Смирнов",
        "brand": "ZAZUL KOREA",
        "position": 92
    },
    {
        "site": "ozon.ru",
        "name": "Рюкзак Nicenonice Classic",
        "price": 1444,
        "old_price": 3000,
        "reviews": 2,
        "seller": "Nicenonice",
        "seller_full": "ИП Яковлев Сергей Александрович",
        "brand": "Nicenonice",
        "position": 71
    },
    {
        "site": "wildberries.ru",
        "name": "Летние шорты бермуды длинные оверсайз широкие пляжные хлопок",
        "price": 1661,
        "old_price": 11396,
        "reviews": 1,
        "seller_full": "Ивашинин В.С. ИП",
        "brand": "VISD"
    },
    {
        "site": "ozon.ru",
        "name": "Силиконовый чехол на Realme Narzo 50i Prime / Реалми Нарзо 50i Прайм Любопытный жираф",
        "price": 378,
        "old_price": 1299,
        "reviews": 31,
        "seller": "Case Place",
        "seller_full": "ИП Сыворотко Константин Константинович",
        "brand": "Case Place",
        "position": 85
    },
    {
        "site": "wildberries.ru",
        "name": "Ботинки зимние замшевые челси",
        "price": 3987,
        "old_price": 9288,
        "reviews": 113,
        "seller": "EVITA Trendy Shoes",
        "seller_full": "ИП Свиридов И. Е.",
        "brand": "EVITA",
        "position": 102
    },
    {
        "site": "wildberries.ru",
        "name": "Елочное украшение",
        "price": 160,
        "old_price": 500,
        "reviews": 2,
        "seller": "Woodshop_Luch",
        "seller_full": "Гагарский Артем Сергеевич",
        "brand": "Pyro_luch",
        "position": 134
    },
    {
        "site": "ozon.ru",
        "name": "Комплект одежды Утенок На каждый день",
        "price": 1045,
        "old_price": 5455,
        "reviews": 495,
        "seller": "ООО \"Волжский трикотаж\"",
        "seller_full": "ООО Волжский трикотаж",
        "brand": "Утенок",
        "position": 106
    },
    {
        "site": "wildberries.ru",
        "name": "Гидрогелевая пленка для Huawei Nova 10 SE без рамки",
        "price": 243,
        "old_price": 540,
        "reviews": 33,
        "seller": "Смарт Лайн",
        "seller_full": "ООО \"ЛТС ИНВЕСТ\"",
        "brand": "SMART LINE",
        "position": 62
    },
    {
        "site": "wildberries.ru",
        "name": "Комбинезон детский зимний для детский",
        "price": 4098,
        "old_price": 11000,
        "reviews": 888,
        "seller": "MiR KIDS",
        "seller_full": "ИП Нгуен Т.",
        "brand": "Mir Kids",
        "position": 230
    },
    {
        "site": "wildberries.ru",
        "name": "Шорты летние",
        "price": 250,
        "old_price": 5000,
        "reviews": 55,
        "seller": "Сильно Стильно",
        "seller_full": "ИП Саитова Я. В.",
        "brand": "RamiNika"
    },
    {
        "site": "wildberries.ru",
        "name": "Диспенсер для жидкого мыла, дозатор",
        "price": 351,
        "old_price": 524,
        "reviews": 14,
        "seller": "Магазин полезных штучек",
        "seller_full": "МАРКЕТКЕЙ ООО",
        "brand": "Idea",
        "position": 103
    },
    {
        "site": "wildberries.ru",
        "name": "Предфильтр (чехол) на угольный фильтр 1500 200",
        "price": 522,
        "old_price": 580,
        "reviews": 3,
        "seller": "Grow Magic",
        "seller_full": "Зылёва Любовь Павловна",
        "brand": "MagicAir",
        "position": 27
    },
    {
        "site": "wildberries.ru",
        "name": "Нож для точного реза с автостопом GRAND-9 09192",
        "price": 496,
        "old_price": 496,
        "reviews": 1,
        "seller": "Ravenstar",
        "seller_full": "ИП Антонов И. С.",
        "brand": "Kraftool",
        "position": 187
    },
    {
        "site": "wildberries.ru",
        "name": "Гирлянда праздничная на день рождения Медвежонок 200 см",
        "price": 265,
        "old_price": 390,
        "reviews": 4,
        "seller": "ООО КАПОНЕ",
        "seller_full": "ООО Капоне",
        "brand": "Riota"
    },
    {
        "site": "wildberries.ru",
        "name": "Кроп топ спортивный базовый с чашечками",
        "price": 360,
        "old_price": 3000,
        "reviews": 90,
        "seller": "COCO life",
        "seller_full": "ИП Мамедов Р. Г.",
        "brand": "Coco Life",
        "position": 139
    },
    {
        "site": "wildberries.ru",
        "name": "Семечки тыквенные очищенные, Семена тыквы",
        "price": 530,
        "old_price": 620,
        "reviews": 187,
        "seller": "Полезный Продукт",
        "seller_full": "ООО \"ГРИН ФУДС\"",
        "brand": "Грин Фудс",
        "position": 36
    },
    {
        "site": "wildberries.ru",
        "name": "Ковер комнатный 100х160 с рисунком",
        "price": 2078,
        "old_price": 6498,
        "reviews": 13,
        "seller": "ДОВЕРИЕ ТЫСЯЧ ПОКУПАТЕЛЕЙ",
        "seller_full": "СЕРВИС ТЕХНОЛОГИЯ ООО",
        "brand": "Коврик соты",
        "position": 172
    },
    {
        "site": "wildberries.ru",
        "name": "Маркер меловой стираемый 5 мм для доски, планера белый 3 шт",
        "price": 491,
        "old_price": 910,
        "reviews": 2778,
        "seller": "САМСОН",
        "seller_full": "Офисмаг-Поволжье ООО",
        "brand": "Brauberg",
        "position": 42
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на realme 9i",
        "price": 307,
        "old_price": 684,
        "reviews": 41,
        "seller": "Andy&Paul",
        "seller_full": "ИП Коновалов П. А.",
        "brand": "Andy&Paul",
        "position": 243
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка именная с принтом его величество Никита",
        "price": 456,
        "old_price": 890,
        "reviews": 7,
        "seller": "Custom merch",
        "seller_full": "ИП Сухарева Е. М.",
        "brand": "Custom merch",
        "position": 225
    },
    {
        "site": "wildberries.ru",
        "name": "Магнитная направляющая ограничитель для швейных машин",
        "price": 180,
        "old_price": 500,
        "reviews": 695,
        "seller": "СозидайКа",
        "seller_full": "ИП Екатерина Сергеевна Кутузова",
        "brand": "СозидайКа",
        "position": 183
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Honor 9 Lite",
        "price": 273,
        "old_price": 740,
        "reviews": 31,
        "seller": "RE:PA Store",
        "seller_full": "Грищев Владимир Викторович ИП",
        "brand": "RE:PA",
        "position": 42
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка именная девочке керамическая фея Татьяна",
        "price": 579,
        "old_price": 1740,
        "reviews": 11,
        "seller": "Семёрочка",
        "seller_full": "Келехсаев Давид Сергеевич ИП",
        "brand": "МАЗЯЛЬДА",
        "position": 67
    },
    {
        "site": "ozon.ru",
        "name": "Колготки Glamour daino gla, 40 den, 1 шт",
        "price": 498,
        "old_price": 722,
        "reviews": 1,
        "seller": "PriveziShop",
        "seller_full": "ИП Бареева Галия Махмутовна",
        "brand": "Glamour"
    },
    {
        "site": "ozon.ru",
        "name": "Платье By Raheem",
        "price": 3113,
        "old_price": 4590,
        "reviews": 270,
        "seller": "L&D",
        "seller_full": "ИП Ахметшина Элина",
        "brand": "By Raheem",
        "position": 64
    },
    {
        "site": "wildberries.ru",
        "name": "Пленка пищевая 22,5 см x 150 м 8 мкм",
        "price": 291,
        "old_price": 582,
        "reviews": 173,
        "seller": "UPAK LAND",
        "seller_full": "ООО \"ЛИОРА\"",
        "brand": "UPAK LAND",
        "position": 204
    },
    {
        "site": "wildberries.ru",
        "name": "3d стикеры и наклейки на телефон, Эстетика",
        "price": 400,
        "old_price": 1000,
        "reviews": 2667,
        "seller": "OKSTICKERS",
        "seller_full": "ИП Князев А. А.",
        "brand": "OKSTICKERS"
    },
    {
        "site": "wildberries.ru",
        "name": "Дозатор косметический",
        "price": 249,
        "old_price": 300,
        "reviews": 2,
        "seller": "Nouvel Dream",
        "seller_full": "ИП Храмшина Д.И.",
        "brand": "Nouvel Dream",
        "position": 3
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на iPhone Xs Max с яблоком",
        "price": 247,
        "old_price": 885,
        "reviews": 1484,
        "seller": "Janky",
        "seller_full": "ИП Жданова Ю. А.",
        "brand": "Janky"
    },
    {
        "site": "wildberries.ru",
        "name": "Повязка на голову с принтом для девочек летняя",
        "price": 126,
        "old_price": 633,
        "reviews": 20,
        "seller_full": "ЛАДИНОС ООО",
        "brand": "HAPPY LAMA"
    },
    {
        "site": "wildberries.ru",
        "name": "Ткань стеганая на синтепоне",
        "price": 585,
        "old_price": 1000,
        "reviews": 145,
        "seller": "Королевство сна",
        "seller_full": "ООО \"КОРОЛЕВСТВО СНА\"",
        "brand": "Королевство сна",
        "position": 198
    },
    {
        "site": "wildberries.ru",
        "name": "Сарафан женский летний длинный на пуговицах платье пляжное",
        "price": 1142,
        "old_price": 8299,
        "reviews": 32,
        "seller": "Every Day For Your (EDFY)",
        "seller_full": "ИП Станкевич С.В.",
        "brand": "Every day for your (EDFU)",
        "position": 158
    },
    {
        "site": "wildberries.ru",
        "name": "Настольная подставка для телефона или планшета - Медведь",
        "price": 414,
        "old_price": 753,
        "reviews": 2,
        "seller": "LoftLove",
        "seller_full": "ИП Павлов В. В.",
        "brand": "LoftLove"
    },
    {
        "site": "wildberries.ru",
        "name": "Арабские духи Suave, 30 мл (Саваж)",
        "price": 675,
        "old_price": 3300,
        "reviews": 53,
        "seller": "Birdie",
        "seller_full": "ИП Д. Р. М.",
        "brand": "CLIVE DORRIS"
    },
    {
        "site": "wildberries.ru",
        "name": "Дрель-шуруповерт Greenpower 20-Li",
        "price": 3204,
        "old_price": 5115,
        "reviews": 2,
        "seller": "900 ВАТТ",
        "seller_full": "ИП Михайлов А. В.",
        "brand": "Zitrek"
    },
    {
        "site": "wildberries.ru",
        "name": "Открытка Шоко-бокс 23 Февраля",
        "price": 378,
        "old_price": 600,
        "reviews": 17,
        "seller_full": "ООО \"РМА БАШНЯ ТЕСЛЫ\"",
        "brand": "NeoGift",
        "position": 198
    },
    {
        "site": "wildberries.ru",
        "name": "Юбка женская длинная в пол",
        "price": 2086,
        "old_price": 5600,
        "reviews": 51,
        "seller": "Ermossa",
        "seller_full": "ИП Байрамуков Р. А.",
        "brand": "ERMOSA",
        "position": 87
    },
    {
        "site": "wildberries.ru",
        "name": "Пистолет бластер и светящийся волчок",
        "price": 1394,
        "old_price": 2000,
        "reviews": 38,
        "seller": "Khanbv",
        "seller_full": "ИП Идирисов И.А.",
        "brand": "Infinity Nado",
        "position": 218
    },
    {
        "site": "ozon.ru",
        "name": "Ремкомплект направляющих тормозного суппорта переднего RENAULT LOGAN 04- /  SANDERO",
        "price": 636,
        "old_price": 1070,
        "reviews": 18,
        "seller": "АвтоТрейд",
        "seller_full": "ИП Кучер Олег Леонидович",
        "brand": "KORTEX",
        "position": 56
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол силиконовый плотный с карабином на AirPods Pro 2",
        "price": 360,
        "old_price": 500,
        "reviews": 3,
        "seller": "Take Case Store",
        "seller_full": "Take Case Store",
        "brand": "Take Case",
        "position": 73
    },
    {
        "site": "wildberries.ru",
        "name": "Хоккей глеб",
        "price": 712,
        "old_price": 800,
        "reviews": 67,
        "seller": "Shtil_on",
        "seller_full": "ИП Евстафьева И. А.",
        "brand": "Shtilon",
        "position": 43
    },
    {
        "site": "ozon.ru",
        "name": "Grattol цветная камуфлирующая база под гель-лак с шиммером Rubber Base Glitter No Hema 22, 9 мл",
        "price": 470,
        "old_price": 940,
        "reviews": 570,
        "seller": "ИП Василик",
        "seller_full": "ИП Василик Антон Игоревич",
        "brand": "Grattol",
        "position": 104
    },
    {
        "site": "wildberries.ru",
        "name": "Серебряный нательный крест с фианитами и эмалью",
        "price": 1333,
        "old_price": 1936,
        "reviews": 5,
        "seller": "Мир Всем",
        "seller_full": "ИП Гусляков С.А.",
        "brand": "Мир Всем"
    },
    {
        "site": "wildberries.ru",
        "name": "Коврик ЭВА в багажник Toyota Prius 20",
        "price": 2444,
        "old_price": 3000,
        "reviews": 4,
        "seller": "CellMat",
        "seller_full": "ИП Сергин Игорь Сергеевич",
        "brand": "CellMat"
    },
    {
        "site": "wildberries.ru",
        "name": "Фатин в горошек (3 мм) мягкий 300*160см",
        "price": 1047,
        "old_price": 2380,
        "reviews": 2,
        "seller": "MY Sewing Stories",
        "seller_full": "ИП Попов Я. В.",
        "brand": "My sewing stories",
        "position": 100
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол с визитницей A 52",
        "price": 469,
        "old_price": 999,
        "reviews": 1,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "Mobi711",
        "position": 233
    },
    {
        "site": "wildberries.ru",
        "name": "Ручка рейлинг мебельная, 128 мм, комплект 8шт",
        "price": 638,
        "old_price": 755,
        "reviews": 45,
        "seller": "MBR 2000",
        "seller_full": "ИП Мартынов Д. Н.",
        "brand": "MBR2000"
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка с принтом в подарок мужчине",
        "price": 539,
        "old_price": 1363,
        "reviews": 24,
        "seller": "ПОСМОТРЕТЬ ВСЕ ТОВАРЫ",
        "seller_full": "ИП Базыльников Андрей Борисович",
        "brand": "PRINT MUST GO ON"
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка и бриджи",
        "price": 626,
        "old_price": 1266,
        "reviews": 38,
        "seller": "Детская одежда - Счастливая Малинка",
        "seller_full": "Безруков Дмитрий Владимирович ИП",
        "brand": "Малинка",
        "position": 132
    },
    {
        "site": "wildberries.ru",
        "name": "Салтон, Гелевые подушечки под пятку, стельки",
        "price": 268,
        "old_price": 358,
        "reviews": 11,
        "seller": "Гармония цен",
        "seller_full": "ООО \"А.Дистрибушен\"",
        "brand": "Salton"
    },
    {
        "site": "ozon.ru",
        "name": "Трусы слипы FuleShaYe, 1 шт",
        "price": 399,
        "old_price": 730,
        "reviews": 7,
        "seller": "ИП Третьякова Е.С.",
        "seller_full": "ИП Третьякова Екатерина Сергеевна",
        "brand": "FuleShaYe",
        "position": 244
    },
    {
        "site": "wildberries.ru",
        "name": "Вратарские перчатки детские футбольные",
        "price": 1398,
        "old_price": 3875,
        "reviews": 10,
        "seller": "Sun Garden",
        "seller_full": "Smbat",
        "brand": "SPORT UP"
    },
    {
        "site": "ozon.ru",
        "name": "Чехол-сумочка-кобура MyPads Fondina Coccodrillo для DOOGEE S60 / S60 Lite из импортной эко-кожи с рельефом кожи крокодила на магнитной застежке с креплением на пояс черный мужской",
        "price": 796,
        "old_price": 1689,
        "reviews": 1,
        "seller": "MyPads миллион чехлов",
        "seller_full": "ИП Завьялова Мария Васильевна",
        "brand": "MyPads",
        "position": 159
    },
    {
        "site": "wildberries.ru",
        "name": "Нарукавники для плавания Aquatic Life. 30 x 15 см. от 5-12 л…",
        "price": 408,
        "old_price": 648,
        "reviews": 1,
        "seller": "ИНДИГО",
        "seller_full": "ООО \"ИНДИГО\"",
        "brand": "Kangaeru"
    },
    {
        "site": "wildberries.ru",
        "name": "Детская зубная щётка U-образная",
        "price": 116,
        "old_price": 1290,
        "reviews": 22,
        "seller": "ИП Любишина Анастасия Игоревна",
        "seller_full": "ИП Любишина А. И.",
        "brand": "i-Kids",
        "position": 168
    },
    {
        "site": "wildberries.ru",
        "name": "Контейнер для хранения с крышкой 500 мл",
        "price": 438,
        "old_price": 877,
        "reviews": 2,
        "seller": "COOK HOUSE",
        "seller_full": "ООО \"ДОМХАУС\"",
        "brand": "Omada",
        "position": 127
    },
    {
        "site": "ozon.ru",
        "name": "Чехол для iPhone 12 Pro Max - прозрачный",
        "price": 400,
        "old_price": 700,
        "reviews": 1,
        "seller": "ГРУППТРЕЙДИНГ",
        "seller_full": "ООО ГРУППТРЕЙДИНГ",
        "brand": "Devia",
        "position": 26
    },
    {
        "site": "wildberries.ru",
        "name": "Носки махровые термо",
        "price": 238,
        "old_price": 850,
        "reviews": 1,
        "seller": "ИП Кулев А. А.",
        "seller_full": "ИП Кулев А. А.",
        "brand": "Весна-хороша",
        "position": 2
    },
    {
        "site": "wildberries.ru",
        "name": "iPhone 14 Pro Max \"Животные\"",
        "price": 357,
        "old_price": 650,
        "reviews": 1,
        "seller": "Nezuko",
        "seller_full": "ИП Мерега Наталья Петровна",
        "brand": "Nezuko",
        "position": 114
    },
    {
        "site": "wildberries.ru",
        "name": "Тряпка для уборки",
        "price": 236,
        "old_price": 430,
        "reviews": 11,
        "seller": "Золотая стрекоза",
        "seller_full": "Золотая стрекоза",
        "brand": 2123,
        "position": 67
    },
    {
        "site": "wildberries.ru",
        "name": "Brina Nordico (V-522) 205 55R16 91T шип",
        "price": 6751,
        "old_price": 8037,
        "reviews": 16,
        "seller": "KOLESATYT",
        "seller_full": "ЗАО ТК \"ЯРШИНТОРГ\"",
        "brand": "Viatti",
        "position": 65
    },
    {
        "site": "wildberries.ru",
        "name": "Костюм зимний",
        "price": 9655,
        "old_price": 14900,
        "reviews": 145,
        "seller": "NIKASTYLE - производитель детской одежды и обуви",
        "seller_full": "НИЛЬС ООО",
        "brand": "NIKASTYLE",
        "position": 240
    },
    {
        "site": "wildberries.ru",
        "name": "toy 2 Bubble Gum",
        "price": 823,
        "old_price": 6999,
        "reviews": 480,
        "seller": "Алмаз",
        "seller_full": "ООО \"АЛМАЗ\"",
        "brand": "MOSCHINO",
        "position": 149
    },
    {
        "site": "wildberries.ru",
        "name": "Ночная сорочка женская с кружевом большие размеры",
        "price": 669,
        "old_price": 1600,
        "reviews": 3694,
        "seller": "MODELENA",
        "seller_full": "Пастухов Павел Александрович ИП",
        "brand": "Натали",
        "position": 137
    },
    {
        "site": "wildberries.ru",
        "name": "Канат джутовый 22 мм 5 метров шпагат веревка",
        "price": 679,
        "old_price": 830,
        "reviews": 1,
        "seller": "Marsik shop",
        "seller_full": "ИП Смольков С. О.",
        "brand": "-",
        "position": 249
    },
    {
        "site": "wildberries.ru",
        "name": "Халат детский махровый с ушками Мишка",
        "price": 835,
        "old_price": 2291,
        "reviews": 131,
        "seller": "zuzu style",
        "seller_full": "ИП Елиас А. Е.",
        "brand": "-",
        "position": 41
    },
    {
        "site": "wildberries.ru",
        "name": "Сульфат калия 1 кг",
        "price": 510,
        "old_price": 597,
        "reviews": 3,
        "seller": "ИП Бизина О. Ю.",
        "seller_full": "ИП Бизина О. Ю.",
        "brand": "Нов-Агро",
        "position": 228
    },
    {
        "site": "wildberries.ru",
        "name": "Светильник потолочный накладной 12w набор 10 шт",
        "price": 2333,
        "old_price": 3696,
        "reviews": 66,
        "seller": "Светру",
        "seller_full": "ООО \"СВЕТРУ\"",
        "brand": "Elvan",
        "position": 243
    },
    {
        "site": "wildberries.ru",
        "name": "Юбка женская длинная в пол",
        "price": 2086,
        "old_price": 5600,
        "reviews": 51,
        "seller": "Ermossa",
        "seller_full": "ИП Байрамуков Р. А.",
        "brand": "ERMOSA"
    },
    {
        "site": "wildberries.ru",
        "name": "Парные брелки бтс Ким Намджун RM и Чон Хосок J Hope",
        "price": 426,
        "old_price": 749,
        "reviews": 8,
        "seller": "A.More Print",
        "seller_full": "\"A.More Prints\" Анна Новикова",
        "brand": "A.More Print"
    },
    {
        "site": "wildberries.ru",
        "name": "Шарф зимний теплый палантин",
        "price": 927,
        "old_price": 4100,
        "reviews": 1448,
        "seller": "AVEAM",
        "seller_full": "ИП \"Соколов\"",
        "brand": "Aveam"
    },
    {
        "site": "ozon.ru",
        "name": "Ремешок для часов",
        "price": 1107,
        "old_price": 2133,
        "reviews": 18,
        "seller": "Mogift",
        "seller_full": "Shenyang Fuchuntai Financial Outsourcing Co., Ltd.",
        "position": 163
    },
    {
        "site": "ozon.ru",
        "name": "Ошейник с блестками из натуральной кожи для кошек и маленьких пород собак, для щенков, для котят размер 20 см - 28 см, 10 мм зеленого цвета с кольцом для поводка",
        "price": 304,
        "old_price": 950,
        "reviews": 400,
        "seller": "7Wings - амуниция для животных",
        "seller_full": "ИП Киселев Дмитрий Сергеевич",
        "brand": "7Wings PREMIUM"
    },
    {
        "site": "wildberries.ru",
        "name": "Лосьон тонизирующий восстанавливающий",
        "price": 6793,
        "old_price": 8088,
        "reviews": 1,
        "seller": "Академия Качества",
        "seller_full": "ИП Канивец Наталья Сергеевна",
        "brand": "Yon Ka",
        "position": 41
    },
    {
        "site": "wildberries.ru",
        "name": "Платье Шакира-2",
        "price": 1019,
        "old_price": 13980,
        "reviews": 3,
        "seller": "Pretty Woman",
        "seller_full": "Хачатуров Эрнест Сергеевич ИП",
        "brand": "PreWoman"
    },
    {
        "site": "wildberries.ru",
        "name": "Покрышка для велосипеда 26\" EAKIA 76-599",
        "price": 2716,
        "old_price": 4200,
        "reviews": 1,
        "seller": "Два колеса",
        "seller_full": "ИП Белов А. А.",
        "brand": "Два колеса"
    },
    {
        "site": "ozon.ru",
        "name": "Лак акриловый Бриллиант Olki Олки фиолетовый, 100 мл",
        "price": 242,
        "old_price": 391,
        "reviews": 13,
        "seller": "БЭСТЕКС",
        "seller_full": "ООО БЭСТЕКС",
        "brand": "Олки / Olki",
        "position": 53
    },
    {
        "site": "wildberries.ru",
        "name": "Картина на холсте",
        "price": 765,
        "old_price": 2000,
        "reviews": 1,
        "seller": "Область печати",
        "seller_full": "ООО \"ОБЛАСТЬ ПЕЧАТИ\"",
        "brand": "Печатник",
        "position": 78
    },
    {
        "site": "ozon.ru",
        "name": "Грунт-эмаль Profilux по ржавчине, Алкидная, Матовое покрытие, 1.9 кг, черный",
        "price": 752,
        "old_price": 1100,
        "reviews": 169,
        "seller": "ВСЕКРАСКИ.РУ",
        "seller_full": "ООО ВСЕКРАСКИ.РУ",
        "brand": "Profilux",
        "position": 144
    },
    {
        "site": "wildberries.ru",
        "name": "женская сумка",
        "price": 6474,
        "old_price": 10000,
        "reviews": 6,
        "seller": "Beauty Shop for Miss",
        "seller_full": "Beauty Shop for Miss",
        "brand": "Coach",
        "position": 39
    },
    {
        "site": "ozon.ru",
        "name": "Резиновые сапоги Demar",
        "price": 1723,
        "old_price": 2730,
        "reviews": 30,
        "seller": "Демар Трейдинг",
        "seller_full": "ООО Демар Трейдинг",
        "brand": "Demar",
        "position": 37
    },
    {
        "site": "wildberries.ru",
        "name": "Платье с открытыми плечами",
        "price": 873,
        "old_price": 7190,
        "reviews": 14,
        "seller": "Giulia Rossi. Новое платье - новая ты!",
        "seller_full": "А-М ООО",
        "brand": "GiuliaRossi",
        "position": 19
    },
    {
        "site": "ozon.ru",
        "name": "Матрас детский Mom's Love Child/Чилд детский, Зависимые пружины, 65х120 см",
        "price": 5774,
        "old_price": 15100,
        "reviews": 13,
        "seller": "SoftSleep",
        "seller_full": "ИП Нурбахтина Альбина Хайдаровна",
        "brand": "Мир Матрасов",
        "position": 226
    },
    {
        "site": "wildberries.ru",
        "name": "VATIKA Комплект Шампунь и кондиционер для придания объема",
        "price": 623,
        "old_price": 1050,
        "reviews": 65,
        "seller_full": "Рыков Александр Михайлович ИП",
        "brand": "Dabur VATIKA",
        "position": 134
    },
    {
        "site": "wildberries.ru",
        "name": "CHANEL No 5",
        "price": 619,
        "old_price": 7650,
        "reviews": 29,
        "seller": "aroma space",
        "seller_full": "Perfume AUS1",
        "brand": "ДУХИ ЖЕНСКИЕ",
        "position": 259
    },
    {
        "site": "wildberries.ru",
        "name": "Костюм брючный вечерний",
        "price": 3288,
        "old_price": 7000,
        "reviews": 394,
        "seller": "Glamorica",
        "seller_full": "ИП Колымажнова А. В.",
        "brand": "Glamorica",
        "position": 259
    },
    {
        "site": "ozon.ru",
        "name": "Магнето МТЗ, ЮМЗ, ХТЗ, ТКЗ, АТЗ, АЗСМ с двигателем ПД-10, ПД-350 - арт. М124Б3",
        "price": 4713,
        "old_price": 10216,
        "reviews": 1,
        "seller": "Автотракторные Детали",
        "seller_full": "ИП Федоров Александр Сергеевич"
    },
    {
        "site": "wildberries.ru",
        "name": "Китайские ореховые конфеты 1кг",
        "price": 667,
        "old_price": 789,
        "reviews": 15,
        "seller": "SaLen",
        "seller_full": "ИП Татауров А. Б.",
        "brand": "-",
        "position": 123
    },
    {
        "site": "wildberries.ru",
        "name": "Пижама детская с шортами Звездные войны Star Wars",
        "price": 2470,
        "old_price": 3050,
        "reviews": 38,
        "seller": "Little Pirates",
        "seller_full": "ИП Новгородцев В. С.",
        "brand": "Little Pirates",
        "position": 127
    },
    {
        "site": "wildberries.ru",
        "name": "Боди для малышей с юбкой для девочки праздничное в садик",
        "price": 923,
        "old_price": 2850,
        "reviews": 800,
        "seller": "Tex.you store",
        "seller_full": "СИМА-ЛЕНД ООО ТД",
        "brand": "Крошка Я"
    },
    {
        "site": "wildberries.ru",
        "name": "Коллекционная модель UAZ Hunter 4690590223222",
        "price": 539,
        "old_price": 599,
        "reviews": 94,
        "seller": "toys26",
        "seller_full": "ИП \"Мясищев\"",
        "brand": "toys26"
    },
    {
        "site": "wildberries.ru",
        "name": "Перчатки для фитнеса",
        "price": 429,
        "old_price": 780,
        "reviews": 2,
        "seller": "Дилижанс",
        "seller_full": "Марьяхина Екатерина Викторовна ИП",
        "brand": "Atlanterra"
    },
    {
        "site": "ozon.ru",
        "name": "Чернослив без косточки натуральный, 1000 г.",
        "price": 440,
        "old_price": 700,
        "reviews": 21,
        "seller": "Betis Food",
        "seller_full": "ИП Нозиров Баходур Кобилджонович"
    },
    {
        "site": "wildberries.ru",
        "name": "футболка варенка укороченная с принтом",
        "price": 1474,
        "old_price": 7000,
        "reviews": 20,
        "seller": "S&M",
        "seller_full": "Абдразаков Сьездбек Болотбекович",
        "brand": "ТОП",
        "position": 35
    },
    {
        "site": "wildberries.ru",
        "name": "Моносерьга гвоздик серебро тучка позолоченная",
        "price": 423,
        "old_price": 1630,
        "reviews": 69,
        "seller": "VG jewelry",
        "seller_full": "ИП Грицай В. В.",
        "brand": "Teya jewelry",
        "position": 52
    },
    {
        "site": "ozon.ru",
        "name": "Трусы стринги, бесшовная модель Alla Buone Invisible Laser",
        "price": 251,
        "old_price": 449,
        "reviews": 438,
        "seller": "ВЕСТ ТРЕЙД",
        "seller_full": "ООО ВЕСТ ТРЕЙД",
        "brand": "Alla Buone"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Samsung Galaxy A13 4G",
        "price": 299,
        "old_price": 999,
        "reviews": 32,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "Mobi711",
        "position": 203
    },
    {
        "site": "wildberries.ru",
        "name": "3D стикеры объемные Егор Крид наклейки",
        "price": 152,
        "old_price": 400,
        "reviews": 382,
        "seller": "Muzankov R.V.",
        "seller_full": "ИП Музанков Р. В.",
        "brand": "Stick Pic"
    },
    {
        "site": "wildberries.ru",
        "name": "Табурет кухонный BRUNO, деревянный",
        "price": 2152,
        "old_price": 3843,
        "reviews": 31,
        "seller": "KETT-UP",
        "seller_full": "КВАЛИТЕТ ООО",
        "brand": "KETT-UP",
        "position": 213
    },
    {
        "site": "ozon.ru",
        "name": "Чехол на мебель для стула BEAUTY BLOG, 36х7см",
        "price": 470,
        "old_price": 1100,
        "reviews": 82,
        "seller": "BEAUTY BLOG",
        "seller_full": "ИП Каляев Вадим Дмитриевич",
        "brand": "BEAUTY BLOG",
        "position": 111
    },
    {
        "site": "wildberries.ru",
        "name": "Силиконовый чехол на Samsung Galaxy S10 с картинкой \"Корги\"…",
        "price": 225,
        "old_price": 684,
        "reviews": 8,
        "seller": "Andy&Paul",
        "seller_full": "ИП Коновалов П. А.",
        "brand": "Andy&Paul",
        "position": 169
    },
    {
        "site": "wildberries.ru",
        "name": "Подстаканник для коляски с усиленным креплением",
        "price": 138,
        "old_price": 395,
        "reviews": 27,
        "seller": "ShaHan",
        "seller_full": "ИП Ханнанова Н. М.",
        "brand": "Shahan"
    },
    {
        "site": "ozon.ru",
        "name": "Ткань для шитья(1 м) Штапель \"Стильная графика на черном\", ш1.45м, вискоза-100%, 90гр/м.кв",
        "price": 456,
        "old_price": 780,
        "reviews": 41,
        "seller": "Мильфлёр натуральные ткани",
        "seller_full": "ИП Созинов Алексей Николаевич",
        "brand": "Мильфлёр",
        "position": 46
    },
    {
        "site": "wildberries.ru",
        "name": "Дед Мороз, к Новому Году, Игрушка, Украшение на Елку",
        "price": 258,
        "old_price": 890,
        "reviews": 602,
        "seller": "Гипермаркет. Игрушки и Товары для Дома",
        "seller_full": "ООО \"ГОРОД ДЕТСТВА\"",
        "brand": "LIKE GOODS",
        "position": 59
    },
    {
        "site": "wildberries.ru",
        "name": "Гель для интимной гигиены 250 мл",
        "price": 458,
        "old_price": 509,
        "reviews": 1,
        "seller": "ТЕЛЕНТ ГРУПП",
        "seller_full": "ООО \"ТЕЛЕНТ ГРУПП\"",
        "brand": "SYNERGETIC",
        "position": 18
    },
    {
        "site": "wildberries.ru",
        "name": "Блокнот Постучись в мою дверь",
        "price": 481,
        "old_price": 580,
        "reviews": 3,
        "seller": "BugrikShop",
        "seller_full": "ИП Бугров А. А.",
        "brand": "BugrikShop"
    },
    {
        "site": "wildberries.ru",
        "name": "Универсальная подставка для канцелярии",
        "price": 343,
        "old_price": 837,
        "reviews": 25,
        "seller": "АРТ ПОДАРКИ",
        "seller_full": "ИП Долбилина Е.Д.",
        "brand": "Kid is Smart",
        "position": 111
    },
    {
        "site": "wildberries.ru",
        "name": "Низкотемпературный флюс для пайки паяльная кислота 15 мл",
        "price": 139,
        "old_price": 278,
        "reviews": 3,
        "seller": "SKRETCHEK",
        "seller_full": "ИП Беляев",
        "brand": "-"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Redmi A1 Plus A2 Plus Poco C51",
        "price": 339,
        "old_price": 999,
        "reviews": 5,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "Mobi711",
        "position": 190
    },
    {
        "site": "wildberries.ru",
        "name": "спортивный костюм женский",
        "price": 1798,
        "old_price": 3700,
        "reviews": 11,
        "seller": "Dream shop",
        "seller_full": "ИП Джабраилов И. Н.",
        "brand": "женский спортивный костюм",
        "position": 172
    },
    {
        "site": "wildberries.ru",
        "name": "Серьги гвоздики сердечки",
        "price": 270,
        "old_price": 1500,
        "reviews": 68,
        "seller": "USandLove",
        "seller_full": "ИП Богачева М. А.",
        "brand": "USandLove",
        "position": 257
    },
    {
        "site": "ozon.ru",
        "name": "Чехол противоударный armors для Xiaomi Redmi Note 10 и Redmi Note 10S / Redmi Note 10 и Redmi Note 10S (Зеленый.)",
        "price": 806,
        "old_price": 1792,
        "reviews": 2,
        "seller": "Anle",
        "seller_full": "Shenzhen Anxile Technology Co., Ltd",
        "position": 149
    },
    {
        "site": "wildberries.ru",
        "name": "Плед пушистый на диван кровать 220х240",
        "price": 1107,
        "old_price": 1350,
        "reviews": 340,
        "seller": "Flow",
        "seller_full": "Flow",
        "brand": "Flow",
        "position": 255
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для Realme C33 - Реалми С33 с принтом",
        "price": 475,
        "old_price": 1100,
        "reviews": 40,
        "seller": "Onlinecase",
        "seller_full": "Кабанов Дмитрий Владимирович ИП",
        "brand": "Print pab"
    },
    {
        "site": "wildberries.ru",
        "name": "Пластиковый чемодан на колесах маленький S отдых и отпуск",
        "price": 3083,
        "old_price": 7906,
        "reviews": 13,
        "seller": "BAGS-ART",
        "seller_full": "ИП Согомонян В. Б.",
        "brand": "BAGS-ART",
        "position": 92
    },
    {
        "site": "ozon.ru",
        "name": "Халат Be lo Us Семья",
        "price": 1971,
        "old_price": 5000,
        "reviews": 1,
        "seller": "Be lo Us",
        "seller_full": "ИП Белоусов Владислав Викторович",
        "brand": "Be lo Us",
        "position": 216
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Redmi Note 12 Turbo Poco F5 с рисунком",
        "price": 299,
        "old_price": 999,
        "reviews": 18,
        "seller": "Case Place",
        "seller_full": "Бойко Никита ИП",
        "brand": "Xiaomi",
        "position": 253
    },
    {
        "site": "wildberries.ru",
        "name": "Ночник настольный прикроватный Луна",
        "price": 942,
        "old_price": 1149,
        "reviews": 2,
        "seller": "LUNA",
        "seller_full": "ИП Ибрагимов Э. А.",
        "brand": "ХозРоскошь",
        "position": 100
    },
    {
        "site": "wildberries.ru",
        "name": "NICH FRAICHE № 270 Парфюмерная вода 50 мл",
        "price": 2567,
        "old_price": 4632,
        "reviews": 2,
        "seller": "DISTRIBUTION GS",
        "seller_full": "Мери Энрикоевна М.",
        "brand": "SHAIK",
        "position": 198
    },
    {
        "site": "wildberries.ru",
        "name": "Инжир сушеный (натуральный) 1 кг. 750 г. 500 г",
        "price": 693,
        "old_price": 1098,
        "reviews": 5,
        "seller": "ФУНДУЧОК",
        "seller_full": "ФУНДУЧОК ООО",
        "brand": "ФУНДУЧОК",
        "position": 207
    },
    {
        "site": "wildberries.ru",
        "name": "Пилки для ногтей 100 180 240 для маникюра набор",
        "price": 850,
        "old_price": 2200,
        "reviews": 8,
        "seller": "GOLDEN STAR",
        "seller_full": "ИП Расули Ш.",
        "brand": "Golden Star Pro",
        "position": 113
    },
    {
        "site": "ozon.ru",
        "name": "Шариковые направляющие Boyard H35 длина 250 мм - 1 комплект",
        "price": 320,
        "old_price": 630,
        "reviews": 162,
        "seller": "AMF",
        "seller_full": "ИП Исаев Михаил Борисович",
        "brand": "Boyard",
        "position": 176
    },
    {
        "site": "wildberries.ru",
        "name": "Краска моющаяся, интерьерная, для стен и обоев 3,9 кг",
        "price": 806,
        "old_price": 2068,
        "reviews": 7,
        "seller": "Malare",
        "seller_full": "ООО \"Маларе Групп\"",
        "brand": "Malare",
        "position": 107
    },
    {
        "site": "wildberries.ru",
        "name": "Спортивное платье",
        "price": 3341,
        "old_price": 12500,
        "reviews": 17,
        "seller": "AL-SHIIRA",
        "seller_full": "ИП Салихова М. Е.",
        "brand": "Al-SHIIRA",
        "position": 154
    },
    {
        "site": "ozon.ru",
        "name": "Магика Алистера Кроули. Практическое руководство по ритуалам Телемы | Дюкетт Лон Майло",
        "price": 772,
        "old_price": 992,
        "reviews": 6,
        "seller": "Ita-Koivuranta",
        "seller_full": "ИП Рагоза Александр Михайлович",
        "position": 71
    },
    {
        "site": "ozon.ru",
        "name": "Набор-подкраска Toyota 6V5 (  Jade Green ) Штрих-корректор 30мл.+ лак от сколов и царапин 30мл.",
        "price": 592,
        "old_price": 1219,
        "reviews": 102,
        "seller": "Сибирский Рубеж",
        "seller_full": "ООО Сибирская Альтернатива",
        "brand": "USP Automotive"
    },
    {
        "site": "ozon.ru",
        "name": "RuNail Professional / Гель-лак Lurex (цвет: Александрит), 5 г № 4083",
        "price": 300,
        "old_price": 429,
        "reviews": 28,
        "seller": "BrandsGoDigital",
        "seller_full": "ООО Брэндс Гоу Диджитал",
        "brand": "RuNail Professional",
        "position": 226
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол книжка для Huawei P40 Lite E",
        "price": 548,
        "old_price": 999,
        "reviews": 6,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян С. М.",
        "brand": "Mobi711",
        "position": 240
    },
    {
        "site": "ozon.ru",
        "name": "Силиконовый чехол на Infinix Hot 30 / Инфиникс Хот 30 \"Подарки на Новый Год\"",
        "price": 392,
        "old_price": 3510,
        "reviews": 5,
        "seller": "Almoa",
        "seller_full": "ИП Хабибулин Артур Ринатович",
        "brand": "Almoa",
        "position": 135
    },
    {
        "site": "ozon.ru",
        "name": "Мусс для ухода за кожей лица, очищение и удаление макияжа ENLU Lab/ Энлю Лаб 150 мл",
        "price": 1235,
        "old_price": 1834,
        "reviews": 5,
        "seller": "Планета Здоровья",
        "seller_full": "ООО Крон",
        "position": 100
    },
    {
        "site": "wildberries.ru",
        "name": "ФУТБОЛКА С РИСУНКОМ ФАНТАСТИЧЕСКИЕ ТВАРИ И ГДЕ ОНИ ОБИТАЮТ",
        "price": 918,
        "old_price": 2700,
        "reviews": 4,
        "seller": "HONEYMONEY",
        "seller_full": "ИП Кулюкин С.Н.",
        "brand": "HONEYMONEY",
        "position": 101
    },
    {
        "site": "wildberries.ru",
        "name": "Ростовский камень, набор из 5 форм для искусственного камня",
        "price": 976,
        "old_price": 1290,
        "reviews": 102,
        "seller": "Триго",
        "seller_full": "ООО \"ТРИГО\"",
        "brand": "Промтехнологии",
        "position": 104
    },
    {
        "site": "wildberries.ru",
        "name": "Значок",
        "price": 300,
        "old_price": 300,
        "reviews": 4,
        "seller": "ЗначокОк",
        "seller_full": "Бублик Татьяна Александровна",
        "brand": "ЗначокОк",
        "position": 257
    },
    {
        "site": "wildberries.ru",
        "name": "Брелок на ключи Хантер Хантер",
        "price": 300,
        "old_price": 1200,
        "reviews": 1,
        "seller": "AdFONS",
        "seller_full": "ИП Афонин А. Н.",
        "brand": "And.Fons",
        "position": 108
    },
    {
        "site": "ozon.ru",
        "name": "Набор акварельных карандашей MILAN Aquarell 12 цветов в картонной упаковке",
        "price": 558,
        "old_price": 862,
        "reviews": 1,
        "seller": "MPM art",
        "seller_full": "ООО ЭМПИЭМ ИМПОРТ",
        "brand": "Milan"
    },
    {
        "site": "wildberries.ru",
        "name": "3 пары Термоноски турмалиновые",
        "price": 850,
        "old_price": 1500,
        "reviews": 23,
        "seller": "ИньЯн05",
        "seller_full": "ИП Бекбиева Р. М.",
        "brand": "иньян05",
        "position": 177
    },
    {
        "site": "wildberries.ru",
        "name": "Шапка",
        "price": 232,
        "old_price": 400,
        "reviews": 20,
        "seller": "Marhatter",
        "seller_full": "Морозовские традиции ООО",
        "brand": "Marhatter",
        "position": 105
    },
    {
        "site": "wildberries.ru",
        "name": "Гидрогелевые патчи для глаз 60 штук",
        "price": 646,
        "old_price": 1038,
        "reviews": 36,
        "seller": "Spa Альганика - официальный магазин",
        "seller_full": "ИП Ильягуев Станислав Ифраимович",
        "brand": "Альганика",
        "position": 231
    },
    {
        "site": "wildberries.ru",
        "name": "Тушь для ресниц Жасминовая твёрдая, тон черный",
        "price": 221,
        "old_price": 260,
        "reviews": 4,
        "seller_full": "ИП Михайлов В. В.",
        "brand": "Рассвет",
        "position": 127
    },
    {
        "site": "ozon.ru",
        "name": "Чехол для iPhone 7 Plus, iPhone 8 Plus с рисунком, прозрачный, с принтом ГЕОМЕТРИЧЕСКИЙ ПРИНТ / Айфон 7 Плюс, Айфон 8 Плюс",
        "price": 300,
        "old_price": 350,
        "reviews": 42,
        "seller": "ORONGO",
        "seller_full": "ИП Накипов Алексей Миннахматович",
        "brand": "ORONGO",
        "position": 14
    },
    {
        "site": "wildberries.ru",
        "name": "Сбывшиеся сны Натальи Петровны Из разговоров с академик",
        "price": 585,
        "old_price": 930,
        "reviews": 1,
        "seller": "МногоКнижие",
        "seller_full": "ООО \"МНОГО КНИГ\"",
        "brand": "Время"
    },
    {
        "site": "wildberries.ru",
        "name": "Ремень кожаный",
        "price": 4281,
        "old_price": 8999,
        "reviews": 22,
        "seller": "Мир ремней",
        "seller_full": "ИП Версткин А. А.",
        "brand": "G i o r g i o A r m a n i"
    },
    {
        "site": "wildberries.ru",
        "name": "Кроссовки зимние спортивные с мехом",
        "price": 1513,
        "old_price": 8900,
        "reviews": 6,
        "seller": "Crossfeet",
        "seller_full": "Crossfeet",
        "brand": "Crossfeet",
        "position": 132
    },
    {
        "site": "wildberries.ru",
        "name": "СС крем для лица SPF 15 Гидроэффект",
        "price": 405,
        "old_price": 410,
        "reviews": 16,
        "seller": "HWshops",
        "seller_full": "Киреева Елена Геннадьевна ИП",
        "brand": "БЕЛИТА",
        "position": 96
    },
    {
        "site": "wildberries.ru",
        "name": "Шнур плетеный для рыбалки Strong Line PE 150м леска плетенка",
        "price": 790,
        "old_price": 888,
        "reviews": 24,
        "seller": "Угра Спорт",
        "seller_full": "Угра Спорт ООО",
        "brand": "Mottomo",
        "position": 145
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол книжка для Huawei P40 Lite E",
        "price": 548,
        "old_price": 999,
        "reviews": 6,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян С. М.",
        "brand": "Mobi711",
        "position": 103
    },
    {
        "site": "wildberries.ru",
        "name": "Именной брелок на ключи с именем Рашид",
        "price": 399,
        "old_price": 420,
        "reviews": 1,
        "seller": "GraverMag",
        "seller_full": "ИП Ковтун Г. Ю.",
        "brand": "GraverMag",
        "position": 115
    },
    {
        "site": "wildberries.ru",
        "name": "Платье трикотажное теплое",
        "price": 1285,
        "old_price": 4290,
        "reviews": 16190,
        "seller": "Katerina Bleska & Tamara Savin T",
        "seller_full": "Савина Тамара Витальевна ИП",
        "brand": "Katerina Bleska&Tamara Savin",
        "position": 163
    },
    {
        "site": "wildberries.ru",
        "name": "гель лак белый для ногтей",
        "price": 163,
        "old_price": 630,
        "reviews": 548,
        "seller": "Zebra",
        "seller_full": "Черныш РЛ",
        "brand": "Zebra Prof",
        "position": 219
    },
    {
        "site": "wildberries.ru",
        "name": "Лоток для приборов",
        "price": 294,
        "old_price": 701,
        "reviews": 1,
        "seller": "BRENDWALL",
        "seller_full": "Никитюк Александр Андреевич ИП",
        "brand": "My Sweet Home",
        "position": 139
    },
    {
        "site": "wildberries.ru",
        "name": "Наклейка с именем Андрей на шар",
        "price": 340,
        "old_price": 567,
        "reviews": 13,
        "seller": "Person.Sticker",
        "seller_full": "ИП Матвиенко",
        "brand": "Person.sticker.Name",
        "position": 198
    },
    {
        "site": "wildberries.ru",
        "name": "Кроссовки",
        "price": 1614,
        "old_price": 10490,
        "reviews": 8,
        "seller": "Class shoe store",
        "seller_full": "ИП Аслиев Н. К.",
        "brand": "class shoe store",
        "position": 243
    },
    {
        "site": "wildberries.ru",
        "name": "Робот \"Истребитель\", трансформируется",
        "price": 1007,
        "old_price": 1445,
        "reviews": 1,
        "seller": "MikiMarket",
        "seller_full": "ООО \"МикиМаркет ГРУПП\"",
        "brand": "MikiMarket"
    },
    {
        "site": "ozon.ru",
        "name": "Линза для очков 1.5 FSV Transitions XTRActive HC Brown Sph +2.50 диаметр 65",
        "price": 3248,
        "old_price": 6200,
        "reviews": 9,
        "seller": "Интернет Оптика",
        "seller_full": "ООО Ваши линзы»",
        "brand": "ESSILOR"
    },
    {
        "site": "wildberries.ru",
        "name": "Тетрадь предметная ГЕОМЕТРИЯ 48л клетка",
        "price": 344,
        "old_price": 499,
        "reviews": 1,
        "seller": "НьюБорн",
        "seller_full": "НЬЮБОРН ООО",
        "brand": "Brauberg",
        "position": 90
    },
    {
        "site": "wildberries.ru",
        "name": "Женские трусы бразильяна Si5503 Brasiliana",
        "price": 562,
        "old_price": 1041,
        "reviews": 1,
        "seller": "Для Подружек",
        "seller_full": "ИП Иванова Е. Н.",
        "brand": "Sisi"
    },
    {
        "site": "wildberries.ru",
        "name": "Автомобильное зарядное устройство в прикуриватель",
        "price": 1996,
        "old_price": 7170,
        "reviews": 8,
        "seller": "Life Style Natali",
        "seller_full": "ИП Лунько Е. А.",
        "brand": "Зарядное устройство BASEUS",
        "position": 78
    },
    {
        "site": "wildberries.ru",
        "name": "Hermessence Ambre Narguile отливант 3 мл",
        "price": 1340,
        "old_price": 2799,
        "reviews": 10,
        "seller": "Ковач Давид Моисеевич",
        "seller_full": "Ковач Давид Моисеевич",
        "brand": "randewo",
        "position": 70
    },
    {
        "site": "ozon.ru",
        "name": "Поппер AKARA Sucker 95F A114",
        "price": 503,
        "old_price": 1103,
        "reviews": 15,
        "seller": "В гармонии с природой",
        "seller_full": "ИП Юхно Елена Анатольевна",
        "brand": "Akara",
        "position": 246
    },
    {
        "site": "wildberries.ru",
        "name": "Демисезонное пальто",
        "price": 4104,
        "old_price": 20270,
        "reviews": 632,
        "seller": "LADY JASMINE",
        "seller_full": "Агаев Афган Аганазар оглы ИП",
        "brand": "Lady Jasmine",
        "position": 104
    },
    {
        "site": "wildberries.ru",
        "name": "Фуксия Цифры 2024 Шар",
        "price": 649,
        "old_price": 829,
        "reviews": 1,
        "seller": "Планета Шаров",
        "seller_full": "ИП Приказчикова С. В.",
        "brand": "Планета Шаров",
        "position": 126
    },
    {
        "site": "wildberries.ru",
        "name": "Бампер на телефон Samsung Galaxy S21",
        "price": 265,
        "old_price": 985,
        "reviews": 7,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "Караханян Эдуард Сергеевич",
        "brand": "Mobi711",
        "position": 36
    },
    {
        "site": "wildberries.ru",
        "name": "Брюки",
        "price": 1009,
        "old_price": 2899,
        "reviews": 61,
        "seller": "KOTON",
        "seller_full": "ООО «Котон Текстиль»",
        "brand": "KOTON"
    },
    {
        "site": "wildberries.ru",
        "name": "Толстовка",
        "price": 2495,
        "old_price": 7900,
        "reviews": 6,
        "seller": "Phil's collection",
        "seller_full": "ИП Филатов А. М.",
        "brand": "Phil's collection",
        "position": 129
    },
    {
        "site": "wildberries.ru",
        "name": "Кашпо для цветов",
        "price": 219,
        "old_price": 300,
        "reviews": 7,
        "seller_full": "ИП Хайрутдинов",
        "brand": "Aira R",
        "position": 132
    },
    {
        "site": "wildberries.ru",
        "name": "Шапочка для плавания текстильная",
        "price": 247,
        "old_price": 550,
        "reviews": 1932,
        "seller": "Ok Sport",
        "seller_full": "OkCompany",
        "brand": "OK SPORT",
        "position": 91
    },
    {
        "site": "wildberries.ru",
        "name": "Поднос пластиковый с бортиками",
        "price": 918,
        "old_price": 3500,
        "reviews": 4,
        "seller": "AllMall",
        "seller_full": "ИП Муратханова",
        "brand": "-"
    },
    {
        "site": "wildberries.ru",
        "name": "Перчатки двухслойные 100% шерсть мериноса",
        "price": 607,
        "old_price": 900,
        "reviews": 7,
        "seller": "R and I",
        "seller_full": "R and I",
        "brand": "R&I",
        "position": 25
    },
    {
        "site": "wildberries.ru",
        "name": "Торцевая разделочная доска с гастроемкостью",
        "price": 3797,
        "old_price": 9649,
        "reviews": 7,
        "seller": "LAKis.ru",
        "seller_full": "ИП Киселева Елена Владимировна",
        "brand": "Экоменаж",
        "position": 165
    },
    {
        "site": "wildberries.ru",
        "name": "Мармелад Ягодный брусника 200г",
        "price": 262,
        "old_price": 656,
        "reviews": 2,
        "seller": "ДикоВкусно",
        "seller_full": "ООО \"Дико Вкусно\"",
        "brand": "Дико вкусно",
        "position": 47
    },
    {
        "site": "wildberries.ru",
        "name": "КРЫШКА МАСЛЯНОГО ФИЛЬТРА OPEL AGILA, ASTRA, CORSA, TIGRA, !",
        "price": 840,
        "old_price": 1000,
        "reviews": 5,
        "seller": "CtoAuto",
        "seller_full": "Белоусов Сергей ВасильевичИП",
        "brand": "Zikmar"
    },
    {
        "site": "ozon.ru",
        "name": "ступица передняя для Lifan Solano - Lifan арт. B3103100",
        "price": 2064,
        "old_price": 2861,
        "reviews": 97,
        "seller": "ASD-AUTO",
        "seller_full": "ИП Львов Сергей Геннадьевич",
        "brand": "Lifan",
        "position": 121
    },
    {
        "site": "wildberries.ru",
        "name": "Питательная тканевая маска для лица с экст. авокадо I'm AVOC…",
        "price": 260,
        "old_price": 367,
        "reviews": 1,
        "seller": "Zegar",
        "seller_full": "Моисеев Андрей Константинович ИП",
        "brand": "Tony Moly"
    },
    {
        "site": "wildberries.ru",
        "name": "Насос помпа для стиральной машины",
        "price": 1402,
        "old_price": 1800,
        "reviews": 1,
        "seller": "ProDеталь",
        "seller_full": "ИП Трофимов А. А.",
        "brand": "ProDеталь",
        "position": 215
    },
    {
        "site": "wildberries.ru",
        "name": "Джемпер с воротником на молнии",
        "price": 3401,
        "old_price": 5999,
        "reviews": 1,
        "seller": "Бифлекс",
        "seller_full": "БИФЛЕКС ООО",
        "brand": "Baon"
    },
    {
        "site": "wildberries.ru",
        "name": "Куртка Осень",
        "price": 1620,
        "old_price": 4000,
        "reviews": 268,
        "seller": "MINA KIDS",
        "seller_full": "a Thường ( MINA KIDS)",
        "brand": "MINA KIDS"
    },
    {
        "site": "wildberries.ru",
        "name": "Куртка с меховой подкладкой",
        "price": 4573,
        "old_price": 6599,
        "reviews": 13,
        "seller": "United Colors of Benetton официальный магазин",
        "seller_full": "ООО \"Казань Реал Эстейт\"",
        "brand": "United Colors of Benetton",
        "position": 179
    },
    {
        "site": "wildberries.ru",
        "name": "Плащ тренчкот эко-кожа",
        "price": 4738,
        "old_price": 13000,
        "reviews": 44,
        "seller_full": "ИП \"Бахшалиев Н.М.\"",
        "brand": "Le'Mira",
        "position": 71
    },
    {
        "site": "wildberries.ru",
        "name": "Плакат с BTS 62 БТС k pop на стену А1, А2, А3, 50 на 70",
        "price": 734,
        "old_price": 2500,
        "reviews": 1,
        "seller": "Poster Premium Print",
        "seller_full": "Пимурзина Анастасия Викторовна",
        "brand": "Плакаты и постеры BTS Funs",
        "position": 29
    },
    {
        "site": "wildberries.ru",
        "name": "Ботинки женские зимние натуральная кожа",
        "price": 10643,
        "old_price": 14600,
        "reviews": 86,
        "seller": "Альфа Мануфактура",
        "seller_full": "Казанский Александр Владиславович ИП",
        "brand": "Альфа Мануфактура"
    },
    {
        "site": "wildberries.ru",
        "name": "Виниловая автонаклейка Ребенок в машине",
        "price": 222,
        "old_price": 600,
        "reviews": 33,
        "seller_full": "ИП \"Катасов\"",
        "brand": "РА МОЛНИЯ",
        "position": 85
    },
    {
        "site": "wildberries.ru",
        "name": "Мантоварка 6 литров с 4 сетками",
        "price": 1983,
        "old_price": 4839,
        "reviews": 674,
        "seller": "Официальный магазин производителя ТМ KALITVA",
        "seller_full": "АЛЮМИНИЙ МЕТАЛЛУРГ РУС АО",
        "brand": "Калитва",
        "position": 211
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка хлопковая базовая с принтом Calvin Klein",
        "price": 975,
        "old_price": 2800,
        "reviews": 128,
        "seller": "Irisha",
        "seller_full": "ИП Киселева И. В.",
        "brand": "IRISHA-BREND.RU",
        "position": 136
    },
    {
        "site": "ozon.ru",
        "name": "Бумажная выкройка детский сарафан 122-158",
        "price": 375,
        "old_price": 418,
        "reviews": 11,
        "seller": "Мама шила малышу",
        "seller_full": "ИП Шаймуратова Алина Игоревна",
        "brand": "Мама шила малышу",
        "position": 248
    },
    {
        "site": "ozon.ru",
        "name": "Мужские наручные часы Spinnaker Dumas SP-5081-II",
        "price": 39900,
        "old_price": 39900,
        "reviews": 31,
        "seller": "Bestwatch",
        "seller_full": "ИП Бойко Ирина Александровна",
        "brand": "Spinnaker",
        "position": 169
    },
    {
        "site": "wildberries.ru",
        "name": "Аксессуары для оружия",
        "price": 754,
        "old_price": 1390,
        "reviews": 143,
        "seller_full": "ИП Золтуев Николай Евгеньевич",
        "brand": "Мушковод Бургай",
        "position": 17
    },
    {
        "site": "wildberries.ru",
        "name": "Мокасины",
        "price": 963,
        "old_price": 3500,
        "reviews": 13,
        "seller": "ROYALLINO MODA",
        "seller_full": "Галандаров Руслан Тахир Оглы ИП",
        "brand": "ROYALLINO MODA",
        "position": 154
    },
    {
        "site": "wildberries.ru",
        "name": "EVA коврики Хонда Цивик 4Д с бортами",
        "price": 3873,
        "old_price": 5490,
        "reviews": 17,
        "seller": "CARPRODUCT",
        "seller_full": "ИП Павлов В. В.",
        "brand": "Carproduct",
        "position": 187
    },
    {
        "site": "wildberries.ru",
        "name": "Сумка нейлоновая через плечо плащевая",
        "price": 981,
        "old_price": 3700,
        "reviews": 131,
        "seller": "Multibags",
        "seller_full": "ИП Белов В. Н.",
        "brand": "Сумки в подарок"
    },
    {
        "site": "wildberries.ru",
        "name": "Кейс с отделением S22 Ultra",
        "price": 469,
        "old_price": 999,
        "reviews": 1,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "Mobi711",
        "position": 145
    },
    {
        "site": "wildberries.ru",
        "name": "Универсальная складная лопата-мультитул 10-в-1 - прочная",
        "price": 2281,
        "old_price": 4500,
        "reviews": 1,
        "seller": "TACTICGU.RU",
        "seller_full": "TACTICGU.RU",
        "brand": "Tactic Guru",
        "position": 134
    },
    {
        "site": "wildberries.ru",
        "name": "Прокладки гигиенические",
        "price": 699,
        "old_price": 1247,
        "reviews": 1,
        "seller_full": "ИП Захарова А. В.",
        "brand": "Kotex",
        "position": 154
    },
    {
        "site": "wildberries.ru",
        "name": "Линзы квартальные -4 контактные с диоптриями для глаз 4 шт",
        "price": 1091,
        "old_price": 1299,
        "reviews": 277,
        "seller": "FOCUS",
        "seller_full": "ООО \"ГЛОБАЛ ВИЖН КЭР\"",
        "brand": "Focus",
        "position": 193
    },
    {
        "site": "wildberries.ru",
        "name": "Аниме фигурка фигурка Евангелион, Рей",
        "price": 495,
        "old_price": 1100,
        "reviews": 315,
        "seller": "Animania",
        "seller_full": "ИП Молчанов Ю. О.",
        "brand": "ANIMANIA_SHOP",
        "position": 161
    },
    {
        "site": "wildberries.ru",
        "name": "Тапочки теплые домашние чуни",
        "price": 567,
        "old_price": 2100,
        "reviews": 388,
        "seller": "Elena&Best",
        "seller_full": "ИП Мураховская Е. Л.",
        "brand": "TRU-la-la",
        "position": 220
    },
    {
        "site": "wildberries.ru",
        "name": "кабель",
        "price": 104,
        "old_price": 290,
        "reviews": 36,
        "seller": "VIT-AV",
        "seller_full": "ИП Кравцова Инна Геннадьевна",
        "brand": "Premier-av",
        "position": 58
    },
    {
        "site": "wildberries.ru",
        "name": "Ариана Гранде автограф на постере, подарок, размер А4",
        "price": 1549,
        "old_price": 3499,
        "reviews": 1,
        "seller": "COLLECTORS",
        "seller_full": "ООО \"СТРОЙИНВЕСТ\"",
        "brand": "COLLECTORS",
        "position": 210
    },
    {
        "site": "wildberries.ru",
        "name": "Чешки для танцев",
        "price": 505,
        "old_price": 1124,
        "reviews": 43,
        "seller": "J-shoes",
        "seller_full": "ИП Дроздова Н. М.",
        "brand": "J-shoes",
        "position": 153
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на стул со спинкой для кухни и гостиной, 1 шт",
        "price": 475,
        "old_price": 1189,
        "reviews": 356,
        "seller": "Пижамка",
        "seller_full": "ИП Слабожанин Д. Г.",
        "brand": "Пижамка"
    },
    {
        "site": "ozon.ru",
        "name": "Наклейки интерьерные на стену для декора  - девушка, модель, шляпка, платье, мода",
        "price": 518,
        "old_price": 2100,
        "reviews": 2,
        "seller": "Интерьерные наклейки",
        "seller_full": "ИП Белоусов Александр Викторович",
        "brand": "Российская Фабрика Виниловых Наклеек",
        "position": 241
    },
    {
        "site": "ozon.ru",
        "name": "Спортивный костюм Атланта-Спорт",
        "price": 2037,
        "old_price": 4500,
        "reviews": 29,
        "seller": "Атланта-Спорт",
        "seller_full": "ИП Пятернева Анна Владимировна",
        "brand": "Атланта-Спорт"
    },
    {
        "site": "wildberries.ru",
        "name": "Сапоги",
        "price": 3054,
        "old_price": 19850,
        "reviews": 5,
        "seller": "BELAVITT MARIYO MODA",
        "seller_full": "Рувинова Эллада Юсуфовна ИП",
        "brand": "Belavitt",
        "position": 257
    },
    {
        "site": "ozon.ru",
        "name": "Ткань для шитья(1 м) Штапель \"Коричнево-алые букетики на малиновом\", ш.1.44м, вискоза-100%, 120гр/м.кв",
        "price": 655,
        "old_price": 1140,
        "reviews": 20,
        "seller": "Мильфлёр натуральные ткани",
        "seller_full": "ИП Созинов Алексей Николаевич",
        "brand": "Мильфлёр",
        "position": 13
    },
    {
        "site": "ozon.ru",
        "name": "Платье",
        "price": 1537,
        "old_price": 2168,
        "reviews": 1,
        "seller": "Longdian",
        "seller_full": "Xincai County Caixiang Trading Company"
    },
    {
        "site": "wildberries.ru",
        "name": "Хлебцы Клюквенный злаковый коктейль, 5 шт 500 г",
        "price": 430,
        "old_price": 663,
        "reviews": 294,
        "seller": "NATUROS",
        "seller_full": "ООО \"НАТУРОС\"",
        "brand": "Dr. Korner",
        "position": 255
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка",
        "price": 1368,
        "old_price": 3380,
        "reviews": 108,
        "seller": "Наместникова Альбина Сагитулловна ИП",
        "seller_full": "Наместникова Альбина Сагитулловна ИП",
        "brand": "Minimal Trend",
        "position": 33
    },
    {
        "site": "wildberries.ru",
        "name": "Блузки повседневное круглогодичный",
        "price": 1565,
        "old_price": 3333,
        "reviews": 222,
        "seller": "GARMETRIC И САЙГОН",
        "seller_full": "ИП Ле Тхи Ван",
        "brand": "GARMETRIC И САЙГОН",
        "position": 2
    },
    {
        "site": "wildberries.ru",
        "name": "Ювелирные серьги",
        "price": 34440,
        "old_price": 84000,
        "reviews": 4,
        "seller": "Ювелирное Производство ЮВЭЛДИ",
        "seller_full": "ИП Крылова Ольга Викторовна",
        "brand": "ЮВЭЛДИ"
    },
    {
        "site": "wildberries.ru",
        "name": "Ева коврики NISSAN KIX (2008-2012)",
        "price": 2457,
        "old_price": 15400,
        "reviews": 1,
        "seller": "Kovrix",
        "seller_full": "ИП Муллаянов А. М.",
        "brand": "Kovrix",
        "position": 99
    },
    {
        "site": "wildberries.ru",
        "name": "Постельное белье семейное с 2 пододеяльниками",
        "price": 3519,
        "old_price": 8583,
        "reviews": 13,
        "seller": "АртДизайн - Текстиль от производителя",
        "seller_full": "ИП Конькова С. В.",
        "brand": "АРТПОСТЕЛЬ"
    },
    {
        "site": "ozon.ru",
        "name": "Masil Маска для волос салонный эффект за 8 секунд - 8 Seconds salon hair mask, 8мл",
        "price": 170,
        "old_price": 170,
        "reviews": 1,
        "seller": "Yourcare",
        "seller_full": "ИП Пахомов Алексей Олегович",
        "brand": "MASIL",
        "position": 174
    },
    {
        "site": "wildberries.ru",
        "name": "Трусы набор 3шт",
        "price": 270,
        "old_price": 300,
        "reviews": 4,
        "seller": "Complimento",
        "seller_full": "Complimento",
        "brand": "Complimento",
        "position": 159
    },
    {
        "site": "wildberries.ru",
        "name": "Кеды женские летние на платформе текстиль классические",
        "price": 640,
        "old_price": 2399,
        "reviews": 2988,
        "seller": "MOON RIVER",
        "seller_full": "ДИДЖИТ ТРЕЙДИНГ ООО",
        "brand": "Moon River",
        "position": 59
    },
    {
        "site": "wildberries.ru",
        "name": "Sea Salt Spray Спрей с морской солью More Inside, 250мл",
        "price": 2984,
        "old_price": 4180,
        "reviews": 1,
        "seller": "Beautiful Mood",
        "seller_full": "Beautiful Mood",
        "brand": "Davines",
        "position": 43
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для телефона",
        "price": 3750,
        "old_price": 5000,
        "reviews": 1,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "-",
        "position": 50
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол книжка Xiaomi Redmi 9 Сяоми Редми 9 с подставкой",
        "price": 217,
        "old_price": 836,
        "reviews": 1,
        "seller": "Butterfly",
        "seller_full": "ИП Алагха Санаа",
        "brand": "MKD-CASE"
    },
    {
        "site": "wildberries.ru",
        "name": "Ремешок для Apple Watch 42 44 мм (Ultra) браслет",
        "price": 242,
        "old_price": 970,
        "reviews": 47,
        "seller": "SHIRKHAN",
        "seller_full": "ИП Мохаммад Ф. Р.",
        "brand": "SHIRKHAN",
        "position": 108
    },
    {
        "site": "wildberries.ru",
        "name": "Поднос пластиковый столовый 35,5см",
        "price": 270,
        "old_price": 750,
        "reviews": 364,
        "seller": "MASTERPIECES",
        "seller_full": "ИП Корчагина Е. А.",
        "brand": "DomKor",
        "position": 19
    },
    {
        "site": "wildberries.ru",
        "name": "Маленький и вместительный кошелек для карт и купюр",
        "price": 450,
        "old_price": 1000,
        "reviews": 258,
        "seller": "PRIME FOODS",
        "seller_full": "ИП Мансуров Ф. Р.",
        "brand": "Evermore",
        "position": 82
    },
    {
        "site": "ozon.ru",
        "name": "Особая серия Очищающее молочко Малиновый Коктейль 265 мл.",
        "price": 368,
        "old_price": 589,
        "reviews": 1,
        "seller": "Beauty and Home",
        "seller_full": "ИП Литошко Екатерина Александровна",
        "brand": "Особая Серия",
        "position": 5
    },
    {
        "site": "wildberries.ru",
        "name": "Угги",
        "price": 1751,
        "old_price": 8650,
        "reviews": 4,
        "seller_full": "ООО ВАЙЛДБЕРРИЗ",
        "brand": "4U",
        "position": 93
    },
    {
        "site": "wildberries.ru",
        "name": "Кружка Ювентус (Juventus)",
        "price": 425,
        "old_price": 1290,
        "reviews": 5,
        "seller": "FanShop",
        "seller_full": "ИП Винокуров Р. Ю.",
        "brand": "XFAN"
    },
    {
        "site": "wildberries.ru",
        "name": "Спицы addiClassic Lace №4,5 150 см + нить + аксессуары",
        "price": 1477,
        "old_price": 3694,
        "reviews": 1,
        "seller": "Ve4nomolodoy",
        "seller_full": "ИП Сайкина И. А.",
        "brand": "ADDI"
    },
    {
        "site": "wildberries.ru",
        "name": "Палетка контуринга Golden decagon",
        "price": 760,
        "old_price": 1140,
        "reviews": 26,
        "seller": "Зелёное яблоко",
        "seller_full": "ИП Стрижакова Э. С.",
        "brand": "Influence Beauty",
        "position": 166
    },
    {
        "site": "wildberries.ru",
        "name": "Мешок для сменной обуви",
        "price": 660,
        "old_price": 1650,
        "reviews": 68,
        "seller": "Cartera",
        "seller_full": "КАРТЕРА ООО",
        "brand": "Cartera"
    },
    {
        "site": "wildberries.ru",
        "name": "Материнская молитва. Молитвы о детях (УКИНО Духовное Преобра",
        "price": 174,
        "old_price": 260,
        "reviews": 3,
        "seller": "Храм Книги",
        "seller_full": "Токарев Виталий Александрович",
        "brand": "Духовное преображение",
        "position": 184
    },
    {
        "site": "wildberries.ru",
        "name": "Пахлава \"Бурма\" 300 г",
        "price": 265,
        "old_price": 590,
        "reviews": 968,
        "seller": "CrimeanSweets",
        "seller_full": "ИП Налимов Р. Г.",
        "brand": "CrimeanSweets",
        "position": 16
    },
    {
        "site": "wildberries.ru",
        "name": "Коврики Toyota Caldina 3 T240 2002-2007",
        "price": 2012,
        "old_price": 4060,
        "reviews": 7,
        "seller": "Prime-avto",
        "seller_full": "ИП Рузматов Тимур Эркинович",
        "brand": "Prime-avto",
        "position": 152
    },
    {
        "site": "wildberries.ru",
        "name": "Алфавит Лора мягкие игрушки для детей развивающие",
        "price": 239,
        "old_price": 3999,
        "reviews": 17,
        "seller": "Simple_13",
        "seller_full": "Алиева Аниса Вагизовна",
        "brand": "-"
    },
    {
        "site": "wildberries.ru",
        "name": "Кардиган вязаный пуговицах",
        "price": 854,
        "old_price": 6205,
        "reviews": 39,
        "seller": "MajoR_FashioN",
        "seller_full": "ИП Давлатов М. Б.",
        "brand": "-",
        "position": 94
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Oppo Reno Z",
        "price": 321,
        "old_price": 684,
        "reviews": 4,
        "seller": "Andy&Paul",
        "seller_full": "ИП Коновалов П. А.",
        "brand": "Andy&Paul",
        "position": 194
    },
    {
        "site": "wildberries.ru",
        "name": "Топ",
        "price": 382,
        "old_price": 1470,
        "reviews": 4,
        "seller": "СТИЛЬТЕКС",
        "seller_full": "ООО \"СТИЛЬТЕКС\"",
        "brand": "ТМ Maryelle-M"
    },
    {
        "site": "ozon.ru",
        "name": "Деревянные заготовки для творчества \"Знак зодиака \"Весы\" 8х6,5см, \"Добрый мастер\" №14059",
        "price": 151,
        "old_price": 321,
        "reviews": 8,
        "seller": "Товары для рукоделия",
        "seller_full": "ООО Товары для рукоделия",
        "brand": "Добрый мастер",
        "position": 54
    },
    {
        "site": "wildberries.ru",
        "name": "Ресницы чёрные Рили M 0.07 8-15мм",
        "price": 474,
        "old_price": 847,
        "reviews": 54,
        "seller": "Первый Профессиональный",
        "seller_full": "ИП Ткачук В. Р.",
        "brand": "Rili",
        "position": 218
    },
    {
        "site": "ozon.ru",
        "name": "Аниме Синяя тюрьма: Блю Лок / Blue Lock Мягкая игрушка 45 CM",
        "price": 1596,
        "old_price": 3606,
        "reviews": 6,
        "seller": "Yang Nongyi shop",
        "seller_full": "Dongguan Manidi Trading Co., Ltd"
    },
    {
        "site": "wildberries.ru",
        "name": "Новогоднее украшение \"Колокольчики\" в пакете",
        "price": 172,
        "old_price": 172,
        "reviews": 15,
        "seller": "Той Импорт",
        "seller_full": "ООО ТОЙ ИМПОРТ",
        "brand": "Снеговичок"
    },
    {
        "site": "wildberries.ru",
        "name": "Мини Эдалт ПР-27 для собак мелких пород 800гр",
        "price": 1057,
        "old_price": 2376,
        "reviews": 2,
        "seller": "AA маркет",
        "seller_full": "ИП Сайкина А. А.",
        "brand": "РОЯЛ КАНИН"
    },
    {
        "site": "wildberries.ru",
        "name": "Кроссовки женские на высокой подошве",
        "price": 970,
        "old_price": 7490,
        "reviews": 2,
        "seller": "Lucky sister official",
        "seller_full": "ООО \"ХУАСЯ\"",
        "brand": "Lucky Sister",
        "position": 253
    },
    {
        "site": "ozon.ru",
        "name": "Простыня на резинке Всё себе , Поплин, 140x200 см",
        "price": 1393,
        "old_price": 2171,
        "reviews": 35,
        "seller": "Postel-fashion",
        "seller_full": "ИП Панин Тимофей Федорович",
        "brand": "Всё себе",
        "position": 30
    },
    {
        "site": "wildberries.ru",
        "name": "Бальзам для мытья посуды\"Гранат\"",
        "price": 316,
        "old_price": 480,
        "reviews": 13,
        "seller": "TorgOpt",
        "seller_full": "ИП Мурашкин Александр Викторович",
        "brand": "Frosch"
    },
    {
        "site": "wildberries.ru",
        "name": "Тюль под лен белый 300х270",
        "price": 1046,
        "old_price": 1864,
        "reviews": 2,
        "seller": "RODNOY DOM",
        "seller_full": "RODNOY DOM",
        "brand": "RODNOY DOM",
        "position": 33
    },
    {
        "site": "wildberries.ru",
        "name": "Камушки декоративные, стеклянные шарики",
        "price": 201,
        "old_price": 610,
        "reviews": 72,
        "seller_full": "Хохлов Александр Михайлович ИП",
        "brand": "Подарок52",
        "position": 126
    },
    {
        "site": "wildberries.ru",
        "name": "Зувей колготки кашемировые зимние",
        "price": 432,
        "old_price": 2400,
        "reviews": 156,
        "seller": "SAMILEM",
        "seller_full": "ИП Казарян А. С.",
        "brand": "SAMILEM",
        "position": 195
    },
    {
        "site": "wildberries.ru",
        "name": "Пилинг для лица Anti-Acne Professional Face Care",
        "price": 1779,
        "old_price": 4238,
        "reviews": 2,
        "seller": "Белорусская косметика",
        "seller_full": "ФИНТРЕЙДИНГ ООО",
        "brand": "БЕЛИТА",
        "position": 162
    },
    {
        "site": "wildberries.ru",
        "name": "Босоножки на низком каблуке",
        "price": 2837,
        "old_price": 5390,
        "reviews": 387,
        "seller": "Здоровая Детская обувь / Женская обувь",
        "seller_full": "ИТ ОБУВЬ ООО",
        "brand": "ARTIMA"
    },
    {
        "site": "wildberries.ru",
        "name": "Глянцевая гидрогелевая пленка на Oppo F7",
        "price": 349,
        "old_price": 698,
        "reviews": 1,
        "seller": "PROtect",
        "seller_full": "Артамонова Ольга Михайловна ИП",
        "brand": "LuxCase",
        "position": 175
    },
    {
        "site": "wildberries.ru",
        "name": "Туфли",
        "price": 6433,
        "old_price": 10590,
        "reviews": 3,
        "seller": "Rendez-Vous",
        "seller_full": "ООО \"РАНДЕВУ\"",
        "brand": "Tendance",
        "position": 82
    },
    {
        "site": "wildberries.ru",
        "name": "Ботинки",
        "price": 5244,
        "old_price": 9250,
        "reviews": 2,
        "seller": "Сириус СпецОдежда",
        "seller_full": "ИП Черкасов Н. С.",
        "brand": "Сириус СпецОдежда",
        "position": 4
    },
    {
        "site": "wildberries.ru",
        "name": "Рюкзак школьный для подростков портфель большой",
        "price": 2246,
        "old_price": 9000,
        "reviews": 53,
        "seller": "WONDER",
        "seller_full": "Пургаева Нина Игоревна",
        "brand": "Wonder Bag",
        "position": 113
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол на Note 30i с рисунком",
        "price": 359,
        "old_price": 999,
        "reviews": 1,
        "seller": "Case Place",
        "seller_full": "Бойко Никита ИП",
        "brand": "Infinix",
        "position": 119
    },
    {
        "site": "wildberries.ru",
        "name": "Мяч футбольный спортивный",
        "price": 1646,
        "old_price": 39201,
        "reviews": 4,
        "seller": "AIBEK SHOP",
        "seller_full": "ИП Жолдыбаев",
        "brand": "Aibek shop",
        "position": 147
    },
    {
        "site": "wildberries.ru",
        "name": "Ежедневник недатированный А5",
        "price": 635,
        "old_price": 1983,
        "reviews": 10,
        "seller": "BUTA",
        "seller_full": "BUTA",
        "brand": "NOBRAND"
    },
    {
        "site": "wildberries.ru",
        "name": "Шапка",
        "price": 333,
        "old_price": 1850,
        "reviews": 11,
        "seller": "COCCODRILLO - Kids are kids",
        "seller_full": "МАРКЕТ ПЛЮС ООО",
        "brand": "Coccodrillo",
        "position": 240
    },
    {
        "site": "ozon.ru",
        "name": "Куртка",
        "price": 10350,
        "old_price": 18210,
        "reviews": 4,
        "seller": "УНИКАЛЬНЫЕ ВЕЩИ!",
        "seller_full": "ИП Новицкий Аркадий Игоревич"
    },
    {
        "site": "wildberries.ru",
        "name": "Очиститель битумных пятен Антибитум 500мл",
        "price": 371,
        "old_price": 1484,
        "reviews": 2,
        "seller": "W_RUBCHIK",
        "seller_full": "ИП Федоров Е. В.",
        "brand": "AVTOмелочь"
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол с визитницей для Samsung Galaxy S10E",
        "price": 469,
        "old_price": 999,
        "reviews": 25,
        "seller": "SuperPrint",
        "seller_full": "ИП Федоров И. И.",
        "brand": "SuperPrint",
        "position": 135
    },
    {
        "site": "wildberries.ru",
        "name": "ботильоны",
        "price": 8286,
        "old_price": 18600,
        "reviews": 1,
        "seller": "Venci",
        "seller_full": "Venci",
        "brand": "Lobas Shoes Original",
        "position": 150
    },
    {
        "site": "wildberries.ru",
        "name": "Босоножки кожаные сандалии летние на плоской подошве",
        "price": 2794,
        "old_price": 15000,
        "reviews": 14,
        "seller": "ИП Оруджова Т. А.",
        "seller_full": "ИП Оруджова Т. А.",
        "brand": "BLAVERT",
        "position": 95
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для туи на зиму 100х120 см",
        "price": 475,
        "old_price": 951,
        "reviews": 12,
        "seller": "PROTENT - про тенты и не только",
        "seller_full": "ИП Илларионов А. В.",
        "brand": "ProTent",
        "position": 18
    },
    {
        "site": "wildberries.ru",
        "name": "Куртка",
        "price": 3996,
        "old_price": 7150,
        "reviews": 8,
        "seller": "Артус",
        "seller_full": "ООО\"ТД \"АРТУС\"",
        "brand": "Артус",
        "position": 181
    },
    {
        "site": "wildberries.ru",
        "name": "Туника пляжная летняя хлопок",
        "price": 1642,
        "old_price": 3975,
        "reviews": 8,
        "seller": "KN shop",
        "seller_full": "ИП Новикова Е. П.",
        "brand": "нет бренда",
        "position": 117
    },
    {
        "site": "wildberries.ru",
        "name": "Махровые носки для мальчика, 6 пар",
        "price": 567,
        "old_price": 700,
        "reviews": 82,
        "seller": "BukinaTeka",
        "seller_full": "ИП Букина А. В.",
        "brand": "BukinaTeka",
        "position": 188
    },
    {
        "site": "wildberries.ru",
        "name": "Мягкая игрушка-антистресс Дракон",
        "price": 1159,
        "old_price": 2210,
        "reviews": 55,
        "seller": "Штучки, к которым тянутся ручки",
        "seller_full": "СОЮЗ ПРОИЗВОДИТЕЛЕЙ ИГРУШЕК ООО",
        "brand": "Штучки, к которым тянутся ручки",
        "position": 205
    },
    {
        "site": "ozon.ru",
        "name": "Чехол задняя-панель-накладка-бампер MyPads Хэллоуин доски тыква для Xiaomi Redmi K20/Xiaomi Mi 9T противоударный",
        "price": 488,
        "old_price": 688,
        "reviews": 1,
        "seller": "MyPads миллион чехлов",
        "seller_full": "ИП Завьялова Мария Васильевна",
        "brand": "MyPads",
        "position": 36
    },
    {
        "site": "ozon.ru",
        "name": "Штора для автомойки, склада, без вставки 330 * 600 материал ПВХ Hanwha Unisol 630 Ю.Корея цв. желтый",
        "price": 18817,
        "old_price": 33682,
        "reviews": 1,
        "seller": "Азбука Тентов",
        "seller_full": "ИП Магаева Ольга Витальевна",
        "brand": "АЗБУКА ТЕНТОВ"
    },
    {
        "site": "wildberries.ru",
        "name": "Зимние Ботинки",
        "price": 1416,
        "old_price": 3887,
        "reviews": 115,
        "seller": "MIRS KIDS",
        "seller_full": "MIRS KIDS",
        "brand": "SALINNA",
        "position": 245
    },
    {
        "site": "wildberries.ru",
        "name": "Лен ткань для шитья Полулен 1м",
        "price": 396,
        "old_price": 862,
        "reviews": 10,
        "seller": "MagOK",
        "seller_full": "ИП Ларина Т. А.",
        "brand": "Ткани Magok",
        "position": 107
    },
    {
        "site": "wildberries.ru",
        "name": "Крем для лица с муцином улитки Anti-Wrinkle Snail Cream 50ml",
        "price": 1287,
        "old_price": 1990,
        "reviews": 4,
        "seller_full": "К-ВИЖЕН ООО",
        "brand": "HANIxHANI",
        "position": 34
    },
    {
        "site": "wildberries.ru",
        "name": "Ежедневник Амина недатированный A5 именной",
        "price": 727,
        "old_price": 1900,
        "reviews": 11,
        "seller_full": "Рябов Валерий Сергеевич ИП",
        "brand": "Msklaser",
        "position": 138
    },
    {
        "site": "wildberries.ru",
        "name": "Хомут пластиковый стяжка 3*150",
        "price": 236,
        "old_price": 260,
        "reviews": 77,
        "seller": "ФаворитЪ",
        "seller_full": "ИП Донской А. В.",
        "brand": "-",
        "position": 81
    },
    {
        "site": "wildberries.ru",
        "name": "Ветровка бомбер весна лето оверсайз",
        "price": 1117,
        "old_price": 11500,
        "reviews": 90,
        "seller": "MODA TYT",
        "seller_full": "ИП Кошкарев Роман Альбертович",
        "brand": "MODA TYT",
        "position": 114
    },
    {
        "site": "wildberries.ru",
        "name": "Фреза алмазная пуля экстра мелкой зерн. 3,1 мм. 10 шт",
        "price": 667,
        "old_price": 937,
        "reviews": 6,
        "seller": "ЭКСПЕРТЫ КРАСОТЫ",
        "seller_full": "ЭКСПЕРТЫ КРАСОТЫ ООО",
        "brand": "ВладМиВа",
        "position": 150
    },
    {
        "site": "wildberries.ru",
        "name": "Стакан именной бокал для пива с гравировкой Лия",
        "price": 522,
        "old_price": 1160,
        "reviews": 7,
        "seller": "VINONO",
        "seller_full": "ИП Кадырова К. С.",
        "brand": "VINONO",
        "position": 107
    },
    {
        "site": "wildberries.ru",
        "name": "Стакан складной силиконовый для кофе",
        "price": 920,
        "old_price": 4318,
        "reviews": 43,
        "seller_full": "Рымарович Роман Михайлович ИП",
        "brand": "Think&Care",
        "position": 120
    },
    {
        "site": "ozon.ru",
        "name": "Картина по номерам Y-862 \"Аниме фарфоровая кукла. Марин Китагава\" 60x80",
        "price": 3203,
        "old_price": 4590,
        "reviews": 28,
        "seller": "BRUSHES-PAINTS",
        "seller_full": "ИП Шишков Иван Вячеславович",
        "brand": "BRUSHES-PAINTS",
        "position": 94
    },
    {
        "site": "wildberries.ru",
        "name": "SHINEFINITY 09 02 Гель-крем Пустынный Шалфей 60 мл",
        "price": 985,
        "old_price": 1955,
        "reviews": 17,
        "seller": "AF.Beauty.Store",
        "seller_full": "ООО \"АФ Бьюти Стор\"",
        "brand": "Wella Professionals",
        "position": 67
    },
    {
        "site": "wildberries.ru",
        "name": "Набор из 3 вещей для поклейки стекла",
        "price": 212,
        "old_price": 212,
        "reviews": 3,
        "seller": "Девита",
        "seller_full": "ООО \"ДЕВИТА\"",
        "brand": "DEVIMAX",
        "position": 132
    },
    {
        "site": "ozon.ru",
        "name": "Bubchen Молочко 50 мл * 3 штуки",
        "price": 640,
        "old_price": 936,
        "reviews": 39,
        "seller": "Домашняя кладовая",
        "seller_full": "ИП Смелов Александр Григорьевич",
        "brand": "Bubchen"
    },
    {
        "site": "ozon.ru",
        "name": "Картина по номерам на холсте с подрамником 40х50 см. Фильмы и сериалы. \"Гарри Поттер. Знак даров Смерти.\", арт. 1232/",
        "price": 850,
        "old_price": 2600,
        "reviews": 219,
        "seller": "МыРисуем.РФ",
        "seller_full": "ИП Канищев Дмитрий Анатольевич",
        "brand": "МыРисуем"
    },
    {
        "site": "wildberries.ru",
        "name": "Клеенка непромокаемая многоразовая большая в кроватку",
        "price": 382,
        "old_price": 1062,
        "reviews": 117,
        "seller": "ПромЭласт",
        "seller_full": "ООО \"ПРОМЭЛАСТ\"",
        "brand": "Колорит"
    },
    {
        "site": "wildberries.ru",
        "name": "Джинсы с высокой посадкой утепленные большие размер",
        "price": 1893,
        "old_price": 4584,
        "reviews": 3139,
        "seller": "ИП Демидова М.В.",
        "seller_full": "ИП Демидова Мария Вячеславовна",
        "brand": "JECCESS"
    },
    {
        "site": "wildberries.ru",
        "name": "Шапка для новорожденных малышей для мальчика на завязках",
        "price": 468,
        "old_price": 1200,
        "reviews": 16,
        "seller": "Orhida Kids",
        "seller_full": "Карибова Бэла Гатемовна ИП",
        "brand": "ORHIDA KIDS",
        "position": 209
    },
    {
        "site": "wildberries.ru",
        "name": "Last Chery масляные, женские духи. 6мл Турция",
        "price": 270,
        "old_price": 1231,
        "reviews": 9,
        "seller": "Интернешнл Селлерс",
        "seller_full": "Гиматутдинов Марат Тагирович",
        "brand": "AKSA Esans"
    },
    {
        "site": "wildberries.ru",
        "name": "Семена Огурца Конни F1 + Соленые Уши F1",
        "price": 265,
        "old_price": 500,
        "reviews": 5,
        "seller": "Айва Сад",
        "seller_full": "ИП Наконечная А. М.",
        "brand": "Семена Алтая",
        "position": 32
    },
    {
        "site": "wildberries.ru",
        "name": "Голографический стикер на стекло Keep it street 60x12 см",
        "price": 747,
        "old_price": 2000,
        "reviews": 25,
        "seller": "Resource Stickers",
        "seller_full": "ИП Товтин К. И.",
        "brand": "Resource Stickers",
        "position": 28
    },
    {
        "site": "wildberries.ru",
        "name": "Обложка для паспорта из натуральной кожи",
        "price": 170,
        "old_price": 1000,
        "reviews": 7,
        "seller": "Azimuth-Trade",
        "seller_full": "Рубайло Максим Максимович ИП",
        "brand": "Max Rhino",
        "position": 36
    },
    {
        "site": "ozon.ru",
        "name": "Стартер для автомобиля (автостартер) Ford Ranger (Форд Ренжер) / Mazda B 2500, BT-50, Mpv (Мазда Б 2500, БТ-50, Мпв), новый",
        "price": 11594,
        "old_price": 15664,
        "reviews": 1,
        "seller": "Вольтаж",
        "seller_full": "ООО \"АТМ\"",
        "brand": "KRAUF",
        "position": 15
    },
    {
        "site": "wildberries.ru",
        "name": "Коврик для мыши скуби ду с мультсериалом комедия фильм",
        "price": 467,
        "old_price": 550,
        "reviews": 1,
        "seller": "DiamondShop",
        "seller_full": "ИП Запольский",
        "brand": "DiamondMousePad",
        "position": 68
    },
    {
        "site": "wildberries.ru",
        "name": "Энергетик Торнадо Storm Шторм",
        "price": 872,
        "old_price": 2000,
        "reviews": 420,
        "seller": "ILYAS GROUP",
        "seller_full": "ИП Хайдари А. Н.",
        "brand": "TORNADO"
    },
    {
        "site": "wildberries.ru",
        "name": "Защитная виниловая самоклеящаяся пленка скин на realme 6s",
        "price": 548,
        "old_price": 1270,
        "reviews": 4,
        "seller": "Официальный представитель",
        "seller_full": "ИП Петрова",
        "brand": "picture_for_gadget",
        "position": 55
    },
    {
        "site": "ozon.ru",
        "name": "Провод ПГВА для автопроводки 6кв.мм",
        "price": 656,
        "old_price": 773,
        "reviews": 97,
        "seller": "torg-electro",
        "seller_full": "ООО ПРОМПОСТАВКА",
        "brand": "ОАО «Щучинский завод «Автопровод»",
        "position": 122
    },
    {
        "site": "ozon.ru",
        "name": "Лоток для кошек с решеткой и совком / туалет для животных, 42 х 31 х 10 см",
        "price": 489,
        "old_price": 1000,
        "reviews": 502,
        "seller": "Universal Farm",
        "seller_full": "ИП Терентьева Ольга Ивановна",
        "brand": "RAFECOFF",
        "position": 107
    },
    {
        "site": "wildberries.ru",
        "name": "Куртка мужская осенняя оверсайз демисезонная стеганая",
        "price": 2510,
        "old_price": 20659,
        "reviews": 48,
        "seller": "R.Lonyr",
        "seller_full": "ИП《ХАСАНОВ АВАЗБЕК ДЖУМАБЕКОВИЧ》",
        "brand": "R.LONYR"
    },
    {
        "site": "ozon.ru",
        "name": "Комплект из 4 шт. Аккумуляторные батарейки AA 2600мАч / С кабелем Type-С в комплекте / Литий-ионные пальчиковые батарейки",
        "price": 1193,
        "old_price": 7497,
        "reviews": 127,
        "seller": "ИП Ковалев Евгений Леонидович",
        "seller_full": "ИП Ковалев Евгений Леонидович"
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка с принтом Цой",
        "price": 845,
        "old_price": 1800,
        "reviews": 28,
        "seller": "ShamanStore",
        "seller_full": "Русинов И.",
        "brand": "ShamanStore",
        "position": 165
    },
    {
        "site": "wildberries.ru",
        "name": "Шорты удлиненные хлопок",
        "price": 1381,
        "old_price": 1814,
        "reviews": 23,
        "seller_full": "Колегов Антон Валерьевич",
        "brand": "LINGEAMO",
        "position": 156
    },
    {
        "site": "wildberries.ru",
        "name": "Ковер 250х350",
        "price": 8220,
        "old_price": 10779,
        "reviews": 3,
        "seller": "Kovry",
        "seller_full": "ИП Курбонова Х. Р.",
        "brand": "NewCarpets",
        "position": 142
    },
    {
        "site": "wildberries.ru",
        "name": "Гель лак для ногтей QN трёхфазный профессиональный 10 мл",
        "price": 192,
        "old_price": 247,
        "reviews": 2,
        "seller": "NAIL-MARKET",
        "seller_full": "ИП Овсянникова О. В.",
        "brand": "Quality Nails"
    },
    {
        "site": "wildberries.ru",
        "name": "Автограф Роберт Паттинсон Бэтмен",
        "price": 485,
        "old_price": 999,
        "reviews": 2,
        "seller": "COLLECTORS",
        "seller_full": "ООО \"СТРОЙИНВЕСТ\"",
        "brand": "COLLECTORS",
        "position": 10
    },
    {
        "site": "ozon.ru",
        "name": "Крючки Dunaev Classik 210 №10 (10шт)",
        "price": 228,
        "old_price": 628,
        "reviews": 2,
        "seller": "Бадис",
        "seller_full": "ООО Бадис",
        "brand": "DUNAEV",
        "position": 69
    },
    {
        "site": "wildberries.ru",
        "name": "Брюки женские утепленные",
        "price": 2103,
        "old_price": 5300,
        "reviews": 1,
        "seller_full": "ИП Додахонов Хайрулло турсунович",
        "brand": "World Fashion Style",
        "position": 127
    },
    {
        "site": "ozon.ru",
        "name": "Кукла Mattel Barbie Экстра Мини Мультяшная кукла-модница HGP63",
        "price": 3330,
        "old_price": 6220,
        "reviews": 4,
        "seller": "PandaModel",
        "seller_full": "Fuzhou MinBaobei Electronic Commerce Co., Ltd.",
        "brand": "Barbie"
    },
    {
        "site": "wildberries.ru",
        "name": "Платье миди праздничное в офис",
        "price": 1381,
        "old_price": 5500,
        "reviews": 68,
        "seller": "Ramanti",
        "seller_full": "Тюркоз Ирфан  ИП",
        "brand": "RAMANTI",
        "position": 225
    },
    {
        "site": "wildberries.ru",
        "name": "Гель-крем для бровей long-wear waterproof тон 12 5г",
        "price": 1431,
        "old_price": 2544,
        "reviews": 1,
        "seller_full": "ИП Захарова А. В.",
        "brand": "ARTDECO",
        "position": 212
    },
    {
        "site": "wildberries.ru",
        "name": "Лопатка-щипцы кухонная",
        "price": 169,
        "old_price": 239,
        "reviews": 12,
        "seller_full": "Шерстнёва Татьяна Валентиновна ИП",
        "brand": "exim",
        "position": 236
    },
    {
        "site": "wildberries.ru",
        "name": "Шампунь и Бальзам для окрашенных волос",
        "price": 1587,
        "old_price": 2699,
        "reviews": 8,
        "seller": "Amalya Home",
        "seller_full": "ОРГНИП 312055028900030",
        "brand": "Kapous"
    },
    {
        "site": "wildberries.ru",
        "name": "Шорты кудо карате спортивные детские",
        "price": 1062,
        "old_price": 4374,
        "reviews": 51,
        "seller": "TENGO",
        "seller_full": "Потапова Татьяна Михайловна ИП",
        "brand": "TENGO",
        "position": 192
    },
    {
        "site": "wildberries.ru",
        "name": "Ковер комнатный 120х320 с рисунком",
        "price": 4796,
        "old_price": 14998,
        "reviews": 2,
        "seller": "ДОВЕРИЕ ТЫСЯЧ ПОКУПАТЕЛЕЙ",
        "seller_full": "СЕРВИС ТЕХНОЛОГИЯ ООО",
        "brand": "Коврик соты",
        "position": 50
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол для телефона",
        "price": 3750,
        "old_price": 5000,
        "reviews": 28,
        "seller": "Аксессуары для гаджетов Mobi711",
        "seller_full": "ИП Абраамян М. С.",
        "brand": "-"
    },
    {
        "site": "ozon.ru",
        "name": "Сквизер ILSA для лимона 215х75х45мм, алюминий, желтый",
        "price": 1599,
        "old_price": 2640,
        "reviews": 83,
        "seller": "Комплекс-Бар Home",
        "seller_full": "ООО РестОтельТрейдинг",
        "brand": "ILSA",
        "position": 243
    },
    {
        "site": "wildberries.ru",
        "name": "Матрас, Топпер-наматрасник Марсель, 170x190 см",
        "price": 6240,
        "old_price": 7800,
        "reviews": 1,
        "seller": "PraktikMatras",
        "seller_full": "ООО Практик",
        "brand": "Praktik",
        "position": 58
    },
    {
        "site": "wildberries.ru",
        "name": "ТЭН водяные нержавеющая сталь 3000W М22, L250мм",
        "price": 1170,
        "old_price": 3000,
        "reviews": 3,
        "seller": "T & F",
        "seller_full": "ОГАННИСЯН ГРАЧЬЯ АЛЕКСАНОВИЧ",
        "brand": "T & F",
        "position": 67
    },
    {
        "site": "ozon.ru",
        "name": "Футболка Design Heroes 1, шт",
        "price": 1388,
        "old_price": 2300,
        "reviews": 1,
        "seller": "Hypnoteez",
        "seller_full": "ИП Гукасов Аркадий Юрьевич",
        "brand": "Design Heroes"
    },
    {
        "site": "wildberries.ru",
        "name": "Шляпа фетровая классическая с полями",
        "price": 3860,
        "old_price": 6190,
        "reviews": 35,
        "seller": "Мир ШЛЯП",
        "seller_full": "Ильичева Ольга Юрьевна ИП",
        "brand": "Мир ШЛЯП",
        "position": 42
    },
    {
        "site": "wildberries.ru",
        "name": "Носки женские высокие набор 2 пары",
        "price": 785,
        "old_price": 2937,
        "reviews": 1518,
        "seller": "TENDEN",
        "seller_full": "Сараева Кристина Игоревна ИП",
        "brand": "Tenden",
        "position": 10
    },
    {
        "site": "wildberries.ru",
        "name": "Эко средство бальзам для стирки шерсти и шелка, 750 мл",
        "price": 454,
        "old_price": 870,
        "reviews": 68,
        "seller": "AR",
        "seller_full": "ИП Хасанов Д. В.",
        "brand": "NORDLAND"
    },
    {
        "site": "ozon.ru",
        "name": "Настенное интерьерное зеркало в раме для спальни, гостиной и прихожей, в ванную комнату MIRROR MASTER, 700х600 мм",
        "price": 10555,
        "old_price": 17245,
        "reviews": 14,
        "seller": "MIRROR MASTER",
        "seller_full": "ИП Еременко Наталья Владимировна"
    },
    {
        "site": "wildberries.ru",
        "name": "Бумага наждачная",
        "price": 273,
        "old_price": 273,
        "reviews": 2,
        "seller": "Maxi-tools",
        "seller_full": "ИП Пушков Б.Ю.",
        "brand": "ARNEZI",
        "position": 235
    },
    {
        "site": "wildberries.ru",
        "name": "Чехол (накладка) Xiaomi Poco X3 NFC",
        "price": 255,
        "old_price": 1500,
        "reviews": 1,
        "seller": "Aem design",
        "seller_full": "ИП Елчиев В. Т.",
        "brand": "Aem design"
    },
    {
        "site": "wildberries.ru",
        "name": "Коврики EVA в салон Hyundai Elantra 5",
        "price": 2016,
        "old_price": 5000,
        "reviews": 1,
        "seller": "MatTucker",
        "seller_full": "ИП Павловский Иван Дмитриевич",
        "brand": "MatTucker",
        "position": 111
    },
    {
        "site": "ozon.ru",
        "name": "Бюстгальтер классическая чашка, на косточках Нижнее белье 1 шт",
        "price": 893,
        "old_price": 2100,
        "reviews": 75,
        "seller": "Колетт",
        "seller_full": "ООО Базис СПб",
        "position": 253
    },
    {
        "site": "ozon.ru",
        "name": "Фотошторы для кухни и спальни JoyArty \"Абстрактные ромбы\", 2 полотна со шторной лентой шириной по 145 см, высота 265 см.",
        "price": 2353,
        "old_price": 7522,
        "reviews": 3,
        "seller": "ООО \"Джой групп\"",
        "seller_full": "ООО Джой Групп",
        "brand": "JoyArty",
        "position": 50
    },
    {
        "site": "wildberries.ru",
        "name": "Слипоны легкие",
        "price": 798,
        "old_price": 1700,
        "reviews": 1019,
        "seller": "ASMO-S",
        "seller_full": "ИП Валиев З. М.",
        "brand": "ASMO"
    },
    {
        "site": "wildberries.ru",
        "name": "Браслет со знаком зодиака \"Рак\", плетёный, кожаный",
        "price": 143,
        "old_price": 199,
        "reviews": 16,
        "seller": "ЛУЧШАЯ СДЕЛКА",
        "seller_full": "ООО \"ЛУЧШАЯ СДЕЛКА\"",
        "brand": "ДИСВЕТ",
        "position": 9
    },
    {
        "site": "wildberries.ru",
        "name": "Кофейный столик Ротанг плетенные пластик",
        "price": 1392,
        "old_price": 2900,
        "reviews": 3,
        "seller": "Market+",
        "seller_full": "ИП \"Market+\"",
        "brand": "Шафран",
        "position": 44
    },
    {
        "site": "wildberries.ru",
        "name": "Мячи пластиковые тренировочные 3* (белые, 6 штук)",
        "price": 774,
        "old_price": 870,
        "reviews": 1,
        "seller": "Sport&Play",
        "seller_full": "ИП Магула О. М.",
        "brand": "HuIESON",
        "position": 173
    },
    {
        "site": "wildberries.ru",
        "name": "USB концентратор 4 в 1 - USB to USB 3.0+USB 2.0*3, 1.2м",
        "price": 723,
        "old_price": 965,
        "reviews": 1,
        "seller": "Exspress-Service",
        "seller_full": "ИП Цыплякова Т. Н.",
        "brand": "Hoco",
        "position": 227
    },
    {
        "site": "ozon.ru",
        "name": "Люстра V4750-8/12, 12xE27 макс. 40Вт",
        "price": 7983,
        "old_price": 12162,
        "reviews": 158,
        "seller": "Vitaluce официальный магазин",
        "seller_full": "ООО КСК-электро",
        "brand": "Vitaluce",
        "position": 73
    },
    {
        "site": "ozon.ru",
        "name": "Русский язык. Рабочая тетрадь. 2 класс. Часть 2. ФГОС | Желтовская Любовь Яковлевна",
        "price": 211,
        "old_price": 378,
        "reviews": 3,
        "seller": "ТК \"Абрис\"",
        "seller_full": "ООО \"Торговая компания \"Абрис\"",
        "brand": "Просвещение"
    },
    {
        "site": "ozon.ru",
        "name": "Стеклянные чешские бусины, граненые круглые, Fire polished, 4 мм, цвет Jet Heavy Metal Comet, 150 шт.",
        "price": 638,
        "old_price": 1268,
        "reviews": 36,
        "seller": "ИП Генералова Валентина Юрьевна",
        "seller_full": "ИП Генералова Валентина Юрьевна",
        "position": 47
    },
    {
        "site": "wildberries.ru",
        "name": "Туника с длинным рукавом теплая",
        "price": 1908,
        "old_price": 5890,
        "reviews": 43,
        "seller": "DAYS",
        "seller_full": "DAYS",
        "brand": "Vienetta Secret",
        "position": 237
    },
    {
        "site": "wildberries.ru",
        "name": "Топ бесшовный на бретельках",
        "price": 407,
        "old_price": 1456,
        "reviews": 401,
        "seller": "Гратти",
        "seller_full": "ООО ГРАТТИ",
        "brand": "La Zenia",
        "position": 155
    },
    {
        "site": "wildberries.ru",
        "name": "Скраб для ног и стоп профессиональный 3в1, набор 2шт",
        "price": 210,
        "old_price": 420,
        "reviews": 37,
        "seller": "Fito Cosmetic - официальный магазин производителя",
        "seller_full": "ООО «Фитокосметик»",
        "brand": "fito cosmetic",
        "position": 38
    },
    {
        "site": "wildberries.ru",
        "name": "Футболка оверсайз - с принтом модная стильная",
        "price": 805,
        "old_price": 2550,
        "reviews": 5,
        "seller": "КК fashion",
        "seller_full": "КК fashion",
        "brand": "КК fashion",
        "position": 61
    },
    {
        "site": "wildberries.ru",
        "name": "Флисовые носки треккинговые термо",
        "price": 554,
        "old_price": 800,
        "reviews": 110,
        "seller": "Экзосфера",
        "seller_full": "ООО \"Экзосфера\"",
        "brand": "Север Цех"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Alliance Perfect тон N1,5 Linen",
        "price": 1108,
        "old_price": 0,
        "reviews": 2029,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris",
        "position": 65
    },
    {
        "site": "megamarket.ru",
        "name": "Адаптирующаяся тональная основа Eveline Cosmetics Wonder Match т.25 Light Beige",
        "price": 563,
        "old_price": 1126,
        "reviews": 14,
        "seller": "Мегамаркет Москва",
        "brand": "Eveline",
        "position": 43
    },
    {
        "site": "megamarket.ru",
        "name": "Матирующая жидкая пудра для лица Белита LAB colour тон 02 натуральный",
        "price": 528,
        "old_price": 0,
        "reviews": 61,
        "seller": "Океандра",
        "brand": "Белита",
        "position": 85
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем для лица SHIK Perfect Liquid Foundation т.01, 20 мл",
        "price": 1750,
        "old_price": 0,
        "reviews": 62,
        "seller": "Официальный магазин SHIK",
        "brand": "SHIK",
        "position": 211
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Paris Alliance Perfect тон R3 Бежевый розовый",
        "price": 1108,
        "old_price": 0,
        "reviews": 2032,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris",
        "position": 254
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем матирующий LuxVisage тон 04 Натуральный",
        "price": 299,
        "old_price": 599,
        "reviews": 212,
        "seller": "Мегамаркет Москва Пушкино",
        "brand": "Luxvisage",
        "position": 91
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем для лица SHIK Perfect Liquid Foundation т.02 20 мл",
        "price": 1750,
        "old_price": 0,
        "reviews": 55,
        "seller": "Официальный магазин SHIK",
        "brand": "SHIK"
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Tarrington House Nadleen 180 см зеленая",
        "price": 2499,
        "old_price": 5499,
        "reviews": 237,
        "seller": "METRO - СберМаркет",
        "brand": "Tarrington House",
        "position": 249
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа Eveline Liquid Control, тон 010 32 мл",
        "price": 902,
        "old_price": 0,
        "reviews": 347,
        "seller": "Подружка Москва (со склада СберМегаМаркет)",
        "brand": "Eveline",
        "position": 178
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем для лица BelorDesign Nude Harmony, тон 201 Light beige",
        "price": 509,
        "old_price": 849,
        "reviews": 250,
        "seller": "Мегамаркет Москва Пушкино",
        "brand": "BelorDesign",
        "position": 68
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа для лица Influence Beauty матирующая тон 03 25 мл",
        "price": 626,
        "old_price": 895,
        "reviews": 244,
        "seller": "Мегамаркет Москва",
        "brand": "Influence Beauty",
        "position": 204
    },
    {
        "site": "megamarket.ru",
        "name": "Основа-кушон тональная Holika Holika Holipop Blur Lasting Cushion розово-бежевый, тон 02",
        "price": 770,
        "old_price": 1100,
        "reviews": 23,
        "seller": "Мегамаркет Москва",
        "brand": "Holika Holika",
        "position": 159
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Paese Long Cover Fluid, тон 01, 30 мл",
        "price": 2266,
        "old_price": 0,
        "reviews": 22,
        "seller": "РИВ ГОШ",
        "brand": "Paese",
        "position": 32
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Alliance Perfect тон 3.D/3.W золотистый бежевый",
        "price": 1108,
        "old_price": 0,
        "reviews": 2032,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris",
        "position": 148
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Shiseido Synchro Skin Radiant Lifting Foundation SPF30, Shell, №160",
        "price": 4634,
        "old_price": 6179,
        "reviews": 56,
        "seller": "Мегамаркет Спб",
        "brand": "Shiseido"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Alliance Perfect тон N4 Бежевый",
        "price": 1108,
        "old_price": 0,
        "reviews": 2022,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris",
        "position": 155
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Dior Capture Totale Super Potent Serum Foundation, SPF 20, 2N Neutral",
        "price": 7935,
        "old_price": 8729,
        "reviews": 10,
        "seller": "Мегамаркет Спб",
        "brand": "DIOR",
        "position": 257
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа для лица Influence Beauty матирующая тон 05 25 мл",
        "price": 626,
        "old_price": 895,
        "reviews": 243,
        "seller": "Мегамаркет Москва",
        "brand": "Influence Beauty",
        "position": 246
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Alliance Perfect тон N2 Ваниль",
        "price": 1167,
        "old_price": 0,
        "reviews": 2062,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris"
    },
    {
        "site": "megamarket.ru",
        "name": "Матирующий крем для лица Payot Pate Grise Tinted Perfecting Cream SPF 30 40 мл",
        "price": 2457,
        "old_price": 3780,
        "reviews": 1,
        "seller": "IBEAUTY Cosmetic Outlet",
        "brand": "PAYOT",
        "position": 105
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Enough Collagen Moisture Foundation SPF15 21 100 мл",
        "price": 280,
        "old_price": 900,
        "reviews": 748,
        "seller": "Космикс-Мегамаркет",
        "brand": "Enough",
        "position": 54
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный кушон увлажняющий SPF 50+, PA+++, тон 23 YU,R Moist Layer Cushion 25 гр",
        "price": 3390,
        "old_price": 0,
        "reviews": 24,
        "seller": "ООО «Прокосметика»",
        "brand": "YU.R"
    },
    {
        "site": "megamarket.ru",
        "name": "Крем тональный Luxvisage Skin Evolution soft matte blur effect матовый, Natural, тон 25",
        "price": 241,
        "old_price": 439,
        "reviews": 318,
        "seller": "Мегамаркет Спб",
        "brand": "Luxvisage"
    },
    {
        "site": "megamarket.ru",
        "name": "Крем-флюид тональный Relouis Paradiso сатиновый, Nude, тон 02",
        "price": 409,
        "old_price": 819,
        "reviews": 64,
        "seller": "Мегамаркет Москва Пушкино",
        "brand": "Relouis"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Bourjois Healthy Mix Relaunch 515 Rose Vanilla 30 мл",
        "price": 1543,
        "old_price": 0,
        "reviews": 36,
        "seller": "МАРКЕТ ИНТ",
        "brand": "Bourjois"
    },
    {
        "site": "megamarket.ru",
        "name": "Тонирующий крем кушон с экстрактом Авокадо ZOZU Beautecret 20 г",
        "price": 235,
        "old_price": 1323,
        "reviews": 242,
        "seller": "Shop House",
        "brand": "Zozu"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа Eveline Liquid Control, тон 030 32 мл",
        "price": 902,
        "old_price": 0,
        "reviews": 360,
        "seller": "Подружка Москва (со склада СберМегаМаркет)",
        "brand": "Eveline",
        "position": 154
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа Eveline Liquid Control, тон 015 32 мл",
        "price": 902,
        "old_price": 0,
        "reviews": 347,
        "seller": "Подружка Москва (со склада СберМегаМаркет)",
        "brand": "Eveline",
        "position": 153
    },
    {
        "site": "megamarket.ru",
        "name": "Увлажняющий тональный крем с коллагеном Enough Collagen Moisture Foundation SPF 15, тон 21",
        "price": 399,
        "old_price": 3340,
        "reviews": 4,
        "seller": "EvMiLiO",
        "brand": "Enough",
        "position": 194
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная URM А1091415 120 см зеленая заснеженная",
        "price": 1153,
        "old_price": 0,
        "reviews": 114,
        "seller": "Любимая Королева",
        "brand": "URM",
        "position": 80
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Limoni All Stay Cover Cushion Refill 02 Medium 15 мл",
        "price": 1452,
        "old_price": 2033,
        "reviews": 19,
        "seller": "LIMONI Маркет",
        "brand": "LIMONI",
        "position": 206
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем SHIK вельветовый с увлажняющим эффектом 101 Cotton Velvet Cover Foundation",
        "price": 1390,
        "old_price": 0,
        "reviews": 19,
        "seller": "Официальный магазин SHIK",
        "brand": "SHIK",
        "position": 114
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Tarrington House Nadleen 180 см зеленая",
        "price": 2499,
        "old_price": 5499,
        "reviews": 237,
        "seller": "METRO - СберМаркет",
        "brand": "Tarrington House"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Elian, тон 0",
        "price": 629,
        "old_price": 1049,
        "reviews": 20,
        "seller": "Мегамаркет Москва",
        "brand": "Elian",
        "position": 242
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем для лица BelorDesign Nude Harmony, тон 201 Light beige",
        "price": 424,
        "old_price": 849,
        "reviews": 256,
        "seller": "Мегамаркет Москва Пушкино",
        "brand": "BelorDesign",
        "position": 60
    },
    {
        "site": "megamarket.ru",
        "name": "Антивозрастной кушон CU Clean-up Skinfit Cushion Pact Spf 50+/Pa+++ 23 тон",
        "price": 3690,
        "old_price": 0,
        "reviews": 0,
        "seller": "MaskShop",
        "brand": "CU SKIN"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Vichy Liftactiv Flexilift Teint тон 15, 30 мл",
        "price": 4748,
        "old_price": 0,
        "reviews": 105,
        "seller": "Медси Здоровье",
        "brand": "VICHY"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Vivienne Sabo Cabaret Premiere со сценическим эффектом, тон 03 фарфор",
        "price": 845,
        "old_price": 0,
        "reviews": 63,
        "seller": "РИВ ГОШ",
        "brand": "Vivienne Sabo"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный кушон Limoni All Stay Cover Cushion Puppy Princess (02 Medium)",
        "price": 5100,
        "old_price": 7140,
        "reviews": 11,
        "seller": "LIMONI Маркет",
        "brand": "LIMONI",
        "position": 205
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем для лица BelorDesign Nude Harmony, тон 204 Natural",
        "price": 427,
        "old_price": 0,
        "reviews": 250,
        "seller": "Диталир",
        "brand": "BelorDesign",
        "position": 132
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная URM А1091416 150 см зеленая заснеженная",
        "price": 1667,
        "old_price": 0,
        "reviews": 58,
        "seller": "Любимая Королева",
        "brand": "URM",
        "position": 50
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем матирующий LuxVisage тон 10 Слоновая кость",
        "price": 299,
        "old_price": 599,
        "reviews": 223,
        "seller": "Мегамаркет Москва",
        "brand": "Luxvisage"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Holy Land Varieties Perfect Cover Moisturizing Make-Up",
        "price": 950,
        "old_price": 0,
        "reviews": 120,
        "seller": "KRASON",
        "brand": "Holy Land",
        "position": 62
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Alliance Perfect тон N1 Слоновая кость",
        "price": 1167,
        "old_price": 0,
        "reviews": 2062,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Elian, тон 0",
        "price": 629,
        "old_price": 1049,
        "reviews": 23,
        "seller": "Мегамаркет Москва",
        "brand": "Elian",
        "position": 89
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа для лица Influence Beauty увлажняющая тон 02 25 мл",
        "price": 671,
        "old_price": 895,
        "reviews": 147,
        "seller": "Мегамаркет Москва",
        "brand": "Influence Beauty",
        "position": 107
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный кушон Limoni All Stay Cover Cushion Puppy Princess (02 Medium)",
        "price": 2346,
        "old_price": 3285,
        "reviews": 0,
        "seller": "LIMONI Маркет",
        "brand": "LIMONI",
        "position": 195
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Enough Collagen Moisture Foundation SPF15 13 100 мл",
        "price": 280,
        "old_price": 900,
        "reviews": 1255,
        "seller": "Космикс-Мегамаркет",
        "brand": "Enough"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Uriage Hyseac 3-Regul SPF 30 40 мл",
        "price": 1615,
        "old_price": 0,
        "reviews": 24,
        "seller": "pharmacosmetica",
        "brand": "Uriage"
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Dior Forever Skin Glow, 1,5 Warm, 30 мл",
        "price": 4949,
        "old_price": 5602,
        "reviews": 36,
        "seller": "Мегамаркет Москва",
        "brand": "DIOR",
        "position": 168
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Zozu с кушоном для лица Корея с экстрактом авокадо",
        "price": 255,
        "old_price": 1699,
        "reviews": 435,
        "seller": "AⱯ Market",
        "brand": "Zozu",
        "position": 201
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем ENOUGH ULTRA X10 Cover Up Collagen ЕНФ 31 SPF 50 №13 Светлый бежевый 100 г",
        "price": 439,
        "old_price": 1350,
        "reviews": 71,
        "seller": "Emtorg ",
        "brand": "Enough",
        "position": 188
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Tarrington House Каролина 1-20506 150 см зеленая",
        "price": 18990,
        "old_price": 19990,
        "reviews": 21,
        "seller": "ViaR",
        "brand": "Tarrington House",
        "position": 166
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Limoni All Stay Cover Cushion Sea Princess SPF 35 PA++ 02 15 мл",
        "price": 2346,
        "old_price": 3285,
        "reviews": 4,
        "seller": "LIMONI Маркет",
        "brand": "LIMONI"
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Yiwu Union Christmas Tree 180 см зеленая",
        "price": 2999,
        "old_price": 0,
        "reviews": 0,
        "seller": "Мегамаркет Москва Пушкино",
        "brand": "Yiwu Union",
        "position": 190
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Estee Lauder Double Wear Stay-In-Place SPF10, 2C3 Fresco, 30 мл",
        "price": 3347,
        "old_price": 5150,
        "reviews": 273,
        "seller": "Мегамаркет Спб",
        "brand": "Estee Lauder"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Alliance Perfect тон D4 Золотистый",
        "price": 1108,
        "old_price": 0,
        "reviews": 2029,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris",
        "position": 103
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Royal Christmas Kansas 953180 180 см зеленая",
        "price": 3550,
        "old_price": 0,
        "reviews": 16,
        "seller": "Vsempodarok",
        "brand": "Royal Christmas",
        "position": 191
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Tarrington House Nadleen 180 см зеленая",
        "price": 2499,
        "old_price": 5499,
        "reviews": 238,
        "seller": "METRO - СберМаркет",
        "brand": "Tarrington House"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный кушон Limoni All Stay Cover Cushion Puppy Princess (01 Light)",
        "price": 2040,
        "old_price": 2856,
        "reviews": 11,
        "seller": "LIMONI Маркет",
        "brand": "LIMONI"
    },
    {
        "site": "megamarket.ru",
        "name": "Сосна искусственная QVATRA QVPNTR QVPNTR-150 150 см зеленая заснеженная",
        "price": 1989,
        "old_price": 3284,
        "reviews": 514,
        "seller": "QVATRA",
        "brand": "QVATRA",
        "position": 211
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'OREAL PARiS Alliance Perfect Совершенное слияние 1.D/1.W 30 мл",
        "price": 1108,
        "old_price": 0,
        "reviews": 2022,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris"
    },
    {
        "site": "megamarket.ru",
        "name": "Крем тональный для лица VIVIENNE SABO Shake тон 04",
        "price": 538,
        "old_price": 769,
        "reviews": 11,
        "seller": "Мегамаркет Москва",
        "brand": "Vivienne Sabo"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Missha Velvet Finish Cushion SPF50+/Pa+++ 23 15 г",
        "price": 631,
        "old_price": 1190,
        "reviews": 128,
        "seller": "Emtorg ",
        "brand": "MISSHA",
        "position": 16
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа Eveline Liquid Control, тон 030 32 мл",
        "price": 902,
        "old_price": 0,
        "reviews": 347,
        "seller": "Подружка Москва (со склада СберМегаМаркет)",
        "brand": "Eveline",
        "position": 25
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Shiseido Synchro Skin Radiant Lifting Foundation SPF30, Alder, №230",
        "price": 4634,
        "old_price": 6179,
        "reviews": 56,
        "seller": "Мегамаркет Москва",
        "brand": "Shiseido",
        "position": 161
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Царь Елка Русская Красавица Премиум РКП-210 210 см зеленая",
        "price": 26990,
        "old_price": 0,
        "reviews": 19,
        "seller": "ООО Дом Сервис",
        "brand": "Царь Елка",
        "position": 72
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Сибирь 190 см зеленая",
        "price": 8060,
        "old_price": 9120,
        "reviews": 11,
        "seller": "ценам.нет ДСМ",
        "brand": "NoBrand"
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Clinique Even Better Makeup SPF 15, Neutral, №CN 52, 30 мл",
        "price": 3920,
        "old_price": 4900,
        "reviews": 38,
        "seller": "Мегамаркет Москва",
        "brand": "Clinique",
        "position": 170
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная 90 см зеленая",
        "price": 1161,
        "old_price": 1590,
        "reviews": 7,
        "seller": "ELshop",
        "brand": "NoBrand",
        "position": 258
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем для лица Vivienne Sabo Shake Foundation Matt 03",
        "price": 564,
        "old_price": 807,
        "reviews": 76,
        "seller": "Мегамаркет Москва",
        "brand": "Vivienne Sabo"
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Estee Lauder Double Wear Sheer Matte 3N1 Ivory Beige, 30 мл",
        "price": 3824,
        "old_price": 5099,
        "reviews": 65,
        "seller": "Мегамаркет Екб",
        "brand": "Estee Lauder",
        "position": 79
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа Eveline Liquid Control, тон 020 32 мл",
        "price": 902,
        "old_price": 0,
        "reviews": 349,
        "seller": "Подружка Москва (со склада СберМегаМаркет)",
        "brand": "Eveline",
        "position": 93
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный кушон Limoni All Stay Cover Cushion Puppy Princess (01 Light)",
        "price": 2040,
        "old_price": 2856,
        "reviews": 11,
        "seller": "LIMONI Маркет",
        "brand": "LIMONI",
        "position": 46
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Aravia для увлажнения и естественного сияния кожи Perfect Tone 01",
        "price": 1247,
        "old_price": 0,
        "reviews": 3,
        "seller": "pharmacosmetica",
        "brand": "Aravia Professional",
        "position": 24
    },
    {
        "site": "megamarket.ru",
        "name": "Основа-кушон тональная Holika Holika Holipop Blur Lasting Cushion бежевый, тон 03",
        "price": 990,
        "old_price": 1100,
        "reviews": 79,
        "seller": "Мегамаркет Москва",
        "brand": "Holika Holika",
        "position": 201
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Заснеженная флокированная 240 см зеленая заснеженная",
        "price": 17250,
        "old_price": 0,
        "reviews": 5,
        "seller": "AMI store",
        "brand": "NoBrand",
        "position": 156
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Yiwu Union Christmas Tree 180 см зеленая",
        "price": 2999,
        "old_price": 0,
        "reviews": 0,
        "seller": "Мегамаркет Москва Пушкино",
        "brand": "Yiwu Union",
        "position": 245
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Estee Lauder Double Wear Sheer Matte 3N1 Ivory Beige, 30 мл",
        "price": 3314,
        "old_price": 5099,
        "reviews": 60,
        "seller": "Мегамаркет Екб",
        "brand": "Estee Lauder"
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Clinique Superbalanced Makeup Ivory, №CN 28, 30 мл",
        "price": 3525,
        "old_price": 4700,
        "reviews": 19,
        "seller": "Мегамаркет Москва",
        "brand": "Clinique",
        "position": 251
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа-кушон Chupa Chups SPF 50+, PA++++, тон 3.0 Fair",
        "price": 1758,
        "old_price": 2197,
        "reviews": 25,
        "seller": "pharmacosmetica",
        "brand": "Chupa Chups"
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Stellary Matte effect 202 светлый фарфор 25 мл",
        "price": 659,
        "old_price": 0,
        "reviews": 27,
        "seller": "GradMart",
        "brand": "Stellary"
    },
    {
        "site": "megamarket.ru",
        "name": "Основа тональная Shiseido Synchro Skin Self-Refreshing Tint SPF20, Light Buna, №215, 30 мл",
        "price": 3854,
        "old_price": 5139,
        "reviews": 0,
        "seller": "Мегамаркет Москва",
        "brand": "Shiseido"
    },
    {
        "site": "megamarket.ru",
        "name": "Основа-кушон тональная Holika Holika Holipop Blur Lasting Cushion бежевый, тон 03",
        "price": 770,
        "old_price": 1100,
        "reviews": 84,
        "seller": "Мегамаркет Москва",
        "brand": "Holika Holika",
        "position": 131
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа Eveline Liquid Control, тон 030 32 мл",
        "price": 902,
        "old_price": 0,
        "reviews": 348,
        "seller": "Подружка Москва (со склада СберМегаМаркет)",
        "brand": "Eveline",
        "position": 158
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Actuel 210 см зеленая заснеженная",
        "price": 18350,
        "old_price": 21320,
        "reviews": 3,
        "seller": "AMI store",
        "brand": "Actuel",
        "position": 173
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Tarrington House Nadleen 210 см зеленая",
        "price": 10400,
        "old_price": 11400,
        "reviews": 255,
        "seller": "ViaR",
        "brand": "Tarrington House"
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Actuel Канадская 180 см зеленая",
        "price": 4490,
        "old_price": 7980,
        "reviews": 31,
        "seller": "AMI store",
        "brand": "Actuel",
        "position": 70
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Holy Land Perfect Cover No.1 30 мл",
        "price": 949,
        "old_price": 0,
        "reviews": 118,
        "seller": "pharmacosmetica",
        "brand": "Holy Land",
        "position": 126
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Enough Collagen Whitening Moisture Foundation SPF15 3 in 1 13 100 мл",
        "price": 342,
        "old_price": 810,
        "reviews": 330,
        "seller": "Космикс-Мегамаркет",
        "brand": "Enough",
        "position": 68
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Max Factor Facefinity Lasting Performance 35 мл",
        "price": 580,
        "old_price": 0,
        "reviews": 0,
        "seller": "Beecolor",
        "brand": "Max Factor",
        "position": 259
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Max Christmas Лесная ЕЛЕС 12 120 см зеленая",
        "price": 2079,
        "old_price": 3699,
        "reviews": 0,
        "seller": "Мегамаркет Москва Томилино",
        "brand": "Max Christmas",
        "position": 211
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный кушон увлажняющий SPF 50+, PA+++, тон 23 YU,R Moist Layer Cushion 25 гр",
        "price": 3390,
        "old_price": 0,
        "reviews": 24,
        "seller": "ООО «Прокосметика»",
        "brand": "YU.R",
        "position": 152
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем L'Oreal Alliance Perfect тон N2 Ваниль",
        "price": 1108,
        "old_price": 0,
        "reviews": 2029,
        "seller": "РИВ ГОШ",
        "brand": "L'Oreal Paris",
        "position": 180
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Luxvisage Skin Evolution soft matte blur effect 35",
        "price": 257,
        "old_price": 393,
        "reviews": 318,
        "seller": "Мегамаркет Спб",
        "brand": "Luxvisage"
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Заснеженная флокированная 240 см зеленая заснеженная",
        "price": 14980,
        "old_price": 17120,
        "reviews": 3,
        "seller": "AMI store",
        "brand": "NoBrand",
        "position": 48
    },
    {
        "site": "megamarket.ru",
        "name": "Тональный крем Eveline Cosmetics Professional Art Make-Up 3в1 т.Натуральный",
        "price": 354,
        "old_price": 506,
        "reviews": 185,
        "seller": "Подружка Москва (со склада СберМегаМаркет)",
        "brand": "Eveline",
        "position": 62
    },
    {
        "site": "megamarket.ru",
        "name": "Ель искусственная Yiwu Union Christmas Tree 150 см зеленая",
        "price": 1699,
        "old_price": 0,
        "reviews": 0,
        "seller": "Мегамаркет Москва Пушкино",
        "brand": "Yiwu Union",
        "position": 85
    },
    {
        "site": "megamarket.ru",
        "name": "Тональная основа для лица Influence Beauty матирующая тон 03 25 мл",
        "price": 626,
        "old_price": 895,
        "reviews": 251,
        "seller": "Мегамаркет Москва",
        "brand": "Influence Beauty",
        "position": 64
    },
    {
        "site": "megamarket.ru",
        "name": "Матирующий тональный крем для лица Eva Mosaic HD Comfort 1 Фарфор",
        "price": 573,
        "old_price": 0,
        "reviews": 45,
        "seller": "РИВ ГОШ",
        "brand": "EVA MOSAIC",
        "position": 77
    },
    {
        "site": "market.yandex.ru",
        "name": "Колонка порт. Digma S-10 черный",
        "price": 450,
        "old_price": 549,
        "reviews": 55,
        "seller": "Krawt",
        "seller_full": "ИП Василева Юлия Владимировна",
        "brand": "DIGMA",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "Yokohama Ice Guard IG55 195/65/15 95T",
        "price": 6050,
        "old_price": 9106,
        "reviews": 935,
        "seller": "КрасШина",
        "seller_full": "ИП Скотченко Василий Геннадьевич",
        "brand": "Yokohama",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "Loreal Professionnel Infinium Pure Strong - Лореаль Инфиниум Пюр Лак для волос сильной фиксации, 300 мл -",
        "price": 874,
        "old_price": 1049,
        "reviews": 57,
        "seller": "COSSALE",
        "seller_full": "ИП Изотов Алексей Александрович",
        "brand": "L'Oreal Professionnel",
        "position": 7
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон HONOR 90 Lite 8/256 ГБ Global для РФ, 2 nano SIM, синий",
        "price": 23490,
        "old_price": 32900,
        "reviews": 189,
        "seller": "ARIGATO",
        "seller_full": "ИП Смирнов Владимир Владимирович",
        "brand": "HONOR",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "BIOLAGE VOLUMEBLOOM шампунь для придания объёма тонким волосам 1000 мл",
        "price": 2079,
        "old_price": 2772,
        "reviews": 36,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "Biolage",
        "position": 17
    },
    {
        "site": "market.yandex.ru",
        "name": "Крем-маска Matrix PRO SOLUTIONIST TOTAL TREAT для экспресс-восстановления волос, 500 мл",
        "price": 1635,
        "reviews": 174,
        "seller": "STECK",
        "seller_full": "ООО ООО «Компания «Аквакосметика»",
        "brand": "Matrix",
        "position": 20
    },
    {
        "site": "market.yandex.ru",
        "name": "Realme GT Neo 3 150W 12/256 ГБ Nitro Blue (синий) Global Version",
        "price": 33480,
        "old_price": 36830,
        "reviews": 40,
        "seller": "Sibdroid",
        "seller_full": "ИП Дементьев Михаил Васильевич",
        "brand": "realme",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь MATRIX нормализующий Biolage Cleanreset Normalizing для кожи головы, 250 мл",
        "price": 837,
        "old_price": 1117,
        "reviews": 30,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "Biolage",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь-крем LOREAL PROFESSIONNEL Chroma Creme с фиолетовым пигментом для нейтрализации желтизны очень светлых волос, 300 мл",
        "price": 1392,
        "old_price": 1832,
        "reviews": 30,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "L'Oreal Professionnel",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "Синий шампунь для холодного блонда Matrix Brass Off 1л",
        "price": 1299,
        "old_price": 1899,
        "reviews": 238,
        "seller": "Бытовая химия-КОСМЕТИК",
        "seller_full": "ИП Болтаева Хосиятхон Абдуразаковна",
        "brand": "Matrix",
        "position": 14
    },
    {
        "site": "market.yandex.ru",
        "name": "Кеды Converse Chuck 70 High-Top, Черный, 42 EU",
        "price": 11832,
        "old_price": 14190,
        "reviews": 26,
        "seller": "Studio Slow",
        "seller_full": "ИП Янчевский Владимир Дмитриевич",
        "brand": "Converse",
        "position": 15
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix кондиционер Total Results Dark Envy для темных волос, 300 мл",
        "price": 830,
        "reviews": 32,
        "seller": "HAIR COSMETICA",
        "seller_full": "ИП Халил Фади Ахмадович",
        "brand": "Matrix",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон HUAWEI Mate 40 Pro 8/256 ГБ, Dual nano SIM, мистический серебристый",
        "price": 50990,
        "old_price": 56990,
        "reviews": 87,
        "seller": "СМ Сотовый мир",
        "seller_full": "ИП Зайкин Александр Викторович",
        "brand": "HUAWEI",
        "position": 12
    },
    {
        "site": "market.yandex.ru",
        "name": "Колонка Borofone BP11 Popular Grey 6941991101922",
        "price": 1420,
        "old_price": 1573,
        "reviews": 3,
        "seller": "ПлеерРу",
        "seller_full": "ИП Павлова Ксения Дмитриевна",
        "brand": "Borofone",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "175/65 R14 Yokohama IG55 ш 86Т (зима) а/шина",
        "price": 4926,
        "old_price": 5214,
        "reviews": 935,
        "seller": "Шина плюс",
        "seller_full": "ИП Брыль С.А.",
        "brand": "Yokohama",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "MATRIX Маска для нейтрализации красных тонов 200 мл",
        "price": 1082,
        "old_price": 1584,
        "reviews": 42,
        "seller": "Мир Лечебной Косметики",
        "seller_full": "ООО «Мир Лечебной Косметики»",
        "brand": "Matrix",
        "position": 9
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь L'Oreal Professionnel Serie Expert Scalp Advanced для склонных к жирности волос, 500 мл",
        "price": 1599,
        "old_price": 2599,
        "reviews": 41,
        "seller": "Бытовая химия-КОСМЕТИК",
        "seller_full": "ИП Болтаева Хосиятхон Абдуразаковна",
        "brand": "L'Oreal Professionnel",
        "position": 13
    },
    {
        "site": "market.yandex.ru",
        "name": "Зимние шины Nokian Tyres Nordman 7 SUV 225/65 R17 106T XL шип",
        "price": 8953,
        "old_price": 9425,
        "reviews": 487,
        "seller": "ИГРЕС",
        "seller_full": "ООО Игрес-Вилс",
        "brand": "Nokian Tyres",
        "position": 17
    },
    {
        "site": "market.yandex.ru",
        "name": "Футболка Nike, Цвет: черный, Размер: 2XL",
        "price": 1949,
        "old_price": 2299,
        "reviews": 31,
        "seller": "Яндекс Маркет",
        "seller_full": "ООО Маркет.Трейд",
        "brand": "NIKE",
        "position": 20
    },
    {
        "site": "market.yandex.ru",
        "name": "Укрепляющий кондиционер для осветленных волос Matrix Total Results Unbreak My Blonde, 1000 мл",
        "price": 1804,
        "old_price": 2165,
        "reviews": 22,
        "seller": "La Bellezza",
        "seller_full": "ООО КАПРИ",
        "brand": "Matrix",
        "position": 13
    },
    {
        "site": "market.yandex.ru",
        "name": "32\" Телевизор Xiaomi Mi TV A2, HD, черный, смарт ТВ, Android",
        "price": 21170,
        "old_price": 23520,
        "reviews": 1436,
        "seller": "Leki",
        "seller_full": "ИП Велибеков Рустам Русланович",
        "brand": "Xiaomi",
        "position": 16
    },
    {
        "site": "market.yandex.ru",
        "name": "Микрофонный комплект JBH BM-800 | черный с золотистым",
        "price": 1969,
        "old_price": 2190,
        "reviews": 41,
        "seller": "MАGstоre",
        "seller_full": "ИП Доронин Максим Вячеславович",
        "brand": "JBH",
        "position": 15
    },
    {
        "site": "market.yandex.ru",
        "name": "Телевизор LG OLED55A1RLA 2021 OLED, HDR, черный",
        "price": 129800,
        "reviews": 63,
        "seller": "Мир_БТ",
        "seller_full": "ИП Родионов Дмитрий Петрович",
        "brand": "LG",
        "position": 15
    },
    {
        "site": "market.yandex.ru",
        "name": "МФУ лазерное Pantum M6507W серый",
        "price": 16189,
        "old_price": 25000,
        "reviews": 891,
        "seller": "kotofoto",
        "seller_full": "ИП Жилин Максим Сергеевич",
        "brand": "Pantum",
        "position": 3
    },
    {
        "site": "market.yandex.ru",
        "name": "Сигнатурный радар-детектор Roadgid Detect с фильтром помех и уникальной системой оповещений",
        "price": 8515,
        "old_price": 14290,
        "reviews": 289,
        "seller": "ООО \"Р-Дистрибуция\"",
        "seller_full": "ООО ООО \"Р-Дистрибуция\"",
        "brand": "Roadgid",
        "position": 13
    },
    {
        "site": "market.yandex.ru",
        "name": "Профессиональный кондиционер MATRIX Instacure для восстановления волос с жидким протеином, 1000 мл",
        "price": 1641,
        "old_price": 2405,
        "reviews": 27,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "Matrix",
        "position": 6
    },
    {
        "site": "market.yandex.ru",
        "name": "Телевизор BBK 43\" 43LEX-7389/FTS2C",
        "price": 22828,
        "reviews": 0,
        "seller": "kotofoto",
        "seller_full": "ИП Бажанов Алексей Викторович",
        "brand": "BBK",
        "position": 5
    },
    {
        "site": "market.yandex.ru",
        "name": "Снуд-хомут DЕGS, черный",
        "price": 530,
        "old_price": 800,
        "reviews": 33,
        "seller": "ДЕГС",
        "seller_full": "ООО ДЕГС",
        "brand": "AiHAM",
        "position": 20
    },
    {
        "site": "market.yandex.ru",
        "name": "Телевизор Samsung 43\" UE43AU7002UXRU Ultra HD 4K SmartTV",
        "price": 32500,
        "reviews": 88,
        "seller": "Орион",
        "seller_full": "ООО ПРАЙМ",
        "brand": "Samsung",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "Колонка Digma D-PS1520 Black",
        "price": 1452,
        "old_price": 1665,
        "reviews": 1,
        "seller": "ПлеерРу",
        "seller_full": "ООО ВОСХОД",
        "brand": "DIGMA",
        "position": 14
    },
    {
        "site": "market.yandex.ru",
        "name": "Беспроводные наушники Apple AirPods Max Space Gray",
        "price": 61234,
        "old_price": 69990,
        "reviews": 0,
        "seller": "QUKE",
        "seller_full": "ИП Обыденнова Яна Валерьевна",
        "brand": "Apple",
        "position": 7
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь MATRIX Соу Сильвер 1 л",
        "price": 1350,
        "old_price": 1978,
        "reviews": 112,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "Matrix",
        "position": 20
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix Total Results Mega Sleek Iron Smoother Термозащитный спрей для гладкости волос, 250 мл",
        "price": 1514,
        "old_price": 2119,
        "reviews": 38,
        "seller": "Maknails",
        "seller_full": "ИП Наджарян Армен Аветисович",
        "brand": "Matrix",
        "position": 16
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix Total Results Curl Can Dream Маска для интенсивного увлажнения кудрявых волос 250 мл",
        "price": 1016,
        "reviews": 42,
        "seller": "BeautyPolis",
        "seller_full": "ИП Лошаков Олег Игоревич",
        "brand": "Matrix",
        "position": 16
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон Xiaomi Redmi Note 12 Pro 5G 8/128 ГБ Global, Dual nano SIM, черный",
        "price": 21990,
        "old_price": 29990,
        "reviews": 273,
        "seller": "Device M.",
        "seller_full": "ИП Казейкин Антон Алексеевич",
        "brand": "Xiaomi",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "Футболка компрессионная с длинным рукавом Jögel Camp Performdry Top Ls, белый/черный размер XXL",
        "price": 2099,
        "reviews": 21,
        "seller": "SPORTAVA",
        "seller_full": "ИП Осипов Андрей Александрович",
        "brand": "Jogel",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь без сульфатов MATRIX TOTAL RESULTS анбрейк МАЙ блонд 1 л",
        "price": 1440,
        "old_price": 1978,
        "reviews": 120,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "Matrix",
        "position": 9
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix Biolage Hydrasource Shampoo - Матрикс Биолаж Гидрасурс Шампунь увлажняющий, 250 мл -",
        "price": 829,
        "old_price": 1078,
        "reviews": 57,
        "seller": "COSSALE",
        "seller_full": "ИП Изотов Алексей Александрович",
        "brand": "Biolage",
        "position": 20
    },
    {
        "site": "market.yandex.ru",
        "name": "Микроволновая печь LG MS-2042DB",
        "price": 11399,
        "reviews": 591,
        "seller": "Выгодная Покупка",
        "seller_full": "ИП Ламакин Сергей Николаевич",
        "brand": "LG",
        "position": 16
    },
    {
        "site": "market.yandex.ru",
        "name": "Loreal Professionnel Absolut Repair - Лореаль Абсолют Репейр Маска с золотой текстурой для очень поврежденных волос, 250 мл NEW -",
        "price": 1644,
        "old_price": 1973,
        "reviews": 131,
        "seller": "COSSALE",
        "seller_full": "ИП Изотов Алексей Александрович",
        "brand": "L'Oreal Professionnel",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "Спрей MATRIX Instacure с жидким протеином и провитамином B5 против ломкости и пористости волос, 200 мл",
        "price": 1251,
        "old_price": 1564,
        "reviews": 50,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "Matrix",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "Кондиционер Matrix Biolage Hydrasource для сухих волос, 200 мл",
        "price": 907,
        "old_price": 1088,
        "reviews": 58,
        "seller": "La Bellezza",
        "seller_full": "ООО КАПРИ",
        "brand": "Biolage",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "Футболка Nike, Цвет: белый, Размер: L",
        "price": 1949,
        "old_price": 2299,
        "reviews": 31,
        "seller": "Яндекс Маркет",
        "seller_full": "ООО Маркет.Трейд",
        "brand": "NIKE",
        "position": 12
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel Expert Silver - Шампунь для нейтрализации желтизны для седых и светлых волос 300 мл",
        "price": 1230,
        "old_price": 2733,
        "reviews": 99,
        "seller": "COSMETICON",
        "seller_full": "ИП Денисюк Иван Олегович",
        "brand": "L'Oreal Professionnel",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "МФУ струйный Canon Pixma MG2540S (0727C007) A4 USB черный",
        "price": 7128,
        "old_price": 7999,
        "reviews": 982,
        "seller": "ПОЗИТРОНИКА",
        "seller_full": "ООО Общество с ограниченной ответственностью  «Рентсофт»",
        "brand": "Canon",
        "position": 20
    },
    {
        "site": "market.yandex.ru",
        "name": "Радар-детектор Artway RD-516",
        "price": 3290,
        "reviews": 599,
        "seller": "Artway-electronics",
        "seller_full": "ООО ПРЕСТИЖ",
        "brand": "Artway",
        "position": 3
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix шампунь Total Results Color Obsessed antioxidants 300 мл",
        "price": 562,
        "old_price": 1300,
        "reviews": 320,
        "seller": "bytbox",
        "seller_full": "ИП Дзортова Хади Ибрагимовна",
        "brand": "Matrix",
        "position": 2
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix Biolage Hydrasource Shampoo - Матрикс Биолаж Гидрасурс Шампунь увлажняющий, 1000 мл -",
        "price": 2098,
        "old_price": 2727,
        "reviews": 57,
        "seller": "COSSALE",
        "seller_full": "ИП Изотов Алексей Александрович",
        "brand": "Biolage",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "Радар-детектор SilverStone F1 FUJI",
        "price": 4390,
        "old_price": 5390,
        "reviews": 20,
        "seller": "SilverStone F1",
        "seller_full": "ИП Мананников Александр Алексеевич",
        "brand": "SilverStone F1",
        "position": 15
    },
    {
        "site": "market.yandex.ru",
        "name": "TOTAL RESULTS BRASS OFF COLOR OBSESSED холодный блонд Кондиционер с витаминизированным маслом для питания волос 300мл",
        "price": 717,
        "old_price": 1050,
        "reviews": 73,
        "seller": "Мир Лечебной Косметики",
        "seller_full": "ООО «Мир Лечебной Косметики»",
        "brand": "Matrix",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "Микроволновая печь соло Hi M020W02 глубина 24.5 см",
        "price": 4599,
        "old_price": 4999,
        "reviews": 133,
        "seller": "ЭЛЬДОРАДО",
        "seller_full": "ООО МВМ",
        "brand": "Hi",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "L′Oreal Professionnel Curl Expression Cream (Крем-гель с термозащитой для завитков кудрявых волос), 250 мл",
        "price": 1450,
        "reviews": 25,
        "seller": "HAIR COSMETICA",
        "seller_full": "ИП Халил Фади Ахмадович",
        "brand": "L'Oreal Professionnel",
        "position": 13
    },
    {
        "site": "market.yandex.ru",
        "name": "Профессиональная маска Liss Unlimited для непослушных волос, 250 мл",
        "price": 2050,
        "reviews": 22,
        "seller": "VinokurovShop",
        "seller_full": "ООО ДИВИ-СТАИЛ",
        "brand": "L'Oreal Professionnel",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "32\" Телевизор Samsung UE32T4510AU 2020 LED, HDR RU, белый",
        "price": 39550,
        "reviews": 213,
        "seller": "MskTV",
        "seller_full": "ИП Авершин Виктор Витальевич",
        "brand": "Samsung",
        "position": 8
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel Metal Detox Масло 50 мл",
        "price": 2035,
        "old_price": 2973,
        "reviews": 12,
        "seller": "Maknails",
        "seller_full": "ИП Наджарян Армен Аветисович",
        "brand": "L'Oreal Professionnel",
        "position": 2
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон Tecno Camon 20 Premier 5G 8/512Gb черный",
        "price": 32999,
        "old_price": 41290,
        "reviews": 4,
        "seller": "RBT.ru",
        "seller_full": "ООО КОМПАНИЯ РБТ",
        "brand": "TECNO",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix Total Results Dark Envy Shampoo Шампунь для блеска темных волос 300 мл",
        "price": 753,
        "old_price": 985,
        "reviews": 48,
        "seller": "Maknails",
        "seller_full": "ИП Наджарян Армен Аветисович",
        "brand": "Matrix",
        "position": 16
    },
    {
        "site": "market.yandex.ru",
        "name": "Спрей L'Oreal Professionnel Tecni.Art Fix Anti-Frizz сильной фиксации, 400 мл",
        "price": 1326,
        "old_price": 1591,
        "reviews": 51,
        "seller": "La Bellezza",
        "seller_full": "ООО КАПРИ",
        "brand": "L'Oreal Professionnel",
        "position": 13
    },
    {
        "site": "market.yandex.ru",
        "name": "Микрофон проводной Thomson M135D 3м черный",
        "price": 1560,
        "reviews": 14,
        "seller": "ПОЗИТРОНИКА",
        "seller_full": "ООО Общество с ограниченной ответственностью  «Рентсофт»",
        "brand": "Thomson",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel Metal Detox Shampoo - Шампунь для восстановления окрашенных волос 300мл",
        "price": 2035,
        "old_price": 2973,
        "reviews": 76,
        "seller": "Maknails",
        "seller_full": "ИП Наджарян Армен Аветисович",
        "brand": "L'Oreal Professionnel",
        "position": 9
    },
    {
        "site": "market.yandex.ru",
        "name": "Телевизор LG 32LQ63506LA",
        "price": 33990,
        "reviews": 20,
        "seller": "OnlyBT",
        "seller_full": "ООО ЭЛЕКТРО МАРКЕТ",
        "brand": "LG",
        "position": 17
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон Infinix NOTE 30i 8/128 ГБ Global для РФ, Dual nano SIM, Obsidian Black",
        "price": 11497,
        "old_price": 16990,
        "reviews": 1433,
        "seller": "Green Zone",
        "seller_full": "ИП Панченко Иван Дмитриевич",
        "brand": "Infinix",
        "position": 1
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон Xiaomi Mi Note 3 (6GB/64GB) Black (Черный)",
        "price": 17300,
        "old_price": 21625,
        "reviews": 157,
        "seller": "Фотосклад",
        "seller_full": "ООО МАРКЕТПЛЕЙС",
        "brand": "Xiaomi",
        "position": 14
    },
    {
        "site": "market.yandex.ru",
        "name": "Авто шина Cordiant Snow Cross 2 205/55R16 94T",
        "price": 5888,
        "old_price": 6595,
        "reviews": 1235,
        "seller": "Шинный Ангар",
        "seller_full": "ООО ТАЕРЛЭНД",
        "brand": "Cordiant",
        "position": 14
    },
    {
        "site": "market.yandex.ru",
        "name": "Футболка Jordan Sport Dri-FIT Men's Short-Sleeve Top black размер L",
        "price": 4062,
        "old_price": 5500,
        "reviews": 0,
        "seller": "Ananas Sport",
        "seller_full": "ИП Ананских Елена Алексеевна",
        "brand": "NIKE",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "Автомобильные шины Nordman 7 205/60 R16 96T",
        "price": 6940,
        "reviews": 1324,
        "seller": "Автоэксперт",
        "seller_full": "ООО АВТОЭКСПЕРТ",
        "brand": "Nokian Tyres",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "Сыворотка LOREAL PROFESSIONNEL Разглаживающая Liss Unlimited для сияния и защиты от влажности, 125 мл",
        "price": 1392,
        "old_price": 1832,
        "reviews": 70,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "L'Oreal Professionnel",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон Infinix Note 30i 8 128Gb Variable Gold",
        "price": 11855,
        "old_price": 19990,
        "reviews": 1,
        "seller": "QUKE",
        "seller_full": "ИП Обыденнова Яна Валерьевна",
        "brand": "Infinix",
        "position": 5
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix шампунь для волос Total results So Silver для нейтрализации желтизны, 300 мл",
        "price": 683,
        "reviews": 112,
        "seller": "HAIR COSMETICA",
        "seller_full": "ИП Халил Фади Ахмадович",
        "brand": "Matrix",
        "position": 14
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel, Крем Tecni.Art Bouncy&Tender для вьющихся волос, 150 мл",
        "price": 882,
        "old_price": 1350,
        "reviews": 89,
        "seller": "Яндекс Маркет",
        "seller_full": "ООО Маркет.Трейд",
        "brand": "L'Oreal Professionnel",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "МФУ струйное Canon PIXMA TS5140, цветн., A4, черный",
        "price": 10579,
        "old_price": 14999,
        "reviews": 17,
        "seller": "АКСА",
        "seller_full": "ООО АКСА-ТРЕЙД",
        "brand": "Canon",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь увлажняющий L'Oreal Professionnel Serie Expert Curl Expression для всех типов кудрявых волос, 300 мл",
        "price": 1450,
        "reviews": 15,
        "seller": "HAIR COSMETICA",
        "seller_full": "ИП Халил Фади Ахмадович",
        "brand": "L'Oreal Professionnel",
        "position": 17
    },
    {
        "site": "market.yandex.ru",
        "name": "Cпрей сухой Tecni.Art Savage Panache Pure сильной фиксации с пудровой текстурой, 250 мл",
        "price": 1200,
        "reviews": 38,
        "seller": "VinokurovShop",
        "seller_full": "ООО ДИВИ-СТАИЛ",
        "brand": "L'Oreal Professionnel",
        "position": 1
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь LOREAL PROFESSIONNEL Scalp Advanced регулирующий баланс чувствительной кожи головы, 300 мл",
        "price": 1392,
        "old_price": 1832,
        "reviews": 31,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "L'Oreal Professionnel",
        "position": 19
    },
    {
        "site": "market.yandex.ru",
        "name": "Авто шина Cordiant Snow Cross 2 185/65R14 90T",
        "price": 4827,
        "old_price": 5406,
        "reviews": 1235,
        "seller": "Шинный Ангар",
        "seller_full": "ООО ТАЕРЛЭНД",
        "brand": "Cordiant",
        "position": 1
    },
    {
        "site": "market.yandex.ru",
        "name": "Matrix Keep Me Vivid - Матрикс Кип Ми Вивид Кондиционер для сохранения ярких и легко вымывающихся оттенков, 1000 мл -",
        "price": 1648,
        "old_price": 2142,
        "reviews": 142,
        "seller": "COSSALE",
        "seller_full": "ИП Изотов Алексей Александрович",
        "brand": "Matrix",
        "position": 13
    },
    {
        "site": "market.yandex.ru",
        "name": "Маска для волос L'Oreal Professionnel Mythic Oil Питательная маска для нормальных и тонких волос 200 мл",
        "price": 2079,
        "reviews": 19,
        "seller": "BeautyPolis",
        "seller_full": "ИП Лошаков Олег Игоревич",
        "brand": "L'Oreal Professionnel",
        "position": 5
    },
    {
        "site": "market.yandex.ru",
        "name": "Фитнес-браслет Xiaomi Smart Band 8 EU Gold",
        "price": 3499,
        "old_price": 4490,
        "reviews": 0,
        "seller": "QUKE",
        "seller_full": "ИП Обыденнова Яна Валерьевна",
        "brand": "Xiaomi",
        "position": 15
    },
    {
        "site": "market.yandex.ru",
        "name": "Умные часы Apple Watch SE 44mm Cellular Aluminum Case with Sport Band (Цвет: Space Gray/Midnight)",
        "price": 26270,
        "reviews": 7,
        "seller": "Lite Mobile",
        "seller_full": "ИП Коваленко Владислав Геннадьевич",
        "brand": "Apple",
        "position": 12
    },
    {
        "site": "market.yandex.ru",
        "name": "Радар-Детектор SilverStone F1 Sochi Z",
        "price": 10400,
        "reviews": 165,
        "seller": "NiKAS66",
        "seller_full": "ИП Горонович Николай Андреевич",
        "brand": "SilverStone F1",
        "position": 17
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel Inforcer маска укрепляющая против ломкости волос, 250 мл, банка",
        "price": 1350,
        "reviews": 60,
        "seller": "HAIR COSMETICA",
        "seller_full": "ИП Халил Фади Ахмадович",
        "brand": "L'Oreal Professionnel",
        "position": 20
    },
    {
        "site": "market.yandex.ru",
        "name": "Гель-крем LOREAL PROFESSIONNEL Liss Control для контроля гладкости, 150 мл",
        "price": 1105,
        "old_price": 1455,
        "reviews": 37,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "L'Oreal Professionnel",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel Tecni. Art Full Volume Extra force 5 Мусс для придания экстра-объема, 250 мл",
        "price": 1251,
        "old_price": 1792,
        "reviews": 36,
        "seller": "Maknails",
        "seller_full": "ИП Наджарян Армен Аветисович",
        "brand": "L'Oreal Professionnel",
        "position": 7
    },
    {
        "site": "market.yandex.ru",
        "name": "Кеды Converse Chuck 70 High-Top, Черный, 42 EU",
        "price": 11832,
        "old_price": 14190,
        "reviews": 26,
        "seller": "Studio Slow",
        "seller_full": "ИП Янчевский Владимир Дмитриевич",
        "brand": "Converse",
        "position": 1
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel шампунь Expert Pro Longer, 300 мл",
        "price": 1125,
        "old_price": 1544,
        "reviews": 243,
        "seller": "Edema ruh",
        "seller_full": "ИП ДАНИЛЬЯНЦ ГЕОРГИЙ АЛЕКСЕЕВИЧ",
        "brand": "L'Oreal Professionnel",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "LED телевизор BBK 32LEM-1002/TS2C черный",
        "price": 11903,
        "old_price": 13340,
        "reviews": 1,
        "seller": "Торговый дом ББК",
        "seller_full": "АО  «Торговый дом ББК»",
        "brand": "BBK",
        "position": 8
    },
    {
        "site": "market.yandex.ru",
        "name": "BIOLAGE COLORLAST Шампунь для окрашенных волос 250мл",
        "price": 762,
        "old_price": 1117,
        "reviews": 50,
        "seller": "КОСМЕДЭЛЬ",
        "seller_full": "ООО СТАЛКЕР-КОНСАЛТИНГ",
        "brand": "Biolage",
        "position": 13
    },
    {
        "site": "market.yandex.ru",
        "name": "Biolage Масло питающее для всех типов волос Exquisite Oil, 100 мл, бутылка",
        "price": 1655,
        "old_price": 2199,
        "reviews": 99,
        "seller": "Бытовая химия-КОСМЕТИК",
        "seller_full": "ИП Болтаева Хосиятхон Абдуразаковна",
        "brand": "Biolage",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "L'Oreal Professionnel Serie Expert Curl Expression Ухаживающий спрей-дымка для рефреша 190 мл",
        "price": 1350,
        "reviews": 18,
        "seller": "HAIR COSMETICA",
        "seller_full": "ИП Халил Фади Ахмадович",
        "brand": "L'Oreal Professionnel",
        "position": 5
    },
    {
        "site": "market.yandex.ru",
        "name": "Авто шина Cordiant Winter Drive 2 195/65R15 95T",
        "price": 4721,
        "old_price": 5288,
        "reviews": 375,
        "seller": "Шинный Ангар",
        "seller_full": "ООО ТАЕРЛЭНД",
        "brand": "Cordiant",
        "position": 10
    },
    {
        "site": "market.yandex.ru",
        "name": "Смартфон Clevercel HUAWEI MATE 50 (хорошее состояние), 8/256GB, черный, Snapdragon 8+ Gen 1 4G (4 nm), Global",
        "price": 34900,
        "old_price": 38900,
        "reviews": 242,
        "seller": "Clevercel",
        "seller_full": "ООО БЕЛМОНТ ТРЕЙДИНГ ВОСТОК",
        "brand": "HUAWEI",
        "position": 3
    },
    {
        "site": "market.yandex.ru",
        "name": "Крем для волос L`OREAL PROFESSIONNEL Siren Waves, 150 мл",
        "price": 1200,
        "reviews": 18,
        "seller": "ОНЛАЙНТРЕЙД.РУ",
        "seller_full": "ООО Онлайн трейд",
        "brand": "L'Oreal Professionnel",
        "position": 9
    },
    {
        "site": "market.yandex.ru",
        "name": "Шампунь MATRIX Keep me vivid для сохранения цвета ярких оттенков300 мл",
        "price": 692,
        "old_price": 880,
        "reviews": 307,
        "seller": "Мир Лечебной Косметики",
        "seller_full": "ООО «Мир Лечебной Косметики»",
        "brand": "Matrix",
        "position": 2
    },
    {
        "site": "market.yandex.ru",
        "name": "Шины летние VIATTI Strada Asimmetriсo V-130 195/65 R15 91 H",
        "price": 3320,
        "reviews": 2095,
        "seller": "КОЛЕСО",
        "seller_full": "ООО САМ-МБ",
        "brand": "Viatti",
        "position": 2
    },
    {
        "site": "market.yandex.ru",
        "name": "Телевизор BBK 32LEM-1035/TS2C черный",
        "price": 10647,
        "reviews": 0,
        "seller": "Интернет-магазин QBO",
        "seller_full": "ООО БРЕНТА",
        "brand": "BBK",
        "position": 11
    },
    {
        "site": "market.yandex.ru",
        "name": "Принтер лазерный HP LaserJet M111a (А4, 600dpi, 18ppm, 16Mb, USB) (7MD67A)",
        "price": 11258,
        "reviews": 46,
        "seller": "dew-shop",
        "seller_full": "ИП Тишин Игорь Владимирович",
        "brand": "HP",
        "position": 17
    },
    {
        "site": "market.yandex.ru",
        "name": "Крем-уход MATRIX Несмываемый Unbreak my blond, 150 мл",
        "price": 1251,
        "old_price": 1564,
        "reviews": 29,
        "seller": "Мир Лечебной Косметики",
        "seller_full": "ООО «Мир Лечебной Косметики»",
        "brand": "Matrix",
        "position": 9
    },
    {
        "site": "market.yandex.ru",
        "name": "Loreal Professionnel Hair Touch Up Консилер для волос коричневый 75 мл 1 шт",
        "price": 972,
        "reviews": 57,
        "seller": "ЕАПТЕКА",
        "seller_full": "ООО ЕАПТЕКА",
        "brand": "L'Oreal Professionnel",
        "position": 18
    },
    {
        "site": "market.yandex.ru",
        "name": "Радар-детектор Fujida Magna",
        "price": 8820,
        "old_price": 18163,
        "reviews": 10,
        "seller": "Fujida Official Store",
        "seller_full": "ООО ФУДЖИДА РУС",
        "brand": "Fujida",
        "position": 6
    },
    {
        "site": "market.yandex.ru",
        "name": "Кондиционер для волос Matrix Biolage FullDensity Кондиционер для тонких волос 200 мл",
        "price": 1012,
        "reviews": 21,
        "seller": "BeautyPolis",
        "seller_full": "ИП Лошаков Олег Игоревич",
        "brand": "Biolage",
        "position": 4
    }
];
export {MOCK_DATA};
