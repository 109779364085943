import cn from "classnames";
import React from 'react';
const EmailContent = ({ email, setEmail, isErrorAuth, setIsErrorAuth }) => {
    const handleChange = (e) => {
        if (isErrorAuth) {
            setIsErrorAuth(false)
        }
        setEmail(e.target.value)
    }
    return (
        <div className="p-5 lg:px-10 lg:py-[30px]">
            <div>
                <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-4">
                    Ваш адрес<br />электронной почты
                </span>
                <div className="relative">
                    <input value={email} onChange={handleChange} autoFocus={true}
                           className={cn("focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] border border-[#CFD0D4] w-full", {
                               'border-[#FE2D2D] focus:border-[#FE2D2D]': isErrorAuth,
                           })}
                           placeholder={'Введите email'}/>
                </div>
            </div>
        </div>
    )
}

export {EmailContent};