import React from 'react';
const ValuesIcon = ({ width = "477", height = "260" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 477 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="74.1172" y="140.895" width="328.758" height="118.478" rx="29.2932" fill="#8F8F8E" fillOpacity="0.2"/>
            <rect x="34.4141" y="93.6307" width="408.17" height="147.097" rx="29.2932" fill="#696969"/>
            <rect x="0.03125" y="0.322632" width="476.93" height="217.868" rx="29.2932" fill="#FFFFFE"/>
            <path d="M158.302 68.1178H158.721V160.602H146.566V85.8602L127.148 93.8233V82.0882L158.302 68.1178ZM191.217 148.867H228.239V160.602H170.82V155.153L205.188 113.661C210.915 106.676 214.827 99.2718 214.827 95.0807C214.827 85.8602 208.261 79.7132 198.342 79.7132C188.423 79.7132 181.578 85.3014 180.879 93.5439L169.982 91.169C171.798 77.3383 182.695 68.1178 198.342 68.1178C215.526 68.1178 226.981 78.875 226.981 95.0807C226.981 102.206 222.371 111.566 216.364 118.691L191.217 148.867ZM285.185 107.654C295.663 111.705 302.509 121.624 302.509 133.36C302.509 150.403 288.259 162.278 268.421 162.278C248.443 162.278 234.054 150.403 234.054 133.499C234.054 121.624 240.899 111.705 251.517 107.654C245.929 104.161 242.296 98.4336 242.296 90.7498C242.296 77.8971 252.774 68.1178 268.421 68.1178C283.928 68.1178 294.546 77.8971 294.546 90.7498C294.546 98.4336 290.774 104.161 285.185 107.654ZM268.421 79.4338C260.877 79.4338 255.009 85.022 255.009 91.8675C255.009 98.5733 260.877 103.742 268.421 103.742C275.965 103.742 281.693 98.713 281.693 91.8675C281.693 85.022 275.965 79.4338 268.421 79.4338ZM268.421 150.823C280.994 150.823 289.656 142.72 289.656 132.521C289.656 121.624 279.877 113.941 268.421 113.941C256.686 113.941 246.907 121.764 246.907 132.382C246.907 142.72 256.267 150.823 268.421 150.823Z" fill="#161B21"/>
            <path d="M365.019 160.602H359.12L356.064 138.427L345.687 156.835H340.996L330.62 138.498L327.493 160.602H321.665L326.142 127.411H330.62L343.342 150.296L355.922 127.411H360.47L365.019 160.602ZM380.234 127.411H401.911V160.602H395.728V133.239H386.062L384.214 147.453C383.077 156.053 379.879 160.602 373.482 160.602H370.142V155.129H372.061C375.614 155.129 377.249 153.423 377.96 147.311L380.234 127.411ZM436.941 127.411H443.124V160.602H436.941V146.814H418.818V160.602H412.634V127.411H418.818V141.27H436.941V127.411Z" fill="#AAAAAA"/>
            <path d="M15.7188 160.951C15.7188 160.951 40.8622 152.777 65.738 126.098C90.6138 99.4182 96.0529 66.5297 96.0529 66.5297" stroke="url(#paint0_linear_536_40220)" strokeWidth="3.66165"/>
            <g filter="url(#filter0_d_536_40220)">
                <circle cx="96.05" cy="66.5297" r="8.40938" fill="#8DB948"/>
            </g>
            <defs>
                <filter id="filter0_d_536_40220" x="69.3324" y="43.4738" width="53.4368" height="53.4353" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3.66165"/>
                    <feGaussianBlur stdDeviation="9.15412"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.513726 0 0 0 0 0.686275 0 0 0 0 0.243137 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_536_40220"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_536_40220" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_536_40220" x1="55.8858" y1="66.5297" x2="55.8858" y2="160.951" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#9DCF4E"/>
                    <stop offset="1" stopColor="#9DCF4E" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export {ValuesIcon};