import { useEffect, useState } from "react";
import { PrimaryButton, TariffRadio } from "../components";
import cn from "classnames";
import React from "react";
const CostSection = () => {
  const [tariffs, setTariffs] = useState([]);
  const [activeCount, setActiveCount] = useState();
  const [activeFreq, setActiveFreq] = useState();
  const [activePeriod, setActivePeriod] = useState();
  const loadTariffs = async () => {
    const response = await fetch("https://back.priceninja.ru/api/tariffs");
    const data = await response.json();
    setTariffs(
      data && data.data
        ? [
            ...data.data,
            {
              id: 99,
              name: "Свыше 3 000",
              count_products: 1001,
              price: -1,
              frequencies: [
                {
                  id: 98,
                  name: "1 раз в сутки",
                  frequency: 1,
                  price: -1,
                  periods: [
                    {
                      id: 1,
                      name: "1 месяц",
                      price_total: -1,
                    },
                    {
                      id: 3,
                      name: "3 месяцa",
                      price_total: -1,
                    },
                    {
                      id: 6,
                      name: "6 месяцев",
                      price_total: -1,
                    },
                    {
                      id: 12,
                      name: "12 месяцев",
                      price_total: -1,
                    },
                  ],
                },
                {
                  id: 97,
                  name: "4 раза в сутки",
                  frequency: 6,
                  price: -1,
                  periods: [
                    {
                      id: 1,
                      name: "1 месяц",
                      price_total: -1,
                    },
                    {
                      id: 3,
                      name: "3 месяцa",
                      price_total: -1,
                    },
                    {
                      id: 6,
                      name: "6 месяцев",
                      price_total: -1,
                    },
                    {
                      id: 12,
                      name: "12 месяцев",
                      price_total: -1,
                    },
                  ],
                },
                {
                  id: 96,
                  name: "24 раза в сутки",
                  frequency: 24,
                  price: -1,
                  periods: [
                    {
                      id: 1,
                      name: "1 месяц",
                      price_total: -1,
                    },
                    {
                      id: 3,
                      name: "3 месяцa",
                      price_total: -1,
                    },
                    {
                      id: 6,
                      name: "6 месяцев",
                      price_total: -1,
                    },
                    {
                      id: 12,
                      name: "12 месяцев",
                      price_total: -1,
                    },
                  ],
                },
              ],
            },
          ]
        : []
    );
    if (data && data.data) {
      setActiveCount(data.data[0].count_products);
      setActiveFreq(data.data[0].frequencies[0].frequency);
      setActivePeriod(data.data[0].frequencies[0].periods[0].id);
    }
  };
  useEffect(() => {
    loadTariffs();
  }, []);
  const tariff = tariffs.find(
    ({ count_products }) => count_products === activeCount
  );
  const tariffPrice =
    (tariff && tariff.price) === -1 ? 0 : tariff && tariff.price;
  const freq = (tariff &&
    tariff.frequencies &&
    tariff.frequencies.find(({ frequency }) => frequency === activeFreq)) || {
    price: 0,
  };
  const freqPrice = freq && freq.price;
  const searched =
    freq && freq.periods && freq.periods.find((el) => el.id === activePeriod);
  const total = (searched && searched.price_total) || 0;
  const activeTariff = tariffs.find((el) => el.count_products === activeCount);
  return (
    <div className={"bg-[#02070D] px-5 lg:px-0"}>
      <div className="pt-[100px] lg:pt-[150px] flex flex-col lg:items-center">
        <div className="flex flex-col lg:items-center">
          <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
            Стоимость
          </span>
          <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
            Прайс Ниндзя
          </span>
        </div>
        <div
          id="tariffs"
          className="bg-[#1B2126] rounded-[20px] lg:rounded-[60px] py-5 lg:p-20 mt-[49px] lg:mt-[90px] w-full lg:w-fit"
        >
          <div className="hidden lg:flex space-x-10">
            <div className="flex flex-grow max-w-[230px] min-w-[230px]">
              <div className="space-y-8 flex-grow">
                <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
                  <span className="block text-white text-sm leading-[16px] font-semibold">
                    ШАГ 1
                  </span>
                </div>
                <div>
                  <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
                    Количество товаров
                  </span>
                  <span className="block text-[#919BA0] font-normal text-[16px]">
                    Выберите кол-во товаров
                    <br />
                    для мониторинга цен
                  </span>
                </div>
                <div className="h-0.5 bg-white/[7%]" />
                <div className="space-y-[17px]">
                  {tariffs.map((tariff) => {
                    const { name, price, count_products } = tariff;
                    const resultName = name.split(" ");
                    if (Number.isInteger(+resultName[0])) {
                      resultName[0] = (+resultName[0]).toLocaleString("ru");
                    }
                    return (
                      <div
                        className="flex items-center"
                        key={count_products + name}
                      >
                        <div
                          className={"flex items-center cursor-pointer"}
                          onClick={() => {
                            if (count_products === 10) {
                              setActivePeriod(1);
                              setActiveFreq(1);
                            }
                            setActiveCount(count_products);
                          }}
                        >
                          <TariffRadio
                            checked={count_products === activeCount}
                            onChange={() => {
                              if (count_products === 10) {
                                setActivePeriod(1);
                                setActiveFreq(1);
                              }
                              setActiveCount(count_products);
                            }}
                          />
                          <span className="block text-white text-[14px] leading-[22px] w-[98px] min-w-[98px] pt-[3px] whitespace-nowrap">
                            {resultName.join(" ")}
                          </span>
                        </div>
                        <span className="block ml-3 text-[#FFE8AE] text-[12px] leading-[20px] pt-[3px]">
                          {price === -1
                            ? "Персонально"
                            : activeCount === count_products
                            ? price === 0
                              ? "Бесплатно"
                              : ""
                            : `${tariffPrice > price ? "-" : "+"} ${Math.abs(
                                price - tariffPrice
                              ).toLocaleString("ru")} ₽`}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-grow max-w-[230px] min-w-[230px]">
              <div className="space-y-8 flex-grow">
                <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
                  <span className="block text-white text-sm leading-[16px] font-semibold">
                    ШАГ 2
                  </span>
                </div>
                <div>
                  <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
                    Частота обновления
                  </span>
                  <span className="block text-[#919BA0] font-normal text-[16px]">
                    Как часто необходимо
                    <br />
                    обновлять цены
                  </span>
                </div>
                <div className="h-0.5 bg-white/[7%]" />
                <div className="space-y-[17px]">
                  {activeTariff &&
                    activeTariff.frequencies.map((freq) => {
                      const { name, price, frequency } = freq;
                      return (
                        <div
                          className="flex items-center"
                          key={frequency + name}
                        >
                          <div
                            className={"flex items-center cursor-pointer"}
                            onClick={() => setActiveFreq(frequency)}
                          >
                            <TariffRadio
                              checked={frequency === activeFreq}
                              onChange={() => setActiveFreq(frequency)}
                            />
                            <span className="block text-white text-[14px] leading-[22px] w-[110px] min-w-[110px] pt-[3px] whitespace-nowrap">
                              {name}
                            </span>
                          </div>
                          <span className="block ml-3 text-[#FFE8AE] text-[12px] leading-[20px] pt-[3px]">
                            {price === -1
                              ? "Персонально"
                              : activeFreq === frequency
                              ? price === 0
                                ? "Бесплатно"
                                : ""
                              : `${freqPrice > price ? "-" : "+"} ${Math.abs(
                                  price - freqPrice
                                ).toLocaleString("ru")} ₽`}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="flex flex-grow max-w-[230px] min-w-[230px]">
              <div className="space-y-8 flex-grow">
                <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
                  <span className="block text-white text-sm leading-[16px] font-semibold">
                    ШАГ 3
                  </span>
                </div>
                <div>
                  <span className="block text-white font-medium text-[20px] whitespace-nowrap leading-[34px] mb-3">
                    Период действия
                  </span>
                  <span className="block text-[#919BA0] font-normal text-[16px]">
                    Выберите кол-во месяцев
                    <br />
                    действия сервиса
                  </span>
                </div>
                <div className="h-0.5 bg-white/[7%]" />
                <div className="space-y-[17px]">
                  {activeTariff &&
                    activeTariff.frequencies.find(
                      (el) => el.frequency === activeFreq
                    ) &&
                    activeTariff.frequencies
                      .find((el) => el.frequency === activeFreq)
                      .periods.filter(
                        (el, index) => !(activeCount === 10 && index !== 0)
                      )
                      .map((period, index) => {
                        const { name, id } = period;
                        return (
                          <div className="flex items-center" key={id + name}>
                            <div
                              className={"flex items-center cursor-pointer"}
                              onClick={() => setActivePeriod(id)}
                            >
                              <TariffRadio
                                checked={id === activePeriod}
                                onChange={() => setActivePeriod(id)}
                              />
                              <span className="block text-white text-[14px] leading-[22px] w-[85px] min-w-[85px] pt-[3px] whitespace-nowrap">
                                {name}
                              </span>
                            </div>
                            {index !== 0 && (
                              <span className="block ml-3 text-white text-[12px] px-1 leading-[22px] mt-[3px] rounded-[7px] bg-[#C93F38] font-semibold">{`- ${
                                10 + (index - 1) * 5
                              } %`}</span>
                            )}
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
            <div className="flex flex-grow max-w-[230px] min-w-[230px]">
              <div className="space-y-8 flex-grow">
                <div className="rounded-[100px] pt-[18px] px-6 pb-4 border border-white/[12%] w-fit">
                  <span className="block text-white text-sm leading-[16px] font-semibold">
                    ШАГ 4
                  </span>
                </div>
                <div>
                  <span className="block text-white font-medium text-[20px] leading-[34px] mb-3">
                    Стоимость
                  </span>
                  <span className="block text-[#919BA0] font-normal text-[16px]">
                    Складывается из кол-ва
                    <br />
                    товаров и обновления цен
                  </span>
                </div>
                <div className="h-0.5 bg-white/[7%]" />
                <div>
                  <span
                    className={cn(
                      "block mb-4 text-[#9DCF4E] text-[39px] font-normal leading-[47px]",
                      {
                        "-tracking-[2px]": activeCount === 1001,
                        "!text-[32px]":
                          (total && total.toLocaleString("ru").length > 5) ||
                          (total && total >= 2000) ||
                          activeCount === 1001,
                      }
                    )}
                  >
                    {activeCount === 1001
                      ? "Персонально"
                      : total === 0
                      ? "Бесплатно"
                      : `${
                          total && (total / activePeriod).toLocaleString("ru")
                        } ₽/мес`}
                  </span>
                  <div className="py-2 px-3 border border-[rgb(157,207,78,0.50)] rounded-lg bg-[rgb(157,207,78,0.04)] w-fit">
                    <span className="block text-[#9DCF4E] text-sm leading-[18px] font-normal">
                      {activeCount === 1001 ? (
                        <span>
                          Мы свяжемся с вами, обсудим ваш запрос
                          <br />и предложим индивидуальный тариф.
                        </span>
                      ) : (
                        <span>
                          Мониторинг цен для <br />
                          {activeCount && activeCount.toLocaleString("ru")}{" "}
                          товаров {freq.name && freq.name.toLowerCase()}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PricesMobile
            freqPrice={freqPrice}
            tariffPrice={tariffPrice}
            activePeriod={activePeriod}
            setActivePeriod={setActivePeriod}
            tariffs={tariffs}
            tariff={tariff}
            freq={freq}
            total={total}
            activeCount={activeCount}
            activeFreq={activeFreq}
            setActiveCount={setActiveCount}
            setActiveFreq={setActiveFreq}
          />
          <div className="px-6 lg:px-0 flex items-center justify-center">
            <PrimaryButton
              className="w-full py-5 lg:py-0 mt-6 lg:mt-20 lg:h-[188px]"
              onClick={() =>
                (window.location.href = "https://go.priceninja.ru/products")
              }
            >
              <span className="block font-semibold lg:font-medium text-[18px] leading-[21px] lg:text-[60px] lg:leading-[124px] lg:-tracking-[3px] text-center text-white">
                Мне все нравится,
                <br className="lg:hidden" /> начинаем!
              </span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const PricesMobile = ({
  tariffs,
  freq,
  total,
  activeCount,
  activeFreq,
  setActiveCount,
  setActiveFreq,
  activePeriod,
  setActivePeriod,
  tariffPrice,
  freqPrice,
}) => {
  const activeTariff = tariffs.find((el) => el.count_products === activeCount);
  return (
    <div className="lg:hidden flex flex-col pl-6">
      <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 1
        </span>
        <div className="grow bg-[#2B3135] h-[1px]" />
      </div>
      <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Выберите кол-во товаров
        <br />
        для мониторинга цен
      </span>
      <div className="relative">
        <div
          className="absolute right-0 w-[35px] h-[70px]"
          style={{
            background:
              "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
          }}
        />
        <div className="mt-3 overflow-x-auto mobile-tariffs-block">
          <div className="flex space-x-2 pb-6">
            {tariffs.map((tariff) => {
              const { name, price, count_products } = tariff;
              const resultName = name.split(" ");
              if (Number.isInteger(+resultName[0])) {
                resultName[0] = (+resultName[0]).toLocaleString("ru");
              }
              return (
                <div
                  className={cn(
                    "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                    {
                      "border-white": count_products === activeCount,
                    }
                  )}
                  key={count_products + name}
                  onClick={() => {
                    if (count_products === 10) {
                      setActivePeriod(1);
                      setActiveFreq(1);
                    }
                    setActiveCount(count_products);
                  }}
                >
                  <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                    {resultName.join(" ")}
                  </span>
                  <span className="block text-[#FFE8AE] text-[10px] leading-[16px] pt-[3px]">
                    {price === -1
                      ? "Персонально"
                      : activeCount === count_products
                      ? price === 0
                        ? "Бесплатно"
                        : ""
                      : `${tariffPrice > price ? "-" : "+"} ${Math.abs(
                          price - tariffPrice
                        ).toLocaleString("ru")} ₽`}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 2
        </span>
        <div className="grow bg-[#2B3135] h-[1px]" />
      </div>
      <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Выберите как часто необходимо <br /> обновлять цены
      </span>
      <div className="relative">
        <div
          className="absolute right-0 w-[35px] h-[70px]"
          style={{
            background:
              "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
          }}
        />
        <div className="mt-3 overflow-x-auto mobile-tariffs-block">
          <div className="flex space-x-2 pb-6">
            {activeTariff &&
              activeTariff.frequencies.map((freq) => {
                const { name, price, frequency } = freq;
                return (
                  <div
                    className={cn(
                      "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                      {
                        "border-white": frequency === activeFreq,
                      }
                    )}
                    key={frequency + name}
                    onClick={() => setActiveFreq(frequency)}
                  >
                    <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                      {name}
                    </span>
                    <span className="block text-[#FFE8AE] text-[10px] leading-[16px] pt-[3px]">
                      {price === -1
                        ? "Персонально"
                        : activeFreq === frequency
                        ? price === 0
                          ? "Бесплатно"
                          : ""
                        : `${freqPrice > price ? "-" : "+"} ${Math.abs(
                            price - freqPrice
                          ).toLocaleString("ru")} ₽`}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 3
        </span>
        <div className="grow bg-[#2B3135] h-[1px]" />
      </div>
      <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Выберите кол-во месяцев <br /> действия сервиса
      </span>
      <div className="relative">
        <div
          className="absolute right-0 w-[35px] h-[70px]"
          style={{
            background:
              "linear-gradient(267deg, #1B2126 16.37%, rgba(27, 33, 38, 0.00) 96.93%)",
          }}
        />
        <div className="mt-3 overflow-x-auto mobile-tariffs-block">
          <div className="flex space-x-2 pb-6">
            {activeTariff &&
              activeTariff.frequencies.find(
                (el) => el.frequency === activeFreq
              ) &&
              activeTariff.frequencies
                .find((el) => el.frequency === activeFreq)
                .periods.filter(
                  (el, index) => !(activeCount === 10 && index !== 0)
                )
                .map((period, index) => {
                  const { name, id } = period;
                  const splittedName = name.split(" ");
                  splittedName[1] = splittedName[1].slice(0, 3);
                  return (
                    <div
                      className={cn(
                        "border border-[rgb(255,255,255,0.07)] py-2 px-3 rounded-[8px]",
                        {
                          "border-white": id === activePeriod,
                        }
                      )}
                      key={id + name}
                      onClick={() => setActivePeriod(id)}
                    >
                      <span className="block text-white text-[12px] leading-[15px] whitespace-nowrap">
                        {splittedName.join(" ")}
                      </span>
                      {index !== 0 && (
                        <span className="block text-[#FF7B74] text-[10px] leading-[16px] pt-[3px] whitespace-nowrap">{`Скидка ${
                          10 + (index - 1) * 5
                        } %`}</span>
                      )}
                      {/*{index !== 0 && <span className="block text-[#FF7B74] text-[10px] leading-[16px]">{`${10 + (index - 1) * 5} %`}</span>}*/}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <div className="flex items-center pr-6">
        <span className="block text-[#D9D9D9] text-xs leading-[18px] font-bold mr-4">
          ШАГ 4
        </span>
        <div className="grow bg-[#2B3135] h-[1px]" />
      </div>
      <span className="block mt-1 text-xs leading-[18px] text-[#919BA0]">
        Осталось оплатить и начать
        <br /> пользоваться!
      </span>
      <div className="mt-3 pr-6">
        <span
          className={cn(
            "block mb-2 text-[#9DCF4E] text-[24px] font-normal leading-[30px]"
          )}
        >
          {activeCount === 1001
            ? "Индивидуально"
            : total === 0
            ? "Бесплатно"
            : `${total && total / activePeriod} ₽/мес`}
        </span>
        <div className="py-2 px-3 border border-[rgb(157,207,78,0.50)] rounded-lg bg-[rgb(157,207,78,0.04)] w-full">
          <span className="block text-[#9DCF4E] text-[10px] leading-[14px] font-normal">
            {activeCount === 1001 ? (
              <span>
                Мы свяжемся с вами, обсудим
                <br />
                ваш запрос и предложим
                <br />
                индивидуальный тариф.
                {/*Мы свяжемся с вами , обсудим<br/>ваш запрос и рассчитаем тариф<br/>индивидуально для вас*/}
              </span>
            ) : (
              <span>
                Мониторинг цен для{" "}
                {activeCount && activeCount.toLocaleString("ru")} товаров{" "}
                {freq.name && freq.name.toLowerCase()}
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export { CostSection };
