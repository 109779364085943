import {useEffect, useState} from "react";
import {MOCK_DATA} from "./mockData";
import cn from "classnames";
import React from 'react';

const getResult = (number) => {
    const str = number + '';
    return str.length === 2 ? str : `0${str}`
}
const ParsingData = () => {
    const [currentData, setCurrentData] = useState([]);
    const [parsingCount, setParsingCount] = useState(120000000 + Math.floor(Math.random() * 3600000))
    useEffect(() => {
        const firstData = [];
        for (let i = 0; i < 15; i++) {
            const randEl = MOCK_DATA[Math.floor(Math.random() * (MOCK_DATA.length))];
            const arr = []
                const data = Object.entries(randEl);
            data.forEach(([key, value], index) => {
                //arr.push(`${key}: ${value}`)
                arr.push(<span><span className="text-[#9DCF4E]">{key}</span>: {value}{(index !== data.length - 1) &&<span className="mr-1.5">{`,`}</span>}</span>)
            })
            //firstData.push(arr.join(', '))
            firstData.push(arr)
        }
        setCurrentData(firstData.map(el => {
            const dateTime = new Date();
            const time = `${getResult(dateTime.getHours())}:${getResult(dateTime.getMinutes())}:${getResult(dateTime.getSeconds())}`;
            const date = `${getResult(dateTime.getDate())}.${getResult(dateTime.getMonth() + 1)}.${dateTime.getFullYear()}`
            return {time, date, message: el};
        }))
    }, []);
    useEffect(() => {
        const timerId = setInterval(() => {
            const resultArr = [...currentData]
            const dateTime = new Date();
            const time = `${getResult(dateTime.getHours())}:${getResult(dateTime.getMinutes())}:${getResult(dateTime.getSeconds())}`;
            const date = `${getResult(dateTime.getDate())}.${getResult(dateTime.getMonth() + 1)}.${dateTime.getFullYear()}`
            const randEl = MOCK_DATA[Math.floor(Math.random() * (MOCK_DATA.length))];
            const arr = [];
            const data = Object.entries(randEl)
            data.forEach(([key, value], index) => {
                //arr.push(`${key}: ${value}`)
                arr.push(<span><span className="text-[#9DCF4E]">{key}</span>: {value}{(index !== data.length - 1) &&<span className="mr-1.5">{`,`}</span>}</span>)
            })
            resultArr.unshift({time, date, message: arr})
            resultArr.pop();
            setCurrentData(resultArr);
        }, 1000)
        return () => clearInterval(timerId)
    }, [currentData])

    useEffect(() => {
        const dateTime = new Date();
        const date = `${getResult(dateTime.getDate())}.${getResult(dateTime.getMonth() + 1)}.${dateTime.getFullYear()}`
        const cached = localStorage.getItem('parsingCount');
        if (cached && (date === JSON.parse(cached).date)) {
                setParsingCount(JSON.parse(cached).count)
        } else {
            const range = Math.floor(Math.random() * 3600000);
            localStorage.setItem("parsingCount", JSON.stringify({
                date,
                count:dateTime.getSeconds() % 2 === 0 ? 120000000 - range : 120000000 + range
            }))
        }
    }, []);

    return (
        <div className="mx-auto z-20 overflow-hidden lg:relative w-[1134px]">
            <div className="px-[24px] py-[20px] lg:px-[30px] lg:py-[26px] bg-[#1D1B1D] lg:rounded-t-[18px] border-b border-[#323232] flex items-center space-x-4">
                <div className="w-2 h-2 rounded-full bg-[#E20808]"/>
                <div className="flex flex-col lg:flex-row lg:space-x-3 lg:items-center space-y-1 lg:space-y-0">
                    <span className="block text-[18px] leading-[19px] lg:text-[20px] lg:leading-[27px] font-bold text-white">
                        {parsingCount.toLocaleString()}
                    </span>
                    <span className="block text-[16px] leading-[19px] lg:text-[20px] lg:leading-[27px] font-medium text-[rgb(255,255,255,0.50)]">
                        данных спарсили вчера
                    </span>
                </div>
            </div>
            <div>
                {currentData.map(({time, date, message}, index) => {
                    return (
                        <div key={index} className={cn("parsing_data flex h-[24px] lg:h-[36px] items-center", {
                            "bg-[#1D1B1D]": index%2 === 0,
                            "bg-black": index%2 === 1
                        })}>
                            <div className="hidden lg:flex items-center justify-center min-w-[111px] w-[111px] text-white leading-[19px] text-[15px] font-medium">
                                {date}
                            </div>
                            <div className="flex items-center justify-center w-[71px] min-w-[71px] lg:min-w-[111px] lg:w-[111px] text-[#F19B38] leading-[12px] text-[10px] lg:leading-[19px] lg:text-[15px] font-medium">
                                {time}
                            </div>
                            <div className="flex items-center grow text-white leading-[12px] text-[10px] lg:leading-[19px] lg:text-[15px] font-medium uppercase text-ellipsis overflow-hidden whitespace-nowrap">
                                {message}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={'absolute bg-[#1D1B1D] w-[1632px] h-[468px] z-10 -bottom-[400px] -left-[300px]'} style={{filter: 'blur(167px)'}}/>
        </div>
    )
}

export {ParsingData}