import { MainPhoneInput, PrimaryButton } from "../components";
import { useState } from "react";
import React from "react";
const QuestionsSection = ({
  phone,
  handlePhoneChange,
  setShowCallbackModal,
}) => {
  const [phoneError, setPhoneError] = useState(false);
  const handleClick = async () => {
    if (phone.length === 13) {
      const resp = await fetch(
        `https://back.priceninja.ru/api/submissions?type=call_back&phone=%2B7${phone
          .split(" ")
          .join("")}&site=${
          window && window.location.pathname === "/parsing"
            ? "parsing"
            : "priceninja"
        }`,
        {
          method: "POST",
        }
      );
      if (resp.status === 200) {
        setShowCallbackModal(true);
      } else {
        setPhoneError(true);
      }
    } else {
      setPhoneError(true);
    }
  };
  return (
    <div className="bg-[#02070D] px-5 lg:px-0">
      <div className="pt-[100px] lg:pt-[150px] flex flex-col lg:items-center">
        <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px] lg:text-center">
          У вас <br className="block lg:hidden" />
          вопросы?
          <br className="hidden lg:block" /> Или предложения?
        </span>
        <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px] lg:text-center">
          Пришлите <br className="block lg:hidden" />
          номер,
          <br className="hidden lg:block" /> а мы
          <br className="block lg:hidden" /> свяжемся:
        </span>
        <MainPhoneInput
          phone={phone}
          handlePhoneChange={(e) => {
            handlePhoneChange(e);
            setPhoneError(false);
          }}
          phoneError={phoneError}
        />
        <PrimaryButton
          className={
            "mt-5 lg:mt-[60px] w-full lg:w-auto py-5 lg:py-8 lg:px-[106px] mb-[80px] lg:mb-[150px]"
          }
          onClick={handleClick}
        >
          <span className="block text-[#FBFBFC] font-medium text-[18px] leading-[21px] lg:text-[60px] lg:leading-[124px] lg:-tracking-[3px]">
            Свяжитесь со мной
          </span>
        </PrimaryButton>
      </div>
    </div>
  );
};

export { QuestionsSection };
