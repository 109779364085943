import React from 'react';
const GarantsIcon = ({ width = "137", height = "137" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_536_40236)">
                <rect x="18.5" y="14.6693" width="100" height="100" rx="30" fill="#0A25FF"/>
                <path d="M46.6797 54.4881V48.2745L69.2992 41.3986L90.3165 48.2745V54.4881C90.3165 67.2913 84.5302 79.4087 74.5731 87.4572L74.2601 87.7101C70.8991 90.4269 66.097 90.4269 62.736 87.7101L62.4231 87.4572C52.466 79.4087 46.6797 67.2913 46.6797 54.4881Z" fill="white" stroke="white" strokeWidth="1.45144" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M58.4688 64.7646L66.5069 73.1499L78.5243 56.0334" stroke="#0A25FF" strokeWidth="2.90288" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_d_536_40236" x="0.540638" y="0.301822" width="135.919" height="135.919" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3.59187"/>
                    <feGaussianBlur stdDeviation="8.97968"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.145098 0 0 0 0 1 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_536_40236"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_536_40236" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export {GarantsIcon}