import React from 'react';
const SettingsMobileIcon = () => {
    return (
        <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_665_23789)">
                <rect width="60" height="60" transform="translate(0 0.407959)" fill="#1B2126"/>
                <rect x="8.61719" y="0.407959" width="42.7654" height="38.2782" rx="4.80423" fill="white"/>
                <path d="M24.8678 15.0623L24.8678 34.1366H22.558L22.558 17.4839H22.4462L17.7894 20.576V18.2289L22.558 15.0623H24.8678ZM29.5667 34.1366V32.4601L35.8627 25.5681C36.6016 24.7609 37.2101 24.0593 37.6882 23.4632C38.1663 22.8609 38.5202 22.2959 38.7499 21.7681C38.9859 21.2341 39.1038 20.6753 39.1038 20.0917C39.1038 19.4211 38.9424 18.8405 38.6195 18.35C38.3029 17.8595 37.8682 17.4807 37.3156 17.2138C36.763 16.9468 36.1421 16.8133 35.4529 16.8133C34.7202 16.8133 34.0807 16.9654 33.5343 17.2696C32.9941 17.5677 32.575 17.9868 32.277 18.527C31.9851 19.0672 31.8392 19.7005 31.8392 20.427H29.6412C29.6412 19.3093 29.8989 18.3283 30.4142 17.4839C30.9296 16.6394 31.6312 15.9813 32.5191 15.5094C33.4132 15.0375 34.416 14.8015 35.5274 14.8015C36.645 14.8015 37.6354 15.0375 38.4984 15.5094C39.3615 15.9813 40.0383 16.6177 40.5288 17.4187C41.0193 18.2196 41.2646 19.1106 41.2646 20.0917C41.2646 20.7933 41.1373 21.4794 40.8827 22.15C40.6344 22.8143 40.1997 23.5563 39.5788 24.3759C38.9641 25.1893 38.1104 26.1828 37.0176 27.3563L32.7333 31.9386V32.0876H41.5999V34.1366H29.5667Z" fill="#1E1E21"/>
                <path d="M21.4414 4.68881C21.4414 4.06967 21.9433 3.56775 22.5625 3.56775H37.4354C38.0545 3.56775 38.5565 4.06967 38.5565 4.68881C38.5565 5.30796 38.0545 5.80987 37.4354 5.80987H22.5625C21.9433 5.80987 21.4414 5.30796 21.4414 4.68881Z" fill="#1E1E21"/>
                <rect x="2.10352" y="29.8104" width="56.1066" height="26.6315" rx="4.75867" fill="#9747FF"/>
                <path d="M11.2715 43.1262C11.2715 41.9522 12.2232 41.0005 13.3972 41.0005H46.9154C48.0894 41.0005 49.0412 41.9522 49.0412 43.1262C49.0412 44.3002 48.0894 45.252 46.9154 45.252H13.3972C12.2232 45.252 11.2715 44.3002 11.2715 43.1262Z" fill="white"/>
                <rect x="18.0078" y="37.1467" width="12.2988" height="12.2988" rx="6.14942" fill="white" stroke="#9747FF" strokeWidth="3"/>
            </g>
            <defs>
                <clipPath id="clip0_665_23789">
                    <rect width="60" height="60" fill="white" transform="translate(0 0.407959)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export {SettingsMobileIcon};