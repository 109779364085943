import React from 'react';
const ServerIcon = () => {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.49219 12.7689H29.5051M3.49219 20.7041H29.5051M6.68468 28.5621H26.3126C28.0757 28.5621 29.5051 27.1328 29.5051 25.3696V8.10655C29.5051 6.34339 28.0757 4.91406 26.3126 4.91406H6.68468C4.92151 4.91406 3.49219 6.34339 3.49219 8.10655V25.3696C3.49219 27.1328 4.92151 28.5621 6.68468 28.5621Z" stroke="#05A2DC" strokeWidth="2"/>
            <circle cx="7.66482" cy="8.88211" r="0.977321" fill="#05A2DC"/>
            <circle cx="7.66482" cy="16.7383" r="0.977321" fill="#05A2DC"/>
            <circle cx="7.66482" cy="24.595" r="0.977321" fill="#05A2DC"/>
            <circle cx="10.7664" cy="8.88211" r="0.977321" fill="#05A2DC"/>
            <circle cx="10.7664" cy="16.7383" r="0.977321" fill="#05A2DC"/>
            <circle cx="10.7664" cy="24.595" r="0.977321" fill="#05A2DC"/>
            <circle cx="13.8679" cy="8.88211" r="0.977321" fill="#05A2DC"/>
            <circle cx="13.8679" cy="16.7383" r="0.977321" fill="#05A2DC"/>
            <circle cx="13.8679" cy="24.595" r="0.977321" fill="#05A2DC"/>
        </svg>
    )
}

export {ServerIcon}