import React from 'react';
const TariffRadio = ({onChange, checked}) => {
    return (
        <>
            <input
                className="block relative float-left mr-3 mt-0.5 h-5 w-5 appearance-none rounded-full hover:duration-500 hover:border-[rgb(255,255,255,0.50)] border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[8px] checked:after:w-[8px] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:bg-white checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100  checked:focus:border-primary checked:focus:before:scale-100 dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:checked:focus:border-primary border-[rgb(255,255,255,0.12)] checked:border-white"
                type="radio" onChange={onChange} checked={checked}
            />
        </>

    )
}

export {TariffRadio }