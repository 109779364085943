import {useState} from "react";
import React from 'react';
const CrossIcon = () => {
    const [hover, setIsHover] = useState(false)
    return (
        <svg className="block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <path d="M5 5L12 12M19 19L12 12M12 12L19 5L5 19" stroke={hover ? "#0A25FF" :"#8E9399"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {CrossIcon};