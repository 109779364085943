import {ConfirmIcon} from "../screenshots";
import React from 'react';

const ANY_SITES = [
    'Интернет-магазины',
    'Маркетплейсы',
    'Каталоги',
    'Соцсети',
    'Мессенджеры',
    'Справочники',
    'Карты',
    'Доски объявлений',
    'Поисковики',
]

const ANY_DATA = [
    'Цены конкурентов',
    'Наличие и характеристики товаров',
    'Контакты',
    'Отзывы',
    'Фотографии',
    'Списки пользователей в соц. сетях и мессенджерах',
    'Позиции товаров в каталоге',
    'Результаты по поисковым запросам',
    'Товары в рекомендательных блоках',
]
const EffectiveParsingSection = () => {
    return (
        <div className="bg-[#02070D] px-5 lg:px-0">
            <div className="pt-[100px] lg:pt-[150px] flex flex-col lg:items-center">
                <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
                   Эффективный
                </span>
                <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                   парсинг всего
                </span>
                <div className="lg:w-[1200px] flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-5 mt-[41px] lg:mt-[100px]">
                    <div className="bg-[#1B2126] py-5 px-6 lg:py-[60px] lg:px-20 rounded-[20px] lg:rounded-[60px] lg:w-[516px]">
                        <span className="block text-[15px] leading-[21px] lg:text-[24px] lg:leading-[34px] text-white font-medium">Спарсим любые сайты</span>
                        <div className="bg-[rgb(255,255,255,0.12)] h-[1px] grow my-[18px] lg:my-[30px]"/>
                        <div className="space-y-2 lg:space-y-3">
                            {ANY_SITES.map((el) =>
                                <div className="flex space-x-2.5 lg:space-x-4 items-center" key={el}>
                                    <div>
                                        <div className="hidden lg:block"><ConfirmIcon /></div>
                                        <div className="lg:hidden"><ConfirmIcon width={"15"} height={"15"}/></div>
                                    </div>
                                    <span className="block text-[#FBFBFC] text-[11px] leading-[17px] lg:text-[18px] lg:leading-[28px] font-normal">{el}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="bg-[#1B2126] py-5 px-6 lg:py-[60px] lg:px-20 rounded-[20px] lg:rounded-[60px] grow">
                        <span className="block text-[15px] leading-[21px] lg:text-[24px] lg:leading-[34px] text-white font-medium">Соберем любые данные</span>
                        <div className="bg-[rgb(255,255,255,0.12)] h-[1px] grow my-[18px] lg:my-[30px]"/>
                        <div className="space-y-2 lg:space-y-3">
                            {ANY_DATA.map((el) =>
                                <div className="flex space-x-2.5 lg:space-x-4 items-center" key={el}>
                                    <div>
                                        <div className="hidden lg:block"><ConfirmIcon /></div>
                                        <div className="lg:hidden"><ConfirmIcon width={"15"} height={"15"}/></div>
                                    </div>
                                    <span className="block text-[#FBFBFC] text-[11px] leading-[17px] lg:text-[18px] lg:leading-[28px] font-normal">{el}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {EffectiveParsingSection};