import React from 'react';
const RefreshIcon = ({ color = "#0A25FF"}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.49918 14.6031V18.6031M5.49918 14.6031C6.53213 17.18 9.05312 19 11.9992 19C15.1702 19 17.8488 16.8915 18.7093 14M5.49918 14.6031H9.49918M18.6254 9.73712L18.6253 5.73712M18.6254 9.73712H14.6253M18.6254 9.73712C17.6846 6.9816 15.0733 5 11.9992 5C8.82816 5 6.14961 7.10851 5.28906 10" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export {RefreshIcon}