import { NumberContent } from "./NumberContent";
import { PhoneConfirmationContent } from "./PhoneConfirmationContent";
import { NameContent } from "./NameContent";
import { EmailContent } from "./EmailContent";
import { EmailConfirmationContent } from "./EmailConfirmarionContent";
import { FinishAuthContent } from "./FinishAuthContent";

const getAuthModalData = (step) => {
  switch (step) {
    case 0: {
      return {
        header: "Войти",
        content: NumberContent,
        checkFunc: async ({
          phone,
          setIsErrorAuth,
          goToPrevStep,
          setPhoneFlag,
          retryPhoneSeconds,
          setPhoneSeconds,
          setAuthType,
        }) => {
          if (phone && phone.length === 13 && retryPhoneSeconds === 0) {
            const resp = fetch(
              `https://back.priceninja.ru/api/auth/check-phone-number?phone_number=%2B7${phone
                .split(" ")
                .join("")}`,
              {
                method: "POST",
              }
            );
            setPhoneFlag(true);
            resp
              .then((response) => {
                if (response.status !== 200) {
                  setIsErrorAuth(true);
                  goToPrevStep();
                  setPhoneFlag(false);
                  setPhoneSeconds(0);
                }
                return response;
              })
              .then((resp) => resp.json())
              .then((data) => {
                const { strategy } = data;
                if (strategy) {
                  setAuthType(() => strategy);
                }
              });
          }
          return phone && phone.length === 13;
        },
      };
    }
    case 1: {
      return {
        header: "Подтвердите вход",
        content: PhoneConfirmationContent,
        checkFunc: async ({ phone, phoneCode, authType, setAuthStep }) => {
          if (!phone || !phoneCode || phoneCode.length !== 5) {
            return false;
          }
          const resp = await fetch(
            `https://back.priceninja.ru/api/auth/check-phone-number-code?phone_number=%2B7${phone
              .split(" ")
              .join("")}&code=${phoneCode.split(" ").join("")}`,
            {
              method: "POST",
            }
          );
          if (resp.status === 200) {
            if (authType !== "registration") {
              setAuthStep(() => 4);
              // const authResp = await fetch(`https://back.priceninja.ru/api/auth/login?phone_number=%2B7${phone.split(' ').join('')}&code=${phoneCode.split(' ').join('')}`, {
              //     method: 'POST'
              // });
              // if (authResp.status === 200) {
              //     const {token} = await authResp.json();
              //     window.location.href = `https://test.priceninja.dev?token=${token}`
              // }
            }
            return true;
          }
          return false;
        },
      };
    }
    case 2: {
      return {
        header: "Представьтесь",
        content: NameContent,
        checkFunc: ({ name }) => name && name.length !== 0,
      };
    }
    case 3: {
      return {
        header: "Последний шаг",
        content: EmailContent,
        checkFunc: async ({
          email,
          setIsErrorAuth,
          goToPrevStep /*, retryEmailSeconds, setEmailFlag, setEmailSeconds*/,
        }) => {
          if (!email) {
            return false;
          }
          // if (retryEmailSeconds === 0) {
          const resultEmail = email.replaceAll("@", "%40");
          const resp = fetch(
            `https://back.priceninja.ru/api/auth/send-email?email=${resultEmail}`,
            {
              method: "POST",
            }
          );
          // setEmailFlag(true);
          resp.then((response) => {
            if (response.status !== 200) {
              setIsErrorAuth(true);
              goToPrevStep();
              // setEmailFlag(false);
              // setEmailSeconds(0);
            }
          });
          return true;
          // }
        },
      };
    }
    case 4: {
      return {
        header: "Подтвердите почту",
        content: EmailConfirmationContent,
        checkFunc: async ({ email, emailCode, phone, name }) => {
          if (!email || !emailCode || emailCode.length !== 5) {
            return false;
          }
          const resultEmail = email.replaceAll("@", "%40");
          const respCode = await fetch(
            `https://back.priceninja.ru/api/auth/check-email-code?email=${resultEmail}&code=${emailCode
              .split(" ")
              .join("")}`,
            {
              method: "POST",
            }
          );
          if (respCode.status === 200) {
            const respReg = await fetch(
              `https://back.priceninja.ru/api/auth/registration?email=${resultEmail}&name=${name}&phone_number=%2B7${phone
                .split(" ")
                .join("")}`,
              {
                method: "POST",
              }
            );
            return respReg.status === 200;
          }
          return false;
        },
      };
    }
    default: {
      return {
        header: "Спасибо за регистрацию",
        content: FinishAuthContent,
        checkFunc: ({ handleClose }) => {
          handleClose();
          return true;
        },
      };
    }
  }
};

export { getAuthModalData };
