import {
    DiagramScreenshot,
    SettingsScreenshot,
    PricesViewScreenshot,
    OnlineOfflineScreenshot,
    OnlineOfflineMobileScreenshot, DiagramMobileIcon, ComfortViewIcon, SettingsMobileIcon
} from "../screenshots";
import React from 'react';
const PricesControlSection = () => {
    return (
        <div className="z-20 bg-[#02070D] relative">
            <div className="pt-[60px] lg:pt-[150px] px-5 lg:px-10 flex flex-col">
                <div className="flex flex-col lg:items-center pb-[50px] lg:pb-[100px]">
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        Прайс Ниндзя
                    </span>
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        разработан специально
                    </span>
                    <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                        для контроля цен<br className="hidden lg:block"/> на <br className="lg:hidden"/>маркетплейсах
                    </span>
                </div>
                <div className="hidden lg:flex space-x-5 self-center">
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px]">
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                Учитываем особенности
                            </span>
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                маркетплейсов
                            </span>
                            <span className="block mt-10 text-lg text-[#919BA0] font-normal">
                                Фиксируем скидки по картам Ozon и ЯPay.
                            </span>
                            <span className="block text-lg text-[#919BA0] mb-20 font-normal">
                                Учитываем промокоды и кешбеки
                            </span>
                            <DiagramScreenshot />
                        </div>
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px] flex-grow justify-between">
                            <div className={"flex flex-col items-center"}>
                                <span className="block text-2xl leading-[34px] text-white  font-medium">
                                    Настраивайте данные
                                </span>
                                <span className="block text-2xl leading-[34px] text-white  font-medium">
                                    в нужном разрезе
                                </span>
                                <span className="block mt-10 text-lg text-[#919BA0] font-normal">
                                    Настройте фильтры и сравнивайте цены
                                </span>
                                <span className="block text-lg text-[#919BA0] font-normal">
                                    с учетом 9 различных параметров,
                                </span>
                                <span className="block text-lg text-[#919BA0] font-normal">
                                    включая способ оплаты, сроки доставки,
                                </span>
                                <span className="block text-lg text-[#919BA0] font-normal">
                                    и склад отгрузки
                                </span>
                            </div>
                            <div>
                                <SettingsScreenshot />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px]">
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                Удобное
                            </span>
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                отображение данных
                            </span>
                            <span className="block mt-10 text-lg text-[#919BA0] font-normal">
                                Сравним цены с конкурентами и
                            </span>
                            <span className="block text-lg text-[#919BA0] font-normal">
                                подсветим лучшие предложения.
                            </span>
                            <span className="block text-lg text-[#919BA0] font-normal">
                                Смотрите динамику цен и наличие
                            </span>
                            <span className="block text-lg text-[#919BA0] font-normal">
                                товаров на наглядных графиках,
                            </span>
                            <span className="block text-lg text-[#919BA0] mb-20 font-normal">
                                таблицах и дашбордах.
                            </span>
                            <PricesViewScreenshot />
                        </div>
                        <div className="flex flex-col items-center p-20 rounded-[60px] bg-[#1B2126] w-[589px]">
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                Изучайте данные
                            </span>
                            <span className="block text-2xl leading-[34px] text-white  font-medium">
                                онлайн или в офлайн
                            </span>
                            <span className="block mt-10 text-lg text-[#919BA0] font-normal">
                                Следите за онлайн-ценами на ноутбуке
                            </span>
                            <span className="block text-lg text-[#919BA0] font-normal">
                                или мобильном и легко сохраняйте
                            </span>
                            <span className="block text-lg text-[#919BA0] mb-20 font-normal">
                                данные в Excel или CSV.
                            </span>
                            <OnlineOfflineScreenshot />
                        </div>
                    </div>
                </div>
                <div className="lg:hidden flex flex-col space-y-2">
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Учитываем <br />особенности<br /> маркетплейсов
                            </div>
                            <div>
                                <DiagramMobileIcon />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Следите за онлайн-ценами на ноутбуке или мобильном и легко сохраняйте данные в Excel или CSV.
                        </div>
                    </div>
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Удобное <br />отображение<br /> данных
                            </div>
                            <div>
                                <ComfortViewIcon />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Сравним цены с конкурентами и подсветим лучшие предложения. Смотрите динамику цен и наличие товаров на наглядных графиках, таблицах и дашбордах.
                        </div>
                    </div>
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Настраивайте <br />данные в нужном<br />разрезе
                            </div>
                            <div>
                                <SettingsMobileIcon />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Настройте фильтры и сравнивайте цены с учетом 9 различных параметров, включая способ оплаты, сроки доставки, и склад отгрузки.
                        </div>
                    </div>
                    <div className="py-5 px-6 rounded-[20px] bg-[#1B2126] w-full space-y-5">
                        <div className="flex items-center">
                            <div className={"flex grow text-[14px] leading-[19px] text-[#FBFBFC] font-medium"}>
                                Изучайте данные <br />онлайн или в <br />офлайн
                            </div>
                            <div>
                                <OnlineOfflineMobileScreenshot />
                            </div>
                        </div>
                        <div className="text-[#919BA0] text-xs leading-[18px] font-normal pr-[80px]">
                            Следите за онлайн-ценами на ноутбуке или мобильном и легко сохраняйте данные в Excel или CSV.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {PricesControlSection};