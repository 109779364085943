import React from 'react';
const ResultsIcon = () => {
    return (
        <svg width="293" height="238" viewBox="0 0 293 238" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_536_40155)">
                <rect x="20.75" y="72.5177" width="89" height="84" rx="12" fill="#E1AD00" shapeRendering="crispEdges"/>
                <path d="M35.3281 98.9369H61.1029M61.1029 97.0038V107.314C61.1029 109.449 59.372 111.18 57.2367 111.18H39.1943C37.0591 111.18 35.3281 109.449 35.3281 107.314V94.4263C35.3281 92.291 37.0591 90.5601 39.1943 90.5601H42.8008C43.3861 90.5601 43.9539 90.7592 44.4109 91.1249L46.2208 92.5727C46.6778 92.9383 47.2456 93.1375 47.8309 93.1375H57.2367C59.372 93.1375 61.1029 94.8685 61.1029 97.0038Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </g>
            <g filter="url(#filter1_d_536_40155)">
                <rect x="49.75" y="52.5177" width="97" height="124" rx="12" fill="#2DA5E1" shapeRendering="crispEdges"/>
                <path d="M88.4164 69.2713L66.5078 77.0038L76.1734 81.5143M88.4164 69.2713L80.684 92.4686L76.1734 81.5143M88.4164 69.2713L76.1734 81.5143" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <g filter="url(#filter2_d_536_40155)">
                <rect x="86.75" y="36.0177" width="96" height="157" rx="12" fill="#9747FF" shapeRendering="crispEdges"/>
                <path d="M127.248 56.1089L115.962 66.1864C115.474 66.6204 114.738 66.6204 114.25 66.1864L102.969 56.0989M111.706 63.9043L103.077 72.7443M127.241 72.6385L118.504 63.9018M104.796 73.3911H125.416C126.84 73.3911 127.994 72.2372 127.994 70.8137V57.9263C127.994 56.5028 126.84 55.3488 125.416 55.3488H104.796C103.373 55.3488 102.219 56.5028 102.219 57.9263V70.8137C102.219 72.2372 103.373 73.3911 104.796 73.3911Z" stroke="white" strokeWidth="2"/>
            </g>
            <g filter="url(#filter3_d_536_40155)">
                <rect x="122.75" y="16.0177" width="150" height="197" rx="12" fill="#9DCF4E" shapeRendering="crispEdges"/>
                <path d="M140.953 40.9569H169.876M140.953 49.7797H169.876M144.503 58.5167H166.326C168.286 58.5167 169.876 56.9275 169.876 54.9671V35.7731C169.876 33.8127 168.286 32.2235 166.326 32.2235H144.503C142.542 32.2235 140.953 33.8127 140.953 35.7731V54.9671C140.953 56.9275 142.542 58.5167 144.503 58.5167Z" stroke="white" strokeWidth="2"/>
                <circle cx="145.594" cy="36.6353" r="1.08664" fill="white"/>
                <circle cx="145.594" cy="45.3702" r="1.08664" fill="white"/>
                <circle cx="145.594" cy="54.1058" r="1.08664" fill="white"/>
                <circle cx="149.04" cy="36.6353" r="1.08664" fill="white"/>
                <circle cx="149.04" cy="45.3702" r="1.08664" fill="white"/>
                <circle cx="149.04" cy="54.1057" r="1.08664" fill="white"/>
                <circle cx="152.493" cy="36.6353" r="1.08664" fill="white"/>
                <circle cx="152.493" cy="45.3702" r="1.08664" fill="white"/>
                <circle cx="152.493" cy="54.1058" r="1.08664" fill="white"/>
                <path d="M197.739 101.301V166.816M197.739 166.816L224.766 139.518M197.739 166.816L170.711 139.518M224.766 183.194H170.711" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <filter id="filter0_d_536_40155" x="0.75" y="56.5177" width="129" height="124" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="10"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.796457 0 0 0 0 0.0670949 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_536_40155"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_536_40155" result="shape"/>
                </filter>
                <filter id="filter1_d_536_40155" x="29.75" y="36.5177" width="137" height="164" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="10"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.647059 0 0 0 0 0.882353 0 0 0 0.8 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_536_40155"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_536_40155" result="shape"/>
                </filter>
                <filter id="filter2_d_536_40155" x="66.75" y="20.0177" width="136" height="197" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="10"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.592157 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 0.8 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_536_40155"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_536_40155" result="shape"/>
                </filter>
                <filter id="filter3_d_536_40155" x="102.75" y="0.0177002" width="190" height="237" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="10"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.498039 0 0 0 0 0.694118 0 0 0 0 0.188235 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_536_40155"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_536_40155" result="shape"/>
                </filter>
            </defs>
        </svg>

    )
}

const ResultsMobileIcon = () => {
    return (
        <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_567_36613)">
                <rect x="12.1641" y="40.2877" width="26" height="26" rx="8" fill="#E1AD00" shapeRendering="crispEdges"/>
                <path d="M17.6875 52.2934H32.9364M32.9364 51.1497V57.2493C32.9364 58.5125 31.9123 59.5366 30.649 59.5366H19.9748C18.7116 59.5366 17.6875 58.5126 17.6875 57.2493V49.6249C17.6875 48.3616 18.7116 47.3375 19.9748 47.3375H22.1085C22.4547 47.3375 22.7907 47.4554 23.0611 47.6717L24.1318 48.5283C24.4022 48.7446 24.7381 48.8624 25.0844 48.8624H30.649C31.9123 48.8624 32.9364 49.8865 32.9364 51.1497Z" stroke="white" strokeWidth="1.18324" strokeLinecap="round"/>
            </g>
            <g filter="url(#filter1_d_567_36613)">
                <rect x="12.1641" y="10.2877" width="26" height="26" rx="8" fill="#9747FF" shapeRendering="crispEdges"/>
                <path d="M32.4956 18.5496L25.8185 24.5116C25.5296 24.7684 25.0943 24.7684 24.8054 24.5116L18.1313 18.5437M23.3001 23.1615L18.1953 28.3914M32.4911 28.3289L27.3223 23.16M19.2124 28.7741H31.4115C32.2537 28.7741 32.9364 28.0914 32.9364 27.2492V19.6248C32.9364 18.7826 32.2537 18.0999 31.4115 18.0999H19.2124C18.3702 18.0999 17.6875 18.7826 17.6875 19.6248V27.2492C17.6875 28.0914 18.3702 28.7741 19.2124 28.7741Z" stroke="white" strokeWidth="1.18324"/>
            </g>
            <g filter="url(#filter2_d_567_36613)">
                <rect x="42.1641" y="40.2877" width="26" height="26" rx="8" fill="#2DA5E1" shapeRendering="crispEdges"/>
                <path d="M61.4147 46.5751L48.4531 51.1497L54.1715 53.8183M61.4147 46.5751L56.84 60.2991L54.1715 53.8183M61.4147 46.5751L54.1715 53.8183" stroke="white" strokeWidth="1.18324" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <g filter="url(#filter3_d_567_36613)">
                <rect x="42.1641" y="10.2877" width="26" height="26" rx="8" fill="#9DCF4E" shapeRendering="crispEdges"/>
                <path d="M47.4297 20.9277H62.8967M47.4297 25.6459H62.8967M49.3279 30.3182H60.9985C62.0468 30.3182 62.8967 29.4683 62.8967 28.42V18.1555C62.8967 17.1071 62.0468 16.2573 60.9985 16.2573H49.3279C48.2796 16.2573 47.4297 17.1071 47.4297 18.1555V28.42C47.4297 29.4683 48.2796 30.3182 49.3279 30.3182Z" stroke="white" strokeWidth="1.06955"/>
            </g>
            <defs>
                <filter id="filter0_d_567_36613" x="0.331671" y="30.8218" width="49.6648" height="49.6648" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2.36648"/>
                    <feGaussianBlur stdDeviation="5.9162"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.796457 0 0 0 0 0.0670949 0 0 0 0.4 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_567_36613"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_567_36613" result="shape"/>
                </filter>
                <filter id="filter1_d_567_36613" x="0.331671" y="0.821806" width="49.6648" height="49.6648" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2.36648"/>
                    <feGaussianBlur stdDeviation="5.9162"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.592157 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 0.8 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_567_36613"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_567_36613" result="shape"/>
                </filter>
                <filter id="filter2_d_567_36613" x="30.3317" y="30.8218" width="49.6648" height="49.6648" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2.36648"/>
                    <feGaussianBlur stdDeviation="5.9162"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.647059 0 0 0 0 0.882353 0 0 0 0.8 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_567_36613"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_567_36613" result="shape"/>
                </filter>
                <filter id="filter3_d_567_36613" x="30.3317" y="0.821806" width="49.6648" height="49.6648" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2.36648"/>
                    <feGaussianBlur stdDeviation="5.9162"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.498039 0 0 0 0 0.694118 0 0 0 0 0.188235 0 0 0 0.6 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_567_36613"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_567_36613" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export {ResultsIcon, ResultsMobileIcon};