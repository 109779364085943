import React from 'react';
const DiagramScreenshot = ({width = "422", height = "217"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 422 217" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.2168 214.785C267.251 214.785 257.868 92.4284 417.52 92.4284" stroke="#B531A4" strokeWidth="2.79176" strokeLinecap="round"/>
            <path d="M417.986 134.77C229.909 134.77 226.211 29.5895 159.079 29.5895C91.9471 29.5895 107.988 88.4041 2.0141 88.4041" stroke="#1B76FF" strokeWidth="2.79176" strokeLinecap="round"/>
            <path d="M417.986 64.4077C272.907 64.4077 287.562 22.8135 219.252 22.8135C139.249 22.8135 112.057 160.117 3.4108 160.117" stroke="#E0BC3B" strokeWidth="2.79176" strokeLinecap="round"/>
            <path d="M417.986 162.189C326.789 162.189 287.219 58.0911 210 58.0911C132.781 58.0911 108.566 126.274 3.40997 126.274" stroke="#613CAA" strokeWidth="2.79176" strokeLinecap="round"/>
            <rect x="101" y="129.409" width="80.961" height="51.1822" rx="16" fill="#B531A4"/>
            <path d="M145.658 146.648L140.472 162.5H136.532L133.044 151.766L129.444 162.5H125.526L120.317 146.648H124.122L127.7 157.79L131.436 146.648H134.833L138.457 157.88L142.148 146.648H145.658ZM159.346 154.257C160.252 154.544 160.962 155.027 161.475 155.706C161.988 156.371 162.245 157.193 162.245 158.175C162.245 159.564 161.701 160.636 160.614 161.39C159.542 162.13 157.972 162.5 155.904 162.5H147.706V146.648H155.451C157.384 146.648 158.863 147.018 159.89 147.758C160.931 148.497 161.452 149.501 161.452 150.769C161.452 151.539 161.264 152.226 160.886 152.83C160.524 153.434 160.01 153.91 159.346 154.257ZM151.352 149.411V153.147H154.998C155.904 153.147 156.591 152.989 157.059 152.672C157.527 152.355 157.761 151.887 157.761 151.268C157.761 150.649 157.527 150.188 157.059 149.886C156.591 149.569 155.904 149.411 154.998 149.411H151.352ZM155.632 159.737C156.598 159.737 157.323 159.579 157.806 159.262C158.304 158.945 158.554 158.454 158.554 157.79C158.554 156.476 157.58 155.819 155.632 155.819H151.352V159.737H155.632Z" fill="white"/>
            <rect x="272" y="145.909" width="80.961" height="51.1822" rx="16" fill="#613CAA"/>
            <path d="M307.156 179L307.134 169.489L302.469 177.324H300.816L296.173 169.693V179H292.731V163.148H295.766L301.699 172.999L307.541 163.148H310.553L310.599 179H307.156ZM328.785 179L328.763 169.489L324.097 177.324H322.444L317.802 169.693V179H314.36V163.148H317.394L323.327 172.999L329.17 163.148H332.182L332.227 179H328.785Z" fill="white"/>
            <g filter="url(#filter0_d_730_18753)">
                <rect x="343.539" width="74.4469" height="51.1822" rx="16" fill="#E0BC3B" shapeRendering="crispEdges"/>
                <path d="M371.862 27.4749V33.0911H368.194V27.4296L362.057 17.2389H365.952L370.187 24.2818L374.421 17.2389H378.022L371.862 27.4749ZM393.828 33.0911L393.805 23.5798L389.14 31.4153H387.487L382.844 23.7836V33.0911H379.402V17.2389H382.437L388.37 27.0899L394.213 17.2389H397.225L397.27 33.0911H393.828Z" fill="#1E1E1E"/>
            </g>
            <rect x="2.0141" y="16.5" width="71.6551" height="51.1822" rx="16" fill="#1B76FF"/>
            <path d="M30.3253 49.8629C28.6797 49.8629 27.1926 49.5081 25.8641 48.7985C24.5506 48.0889 23.5164 47.1152 22.7616 45.8772C22.0218 44.6241 21.6519 43.2201 21.6519 41.665C21.6519 40.11 22.0218 38.7135 22.7616 37.4755C23.5164 36.2224 24.5506 35.2411 25.8641 34.5315C27.1926 33.822 28.6797 33.4672 30.3253 33.4672C31.9709 33.4672 33.4505 33.822 34.7639 34.5315C36.0774 35.2411 37.1116 36.2224 37.8664 37.4755C38.6213 38.7135 38.9987 40.11 38.9987 41.665C38.9987 43.2201 38.6213 44.6241 37.8664 45.8772C37.1116 47.1152 36.0774 48.0889 34.7639 48.7985C33.4505 49.5081 31.9709 49.8629 30.3253 49.8629ZM30.3253 46.7377C31.2614 46.7377 32.1068 46.5264 32.8617 46.1036C33.6165 45.6658 34.2053 45.0619 34.6281 44.292C35.0659 43.522 35.2848 42.6464 35.2848 41.665C35.2848 40.6837 35.0659 39.8081 34.6281 39.0381C34.2053 38.2681 33.6165 37.6718 32.8617 37.2491C32.1068 36.8112 31.2614 36.5923 30.3253 36.5923C29.3893 36.5923 28.5438 36.8112 27.789 37.2491C27.0341 37.6718 26.4378 38.2681 25.9999 39.0381C25.5772 39.8081 25.3659 40.6837 25.3659 41.665C25.3659 42.6464 25.5772 43.522 25.9999 44.292C26.4378 45.0619 27.0341 45.6658 27.789 46.1036C28.5438 46.5264 29.3893 46.7377 30.3253 46.7377ZM54.4414 46.6019V49.5911H40.582V47.2133L49.2781 36.7282H40.7632V33.7389H54.1017V36.1168L45.4283 46.6019H54.4414Z" fill="white"/>
            <defs>
                <filter id="filter0_d_730_18753" x="339.539" y="0" width="82.4469" height="59.1822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_730_18753"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_730_18753" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export {DiagramScreenshot}