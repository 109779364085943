import {DocumentIcon} from "../../screenshots";
import cn from "classnames";
import React from 'react';
const NameContent = ({ name, setName, isErrorAuth, setIsErrorAuth }) => {
    const handleChange = (e) => {
        if (isErrorAuth) {
            setIsErrorAuth(false)
        }
        setName(e.target.value)
    }
    return (
        <div className="p-5 lg:px-10 lg:py-[30px] lg:pb-4 flex flex-col justify-between h-full">
            <div>
                <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-4">
                    Как к вам<br />обращаться?
                </span>
                <div className="relative">
                    <input value={name} onChange={handleChange} autoFocus={true}
                           className={cn("focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] border border-[#CFD0D4] w-full", {
                               'border-[#FE2D2D] focus:border-[#FE2D2D]': isErrorAuth,
                           })}
                           placeholder={'Введите имя'}/>
                </div>
            </div>
            <div className="bg-[#F4F4F4] rounded-[12px] py-4 px-4 lg:px-5 flex items-center mt-5 lg:mt-0">
                <DocumentIcon />
                <span className="block ml-3 font-medium text-[#989BA5] text-xs leading-[17px]">
                    Нажимая “Продолжить” вы принимаете <br className="hidden lg:block"/>условия <span className="inline-block ml-1 underline cursor-pointer">публичной оферты</span>
                </span>
            </div>
        </div>
    )
}

export {NameContent};