import {useRef, useState} from "react";
import {CrossIcon, PlayIcon} from "../screenshots";
import ReactPlayer from 'react-player';

const ShowVideoModal = (props) => {
    const {setShowVideo} = props;
    const playerRef = useRef(null);
    const [videoMode, setVideoMode] = useState(false)

    return (
        <div className={"flex flex-col fixed bg-[rgb(15,22,29,0.90)] w-screen h-screen left-0 z-40 items-center justify-center overflow-hidden top-0"} /*style={{top: window && window.scrollY}}*/>
            <div className="fixed bottom-0 lg:relative w-full lg:w-[815px]">
                <div className="rounded-[12px]">
                    <div className={"flex justify-between px-8 py-6 bg-white items-center rounded-t-[12px]"}>
                        <span className="block text-[#0E141A] text-2xl leading-[34px] font-medium">
                            Видео о сервисе
                        </span>
                        <button onClick={() => {
                            setShowVideo(false);
                            document.querySelector('body').style.overflow = '';
                            document.querySelector('body').classList.remove('disabled-scroll');
                            document.querySelector('html').style.overflow = '';
                            setVideoMode(false)
                        }}>
                            <CrossIcon />
                        </button>
                    </div>
                </div>
                {
                    videoMode ? <ReactPlayer ref={playerRef} url={'https://youtu.be/0BIaDVnYp2A'} controls={true} className={"react-player"}/>:
                        <div className="relative min-w-[815px] min-h-[416px] rounded-b-[12px]" style={{background: 'radial-gradient(50% 50% at 50% 50%, rgba(74, 76, 166, 0.82) 0%, rgba(40, 40, 185, 0.82) 100%)'}}>
                            {/*<img alt={'demoImg'} src={VideoModalImage} className="rounded-b-[12px]" />*/}
                            <div className="absolute mx-auto top-4 left-24 lg:left-[349px] lg:top-[150px] cursor-pointer" onClick={() => setVideoMode(true)}>
                                <PlayIcon />
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
export {ShowVideoModal}