import cn from 'classnames';
import {useState} from "react";
import React from 'react';
const PrimaryButton = (props) => {
    const [isHover, setIsHover] = useState(false)
    const { className, style, hoverStyle, onClick, noUseHover } = props;
    let resultStyle = {...style};
    if (hoverStyle && isHover) {
        resultStyle = {...resultStyle, ...hoverStyle}
    }
    return (
        <button className={cn('bg-[#0A25FF] hover:duration-500 rounded-[100px]',{
            [className]: className,
            'lg:hover:bg-[#0A25FF]/80': !noUseHover,
        })} style={resultStyle} onClick={onClick}
            onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            {props.children}
        </button>
    )
}

export {PrimaryButton}