import React from 'react';
const HighLights = () => {
    return (
        <div className="hidden lg:block">
            <div className={'absolute bg-[#005080] rounded-[2787px] -left-[2088px] top-[604px] w-[2787px] h-[1465px] z-10'} style={{filter: 'blur(167px)'}}/>
            <div className={'absolute bg-[#04FFB1] rounded-[1000px] -left-[47px] top-[1188px] w-[998px] h-[740px]'} style={{filter: 'blur(167px)', zIndex: 11}}/>
            <div className={'absolute bg-[#0035FF] rounded-[1715px] -rotate-6 -right-[1000px] top-[819px] w-[1715px] h-[1006px] z-10'} style={{filter: 'blur(223px)'}}/>
            <div className={'absolute bg-[#0019FF] rounded-[1294px] rotate-6 -right-[47px] top-[977px] w-[1294px] h-[794px] z-10'} style={{filter: 'blur(167px)'}}/>
            <div className={'absolute bg-[#1AC7FF] rounded-[829px] rotate-12 -right-[627px] top-[170px] w-[627px] h-[829px] z-10'} style={{filter: 'blur(112px)'}}/>
            <div className={'absolute bg-[#1AC7FF] rounded-[722px] rotate-12 -right-[507px] top-[604px] w-[648px] h-[723px] z-10'} style={{filter: 'blur(112px)'}}/>
            <div className={'absolute bg-[#FF94C8] rounded-[1012px] rotate-12 -right-[47px] top-[1094px] w-[1012px] h-[625px] z-10'} style={{filter: 'blur(112px)'}}/>
        </div>
    )
}

const HighLightsParsing = () => {
    return (
        <div className="hidden lg:block">
            <div className={'absolute bg-[#FCA541] rounded-[441px] left-[441px] top-[869px] w-[1032px] h-[304px] z-10'} style={{filter: 'blur(211px)'}}/>
            <div className={'absolute bg-[#0F42EB] -left-[800px] top-[789px] w-[1611px] h-[709px] z-10 rotate-[40deg]'} style={{filter: 'blur(233px)'}}/>
            <div className={'absolute bg-[#DDED24] -right-[427px] top-[1369px] w-[2449px] h-[754px] z-10 -rotate-[40deg]'} style={{filter: 'blur(167px)'}}/>
            <div className={'absolute bg-[#0A9ECD] -right-[427px] top-[1169px] w-[2449px] h-[754px] z-10 -rotate-[17deg]'} style={{filter: 'blur(167px)'}}/>
            <div className={'absolute bg-[#00F3F9] rounded-[3692px] -left-[1305px] top-[1169px] w-[3692px] h-[992px] z-10 -rotate-[2deg]'} style={{filter: 'blur(233px)'}}/>
        </div>
    )
}

export { HighLights, HighLightsParsing }