import React from 'react';
const LinkIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M5.5027 4.27368H11.8008M11.8008 4.27368V10.5718M11.8008 4.27368L4.30078 11.7737" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export {LinkIcon}