import React from 'react';
const HowWorksSection = () => {
    return (
        <div className="px-10 bg-[#02070D]">
            <div className="border-b border-[#4E5156] flex flex-col items-center grow py-[55px]">
                <span className="text-[#FBFBFC] text-[60px] leading-[90px] font-medium -tracking-[2px] lg:-tracking-[3px]">
                    Как работает <span className="text-[#3E4349]">Прайс Ниндзя</span>
                </span>
                <div className="flex relative mt-[60px]">
                    <div className="flex flex-col items-center mr-[105px] z-10">
                        <div className="flex w-[60px] h-[60px] rounded-full bg-[#0A25FF] items-center justify-center"
                             style={{filter: 'drop-shadow(0px 4px 20px #0A25FF)'}}>
                            <span className="block text-[18px] text-[#FBFBFC] leading-[18px] font-semibold">1</span>
                        </div>
                        <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[20px] mt-5 text-center">
                            Вставляете ссылки<br/>на свои товары из любых<br/>маркетплейсов
                        </span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="258" height="61" viewBox="0 0 258 61" fill="none"
                         className="absolute -top-[25px] left-[135px]">
                        <path
                            d="M1.16016 59.7859C26.3301 59.7859 106.66 27.2009 107.16 8.65092C107.66 -9.8991 68.2012 8.83144 82.3419 27.2009C123.077 80.1184 236.302 -0.683231 256.856 45.8282"
                            stroke="white" strokeOpacity="0.3" strokeLinecap="round" strokeDasharray="6 6"/>
                    </svg>
                    <div className="flex flex-col items-center mr-[90px] z-10">
                        <div className="flex w-[60px] h-[60px] rounded-full bg-[#0A25FF] items-center justify-center"
                             style={{filter: 'drop-shadow(0px 4px 20px #0A25FF)'}}>
                            <span className="block text-[18px] text-[#FBFBFC] leading-[18px] font-semibold">2</span>
                        </div>
                        <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[20px] mt-5 text-center">
                            Добавляйте ссылки на<br/>товары конкурентов
                        </span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="261" height="39" viewBox="0 0 261 39" fill="none" className="absolute left-[443px] top-[10px]">
                        <path
                            d="M1.37109 26.2355C39.2792 26.2355 70.7287 46.0993 109.653 34.1464C153.471 20.6906 193.994 -9.08068 242.083 4.70192C248.707 6.6005 254.473 7.61681 260.175 11.7019"
                            stroke="white" strokeOpacity="0.3" strokeLinecap="round" strokeDasharray="6 6"/>
                    </svg>
                    <div className="flex flex-col items-center z-10">
                        <div className="flex w-[60px] h-[60px] rounded-full bg-[#0A25FF] items-center justify-center"
                             style={{filter: 'drop-shadow(0px 4px 20px #0A25FF)'}}>
                            <span className="block text-[18px] text-[#FBFBFC] leading-[18px] font-semibold">👍</span>
                        </div>
                        <span className="block text-[#FBFBFC] font-medium text-[16px] leading-[20px] mt-5 text-center">
                            “Прайс Ниндзя” сравнит цены<br/>и покажет, у кого лучшая цена<br/>и как они изменяются каждый<br/>день
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {HowWorksSection};