import { EmptyArchiveIcon } from "../../screenshots";
import cn from "classnames";
import React from "react";
const EmailConfirmationContent = ({
  phone,
  name,
  email,
  emailCode,
  setEmailCode,
  isErrorAuth,
  setIsErrorAuth,
  handleClickNext,
  phoneCode,
  setAuthStep,
}) => {
  const handleConfirmChange = (e) => {
    if (isErrorAuth) {
      setIsErrorAuth(false);
    }
    let content = e.target.value;
    content = Array.from(content).filter(
      (ltr) => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58
    );
    let [first, second] = [
      content.slice(0, 2).join(""),
      content.slice(2, 4).join(""),
    ];
    e.target.value = "";
    if (first.length) e.target.value += `${first}`;
    if (second.length) e.target.value += ` ${second}`;
    setEmailCode(e.target.value);
    if (e.target.value.length === 5) {
      handleClickNext({ email, emailCode: e.target.value, phone, name });
    }
  };
  return (
    <div className="px-10 py-[30px]">
      <span className="block text-[#0E141A] font-medium text-base leading-[22px]">
        {`На адрес ${email} поступит письмо.`}
      </span>
      <span className="block text-[#0E141A] font-medium text-base leading-[22px] mb-4">
        Введите код из письма
      </span>
      <div className="relative">
        <input
          value={emailCode}
          onChange={handleConfirmChange}
          autoFocus={true}
          inputMode="numeric"
          className={cn(
            "focus:border-[#7F8084] outline-0 cursor-pointer rounded-[12px] p-4 lg:p-5 block text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] text-[#0E141A] font-normal placeholder:text-[#CACDD7] border border-[#CFD0D4] w-[94px] lg:min-w-[114px] lg:w-[114px]",
            {
              "border-[#FE2D2D] focus:border-[#FE2D2D]": isErrorAuth,
            }
          )}
          placeholder={"00 00"}
        />
      </div>
      {/*{*/}
      {/*    retryEmailSeconds === 0 ?*/}
      {/*        <button className="flex bg-none border-none items-center mt-5" onClick={async () => {*/}
      {/*            const resultEmail = email.replaceAll('@', '%40')*/}
      {/*            const resp = await fetch(`https://back.priceninja.ru/api/auth/send-email?email=${resultEmail}`, {*/}
      {/*                method: 'POST'*/}
      {/*            })*/}
      {/*            if (resp.status !== 200) {*/}
      {/*                setIsErrorAuth(true);*/}
      {/*                goToPrevStep();*/}
      {/*            }*/}
      {/*        }}>*/}
      {/*            <RefreshIcon />*/}
      {/*            <span className="block text-[#0A25FF] text-base leading-[22px] font-medium ml-2 mt-[1px]">*/}
      {/*                Повторить письмо*/}
      {/*            </span>*/}
      {/*        </button>*/}
      <div className="mt-5">
        {/*<div className={"flex space-x-2 text-[#8E9399]"}>*/}
        {/*    <RefreshIcon color={"#8E9399"}/>*/}
        {/*    <span className="block text-base leading-[22px] font-medium ml-2 mt-[1px] cursor-pointer">*/}
        {/*        {`Повторить письмо можно через ${retryEmailSeconds} сек`}*/}
        {/*    </span>*/}
        {/*</div>*/}
        <button
          className="flex bg-none border-none items-center"
          onClick={async () => {
            setAuthStep && setAuthStep(() => 5);
            // const authResp = await fetch(`https://back.priceninja.ru/api/auth/login?phone_number=%2B7${phone.split(' ').join('')}&code=${phoneCode.split(' ').join('')}`, {
            //     method: 'POST'
            // });
            // if (authResp.status === 200) {
            //     const {token} = await authResp.json();
            //     window.location.href = `https://test.priceninja.dev?token=${token}`
            // }
          }}
        >
          <EmptyArchiveIcon />
          <span className="block text-[#0A25FF] text-base leading-[22px] font-medium ml-2 mt-[1px] cursor-pointer">
            Код не пришел
          </span>
        </button>
      </div>

      {/*}*/}
    </div>
  );
};

export { EmailConfirmationContent };
