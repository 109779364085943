import {FormatIcon, FormatMobileIcon} from "../screenshots/FormatIcon";
import {GarantsIcon, LikeIcon, ResultsIcon, ResultsMobileIcon, SecurityIcon, ValuesIcon} from "../screenshots";
import {PrimaryButton} from "../components";
import React from 'react';
const ParsingBenefitsSection = ({setShowConnectUsModal}) => {
    return (
        <div className="bg-[#02070D] px-5 lg:px-0">
            <div className="pt-[100px] lg:pt-[150px] flex flex-col lg:items-center">
                <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#3E4349] font-medium -tracking-[2px] lg:-tracking-[3px]">
                   Парсинг — <br className={"lg:hidden"}/>это просто
                </span>
                <span className="block text-[36px] leading-[40px] lg:text-[95px] lg:leading-[100px] text-[#FBFBFC] font-medium -tracking-[2px] lg:-tracking-[3px]">
                   и очень удобно
                </span>
                <div className="lg:w-[1200px] flex flex-col space-y-2 lg:space-y-5 my-10 lg:my-[100px]">
                    <div className="hidden lg:flex space-x-5 h-[715px]">
                        <div className="bg-[#1B2126] pt-[120px] pb-20 px-20 rounded-[60px] grow flex flex-col items-center justify-between">
                            <ResultsIcon />
                            <div className="flex flex-col items-center">
                                <span className="block text-white text-[24px] leading-[34px] font-medium text-center mb-10">
                                    Удобно получать результат
                                </span>
                                <span className="block text-[#919BA0] text-[18px] leading-[28px] font-normal text-center">
                                    Скачивайте файлы в личном кабинете<br/>или получайте на почту. Сохраним на<br/>ваш сервер, отправим результаты<br/>парсинга в телеграм или отдадим по API
                                </span>
                            </div>
                        </div>
                        <div className="bg-[#1B2126] pt-[100px] pb-20 px-20 rounded-[60px] w-fit flex flex-col items-center justify-between">
                            <FormatIcon />
                            <div className="flex flex-col items-center">
                                <span className="block text-white text-[24px] leading-[34px] font-medium text-center mb-10">
                                    Выбирайте<br/> подходящий формат
                                </span>
                                <span className="block text-[#919BA0] text-[18px] leading-[28px] font-normal text-center">
                                    Подготовим таблицы в удобном<br/>для вас формате: xlsx, csv, json<br/>или Google Таблицы
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:flex space-x-5 h-[650px]">
                        <div className="bg-[#1B2126] pt-[110px] pb-[70px] px-[70px] rounded-[60px] grow flex flex-col items-center justify-between">
                            <SecurityIcon />
                            <div className="flex flex-col items-center">
                                <span className="block text-white text-[24px] leading-[32px] font-medium text-center mb-5">
                                    Обходим защиты
                                </span>
                                <span className="block text-[#919BA0] text-[18px] leading-[28px] font-normal text-center">
                                    Обходим все существующие<br/>степени защиты от парсинга,<br/>включая капчу
                                </span>
                            </div>
                        </div>
                        <div className="bg-[#1B2126] pt-[110px] pb-[70px] px-[70px] rounded-[60px] w-[725px] flex flex-col items-center justify-between">
                            <ValuesIcon />
                            <div className="flex flex-col items-center">
                                <span className="block text-white text-[24px] leading-[32px] font-medium text-center mb-5">
                                    Любые объемы
                                </span>
                                <span className="block text-[#919BA0] text-[18px] leading-[28px] font-normal text-center">
                                    Ежедневно нащи парсеры делают более<br/>128 000 000 запросов. И если нужно мы<br/>сохраним для вас весь интернет
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:flex space-x-5">
                        <div className="bg-[#1B2126] p-[70px] rounded-[60px] grow flex justify-between space-x-[50px]">
                            <div className="grow space-y-5">
                                <span className="block text-white text-[24px] leading-[32px] font-medium">
                                    Гарантия<br/>достоверности
                                </span>
                                <span className="block text-[#919BA0] text-[18px] leading-[28px] font-normal">
                                    Мы ежедневно проверяем<br/>корректность данных и<br/>оперативно реагируем, если<br/>на сайтах вносятся изменения
                                </span>
                            </div>
                            <GarantsIcon />
                        </div>
                        <div className="bg-[#1B2126] p-[70px] rounded-[60px] grow flex justify-between space-x-[50px]">
                            <div className="grow space-y-5">
                                <span className="block text-white text-[24px] leading-[32px] font-medium">
                                    С заботой<br/>о ваших сайтах
                                </span>
                                <span className="block text-[#919BA0] text-[18px] leading-[28px] font-normal">
                                    Наш парсинг не нагружает<br/>сервера сайтов с которых<br/>собирается иниформация<br/>и не мешает их работе
                                </span>
                            </div>
                            <LikeIcon />
                        </div>
                    </div>
                    <div className="bg-[#1B2126] px-6 py-5 rounded-[20px] lg:hidden">
                        <div className="flex items-center justify-between">
                            <span className="block text-[14px] font-medium leading-[19px] text-[#FBFBFC]">
                                Удобно получать<br/>результат
                            </span>
                            <ResultsMobileIcon />
                        </div>
                        <span className="block mt-5 text-[12px] text-[#919BA0] leading-[18px] pr-[83px]">
                            Скачивайте файлы в личном кабинете или получайте на почту. Сохраним на ваш сервер, отправим результаты парсинга в телеграм или отдадим по API
                        </span>
                    </div>
                    <div className="bg-[#1B2126] px-6 py-5 rounded-[20px] lg:hidden">
                        <div className="flex items-center justify-between">
                            <span className="block text-[14px] font-medium leading-[19px] text-[#FBFBFC]">
                                Выбирайте<br/>подходящий<br/>формат
                            </span>
                            <FormatMobileIcon />
                        </div>
                        <span className="block mt-5 text-[12px] text-[#919BA0] leading-[18px] pr-[80px]">
                            Подготовим таблицы в удобном для вас формате: xlsx, csv, json или Google Таблицы
                        </span>
                    </div>
                    <div className="bg-[#1B2126] px-6 py-5 rounded-[20px] lg:hidden">
                        <div className="flex items-center justify-between">
                            <span className="block text-[14px] font-medium leading-[19px] text-[#FBFBFC]">
                                Обходим<br />защиты
                            </span>
                            <SecurityIcon width={"60"} height={"60"} />
                        </div>
                        <span className="block mt-5 text-[12px] text-[#919BA0] leading-[18px] pr-[80px]">
                            Обходим все существующие степени защиты от парсинга, включая капчу
                        </span>
                    </div>
                    <div className="bg-[#1B2126] px-6 py-5 rounded-[20px] lg:hidden">
                        <div className="flex items-center justify-between">
                            <span className="block text-[14px] font-medium leading-[19px] text-[#FBFBFC]">
                                Любые<br />объемы
                            </span>
                            <ValuesIcon width={"60"} height={"60"} />
                        </div>
                        <span className="block mt-5 text-[12px] text-[#919BA0] leading-[18px] pr-[80px]">
                            Ежедневно нащи парсеры делают более 128 000 000 запросов. И если нужно мы сохраним для вас весь интернет
                        </span>
                    </div>
                    <div className="bg-[#1B2126] px-6 py-5 rounded-[20px] lg:hidden">
                        <div className="flex items-center justify-between">
                            <span className="block text-[14px] font-medium leading-[19px] text-[#FBFBFC]">
                                Гарантия<br />достоверности
                            </span>
                            <GarantsIcon width={"60"} height={"60"} />
                        </div>
                        <span className="block mt-5 text-[12px] text-[#919BA0] leading-[18px] pr-[80px]">
                            Мы ежедневно проверяем корректность данных и оперативно реагируем, если на сайтах вносятся изменения
                        </span>
                    </div>
                    <div className="bg-[#1B2126] px-6 py-5 rounded-[20px] lg:hidden">
                        <div className="flex items-center justify-between">
                            <span className="block text-[14px] font-medium leading-[19px] text-[#FBFBFC]">
                                С заботой<br />о ваших сайтах
                            </span>
                            <LikeIcon width={"60"} height={"60"} />
                        </div>
                        <span className="block mt-5 text-[12px] text-[#919BA0] leading-[18px] pr-[80px]">
                            Наш парсинг не нагружает сервера сакйтов с которых собирается иниформация и не мешает их работе
                        </span>
                    </div>
                </div>
                <PrimaryButton className={"lg:mt-[60px] w-full lg:w-auto py-5 lg:py-8 lg:px-[106px]"} onClick={() => {
                    setShowConnectUsModal(true);
                    document.querySelector('body').style.overflow = 'hidden';
                    document.querySelector('body').classList.add('disabled-scroll');
                    document.querySelector('html').style.overflow = 'hidden';
                }}>
                    <span className="block text-[#FBFBFC] font-medium text-[18px] leading-[21px] lg:text-[60px] lg:leading-[124px] lg:-tracking-[3px]">
                        Расскажите о вашей задаче
                    </span>
                </PrimaryButton>
            </div>
        </div>
    )
}

export {ParsingBenefitsSection};