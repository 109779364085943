import {CrossIcon} from "../screenshots";
import {PrimaryButton} from "./PrimaryButton";
import React from 'react';
const CallbackModal = ({showCallbackModal, setShowCallbackModal}) => {
    if (!showCallbackModal) {
        return null;
    }
    const handleClose = () => {
        setShowCallbackModal(false)
        document.querySelector('body').style.overflow = '';
        document.querySelector('body').classList.remove('disabled-scroll');
        document.querySelector('html').style.overflow = '';
    }

    return (
        <div className={"flex flex-col fixed bg-[rgb(15,22,29,0.90)] w-screen h-screen left-0 z-40 items-center justify-end lg:justify-center overflow-hidden top-0"} /*style={{top: window && window.scrollY}}*/ onClick={handleClose}>
            <div className="fixed bottom-0 lg:relative w-full lg:w-[586px]">
                <div className="border-b" onClick={(e) => e.stopPropagation()}>
                    <div className={"flex justify-between px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center rounded-t-[12px] "}>
                        <span className="block text-[#0E141A] text-[18px] leading-[27px] lg:text-2xl lg:leading-[34px] font-semibold ">
                            Мы получили вашу заявку
                        </span>
                        <button onClick={handleClose}>
                            <CrossIcon />
                        </button>
                    </div>
                </div>
                <div className="w-full lg:w-[586px] h-fit bg-white p-5 lg:px-10 lg:py-[30px]" onClick={(e) => e.stopPropagation()}>
                    <span className="block text-[#0E141A] text-[16px] font-medium leading-[22px]">Свяжемся с вами <br className={"lg:hidden"}/>в течение рабочего дня</span>
                </div>
                <div className={"flex px-5 lg:px-10 py-4 lg:py-[30px] bg-white items-center lg:rounded-b-[12px] w-full lg:w-[586px]"} onClick={(e) => e.stopPropagation()}>
                    <PrimaryButton className="py-5 pl-8 pr-6 flex grow lg:grow-0 justify-center" onClick={handleClose}>
                        <span className="block text-white text-base font-semibold leading-[20px] mr-2.5">
                            Спасибо
                        </span>
                    </PrimaryButton>
                </div>
            </div>
        </div>
     )
}

export {CallbackModal};